define(
    'directives/contentConfigurator/contentSnippets/carouselSnippet/CarouselSnippet',[
        'app',
        'angular',
        'services/ContentBuilderHelper',
        'directives/contentConfigurator/contentSnippets/_snippet'
    ],
    function (app) {
        'use strict';
        app.directive('carouselSnippet', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'E',
                scope: {
                    metaIdentifier: '<',
                    snippet: "=",
                    position: "=",
                    parent: "<"
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/carouselSnippet/views/carouselSnippet.html",
                controller: ['$scope', '$controller', '$element', '$compile', '$timeout', 'ContentBuilderHelper',
                    function ($scope, $controller, $element, $compile, $timeout, ContentBuilderHelper) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/carouselSnippet/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customStyleFake = path + "styleFake.css";
                        $scope.customJs = path + "js.html";
                        $scope.includeVisibility = true;

                        var wrapper = $($element).find('[data-content-snippet="carousel"]');
                        var template = '<div class="carousel-container flexslider carousel"><ul class="slides"><li ng-repeat="item in snippet.config.items"><a ng-if="item.url" ng-href="{{item.url}}"><img ng-src="{{item.image}}" alt="{{item.title}}"/><span>{{item.title}}</span></a><div ng-if="!item.url"><img ng-src="{{item.image}}" alt="{{item.title}}" /><span>{{item.title}}</span></div></li></ul</div>';

                        angular.extend(this, $controller('snippetController', {
                            $scope: $scope
                        }));

                        var _init = function () {
                            $scope.snippetType = 'carousel';
                            $scope.elementType = $scope.ELEMENT_TYPE.SNIPPET;

                            if($scope.snippet.config === undefined) {
                                $scope.snippet.config = { items: [] };
                            }
                            ContentBuilderHelper.subscribeSizeChanged($scope, function (e, args) {
                                _refresh(); // refresh flexslider on fake viewport change
                            });
                            _refresh();
                        };
                        _init();
                        function _refresh() {
                            wrapper.find('.carousel-container').remove();
                            wrapper.append($compile(template)($scope));
                            $scope.includeVisibility = false;
                            $timeout(function () {
                                $scope.$apply();
                                $scope.includeVisibility = true;
                            }, 100);
                        }

                        $scope.$watch('snippet.config', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                _refresh();
                            }
                        }, true);

                    }]
            }
        }]);
    }
);

