/**
 * Created by nmayer on 24.05.16.
 */
define('services/AttributeService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('AttributeService', [
        '$injector',
        AttributeService
    ]);
});


function AttributeService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Attribute');

    angular.extend(this, crudInstance);

    var _findError = function (response) {
        console.error(response);
    };

    this.deleteOption = function (id) {
        var model = new $injector.get("ModelFactory").Attribute();

        return model.deleteOption({id: id}).$promise;
    };


    this.getTypes = function () {
        var model = new $injector.get("ModelFactory").Attribute();
        return model.getTypes().$promise.then(
            function (response) {
                attrTypes = response.data;
            }
        );
        return ref.promise;
    };

    this.getAttrTypes = function () {
        return attrTypes;
    };

    return this;
};
