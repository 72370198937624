define('services/BackupService',[
    'app',
    'modules/module'
], function (app, module) {
    'use strict';
    return module.factory('BackupService', [
        '$injector',
        'ModelFactory', 
        '$location',
        BackupService
    ]);
});

function BackupService($injector, ModelFactory, $location) {
    this.listBackup = () => {
        return ModelFactory.Backup().listBackup().$promise.then(function (response) {
            return response;
        });
    } 

    this.createBackup = () => {
        return ModelFactory.Backup().createBackup().$promise.then(function (response) {
            return response;
        });
    } 

    this.downloadBackup = (timestamp, index, item) => {
        return ModelFactory.Backup().downloadBackup({
                timestamp: timestamp
        }, function (response) {
            var fileType = response.headers['content-type'] + ';charset=utf-8';
            var blob = new Blob([response.data], { type: fileType });
            var objectUrl = window.URL || window.webkitURL;
            var link =  objectUrl.createObjectURL(blob);    
            item.fileUrl = link;
            item.disabled = false;
            console.log(item);
            setTimeout(()=> {
                console.log("#backup_" + index);
                document.getElementById("backup_" + index).setAttribute("href", link);
                document.getElementById("backup_" + index).click();
            }, 1000)
        });
    }
    
    return this;
}  ;
