define('modules/wawi/tag/controllers/TagFormController',[
    'modules/module',
    'modules/basemodule/controllers/EntityFormController',
    'services/ValidationService',
    'directives/languageForm/languageForm',
    'settings'
],
    function (module) {
        'use strict';
        module.controller('TagFormController', [
            '$scope',
            '$controller',
            'BASE_TEMPLATES_PATH',
            'ValidationService',
            TagFormController
        ]);
    }
);


function TagFormController($scope, $controller, BASE_TEMPLATES_PATH, ValidationService) {

    $scope.formTabs = [
        {
            label: 'Allgemein',
            template: BASE_TEMPLATES_PATH + '/wawi/tag/form-main.html',
            active: true
        }
    ];

    $scope.listState = 'root.tags';

    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

    $scope.init('Tag').then(function () {

    });

    $scope.validate = function () {
        return ValidationService.validate($scope.entity, {
            name: {
                method: "isset",
                fieldName: "Name",
                errorMsg: "Das Feld Name darf in der Standardsprache des Shops nicht leer sein",
                isLocaleValue: true
            }
        });
    };



};
