define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/documentation/documentation',[
        'app',
        'settings',
        'services/ContentBuilderHelper'
    ],
    function (app) {
        'use strict';
        app.directive('ngDocumentation', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        inputData: "<data",
                        stateEditing: "<"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/sectionSnippet/directives/documentation/views/index.html',
                    controller: ['$scope', '$timeout', '$element', '$compile', 'ContentBuilderHelper', function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/documentation/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customStyleFake = path + "styleFake.css";
                        $scope.customJs = path + "js.html";
                        $scope.customJs2 = path + "js2.html";
                        $scope.includeVisibility = true;

                        var wrapper = $($element).find('[data-content-block="documentation"]');

                        var template = '<div class="documentation__container"> <ul class="documentation__tabs nav nav-tabs"> <li ng-repeat="tab in data" ng-class="{\'active\': $first}"><a data-toggle="tab" ng-href="#{{uniqueId+($index+1)}}" ng-content-clickable><i class="fa fa-{{tab.icon}}"></i>{{tab.title}}</a> </li> </ul> <div class="tab-content"> <div ng-repeat="tab in data" ng-attr-id={{uniqueId+($index+1)}} class="documentation__content tab-pane" ng-class="{\'in active\': $first}"> <ul> <li ng-repeat="item in tab.items"> <div class="row row-no-padding"> <div class="col col-md-8"> <a ng-attr-href="{{getItemHrefValue(item)}}" ng-attr-data-poster="{{item.video ? item.image : undefined}}" class="documentation__file" ng-class="{\'required\' : item.required }" ng-attr-data-file-url="{{item.required ? \'r\' : \'\'}}{{item.file}}" ng-attr-data-html="{{getItemDataHtml(item)}}"> {{item.title}} </a> <p>{{item.descr}}</p> </div> <div class="col col-md-offset-3 col-md-1"> <img class="documentation__thumbnail" ng-src={{item.image}} alt="{{item.title}}"  ng-attr-data-poster="{{item.video ? item.image : undefined}}" ng-attr-data-html="{{getItemDataHtml(item)}}" /> </div> </div> </li> </ul> </div> </div> </div>';
                        //

                        $scope.getItemHrefValue = function (item) {
                           return !item.required && !item.video ? item.file : undefined;
                        }
                        $scope.getItemDataHtml = function (item) {
                          return item.video && !item.required ? '<video class="lg-video-object lg-html5" controls preload="none"><source src="' + item.file + '" type="video/mp4"> Your browser does not support HTML5 video. </video>' : undefined;
                        }
                        function _init() {
                            $scope.data = angular.merge({}, $scope.inputData);
                            $scope.uniqueId = Math.random().toString(36).substr(2, 9) + '-';
                            ContentBuilderHelper.subscribeSizeChanged($scope, function (e, args) {
                                _refresh(); // refresh flexslider on fake viewport change
                            });
                            _refresh();
                        }

                        _init();

                        function _refresh() {
                            try {
                                wrapper.documentation('destroy');
                            } catch (e) { }
                            wrapper.find('.documentation__container').remove();
                            wrapper.append($compile(template)($scope));
                            $scope.includeVisibility = false;
                            $timeout(function () {
                                $scope.$apply();
                                $scope.includeVisibility = true;
                            }, 100);
                        }

                        $scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                        $scope.$watch('stateEditing', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                    }]
                };
            }
        ]);
    }
);

