define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/customPageStyles/customPageStyles',[
        'app',
        'settings'
    ],
    function (app) {
        'use strict';
        app.directive('ngCustomPageStyles', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        inputData: "<data",
                        stateEditing: "<"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/sectionSnippet/directives/customPageStyles/views/index.html',
                    controller: ['$scope', '$timeout', '$element', function ($scope, $timeout, $element) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/customPageStyles/views/";

                        $scope.includeVisibility = true;

                        function _init() {
                            $scope.data = angular.merge({}, $scope.inputData);
                        };
                        _init();

                        $scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if(newValue !== oldValue) {
                                _init();
                            }
                        });
                        $scope.$watch('stateEditing', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _init();
                            }
                        });
                    }]
                };
            }
        ]);
    }
);

