define('modules/settings/importexportcsv/ImportExportCSVController',['modules/module',
        'services/ImportExportService'
    ],
    function (module) {
        'use strict';
        module.controller('ImportExportCSVController', ['$scope', 'ImportExportService', 'NotificationService', 'ShopService', 'LocaleService', 'ValidationService', 'BASE_TEMPLATES_PATH', '$window',
            function ($scope, ImportExportService, NotificationService, ShopService, LocaleService, ValidationService, BASE_TEMPLATES_PATH, $window) {
                $scope.object = {};

                $scope.object.productsCSV = undefined;
                $scope.object.refinementProductsCSV = undefined;
                $scope.object.imagesZip = undefined;
                $scope.object.recipientsCSV = undefined;

                $scope.object.importType = undefined;
                $scope.object.exportType = undefined;
                $scope.object.shopId = undefined;
                $scope.object.localeId = undefined;

                $scope.importTypes = [{"name": "Produkt"}, {"name": "Empfänger"}];
                $scope.exportTypes = [{"name": "Produkt"}, {"name": "Empfänger"}];

                $scope.formTabs = [
                    {
                        label: 'Import',
                        template: BASE_TEMPLATES_PATH + '/settings/importExport/import.html',
                        active: true
                    },
                    {
                        label: 'Export',
                        template: BASE_TEMPLATES_PATH + '/settings/importExport/export.html'
                    }
                ];

                $scope.object.exportShopId = undefined;
                $scope.object.exportLocaleId = undefined;
                $scope.selected = {};

                $scope.findMenuItemByName = function (name) {
                    for (var i = 0; i < $scope.formTabs.length; i++) {
                        if ($scope.formTabs[i].label === name) {
                            return $scope.formTabs[i];
                        }
                    }
                };

                $scope.changeTab = function (menuItem) {
                    if (typeof menuItem === 'string') {
                        menuItem = $scope.findMenuItemByName(menuItem);
                    }
                    if ($scope.selected.menuItem !== undefined) {
                        $scope.selected.menuItem.active = false;
                    }
                    menuItem.active = true;
                    $scope.selected.menuItem = menuItem;
                };

                $scope.initMenu = function () {
                    $scope.formTabs.forEach(function (menuItem) {
                        if (menuItem.active === true) {
                            $scope.selected.menuItem = menuItem;
                            $scope.selected.menuItem.active = true;
                        }
                    });
                };

                $scope.initMenu();

                ShopService.findAll().then(function () {
                    $scope.shops = ShopService.getAll(true);
                });

                LocaleService.findAll().then(function () {
                    $scope.locales = LocaleService.getAll(true);
                });

                $scope.transferCSV = function () {
                    if($scope.object.importType === 'Produkt') {
                        var validateConfig = {
                            shopId: {
                                method: "isset",
                                fieldName: "Shop",
                            },
                            localeId: {
                                method: "isset",
                                fieldName: "Shopsprache",
                            }
                        };

                        if (!ValidationService.validate($scope.object, validateConfig)) {
                            NotificationService.notifyValidationErrors(ValidationService.getErrors());
                            return;
                        }

                        ImportExportService.importCSV(
                            $scope.object.productsCSV,
                            $scope.object.refinementProductsCSV,
                            $scope.object.imagesZip,
                            $scope.object.importType,
                            $scope.object.shopId,
                            $scope.object.localeId
                        ).then(
                            function (data) {
                                if (data.validationErrors) {
                                    return;
                                }
                                NotificationService.notifySuccess({statusText: 'Import erfolgreich gestartet'});
                                $scope.object.productsCSV = undefined;
                                $scope.object.refinementProductsCSV = undefined;
                                $scope.object.imagesZip = undefined;
                                $scope.object.importType = undefined;
                                $scope.object.shopId = undefined;
                                $scope.object.localeId = undefined;
                            });
                    } else if($scope.object.importType === 'Empfänger') {
                        ImportExportService.importRecipientsCSV($scope.object.recipientsCSV)
                            .then(function (data) {
                                if (data.validationErrors) {
                                    return;
                                }
                                NotificationService.notifySuccess({statusText: 'Der Import war erfolgreich'});
                                $scope.object.recipientsCSV = undefined;
                            });
                    }
                };

                $scope.validateCSV = function () {
                    var validateConfig = {
                        shopId: {
                            method: "isset",
                            fieldName: "Shop",
                        },
                        localeId: {
                            method: "isset",
                            fieldName: "Shopsprache",
                        }
                    };

                    if (!ValidationService.validate($scope.object, validateConfig)) {
                        NotificationService.notifyValidationErrors(ValidationService.getErrors());
                        return;
                    }

                    ImportExportService.validateCSV(
                        $scope.object.productsCSV,
                        $scope.object.refinementProductsCSV,
                        $scope.object.imagesZip,
                        $scope.object.importType,
                        $scope.object.shopId,
                        $scope.object.localeId
                    ).then(
                        function (data) {
                            if (data.validationErrors) {
                                return;
                            }
                            NotificationService.notifySuccess({statusText: 'Testlauf war erfolgreich'});
                        });
                };

                $scope.exportCSV = function () {
                    if($scope.object.exportType === 'Produkt') {
                        var validateConfig = {
                            exportShopId: {
                                method: "isset",
                                fieldName: "Shop",
                            },
                            exportLocaleId: {
                                method: "isset",
                                fieldName: "Shopsprache",
                            }
                        };

                        if (!ValidationService.validate($scope.object, validateConfig)) {
                            NotificationService.notifyValidationErrors(ValidationService.getErrors());
                            return;
                        }

                        $window.location.href = "/api/v1/core/import-export/export-csv?shopId=" + $scope.object.exportShopId
                            + "&localeId=" + $scope.object.exportLocaleId;
                        $scope.object.exportShopId = undefined;
                        $scope.object.exportLocaleId = undefined;
                    } else if($scope.object.exportType === 'Empfänger') {
                        $window.location.href = "/api/v1/core/import-export/export-recipients-csv";
                    }
                }
            }
        ]);
    }
);

