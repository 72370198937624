define('services/OrderService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('OrderService', ['$injector',
        function ($injector) {
            var crudInstance = $injector.instantiate(CrudService);
            crudInstance.setType('Order');
            angular.extend(this, crudInstance);

            this.reorder = function (shopOrder) {
                return new $injector.get("ModelFactory").ShoppingCart().reorder({shopOrderId: shopOrder.id}).$promise;
            };

            this.sendEmail = function (shopOrder, orderState, comment) {
                return new $injector.get("ModelFactory").ShopOrder().sendEmail(
                    {
                        orderMultipleStatesOrderId: shopOrder.id,
                        orderMultipleStatesOrderStateId: orderState.id,
                        comment: comment || null
                    },
                    shopOrder
                ).$promise;
            };

            this.cancel = function (cancellation) {
                return new $injector.get("ModelFactory").Order().cancel(cancellation).$promise;
            };

            return this;
        }]);
});
