define('services/MetadataService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('MetadataService', [
        '$injector',
        MetadataService
    ]);
});

function MetadataService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('MetaData');

    angular.extend(this, crudInstance);
    var values = null;
    this.findValues = function (metadataId) {
        var model = new $injector.get("ModelFactory").Metadata();
        return model.findValues({id: metadataId}).$promise.then(function (data) {
            values = data;
        }, function () {
            console.error("Error in findValues (MetadataService)");
        });
    };
    this.getValues = function(){
        return values;
    };

    this.updateAll = function (items) {
        var promise = $injector.get("ModelFactory")["MetaData"]().updateAll(items).$promise;
        return promise;
    };

    this.updatePosition = function (item) {
        var promise = $injector.get("ModelFactory")["MetaData"]().updatePosition(item).$promise;
        return promise;
    };


    return this;
};
