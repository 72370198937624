define(
    'directives/contentConfigurator/contentSnippets/textSnippet/TextSnippetConfig',[
        'app',
        'angular',
        'services/ContentBuilderHelper',
        'directives/contentConfigurator/contentSnippets/_snippet'
    ],
    function (app) {
        'use strict';
        app.directive('textSnippetConfig', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'E',
                scope: {
                    metaIdentifier: '<',
                    elementIdentifier: '<',
                    element: '='
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/textSnippet/views/textSnippetConfig.html",
                controller: ['$scope', '$controller', 'ContentBuilderHelper',
                    function ($scope, $controller, ContentBuilderHelper) {

                        angular.extend(this, $controller('snippetConfigController', {
                            $scope: $scope
                        }));

                        $scope.options.title = 'Text';
                        $scope.options.description = 'Text snippet which can be H1, H2, H3, P tag, with link, icon and custom classes';

                        $scope.types = [
                            { id: 'p', name: 'Paragraph' },
                            { id: 'h1', name: 'Header 1' },
                            { id: 'h2', name: 'Header 2' },
                            { id: 'h3', name: 'Header 3' }
                        ];
                        $scope.iconPositions = [
                            { id: 'left', name: 'Left' },
                            { id: 'right', name: 'Right' }
                        ];

                        $scope.config = {
                            type: undefined,
                            classes: undefined,
                            link: undefined,
                            link_target_blank: false,
                            icon: undefined,
                            iconPosition: undefined
                        }

                        $scope.previousElementIdentifier = undefined;
                        var _init = function () {
                            if (typeof $scope.element.config !== "undefined") {
                                $scope.config = $scope.element.config;
                            }
                        };

                        // Track elementIdentifier change for cases when you switch between different sections, directive stays the same only data is updated
                        $scope.$watch('elementIdentifier', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                if (typeof $scope.element.config !== "undefined") {
                                    $scope.config = $scope.element.config;
                                }
                            }
                        });
                        $scope.$watch('config', function (newValue, oldValue) {
                            // Do not propagate config change on first load or when switching to different snippet of same type (section)
                            if ($scope.previousElementIdentifier !== $scope.elementIdentifier) {
                                $scope.previousElementIdentifier = $scope.elementIdentifier;
                            } else {
                                if (newValue === undefined && newValue === oldValue) {
                                    return;
                                }
                                $scope.element.config = { // this will trigger section directive watchers
                                    type: $scope.config.type,
                                    classes: $scope.config.classes,
                                    link: $scope.config.link,
                                    link_target_blank: $scope.config.link_target_blank,
                                    icon: $scope.config.icon,
                                    iconPosition: $scope.config.iconPosition
                                };
                            }


                        }, true);
                        _init();

                    }]
                }
            }]);
        }
        );


