define('modules/cms/controllers/ProductRatingController',[
        'modules/module',
        'modules/basemodule/controllers/EntityListController',
    ],
    function (module) {
        'use strict';
        module.controller('ProductRatingController', [
            '$scope',
            '$controller',
            'BASE_TEMPLATES_PATH',
            ProductRatingController
        ]);
    }
);

function ProductRatingController ($scope, $controller, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('EntityListController', {
        $scope: $scope
    }));

    $scope.tableOptions.fields = [
        {
            id: 'PRODUCT_SKU',
            heading: 'SKU',
            attribute: 'productSku',
            searchDisabled: true,
            orderDisabled: true
        },
        {
            id: 'CUSTOMER_FULL_NAME',
            heading: 'Customer',
            attribute: 'customerFullName',
            searchDisabled: true,
            orderDisabled: true
        },
        {
            id: 'RATING',
            heading:        'Rating',
            attribute:      'rating'
        },
        {
            id: 'COMMENT',
            heading:        'Comment',
            template: BASE_TEMPLATES_PATH + '/cms/productRating/comment-col.html',
            attribute:      'comment'
        },
        {
            id: 'STATUS',
            heading:        'Status',
            attribute:      'publishState'
        },
        {
            id: 'DATE',
            heading:        'Datum',
            template: BASE_TEMPLATES_PATH + '/cms/productRating/rating-date-col.html',
            attribute:      'createdAt',
            searchDisabled: true
        },
    ];

    $scope.formState = 'root.productRating';

    $scope.init('ProductRating');
};
