/**
 * Created by Nico on 26.07.2016.
 */
define('modules/wawi/refinementProduct/RefinementProductListController',[
    'modules/module',
    'modules/basemodule/controllers/EntityListController',
    'settings'
],
    function (module) {
        'use strict';
        module.controller('RefinementProductListController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.tableOptions.fields = [
                    {
                        id: 'NAME',
                        heading: 'Name',
                        attribute: 'name'
                    },
                    {
                        id: 'DESCRIPTION',
                        heading: 'Beschreibung',
                        attribute: 'description'
                    },
                    {
                        id: 'COMPANY',
                        heading: 'Hersteller',
                        attribute: 'companyName'
                    },
                    {
                        id: 'MAXCOLORS',
                        heading: 'Farben',
                        attribute: 'maxColors',
                        searchDisabled: true,
                        orderDisabled: true
                    },
                    {
                        id: 'MAXSIZE',
                        heading: 'Größe',
                        attribute: 'maxSize',
                        searchDisabled: true,
                        orderDisabled: true

                    }/*,
                    {
                        id: 'DATE',
                        heading: 'Datum',
                        template: BASE_TEMPLATES_PATH + '/wawi/seminar/column/date-col.html',
                        attribute: 'startDate'
                    }*/
                ];

                $scope.formState = 'root.refinementProduct';

                $scope.init('RefinementProduct');
            }
        ]);
    }
);

