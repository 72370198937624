define('services/CmsTagUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('CmsTagUnpagedService', [
        '$injector',
        CmsTagUnpagedService
    ]);
});


function CmsTagUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('CmsTag');

    angular.extend(this, crudInstance);

    return this;
};
