define('services/ToolboxService',[
    'angular',
    'modules/module'
], function (angular, module) {
    return module.factory('Toolbox', ['$state', function($state) {

        return {
                pick: function(array, obj) {
                    var result = true;

                    for(var i=0; i<array.length; i++) {
                        angular.forEach(obj, function(value, key) {
                            if(array[i][key] != value) {
                                result = false;
                            }
                        }, log);

                        if(result === true) {
                            return obj;
                        }
                    }
                }                 
            }
    }]);
});
