define('modules/econ/controllers/EconDashboardController',[
        'modules/module',
        'services/EconRecipientService',
        'services/EconRecipientListService',
        'services/EconStatisticsService'
    ],
    function (module) {
        'use strict';
        module.controller('EconDashboardController', [
            '$scope',
            '$rootScope',
            '$window',
            'EconStatisticsService',
            '$sce',
            '$timeout',
            '$location',
            'BASE_TEMPLATES_PATH',
            EconDashboardController
        ]);
    }
);

function EconDashboardController($scope, $rootScope, $window, EconStatisticsService, $sce, $timeout, $location, BASE_TEMPLATES_PATH) {

    $scope.diagrammColors = ['#95c11f', '#425217', '#a5cd39', '#6b8525', '#b7d761', '#29330e', '#c9e188', '#8cae31', '#e0eeba', '#f2f8e1'];

    $scope.date = {
        start: new Date(new Date().setDate(new Date().getDate() - 30)),
        end: new Date()
    };


    $scope.dateOptions = [{
        name: "last 30 days",
        date: {
            start: new Date(new Date().setDate(new Date().getDate() - 30)),
            end: new Date()
        }
    },
        {
            name: "last 60 days",
            date: {
                start: new Date(new Date().setDate(new Date().getDate() - 60)),
                end: new Date()
            }
        },
        {
            name: "last 3 months",
            date: {
                start: new Date(new Date().setDate(new Date().getDate() - 91)),
                end: new Date()
            }
        },
        {
            name: "last 6 months",
            date: {
                start: new Date(new Date().setDate(new Date().getDate() - 182)),
                end: new Date()
            }
        },
        {
            name: "last 12 months",
            date: {
                start: new Date(new Date().setDate(new Date().getDate() - 365)),
                end: new Date()
            }
        },
    ];

    $scope.dateDaysChange = function() {
        $scope.date = $scope.selectedDate.date;
        $rootScope.$emit('getStatistic', $scope.date);
    }

    $scope.selectedDate = $scope.dateOptions[0];


    $scope.getStatistic = function (date) {

        $scope.newsletterRecipientListLabels = [];
        $scope.newsletterRecipientListValues = [[], [], []];

        $scope.subscribeUnsubscribeLabels = [];
        $scope.subscribeUnsubscribeValues = [[], []];

        $scope.newsletterClickRateValues = [];
        $scope.newsletterClickRateLabels = [];
        $scope.newsletterClickRateSeries = [];

        $scope.amountOpenedValues = [[], []];
        $scope.amountOpenedLabels = [];
        $scope.amountOpenedSeries = [];

        $scope.deviceData = [];
        $scope.deviceLabels = [];
        $scope.originData = [];
        $scope.originLabels = [];

        $scope.selectedRegions = [];

        if(date == undefined) {
            date = {
                start: new Date(new Date().setDate(new Date().getDate()-30)),
                end: new Date()
            };
        }
        var monthStart = date.start.getMonth()+1;
        var monthEnd = date.end.getMonth()+1;

        var dateObj = {};
        dateObj.start = date.start.getFullYear() + '-' + monthStart + '-' + date.start.getDate();
        dateObj.end = date.end.getFullYear() + '-' + monthEnd + '-' + date.end.getDate();

        $scope.date = date;

        EconStatisticsService.setAdditionalParams([{name: "sufrom", value: dateObj.start}, {name: "suto", value: dateObj.end}]);
        EconStatisticsService.findAll(true).then(function () {
            $scope.statistics = EconStatisticsService.getAll();


            if ($scope.statistics.allActionStatistics != undefined) {
                $scope.statistics.allActionStatistics.name = 'Alle Kampagnen';
            }
            $scope.actionStatisticsWithAll = [];
            $scope.actionStatisticsWithAll.push($scope.statistics.allActionStatistics);
            $scope.statistics.actionStatistics.forEach(function (actionStatistic) {
                $scope.actionStatisticsWithAll.push(actionStatistic);
            });

            /*ORIGIN*/
            $scope.actionStatisticsWithAll.forEach(function (actionStatistic) {
                if ($scope.selectedActionOrigin == null) {
                    $scope.selectedActionOrigin = actionStatistic;
                    $scope.setOriginDashboard();
                }
            });
            $scope.originOptions = {
                legend: {
                    display: true
                }
            };

            /*DEVICE*/
            $scope.actionStatisticsWithAll.forEach(function (actionStatistic) {
                if ($scope.selectedActionDevice == null) {
                    $scope.selectedActionDevice = actionStatistic;
                    $scope.setDevicesDashboard();
                }
            });

            $scope.deviceOptions = {
                legend: {
                    display: true
                }
            };

            /*AMOUNT OPENED*/
            $scope.amountOpenedSeries = ['Geöffnet', 'Ungeöffnet'];
            $scope.statistics.actionStatistics.forEach(function (actionStatistic) {
                $scope.amountOpenedValues[0].push(actionStatistic.amountOpened);
                $scope.amountOpenedValues[1].push(actionStatistic.amountSent - actionStatistic.amountOpened);
                $scope.amountOpenedLabels.push(actionStatistic.name);
            });

            $scope.amountOpenedOptions = {
                legend: {
                    display: true
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        barThickness: 40,
                        ticks: {
                            autoSkip: false
                        }
                    }],
                    yAxes: [{
                        barThickness: 40,
                        ticks: {
                            autoSkip: false
                        }
                    }]
                }
            };

            /*ACTION STATISTICS*/
            $scope.newsletterClickRateSeries = ['Klicks'];
            $scope.statistics.actionStatistics.forEach(function (actionStatistic) {
                if ($scope.selectedAction == null) {
                    $scope.selectedAction = actionStatistic;
                    $scope.setClickRateDashboard();
                }
            });

            $scope.newsletterClickRateOptions = {
                tooltips: {
                    enabled: true,
                    mode: 'label',
                    backgroundColor: '#e6e6e6',
                    bodyFontColor: '#000',
                    titleFontColor: '#000',
                    footerFontColor: '#000',
                    titleFontStyle: 'normal',
                    footerFontStyle: 'normal',
                    custom: function (tooltip) {

                    },
                    callbacks: {
                        title: function (tooltipItem, data) {
                            var amountClick = $scope.selectedAction.amountClicks[tooltipItem[0].index];
                            var title = amountClick.html;
                            if (title.charAt(0) == '<') {
                                if (title.indexOf('alt=') == -1) {
                                    title = ''
                                } else {
                                    title = title.substring(title.indexOf('alt=') + 5, title.indexOf('"', title.indexOf('alt=') + 5));
                                }
                            }

                            return "Bezeichnung:  " + title;
                        },
                        beforeBody: function (tooltipItem, data) {

                        },
                        label: function (tooltipItem, data) {
                            /*var amountClick = $scope.selectedAction.amountClicks[tooltipItem.index];
                             var urlParts = amountClick.url.split("/");
                             if (urlParts[3]) {
                             var urlPart = urlParts[3];
                             var start = amountClick.url.indexOf(urlPart);
                             urlPart = amountClick.url.substring(start, amountClick.url.length);
                             if (urlPart.length > 90) {
                             urlPart = urlPart.substring(0, 89) + "...";
                             }
                             return ".../" + urlPart;
                             }*/
                            var amountClick = $scope.selectedAction.amountClicks[tooltipItem.index];
                            return "Klickanzahl:     " + amountClick.clicks + " Klicks"
                        },
                        afterLabel: function (tooltipItem, data) {

                        },
                        footer: function (tooltipItem, data) {
                            return "Link folgen:      Auf Balken Klicken";
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            min: 0,
                            stepSize: 1,
                            display: false
                        },
                        barThickness: 20,
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Klickanzahl auf Links'
                        }
                    }],
                    yAxes: [{
                        barThickness: 20,
                        ticks: {
                            display: false
                        },
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Geklickte Links'
                        }
                    }]
                }
            };


            /*RECIPIENTLISTS*/
            $scope.newsletterRecipientListSeries = ['Aktiv', 'Opt In nicht bestätigt', 'Abgemeldet'];
            $scope.statistics.recipientListStatistics.recipientLists.forEach(function (recipientList) {
                $scope.newsletterRecipientListLabels.push(recipientList.name);
                $scope.newsletterRecipientListValues[0].push(recipientList.activeAmount);
                $scope.newsletterRecipientListValues[1].push(recipientList.optInNotConfirmedAmount);
                $scope.newsletterRecipientListValues[2].push(recipientList.disabledAmount);
            });

            $scope.newsletterRecipientListOptions = {
                legend: {
                    display: true
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        barThickness: 40,
                        ticks: {
                            autoSkip: false
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        barThickness: 40,
                        ticks: {
                            autoSkip: false
                        }
                    }]
                }
            };


            /*SUBSCRIBE -- UNSUBSCRIBE*/
            var tmpDate;
            var tmpMonth;
            $scope.statistics.subscriptionsUnsubscriptions.forEach(function (item) {
                tmpDate = new Date(item.date);
                tmpMonth = tmpDate.getMonth() + 1;
                $scope.subscribeUnsubscribeLabels.push(tmpDate.getDate() + '.' + tmpMonth + '.' + tmpDate.getFullYear());
                $scope.subscribeUnsubscribeValues[0].push(item.subscribeAmount);
                $scope.subscribeUnsubscribeValues[1].push(item.unsubscribeAmount);
            });

            $scope.subscribeUnsubscribeSeries = ['Anmeldungen', 'Abmeldungen'];

            $scope.subscribeUnsubscribeOptions = {
                legend: {
                    display: true
                }
            };
        });
    };

    $scope.getStatistic();

    $rootScope.$on('getStatistic', function(event, data){
        $scope.getStatistic(data);
    });

    $scope.setClickRateDashboard = function () {
        $scope.newsletterClickRateValues = [];
        $scope.newsletterClickRateLabels = [];

        if ($scope.selectedAction) {
            $scope.selectedAction.amountClicks.forEach(function (amountClick) {
                $scope.newsletterClickRateValues.push(amountClick.clicks);
                $scope.newsletterClickRateLabels.push("Link");
            });
        }
    };

    $scope.actionChanged = function () {
        $scope.setClickRateDashboard();
    };

    $scope.newsletterClickRateOnClick = function (item) {
        if (item[0] != undefined) {
            var index = item[0]._index;
            if ($scope.selectedAction != undefined) {
                var amountClick = $scope.selectedAction.amountClicks[index];
                
                EconStatisticsService.getTrackedClicksByTrackedLinkId(amountClick.trackedLinkId).then(function(trackedClicks) {
                    $scope.showNewsletterClickRatesModal({
                        amountClick: amountClick,
                        selectedAction: $scope.selectedAction,
                        trackedClicks: trackedClicks
                    });
                });
            }
        }
    };

    $scope.newsletterClickRateOnHover = function (item) {
        if (item[0] != undefined) {
            var index = item[0]._index;
            var amountClick = $scope.selectedAction.amountClicks[index];
            var position = jQuery("#click-rates-chart").position();
            jQuery("#click-rate-tooltip").remove();
            jQuery("#right-box").append("<div style='top:" + (position.top + 50) + "px;left:" + (position.left + 785) + "px;' id='click-rate-tooltip' class='click-rate-tooltip'><h3>Linkinhalt</h3>" + amountClick.html + "</div>");
            jQuery("#click-rates-chart").mouseout(function () {
                jQuery("#click-rate-tooltip").remove();
            });
        }
    };

    $scope.setDevicesDashboard = function () {
        $scope.deviceValues = [];
        $scope.deviceLabels = [];

        var amountDevices = 0;
        if ($scope.selectedActionDevice != undefined) {
            $scope.selectedActionDevice.amountPlatformTypes.forEach(function (device) {
                amountDevices += device.amount;
            });

            $scope.selectedActionDevice.amountPlatformTypes.forEach(function (type) {
                $scope.deviceValues.push(Math.round((type.amount / amountDevices * 100 * 100) / 100));
                $scope.deviceLabels.push(type.name);
            });
        }
    };

    $scope.actionChangedDevices = function () {
        $scope.setDevicesDashboard();
    };

    $scope.setOriginDashboard = function () {
        $scope.originValues = [];
        $scope.originLabels = [];

        var amountOrigins = 0;
        if ($scope.selectedActionOrigin != undefined) {
            $scope.selectedActionOrigin.amountCountries.forEach(function (origin) {
                amountOrigins += origin.amount;
            });

            $scope.selectedActionOrigin.amountCountries.forEach(function (country) {
                $scope.originValues.push(Math.round((country.amount / amountOrigins * 100 * 100) / 100));
                $scope.originLabels.push(country.name);
            });
        }
    };

    $scope.actionChangedOrigin = function () {
        $scope.setOriginDashboard();
    };

    $scope.setRegions = function (item) {
        if (item[0] != undefined) {
            var origin = item[0]._model.label;
            for (var i = 0; i < $scope.selectedActionOrigin.amountCountries.length; i++) {
                if ($scope.selectedActionOrigin.amountCountries[i].name == origin) {
                    $scope.selectedRegions = $scope.selectedActionOrigin.amountCountries[i].regions;
                    $scope.selectedRegions.sort(function (a, b) {
                        return b.amount - a.amount
                    });
                    $scope.$apply();
                    break;
                }
            }
        }
    };

    /*
     Modal Dialog functions
     */
    $scope.onSuccess = function (result) {
    };

    $scope.onCancel = function () {};

    $scope.dialogDashboardController = function ($scope, dataToPass, $mdDialog, $rootScope) {

        $scope.date = {
            start: dataToPass.start,
            end: dataToPass.end
        };

        $scope.data = dataToPass;
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.getStatistic = function () {
            $rootScope.$emit('getStatistic', $scope.date);
            $rootScope.$emit('closeModal');
        };


    };

    $scope.newsletterClickRatesModalController = function ($scope, dataToPass, $mdDialog, $rootScope) {
        $scope.data = dataToPass;

        $scope.trackedClicksTableFields = [{
            id: 'TIME_CLICKED',
            heading: 'Klickzeit',
            attribute: 'createdAt',
            filterDisabled: true,
            orderDisabled: true,
            suggestEnabled: true
        },{
            id: 'EMAIL',
            heading: 'E-Mail',
            attribute: 'recipientDTO.email',
            template: BASE_TEMPLATES_PATH + '/econ/col/tracked-click/recipient-email-col.html',
            filterDisabled: true,
            orderDisabled: true,
            suggestEnabled: false
        },{
            id: 'FIRST_NAME',
            heading: 'Vorname',
            attribute: 'recipientDTO.firstName',
            template: BASE_TEMPLATES_PATH + '/econ/col/tracked-click/recipient-first-name-col.html',
            filterDisabled: true,
            orderDisabled: true,
            suggestEnabled: false
        },{
            id: 'LAST_NAME',
            heading: 'Nachname',
            attribute: 'recipientDTO.lastName',
            template: BASE_TEMPLATES_PATH + '/econ/col/tracked-click/recipient-last-name-col.html',
            filterDisabled: true,
            orderDisabled: true,
            suggestEnabled: false
        }];
        
        $scope.onTrackedClickRowClick = function(trackedClick) {
            $location.path("/econ/recipient/" + trackedClick.recipientDTO.id);
            $scope.cancel();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    };
}


;
