define('services/ImageService',[
    'app',
    'modules/module',
    'services/NotificationService',
    'modules/models',
    'services/EntityCrudService',
    'services/SessionValuesService',
], function (app, module) {
    'use strict';
        return module.factory('ImageService', ['$q', '$rootScope', '$cookies', '$injector', '$timeout', 'NotificationService', 'SessionValuesService',
            function ($q, $rootScope, $cookies, $injector, $timeout, NotificationService, SessionValuesService) {

        var crudInstance = $injector.instantiate(CrudService);
        angular.extend(this, crudInstance);
        this.setType('Image');

        var _images = [],
            _directories = [],
            _imageAttributes = {},
            _image,
            _totalPages,
            _totalItems,
            _fileUploader;

        if($cookies.get('_pageSize') === undefined){
            $cookies.put('_pageSize', 10);
        }

        if($cookies.get('_currentPage') === undefined){
            $cookies.put('_currentPage', 0);
        }

        var initFileUploader = function (uploader) {
            _fileUploader = uploader;
            _fileUploader.filters.push({
                name: 'imageFilter',
                fn: function(item, options) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    return '|jpg|png|jpeg|bmp|gif|svg|svg+xml|'.indexOf(type) !== -1;
                }
            });
            _fileUploader.onBeforeUploadItem = function (item) {
                item.formData.push({description: item.description});
            }
        };

        var loadImages = function (id) {
            var ref = $q.defer();
            $injector.get("ModelFactory").Image().findDirectory({id: id}).$promise.then(function(response){
                _images = response.files;
                ref.resolve();
                $rootScope.$broadcast('imagesChanges', _images);
            }, NotificationService.notifyRequestError);
            return ref.promise;
        };

        var _loadDirectories = function(page, pageSize, directoryId, query) {
            var ref = $q.defer();
            var params = {page: page, size: pageSize, directoryId: directoryId, query: query};
            if(!directoryId){
                delete params.directoryId;
            }
            if(!query){
                delete params.query;
            }

            $injector.get("ModelFactory").Image().findAll(params).$promise.then(function(response){
                _directories = response.children;
                _images = response.files;
                ref.resolve(response);
            }, NotificationService.notifyRequestError);
            return ref.promise;
        };

        var saveImage = function (image) {
            var ref = $q.defer();
            $injector.get("ModelFactory").Image().save(image).$promise.then(function(response) {
                _image = response;
                ref.resolve();
            }, NotificationService.notifyRequestError);
            return ref.promise;
        };

        var uploadItem = function (item) {
            item.onSuccess = function (response) {
                $rootScope.$broadcast('imageUploaded', response);
            };
            item.headers = SessionValuesService.getFullSessionHeader();
            $timeout(item.upload(),1);
        };

        this.initFileUpload = function (uploader) {
            initFileUploader(uploader);
            return _fileUploader;
        };

        this.getImages = function(){
            return _images;
        };

        this.getTotalItems = function () {
            return _totalItems;
        };

        this.getImage = function() {
            return _image;
        };

        this.loadImage = function(id) {
            var ref = $q.defer();
            $injector.get("ModelFactory").Image().findOne({id: id}).$promise.then(function(response) {
                _image = response;
                ref.resolve();
            }, NotificationService.notifyRequestError);
            return ref.promise;
        };

        this.deleteImage = function (id) {
            var ref = $q.defer();
            $injector.get("ModelFactory").Image().delete({id: id}).$promise.then(function(response){
                _image = undefined;
                ref.resolve();
            }, NotificationService.notifyRequestError);
            return ref.promise;
        };

        this.uploadImage = function (item) {
            uploadItem(item);
        };

        this.uploadMultipleImages = function (items) {
            items.forEach(function(item) {
                uploadItem(item);
            })
        };

        this.getPageSize = function () {
            return $cookies.get('_pageSize');
        };

        this.getTotalPages = function () {
            return _totalPages;
        };

        this.getTotalItems = function () {
            return _totalItems;
        };

        this.loadImages = function(id){
            return loadImages(id);
        };

        this.saveImage = function(image) {
            return saveImage(image);
        };

        this.loadDirectories = function(page, pageSize, directoryId, query) {
            return _loadDirectories(page, pageSize, directoryId, query);
        };

        this.getDirectories = function() {
            return _directories
        };

        return this;
    }]);
});

