/**
 * Created by Nico on 04.08.2016.
 */
define('services/ImExMappingPropertyService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('ImExMappingPropertyService', [
        '$injector',
        ImExMappingPropertyService
    ]);
});


function ImExMappingPropertyService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('ImExMappingProperty');

    angular.extend(this, crudInstance);

    return this;
};
