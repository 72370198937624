define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/popoverPinpointList/popoverPinpointList',[
        'app',
        'settings'
    ],
    function (app) {
        'use strict';
        app.directive('ngPopoverPinpointList', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        inputData: "<data",
                        stateEditing: "<"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/popoverPinpointList/views/index.html",
                    controller: ['$scope', '$timeout', '$element', function ($scope, $timeout, $element) {

                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/popoverPinpointList/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customStyleFake = path + "styleFake.css";
                        $scope.customJs = path + "js.html";
                        $scope.includeVisibility = true;

                        function _init() {
                            $scope.data = angular.merge([], $scope.inputData);
                        };
                        _init();

                        $scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                _init();
                                $($element).find('[data-content-block="popover-pinpoint-list"]').popoverPinpointList('destroy');
                                $scope.includeVisibility = false;
                                $timeout(function () {
                                    $scope.includeVisibility = true;
                                }, 100);
                            }
                        });
                    }]
                };
            }
        ]);
    }
);


/* sample data
[
    {
        "image": "/asset/images/content/map_pinpoint/map.png",
        "caption": "Lorem Ipsum 1",
        "subcaption": "Lorem sub ipsum 2",
        "pins": [
            {
            "top": "37.8%",
            "left": "56%",
            "html": "<h3>Deutschland</h3><p><span>KRUPS Fördersysteme GmbH</span><br/><span>Industriegebiet Urbacher Wald Ringstr. 13</span><br/><span>56307 Dernbach</span><br/><span>D - Germany</span><br/><br/><span>Telefon: +49(2689)9435 - 0</span><br/><span>Fax: +49(2689)9435 - 35</span><br/><br/><span>info@krups-online.de</span><br/><span>www.krups-online.de</span><br/></p>"
            },
            {
                "top": "49.8%",
                "left": "25.3%",
                "html": "<h3>Deutschland</h3><p><span>KRUPS Fördersysteme GmbH</span><br/><span>Industriegebiet Urbacher Wald Ringstr. 13</span><br/><span>56307 Dernbach</span><br/><span>D - Germany</span><br/><br/><span>Telefon: +49(2689)9435 - 0</span><br/><span>Fax: +49(2689)9435 - 35</span><br/><br/><span>info@krups-online.de</span><br/><span>www.krups-online.de</span><br/></p>"
            }
        ]
    },
    {
        "image": "/asset/images/content/map_pinpoint/map2.png",
        "caption": "Lorem Ipsum 2",
        "subcaption": "Lorem sub ipsum 2",
        "pins": [
            {
            "top": "13.8%",
            "left": "30%",
            "html": "<h3>Lorem Ipsum 2 First Point</h3><p><span>KRUPS Fördersysteme GmbH</span><br/><span>Industriegebiet Urbacher Wald Ringstr. 13</span><br/><span>56307 Dernbach</span></p>"
            },
            {
                "top": "76%",
                "left": "32%",
                "html": "<h3>Lorem Ipsum 2 Second Point</h3><p><span>KRUPS Fördersysteme GmbH</span><br/><span>Industriegebiet Urbacher Wald Ringstr. 13</span><br/><span>56307 Dernbach</span><br/><span>D - Germany</span></p>"
            }
        ]
    }
]
*/
;
