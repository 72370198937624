define(
  'directives/contentConfigurator/contentRow/ContentRowConfig',[
    'app',
    'angular',
    'services/ContentBuilderHelper',
    'directives/contentConfigurator/contentSnippets/_snippet'
  ],
  function (app) {
    'use strict';
    app.directive('contentRowConfig', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
      return {
        restrict: 'E',
        scope: {
          metaIdentifier: '<',
          elementIdentifier: '<',
          // snippet: "=",
          element: '='
        },
        templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentRow/views/contentRowConfig.html",
        controller: ['$scope', '$controller', 'ContentBuilderHelper',
          function ($scope, $controller, ContentBuilderHelper) {

            /*angular.extend(this, $controller('snippetController', {
                $scope: $scope
            }));*/

            var tab = 0;

            $scope.tab = function (id) {
              return tab === id;
            };

            $scope.setTab = function (id) {
              tab = id;
            };

            $scope.onChangedWrapMode = function (pos) {
              $scope.onChangedResponsiveSync(pos, false);
              ContentBuilderHelper.setViewportSetup(ContentBuilderHelper.getViewportSetupId());
            };

            $scope.onChangedFlowDirection = function (view) {
              if ($scope.element && $scope.element.flowDirection) {
                $scope.element.flowDirection[view] = $scope.element.flowDirection[view] === 'row' ? 'row-reverse' : 'row';
              }
            };

            $scope.onChangedResponsiveSync = function (pos, apply) {
              var master = '';

              switch (pos) {
                case 0: {
                  master = $scope.element.responsive['DESKTOP'];
                  break;
                }
                case 1: {
                  master = $scope.element.responsive['LAPTOP'];
                  break;
                }
                case 2: {
                  master = $scope.element.responsive['TABLET'];
                  break;
                }
                case 3: {
                  master = $scope.element.responsive['MOBILE'];
                  break;
                }
              }
              for (var i = pos; i < $scope.responsiveSync.length; i++) {
                if (apply) {
                  $scope.responsiveSync[i] = !$scope.responsiveSync[i];
                }
                if ($scope.responsiveSync[i]) {
                  switch (pos) {
                    case 0: {
                      $scope.element.responsive['LAPTOP'] = master;
                      $scope.element.responsive['TABLET'] = master;
                      $scope.element.responsive['MOBILE'] = master;
                      break;
                    }
                    case 1: {
                      $scope.element.responsive['TABLET'] = master;
                      $scope.element.responsive['MOBILE'] = master;
                      break;
                    }
                    case 2: {
                      $scope.element.responsive['MOBILE'] = master;
                      break;
                    }
                  }
                }
              }
            };

            var _init = function () {
              tab = 0;
              $scope.responsiveSync = [];
              for (var att in ContentBuilderHelper.VIEWPORT_SIZE) {
                $scope.responsiveSync.push(false);
              }

              if ($scope.element.style === undefined) {
                $scope.element.style = {};
              }

              ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
                if (args.identifier === $scope.metaIdentifier || args.identifier === "GLOBAL") {
                  $scope.edit = args.edit;
                  $scope.final = args.preSave || false;
                }
              });

            };
            _init();
          }]
      }
    }]);
  }
);

