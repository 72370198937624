define('services/NoteUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('NoteUnpagedService', [
        '$injector',
        NoteUnpagedService
    ]);
});


function NoteUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('Note');

    angular.extend(this, crudInstance);

    var _note = {};
    var _notes = [];

    this.findOneNote =  function(customerId, id) {
        return new $injector.get("ModelFactory")['Note']().findOne({customerId: customerId,id: id}).$promise.then(function(response) {
            _note = response;
        });
    };

    this.getOneNote = function () {
        return _note;
    };

    this.saveNote = function(customerId, note) {
        if(note.id != undefined) {
            var promise = $injector.get("ModelFactory")['Note']().update({customerId: customerId}, note).$promise;
        } else {
            var promise = $injector.get("ModelFactory")['Note']().create({customerId: customerId}, note).$promise;
        }
        return promise;
    };

    this.findAllNotes = function(customerId) {
        return $injector.get("ModelFactory")['Note']().findAll({customerId: customerId}).$promise.then(function (response) {
            _notes   = response.notes;
            return response;
        });
    };

    this.getAllNotes = function () {
        return _notes;
    };

    this.deleteNote = function (customerId, id) {
        var promise = $injector.get("ModelFactory")['Note']().delete({customerId: customerId, id: id}).$promise;
        return promise;
    };

    return this;
};
