define('services/RoleService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('RoleService', ['$injector', RoleService

    ]);
});

function RoleService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Role');

    angular.extend(this, crudInstance);

    return this;
};
