define(
    'directives/productSelect/productSelect',[
        'app'
    ],
    function (app) {
        'use strict';
        app.directive('ngProductSelect', [
            'BASE_DIRECTIVES_PATH',
            'BASE_TEMPLATES_PATH',
            'PRODUCT_FILTER_OPTIONS',
            'ProductService',
            'MetadataUnpagedService',
            'AttributeUnpagedService',
            'CategoryService',
            'LocalStorageService',
            '$rootScope',
            function (
                BASE_DIRECTIVES_PATH,
                BASE_TEMPLATES_PATH,
                PRODUCT_FILTER_OPTIONS,
                ProductService,
                MetadataUnpagedService,
                AttributeUnpagedService,
                CategoryService,
                LocalStorageService,
                $rootScope) {
                return {
                    scope: {
                        selectedItems: "="
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + "/productSelect/views/product-select.html",
                    controller: ['$scope', function ($scope) {
                        $scope.selected = {};
                        $scope.selected.currentPage = 0;
                        $scope.selected.pageSize = 10;
                        $scope.selected.totalItems = 0;

                        $scope.entityName = "Product";
                        $scope.single = "Product";

                        $scope.tableOptions = {};

                        $scope.tableOptions.fields = [
                            {
                                id: 'SORT_ORDER',
                                heading: 'Sortierung',
                                attribute: 'sortOrder',
                                template: BASE_TEMPLATES_PATH + '/wawi/product/col/sort-order-col.html',
                                orderDisabled: true

                            },
                            {
                                id: 'THUMB_IMG',
                                heading: 'Bild',
                                template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-thumb-col.html',
                                searchDisabled: true,
                                orderDisabled: true
                            },
                            {
                                id: 'STATUS',
                                heading: 'Status',
                                attribute: 'publishState',
                                searchDisabled: true
                            },
                            {
                                id: 'PRODUCT_TYPE',
                                heading: 'Produktart',
                                attribute: 'productType',
                                searchDisabled: true,
                                orderDisabled: true
                            },
                            {
                                id: 'NAME',
                                heading: 'Name',
                                template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-name-col.html',
                                attribute: 'name'
                            },
                            {
                                id: 'SKU',
                                heading: 'SKU',
                                attribute: 'sku'
                            },
                            {
                                id: 'SLUG',
                                heading: 'Slug',
                                attribute: 'slug'
                            },
                            {
                                id: 'CATEGORY',
                                heading: 'Kategorien',
                                template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-category-col.html',
                                attribute: 'category'
                            },
                            {
                                id: 'ORIGIN',
                                heading: 'Ist der Ursprung',
                                attribute: 'origin'
                            },
                            {
                                id: 'COPY_MODE',
                                heading: 'Kopiermodus',
                                attribute: 'copyMode'
                            },
                            {
                                id: 'SYNC_FIELDS',
                                heading: 'Synchronisationsfelder',
                                template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-sync-field-col.html',
                                attribute: 'syncFields'
                            }
                        ];

                        $scope.tableOptions.optionalFields = [
                            {
                                id: 'DESCRIPTION',
                                heading: 'Langbeschreibung',
                                attribute: 'description',
                                searchDisabled: true,
                                orderDisabled: true
                            }
                        ];

                        $scope.entityService = ProductService;

                        $scope.setPageSize = function () {
                            $scope.entityService.setPageSize($scope.selected.pageSize, false);
                            $scope.filterProducts(true);
                        };

                        $scope.setPage = function (currentPage) {
                            if (currentPage) {
                                $scope.selected.currentPage = currentPage;
                            }
                            $scope.entityService.setCurrentPage($scope.getRealCurrentPage(), false);
                            $scope.filterProducts(false);
                        };

                        $scope.addFilter = function () {
                            if ($scope.filters === undefined) {
                                $scope.filters = [];
                            }
                            $scope.filters.push({});
                        };

                        $scope.addFilter();

                        $scope.showCurrentlyDisplayed = function () {
                            var from = parseInt($scope.selected.pageSize) * parseInt($scope.getRealCurrentPage());
                            from = from + 1;

                            if ($scope.items === undefined || $scope.items.length === 0) {
                                from = 0;
                            }

                            var to = ((from + parseInt($scope.selected.pageSize)) < $scope.selected.totalItems) ? (from + parseInt($scope.selected.pageSize) - 1) : $scope.selected.totalItems;

                            return (from) + ' - ' + to;
                        };

                        $scope.getRealCurrentPage = function () {
                            var cur = angular.copy(parseInt($scope.selected.currentPage));
                            cur = (cur === 0) ? cur : (cur - 1);
                            return cur;
                        };

                        $scope.removeFilter = function (index) {
                            $scope.filters.splice(index, 1);
                        };

                        $scope.filterProducts = function (reset) {

                            if (reset) {
                                LocalStorageService.write('ProductCurrentPage', 0);
                            }
                            var filters = [];
                            for (var i = 0; i < $scope.filters.length; i++) {
                                if ($scope.filters[i].filterType != undefined && $scope.filters[i].filterType != "" &&
                                    $scope.filters[i].filterCond != undefined && $scope.filters[i].filterCond != "" &&
                                    $scope.filters[i].filterValue != undefined && $scope.filters[i].filterValue != "") {
                                    filters.push($scope.filters[i]);
                                }
                            }

                            if (!$scope.data.filterIsActive) {
                                filters = [];
                            }

                            $scope.entityService.filterForBulkUpdate(filters, $scope.entityService.getAdditionalParams(), true).then(function () {
                                $scope.items = $scope.entityService.getBulkUpdateProducts().data;
                                for (var i = 0; i < $scope.items.length; i++) {
                                    for (var j = 0; j < $scope.selectedItems.length; j++) {
                                        if($scope.items[i].id === $scope.selectedItems[j].id) {
                                            $scope.items[i].selected = true;
                                        }
                                    }
                                }

                                $scope.selected.totalItems = $scope.entityService.getBulkUpdateProducts().count;
                                $scope.selected.totalPages = $scope.entityService.getBulkUpdateProducts().pages;

                                if ($scope.selected.totalPages === 0) {
                                    //If there are no items the total pages size should be displayed as 1
                                    $scope.selected.totalPages = 1;
                                }

                                $scope.selected.currentPage = $scope.entityService.getBulkUpdateProducts().page + 1;

                            });
                        };

                        $scope.entityService.setQuery({}, false);

                        if ($scope.entityService.getPageSize() == null) {
                            $scope.selected.pageSize = 10;
                            $scope.setPageSize();
                        }

                        $scope.object = {};

                        $scope.data = {filter: false, filterIsActive: true};

                        $scope.searchTerm = "";

                        $scope.availableFilterOptions = PRODUCT_FILTER_OPTIONS;

                        MetadataUnpagedService.findAll().then(function () {
                            $scope.metadatas = MetadataUnpagedService.getAll(true).data;
                        });

                        AttributeUnpagedService.findAll().then(function () {
                            $scope.attributes = AttributeUnpagedService.getAll(true).data;
                        });

                        CategoryService.findAll().then(function () {
                            $scope.categories = CategoryService.getAll().data;
                            $scope.suggestCategories = CategoryService.getAll().data;
                            $rootScope.$broadcast("$suggestSearchList", $scope.suggestCategories, "category");
                        });

                        $scope.getSelectOptions = function (type) {
                            for (var i = 0; i < $scope.availableFilterOptions.length; i++) {
                                if ($scope.availableFilterOptions[i].id === type) {
                                    return $scope.availableFilterOptions[i].types
                                }
                            }
                        };

                        $scope.getMetadataById = function (metadataNameId) {
                            if (metadataNameId != undefined) {
                                for (var i = 0; i < $scope.metadatas.length; i++) {
                                    if (metadataNameId == $scope.metadatas[i].id) {
                                        return $scope.metadatas[i];
                                    }
                                }
                            }
                        };

                        $scope.getAttributeById = function (attributeNameId) {
                            if (attributeNameId != undefined) {
                                for (var i = 0; i < $scope.attributes.length; i++) {
                                    if (attributeNameId == $scope.attributes[i].id) {
                                        return $scope.attributes[i];
                                    }
                                }
                            }
                        };

                        $scope.activateFilters = function () {
                            if ($scope.data.filterIsActive) {
                                if ($scope.filters.length > 0) {
                                    $scope.filterProducts(false, true);
                                }
                            }
                        };

                        $scope.setFilter = function () {
                            $scope.data.filter = !$scope.data.filter;
                        };

                        $scope.onSearchChange = function ($event) {
                            $event.stopPropagation();
                        };

                        $scope.$watch('filters', function (newVal, oldVal) {
                            if (newVal === undefined) {
                                return;
                            }

                            for (var i = 0; i < $scope.filters.length; i++) {
                                if (oldVal[i] != undefined) {
                                    if (newVal[i].filterValue !== oldVal[i].filterValue) {
                                        $scope.filters[i].filterOptions = [];
                                    }
                                }
                            }

                            $scope.entityService.setFilters($scope.filters);
                        }, true);

                        $scope.filterProducts(true);
                    }]
                };
            }]);
    });

