define(
	'directives/formatDate/formatDate',[
		'app',
		'settings',
		'uiBootstrap'
	],
	function (app) {
		'use strict';
		app.directive('formatDate', ['BASE_DIRECTIVES_PATH',
			function (BASE_DIRECTIVES_PATH) {
				return {
					require: 'ngModel',
					link: function (scope, elem, attr, modelCtrl) {
						modelCtrl.$formatters.push(function (modelValue) {
							return new Date(modelValue);
						});
					}};
			}]);
	});
