define('services/EconRecipientService',[
    'app',
    'modules/module',
    'services/EntityCrudService',
    'services/LocalStorageService'
], function (app, module) {
    'use strict';
    return module.factory('EconRecipientService', [
        '$injector', 'LocalStorageService',
        RecipientService
    ]);
});

function RecipientService($injector, LocalStorageService) {

    var crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Recipient');

    angular.extend(this, crudInstance);

    this.sendOptIns = function () {
        this.getModel().sendOptIns();
    };

    this.sendBulkOptIns = function (ids) {
        this.getModel().sendBulkOptIns({ids: ids});
    };

    this.findByRecipientListId = function (recipientListId) {
        var params = {
            page: LocalStorageService.read('RecipientCurrentPage'),
            size: LocalStorageService.read('RecipientPageSize')
        };
        return this.getModel().findByRecipientListId(params, {recipientListId: recipientListId}).$promise.then(this.findAllSuccess, this.findAllError);
    };

    return this;
};
