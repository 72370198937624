define('filters/UnitTypeFilter',[
	'app'
	],	
	function (app){
		'use strict';
		app.filter('unitTypeFilter', function () {
	        var bar;
	        return function (units, types) {
	        	if(units === undefined){
	        		return []
	        	}
	        	if(types === undefined) {
	        		return units;
	        	}
	        	var validUnits = []
	        	units.forEach(function (unit) {
	        		if(types.indexOf(unit.unitType) !== -1) {
	        			validUnits.push(unit);
	        		}
	        	});
	        	return validUnits;
	        };
	    });
	}
);
