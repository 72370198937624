define(
    'directives/imageList/imageList',[
        'app',
        'settings',
        'services/ImageService',
        'directives/bufferedScroll/bufferedScroll'
    ],
    function (app) {
        'use strict';
        app.directive('ngImageList', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        deletable: "=",
                        onDragStart: "=",
                        onDragEnd: "=",
                        selectImageCallback: "=?"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + "/imageList/views/imagelist.html",
                    controller: ['$scope', '$rootScope', 'ImageService', function ($scope, $rootScope, ImageService) {

                        $scope.directoryId;
                        $scope.query;
                        $scope.selectedPage = 1;
                        $scope.pageSize = 5;
                        $scope.totalFileElements = 0;
                        $scope.getImageSrc = function (image) {
                            return "/api/v1/media/images/" + image.id + "/default-characteristic";
                        };

                        if (typeof $scope.deletable === "undefined") {
                            $scope.deletable = true;
                        }
                        $scope.selectImage = function(image){
                            if (typeof $scope.selectImageCallback !== "undefined") {
                                $scope.selectImageCallback(image, $scope.getImageSrc(image));
                            }
                        };

                        // if ($scope.deletable) {
                        //     $scope.deleteImage = ImageService.deleteImage;
                        // }

                        $scope.rootDirectory = function(){
                            $scope.selectedPage = 1;
                            $scope.directoryId = -1;
                            $scope.loadImages($scope.selectedPage, $scope.pageSize);
                            delete $scope.directories;
                        };

                        $scope.loadImages = function (page, pageSize, directoryId, query) {
                            ImageService.loadDirectories(page - 1, pageSize, directoryId, query).then(function (response) {
                                $scope.directories = response.children;
                                $scope.directories.unshift({id: response.id, name: response.name});
                                $scope.images = response.files;
                                $scope.totalFileElements = response.totalFileElements;
                            });
                        };

                        $rootScope.$on('ANGULAR_DRAG_START', $scope.onDragStart);
                        $rootScope.$on('ANGULAR_DRAG_END', $scope.onDragEnd);

                        $scope.$on('imageUploaded', function (event, data) {
                            $scope.directoryId = data.directoryId;
                            $scope.selectedPage = 1;
                            $scope.loadImages($scope.selectedPage, $scope.pageSize, $scope.directoryId, $scope.query);
                        });

                        $scope.directoryChange = function (directoryId) {
                            $scope.directoryId = directoryId;
                            if (directoryId != undefined) {
                                $scope.loadImages($scope.selectedPage, $scope.pageSize, directoryId, $scope.query);
                            }
                        };

                        $scope.deleteImage = function (id) {
                            ImageService.deleteImage(id).then(function () {
                                $scope.loadImages($scope.selectedPage, $scope.pageSize, $scope.directoryId, $scope.query);
                            })
                        };

                        $scope.pageChanged = function () {
                            $scope.loadImages($scope.selectedPage, $scope.pageSize, $scope.directoryId, $scope.query);
                        };

                        $scope.queryChanged = function(){
                            if(!$scope.queryTimer){
                                clearTimeout($scope.queryTimer);
                            }

                            $scope.queryTimer = setTimeout(function () {
                                $scope.loadImages($scope.selectedPage, $scope.pageSize, $scope.directoryId, $scope.query);
                            }, 800);
                        };

                        $scope.loadImages($scope.selectedPage, $scope.pageSize);
                    }]
                };
            }
        ]);
    }
);

