define(
	'directives/controls/customRadio/CustomRadio',[
		'app',
        'settings'
   	], 
   	function (app) {
		'use strict';
		app.directive('ngCustomRadio', ['BASE_DIRECTIVES_PATH', function(BASE_DIRECTIVES_PATH) {
            return {
                scope: {
                    outerLabel: "@",
                    outerLabelClass: "@",
                    label: "@",
                    options: "=",
                    styleClass: "@",
                    disable: "=",
                    result: "="
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/controls/customRadio/views/CustomRadio.html",
                controller: ['$scope', function($scope) {

                    if($scope.display === undefined) {
                        $scope.display = "display";
                    }

                    if($scope.value === undefined) {
                        $scope.value = "value";
                    }

                    if($scope.outerLabel === undefined) {
                        $scope.outerLabel = false;
                    }

                    $scope.getValue = function(option, path)
                    {
                        var dp = path.split(".");

                        var value = option;
                        for(var i=0; i<dp.length; i++)
                        {
                            value = value[dp[i]];
                        }

                        return value;
                    };

                    $scope.$watch('result', function(newValue, oldValue) {
                        if(newValue === undefined || newValue == oldValue) return;
                    });

                }]
            }
        }
])});

