define('modules/content/controllers/ContentFormController',[
    'modules/module',
	'settings',
    'modules/basemodule/controllers/EntityFormController',
    'services/CategoryService',
    'services/NavigationService',
    'services/TagService'
    ],
    function (module) {
        'use strict';
        module.controller('ContentFormController', [
            '$scope',
            '$controller',
            'CategoryService',
            'NavigationService',
			'TagService',
			'BASE_TEMPLATES_PATH',
            ContentFormController
        ]);
    }
);

function ContentFormController ($scope, $controller, CategoryService, NavigationService, TagService, BASE_TEMPLATES_PATH) {

    $scope.formTabs = [
        {
            label: 'Allgemein',
            template: BASE_TEMPLATES_PATH + '/content/partials/form-main.html',
            active: true
        },
        /*{
            label: 'Meta-Infos',
            template: BASE_TEMPLATES_PATH + '/content/partials/form-meta.html'
        },*/
        {
            label: 'Kategorien',
            template: BASE_TEMPLATES_PATH + '/content/partials/form-categories.html'
        },
        {
            label: 'Tags',
            template: BASE_TEMPLATES_PATH + '/content/partials/form-tags.html'
        }
        /*{
            label: 'Bilder',
            template: BASE_TEMPLATES_PATH + '/content/partials/form-images.html'
        }*/

    ];

	$scope.listState = 'root.contents';


    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

	//we need this because of a buggy behaviour of tinymce
	$scope.entity = {};
	$scope.entity.content = "";

    $scope.init("Content").then(function() {
		CategoryService.findAll().then(function () {
			$scope.categories = CategoryService.getAll();
			$scope.categories.forEach(function (category) {
				category.selected = false;
			});
			if (!$scope.newEntity) {
				$scope.entity.categories.forEach(function (selectedCategory) {
					$scope.categories.forEach(function (category) {
						if (category.id === selectedCategory.id) {
							category.selected = true;
						}
					});
				});
			}
		});

		TagService.findAll().then(function () {
			$scope.tags = TagService.getAll();
			$scope.tags.forEach(function (tag) {
				tag.selected = false;
			});

			if (!$scope.newEntity) {
				$scope.entity.tags.forEach(function (selectedTag) {
					$scope.tags.forEach(function (tag) {
						if (tag.id === selectedTag.id) {
							tag.selected = true;
						}
					});
				});
			}
		});

		$scope.setSelectedCategories = function () {
			$scope.entity.categories = [];
			$scope.categories.forEach(function (category) {
				if (category.selected) {
					delete category.selected;
					$scope.entity.categories.push(category);
				}
			});
		};

		$scope.setSelectedTags = function () {
			$scope.entity.tags = [];
			$scope.tags.forEach(function (tag) {
				if (tag.selected) {
					delete tag.selected;
					$scope.entity.tags.push(tag);
				}
			});
		};

		$scope.update = function () {
			$scope.setSelectedCategories();
			$scope.setSelectedTags();
			$scope.entityService.update($scope.entity).then(function () {
				NavigationService.changeState($scope.listState);
			});
		};

		$scope.create = function () {
			$scope.setSelectedCategories();
			$scope.setSelectedTags();
			$scope.entityService.create($scope.entity).then(function () {
				NavigationService.changeState($scope.listState);
			});
		};


    });
}
;
