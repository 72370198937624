define('modules/econ/controllers/EconRecipientListFormController',[
    'modules/module',
    'settings',
    'directives/entityListSelect/EntityListSelect',
    'services/EconRecipientListService',
    'services/EconRecipientService',
    'services/EconMailtemplateService',
    'services/NavigationService'
],
        function (module) {
            'use strict';
            module.controller('EconRecipientListFormController', [
                '$scope',
                '$controller',
                'EconRecipientListService',
                'EconRecipientService',
                'NavigationService',
                'EconMailtemplateService',
                'PublishStateService',
                'BASE_TEMPLATES_PATH',
                EconRecipientListFormController
            ]);
        }
);


function EconRecipientListFormController($scope, $controller, EconRecipientListService, EconRecipientService, NavigationService,
    EconMailtemplateService, PublishStateService, BASE_TEMPLATES_PATH) {

    $scope.formTabs = [
        {
            label: 'Allgemein',
            template: BASE_TEMPLATES_PATH + '/econ/partials/_form-recipient-list-main.html',
            active: true
        },
        {
            label: 'Empfänger',
            template: BASE_TEMPLATES_PATH + '/econ/partials/_form-recipient-list-recipients.html'
        }
    ];

    $scope.colors = [{display: "Rot", value: "red"}, {display: "Orange", value: "orange"}, {display: "Blau", value: "blue"}, {display: "Grün", value: "green"}, {display: "Rot", value: "red"}, {display: "Violett", value: "BlueViolet"}, {display: "Cyan", value: "Cyan"}, {display: "Türkis", value: "DarkTurquoise "}];

    $scope.validationConfig = {
        name: {
            method: "isset",
            fieldName: "Listenname"
        },
        color: {
            method: "isset",
            fieldName: "Farbe"
        },
        shortcut: {
            method: "isset",
            fieldName: "Kürzel"
        },
        optinTemplateId: {
            method: "isset",
            fieldName: "Opt-In Template"
        },
        unsubscribeTemplateId: {
            method: "isset",
            fieldName: "Abmelde Template"
        }
    };

    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

    $scope.listState = 'root.econRecipientLists';

    $scope.recipientTableOptions = {
        name: 'Schiffe',
        columns: [
            {
                heading: 'Vorname',
                attribute: 'firstName'
            },
            {
                heading: 'Nachname',
                attribute: 'lastName'
            },
            {
                heading: 'Email',
                attribute: 'email'
            }
        ]
    };


    $scope.selected = {};
    $scope.selected.recipients = [];
    $scope.publishStates = [];
    $scope.econRecipientService = EconRecipientService;

    $scope.init(EconRecipientListService).then(function () {

        $scope.getOnlyAssignedRecipients();

        EconMailtemplateService.findAll(true).then(function () {
            $scope.mailTemplates = EconMailtemplateService.getAll();
        });

        PublishStateService.findAll().then(function () {
            $scope.publishStates = PublishStateService.getAll();
        });
    });

    $scope.$on("beforeUpdate", function () {
        $scope.setSelectedRecipients();
    });

    $scope.$on("beforeCreate", function () {
        $scope.setSelectedRecipients();
    });

    $scope.setSelectedRecipients = function () {
        $scope.entity.recipients = [];
        $scope.selected.recipients.forEach(function (recipient) {
            $scope.entity.recipients.push(recipient);
        });
    };

    $scope.getOnlyAssignedRecipients = function () {
        if (!$scope.newEntity) {
            EconRecipientService.findByRecipientListId($scope.entity.id).then(function () {
                if (!$scope.newEntity) {
                    $scope.entity.recipients.forEach(function (selectedRecipient) {
                        $scope.selected.recipients.push(selectedRecipient);
                    });
                }
            });
        }
    };
}
;
