define('modules/econ/controllers/EconMailtemplateController',[
    'modules/module',
    'modules/basemodule/controllers/EntityListController'
	], 
	function (module) {
        'use strict';
		module.controller('EconMailtemplateController', [
			'$scope',
			'$controller',
            MailtemplateController
        ]);
    }
);

function MailtemplateController($scope, $controller) {

    angular.extend(this, $controller('EntityListController', {
        $scope: $scope
    }));

    $scope.formState = 'root.econMailtemplate';

    $scope.tableOptions.fields = [
        {
            id: 'NAME',
            heading: 'Name',
            attribute: 'name'
        },
        {
            id: 'SENDER',
            heading: 'Absender',
            attribute: 'sender'
        },
        {
            id: 'SUBJECT',
            heading: 'Betreff',
            attribute: 'subject'
        }
    ];

    $scope.init('Mailtemplate');
   
};
