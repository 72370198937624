/**
 * Created by antarya on 12.07.2019.
 */
define('modules/crm/ticket/controllers/TicketFormController',[
    'modules/module',
    'modules/basemodule/controllers/EntityFormController',
    'services/ValidationService',
    'services/TicketUnpagedService',
    'services/CustomerService',
    'services/ShopService',
    'services/NotificationService',
    'modules/crm/controllers/SelectCustomerController',
    'settings'
],
    function (module) {
        'use strict';
        module.controller('TicketFormController', [
            '$scope',
            '$rootScope',
            '$controller',
            '$q',
            'ValidationService',
            'TicketUnpagedService',
            'CustomerService',
            'ShopService',
            'NotificationService',
            'BASE_TEMPLATES_PATH',
            TicketFormController
        ]);
    }
);

function TicketFormController($scope, $rootScope, $controller, $q, ValidationService, TicketUnpagedService, CustomerService, ShopService, NotificationService, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

    $scope.formTabs = [
        {
            label: 'Kommunikation ',
            template: BASE_TEMPLATES_PATH + '/crm/ticket/form-main.html',
            active: true
        }
    ];

    $scope.listState = 'root.tickets';

    $scope.communication = {
        content: '',
        files: [],
        file: undefined
    }

    $scope.ticketStatuses = []

    $scope.tinymceOptions = {
        onChange: function (e) { },
        inline: false,
        plugins: 'autolink link image lists charmap print code',
        skin: 'lightgray',
        theme: 'modern',
        menubar: false,
        statusbar: false
    };

    $scope.init("Ticket").then(function () {
        if ($scope.entity.customerId != undefined) {
            CustomerService.findOne($scope.entity.customerId).then(function () {
                var customer = CustomerService.getOne();
                if (customer.error) {
                    $scope.customer = "Gastkauf";
                } else {
                    $scope.customer = customer.firstName + ' ' + customer.lastName;
                    $scope.entity.customerId = customer.id;

                }
            });
        }

        if (!$scope.entity.id) {
            ShopService.findShop().then(function () {
                $scope.shop = ShopService.getOne();
                $scope.entity.author = $scope.shop.name + ' Team';
            });
        }

        TicketUnpagedService.getStatuses().then(function (data) {
            $scope.ticketStatuses = data;
            if (!$scope.entity.id) {
                $scope.entity.status = data.filter(function (f) { return f.name === 'OPEN' })[0].id
            }
        })

    });

    $scope.DeregisterBeforeCreate = () => {
        $rootScope.$on("beforeCreate", () => {
        })
    }

    $scope.$on("afterUpdate", function () {
        $scope.scrollDown();
    });

    $scope.$on("afterCreate", function () {
        $scope.scrollDown();
    });

    $scope.validationConfig = {
        name: {
            method: "isset",
            fieldName: "Name"
        },
        status: {
            method: "isset",
            fieldName: "Status"
        },
        customerId: {
            method: "isset",
            fieldName: "Customer"
        },
        author: {
            method: "isset",
            fieldName: "Author"
        },
        communications: {
            method: "isset",
            fieldName: "Communications",
            errorMsg: "Kommunikation ermöglichen",
            customValidate: function (entity) {
                if(!$scope.entity.id) {
                    if($scope.communication.content === "") {
                        return false;
                    }
                } else {
                    if($scope.communication.content === "" && $scope.communication.files.length > 0) {
                        return false
                    }
                }
                return true;
            }
        }
    };

    $scope.beforeUpdateOrCreate = function () {
        return $scope.uploadCommunicationDocuments().then(function (documents) {
            if (!documents) {
                return;
            }

            $scope.addCommunication($scope.communication.content, $scope.entity.id, documents);

            $scope.communication.content = '';
            $scope.communication.files = [];
            $scope.communication.file = undefined
        });
    };

    $scope.prepareCreateDelayed = $scope.beforeUpdateOrCreate;
    $scope.prepareSaveDelayed = $scope.beforeUpdateOrCreate;

    $scope.createOrUpdate = function () {
        if($scope.entity.id) {
            $scope.update(false);
        } else {
            $scope.create(false);
        }
    };

    $scope.getStringInitials = function (name) {
        if (typeof name === "undefined") {
            return "";
        }

        return name
            .toString()
            .split(" ")
            .filter(function (f, fi) { return fi < 2; })
            .map(function (m) {
                return m.charAt(0)
            })
            .join("")
            .toUpperCase();
    };

    $scope.scrollDown = function () {
        var el = document.querySelector('.communication-chat');
        setTimeout(function () {

            el.scrollTop = el.scrollHeight;
        }, 300);
    }

    $scope.uploadCommunicationDocuments = function() {
        var deferred = $q.defer();

        var documents = [];

        if (!$scope.communication.files || $scope.communication.files.length === 0) {
            deferred.resolve(documents);
            return deferred.promise;
        }
        CustomerService.transferDocuments(
            $scope.communication.files
        ).then(function(documents) {
            if (angular.isArray(documents)) {
                deferred.resolve(documents);
            }
        });

        return deferred.promise;
    };

    $scope.addCommunication = function (content, ticketId, documents) {
        if (!$scope.entity.communications) {
            $scope.entity.communications = [];
        }
        if($scope.communication.content === "") {
            return;
        }
        $scope.entity.communications.unshift({
            author: $scope.shop.name + " Team",
            content: content,
            createdAt: new Date(),
            ticketId: ticketId,
            ownComment: true,
            documents: documents
        });
    };

    $scope.removeCommunicationFile = function (file) {
        var indexToRemove = $scope.communication.files.indexOf(file);
        if (indexToRemove > -1) {
            $scope.communication.files.splice(indexToRemove, 1);
        }
    };

    $scope.dateDifference = date => {
        str = "";
        let today = new Date();
        let created = new Date(date);
        var diff = +today.getTime() - +created.getTime();
        var year = diff / (1000 * 60 * 60 * 24 * 365);
        var month = +today.getMonth() - +created.getMonth();
        if(month < 0) {
            month += 12;
        }
        var days = diff / (1000 * 60 * 60 * 24);
        var hours = diff / (1000 * 60 * 60);
        var minutes = diff / (1000 * 60);
        var seconds = diff / (1000);
        
        if(year >= 1) {
            str = Math.round(year) + " jahr(en) ago";
        } else {
            if(month >= 1) {
                str = Math.round(month) + " Monat(en) ago";
            } else {
                if(days >= 1) {
                    str = Math.round(days) + " tag(en) ago";
                } else {
                    if(hours >= 1) {
                        str = Math.round(hours) + " stunde(n) ago";
                    } else {
                        if(minutes >= 1) {
                            str = Math.round(minutes) + " minute(n) ago";
                        } else {
                            str = Math.round(seconds) + " sekunde(n) ago";
                        }
                    }
                }
            }
        }
        return str;
    } 

    $scope.watchCommunicationDocumentInput = $scope.$watch('communication.file', function (newValue, oldValue) {
        if (!newValue) {
            return;
        }

        if ($scope.communication.files.length >= 10) {
            NotificationService.notifyGeneralError({ statusText: 'Du kannst nicht mehr als 10 Dateien hochladen.' })
            return;
        }

        $scope.communication.files.push(newValue);
    });

    $scope.ticketDialogCustomerSelectController = ['$scope', 'dataToPass', '$mdDialog', function ($dialogCustomerScope, dataToPass, $mdDialog) {
        angular.extend(this, $controller('SelectCustomerController', {
            $scope: $dialogCustomerScope
        }));

        $dialogCustomerScope.setCustomer = function (customer) {
            $scope.customer = customer.firstName + ' ' + customer.lastName;
            $scope.entity.customerId = customer.id;
            $scope.entityService.setType("Ticket");
            $mdDialog.hide();
        };

        $dialogCustomerScope.cancel = function () {
            $mdDialog.cancel();
        };

        $dialogCustomerScope.addSelectedCustomerAndBack = function (inv, ship) {
            if ($dialogCustomerScope.selected.items.length == 1) {
                $dialogCustomerScope.addSelectedCustomer();
                $mdDialog.hide($dialogCustomerScope.customer);
            }
        }
    }];

    $scope.$on("destroy", () => {
        $scope.DeregisterBeforeCreate();
    })
}
;
