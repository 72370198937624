define(
    'directives/controls/customTextarea/CustomTextarea',[
        'app',
        'settings'
    ],
    function (app) {
        'use strict';
        app.directive('ngCustomTextarea', ['BASE_DIRECTIVES_PATH', function(BASE_DIRECTIVES_PATH) {
            return {
                scope: {
                    label: "@",
                    options: "=",
                    result: "=",
                    placeholder: "@",
                    customColor: "=",
                    disable:"=",
                    changeFunction: "&", // function binding, is passed directly to input element
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/controls/customTextarea/views/CustomTextarea.html",
                controller: ['$scope', function($scope) {

                }]
            }
        }
        ])});
