define('services/ShoppingCartService',[
    'modules/module'
], function (module) {

    return module.factory('ShoppingCartService', ['$q', '$rootScope', 'ModelFactory', 'ShopService', function ($q, $rootScope, ModelFactory, ShopService) {

        var _shoppingCart = {id: -1};
        _shoppingCart.orderProducts = [];
        var _currencyIso;

        var _cartStatus = function () {

            var deferred = $q.defer();
            var isResolved = false;

            return {
                defer: function () {
                    if (isResolved) {
                        deferred = $q.defer();
                        isResolved = false;
                    }
                    return deferred;
                },
                resolve: function () {
                    isResolved = true;
                    deferred.resolve();
                    return deferred;
                },
                reject: function () {
                    isResolved = true;
                    deferred.reject();
                    return deferred;
                }
            };
        };

        var createCartProduct = function (order) {
            var copy = angular.copy(order.product);
            return {
                product: {
                    id: copy.id,
                    manufacturer: copy.manufacturer,
                    name: copy.name
                },
                price: copy.sellingPrice,
                amount: order.amount,
                comment: order.comment,
                orderPrintAreas: order.orderPrintAreas,
                appointmentId: order.appointmentId,
                seminarParticipantTypeId: order.seminarParticipantTypeId,
                totalWeight: copy.totalWeight,
                weightUnit: copy.weightUnit
            };
        };

        var _updateCartProcessIsRunning = false;

        var _saveShoppingCart = function () {
            if (_updateCartProcessIsRunning) {
                return {
                    then: function () {
                        console.log("please wait...");
                    }
                };
            }

            _updateCartProcessIsRunning = true;
            var _productsInCart = {id: _shoppingCart.id};
            _productsInCart.orderProducts = [];
            if (_shoppingCart.orderProducts) {
                _shoppingCart.orderProducts.forEach(function (position) {
                    _productsInCart.orderProducts.push({
                        productId: position.product ? position.product.id : null,
                        amount: position.amount,
                        comment: position.comment,
                        orderPrintAreas: position.orderPrintAreas,
                        appointmentId: position.appointmentId,
                        seminarParticipantTypeId: position.seminarParticipantTypeId,
                        seminarParticipantId: position.orderSeminarParticipant ? position.orderSeminarParticipant.id : position.seminarParticipantId,
                        productSku: position.productSku,
                        productName: position.productName,
                        price: position.price,
                        taxValue: position.taxValue,
                        productDiscount: position.productDiscount,
                        productPercentageDiscount: position.productPercentageDiscount,
                        custom: position.custom,
                        totalWeight: position.totalWeight,
                        weightUnit: position.weightUnit,
                        weightUnitId: position.weightUnitId
                    });
                });
            }
            _productsInCart.insertedCouponCodeValue = _shoppingCart.insertedCouponCodeValue;
            return ModelFactory.ShoppingCart(_currencyIso).save(_productsInCart).$promise.then(function (response) {
                if (response.orderProducts != undefined) {
                    response.orderProducts.sort(function (a, b) {
                        if (!a.product || !b.product) {
                            return 0;
                        }

                        return a.product.id - b.product.id;
                    });
                }
                if (response.ok == false) {
                    _updateCartProcessIsRunning = false;
                    /*Notification.notifyGeneralError({
                     statusText: 'ALERTS.GENERAL_ERROR.STATUS',
                     msg: 'ALERTS.GENERAL_ERROR.MSG'
                     });*/
                    return;
                }
                _shoppingCart = response;

                if(_shoppingCart.responseData != null) {
                    if(_shoppingCart.responseData.messages != null) {
                        if(_shoppingCart.responseData.messages["CART_MESSAGE"] != null) {
                            for(var i=0; i < _shoppingCart.responseData.messages["CART_MESSAGE"].length; i++) {
                                if(_shoppingCart.responseData.messages["CART_MESSAGE"][i].type === "SUCCESS_MESSAGE") {
                                    /*Notification.notifySuccess({
                                     statusText: 'Erfolgreich',
                                     msg: _shoppingCart.responseData.messages["CART_MESSAGE"][i].message
                                     });*/
                                } else {
                                    /*Notification.notifyGeneralError({
                                     statusText: 'Fehler',
                                     msg: _shoppingCart.responseData.messages["CART_MESSAGE"][i].message
                                     });*/
                                }
                            }
                        }
                    }
                }

                _mapMetadata();
                _cartStatus().resolve(response);
                _updateCartProcessIsRunning = false;
                $rootScope.$emit('ShoppingCartChanges');
            });
        };

        var _mapMetadata = function () {
            for (var i = 0; _shoppingCart.orderProducts && i < _shoppingCart.orderProducts.length; i++) {
                var orderProduct = _shoppingCart.orderProducts[i];
                if (orderProduct.product && orderProduct.product.metadatas != undefined) {
                    for (var j = 0; j < orderProduct.product.metadatas.length; j++) {
                        orderProduct.product[orderProduct.product.metadatas[j].name] = orderProduct.product.metadatas[j];
                    }
                }
            }
        };

        return {
            getProducts: function () {
                return _shoppingCart.orderProducts;
            },
            addProducts: function (order) {
                for (var i = 0; i < _shoppingCart.orderProducts.length; i++) {
                    var orderProduct = _shoppingCart.orderProducts[i];
                    for (var j = 0; j < order.length; j++) {
                        if (orderProduct.product && orderProduct.product.id == order[j].product.id) {
                            var newAmount = parseInt(order[j].amount);
                            newAmount += orderProduct.amount;
                            if (newAmount > orderProduct.product.totalAvailableStockLevel) {
                                Notification.notifyGeneralError({
                                    statusText: 'Es stehen nicht genug Produkte zur Verfügung'
                                });
                                return;
                            }
                            break;
                        }
                    }
                }

                for (var k = 0; k < order.length; k++) {
                    var copy = createCartProduct(order[k]),
                        existing = false;
                    _shoppingCart.orderProducts.forEach(function (productInCart) {
                        if (productInCart.product != undefined) {
                            if (productInCart.product.id === copy.product.id) {
                                productInCart.amount = parseInt(productInCart.amount) + parseInt(order[k].amount);
                                productInCart.comment = copy.comment;
                                productInCart.orderPrintAreas = copy.orderPrintAreas;
                                productInCart.totalWeight = copy.totalWeight;
                                productInCart.weightUnit = copy.weightUnit;
                                existing = true;
                                return;
                            }
                        }
                    });
                    if (!existing) {
                        copy.amount = order[k].amount;
                        _shoppingCart.orderProducts.push(copy);
                    }
                }
                return _saveShoppingCart();
            },
            removeProduct: function (productId, skipSave) {
                _shoppingCart.orderProducts.forEach(function (position) {
                    if (position.product.id == productId) {
                        var index = _shoppingCart.orderProducts.indexOf(position);
                        _shoppingCart.orderProducts.splice(index, 1);
                    }
                });
                if (!skipSave) {
                    return _saveShoppingCart();
                }
            },
            changeAmount: function (productId, newAmount) {
                _shoppingCart.orderProducts.forEach(function (position) {
                    if (position.product && position.product.id == productId) {
                        position.amount = newAmount;
                    }
                });
                return _saveShoppingCart();
            },
            clear: function () {
                var deferred = _cartStatus().defer();
                _shoppingCart.orderProducts = [];
                _saveShoppingCart();
                return deferred.promise;
            },
            loadById: function (shoppingCartId) {
                return ModelFactory.ShoppingCart(_currencyIso).load({id: shoppingCartId}).$promise;
            },
            getCart: function () {
                return _shoppingCart;
            },
            saveShoppingCart: function (shoppingCart) {
                if (!shoppingCart.id) {
                    shoppingCart.id = -1; // should be create a new shopping cart
                }
                _shoppingCart = shoppingCart;
                return _saveShoppingCart();
            },
            setShoppingCart: function (shoppingCart) {
                _shoppingCart = shoppingCart;
            },
            setCurrencyIso: function (currencyIso) {
                _currencyIso = currencyIso;
            }
        };
    }]);
});

