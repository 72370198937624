define(
    'directives/contentConfigurator/_elementConfig/ElementConfig',[
        'app',
        'angular',
        'services/ContentBuilderHelper',
        'directives/contentConfigurator/contentSnippets/textSnippet/TextSnippetConfig',
        'directives/contentConfigurator/contentSnippets/accordionSnippet/AccordionSnippetConfig',
        'directives/contentConfigurator/contentSnippets/listSnippet/ListSnippetConfig',
        'directives/contentConfigurator/contentSnippets/carouselSnippet/CarouselSnippetConfig',
        'directives/contentConfigurator/contentSnippets/imageSnippet/ImageSnippetConfig',
        'directives/contentConfigurator/contentSnippets/sliderSnippet/SliderSnippetConfig',
        'directives/contentConfigurator/contentSnippets/tinySnippet/TinySnippetConfig',
        'directives/contentConfigurator/contentSnippets/sectionSnippet/SectionSnippetConfig',
        'directives/contentConfigurator/contentRow/ContentRowConfig',
        'directives/contentConfigurator/contentCell/ContentCellConfig',
        // 'directives/contentConfigurator/_rowConfig/RowConfig',
        'directives/contentConfigurator/_styleMenu/StyleMenu'
    ],
    function (app) {
        'use strict';
        app.directive('elementConfig', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'EA',
                scope: {
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/_elementConfig/views/elementConfig.html",
                controller: ['$scope', '$rootScope', '$timeout', 'ContentBuilderHelper',
                    function ($scope, $rootScope, $timeout, ContentBuilderHelper) {

                        $scope.ELEMENT_TYPE = ContentBuilderHelper.ELEMENT_TYPE;
                        var tab = 0;

                        $scope.tab = function (id) {
                            return tab === id;
                        };

                        $scope.setTab = function (id) {
                            tab = id;
                        };

                        $scope.currentElementIs = function (etype, stype) {
                            if (!$scope.currentElementHandle) {
                                return false;
                            }
                            return stype === $scope.currentElementHandle.stype && etype === $scope.currentElementHandle.etype;
                        };

                        var _init = function () {
                            tab = 0;
                            $scope.showStore = false;
                            $scope.overrideShow = false;
                            $scope.currentElementHandle = undefined;

                            ContentBuilderHelper.subscribeSelected($scope, function(event, args) {
                                $timeout( function() {
                                    var selectedHandle = ContentBuilderHelper.selectedElement(args.metaIdentifier);
                                    $scope.currentElementHandle = {
                                        id: selectedHandle.elementIdentifier,
                                        metaId: args.metaIdentifier,
                                        etype: selectedHandle.elementType,
                                        stype: selectedHandle.snippetType,
                                        element: selectedHandle.element
                                    };
                                }, 0);
                            });

                        };
                        _init();

                    }]
            }
        }]);
    }
);

