define('modules/wawi/product/controllers/ProductBaseFormController',[
    'modules/module',
    'modules/basemodule/controllers/EntityFormController',
    'directives/controls/customInputTime/CustomInputTime',
    'directives/listSelect/ListSelect',
    'directives/listSelectSku/ListSelectSku',
    'services/CategoryService',
    'services/TagUnpagedService',
    'services/ProductUnpagedService',
    'services/MetadataSetUnpagedService',
    'services/MetadataService',
    'services/MetadataUnpagedService',
    'services/UnitUnpagedService',
    'services/NotificationService',
    'services/ShopService',
    'services/SessionValuesService',
    'services/SellingProductService',
    'services/CustomerGroupService',
    'services/AttributeService',
    'filters/RemoveSpacesAndSpecialCharacters',
    'settings'
],
    function (module) {
        'use strict';
        module.controller('ProductBaseFormController', [
            '$rootScope',
            '$scope',
            '$controller',
            '$state',
            'PublishStateService',
            'ManufacturerService',
            'UnitUnpagedService',
            'TaxService',
            'TagUnpagedService',
            'ProductUnpagedService',
            'CategoryService',
            'RefinementProductService',
            'MetadataSetUnpagedService',
            'MetadataService',
            'MetadataUnpagedService',
            'NotificationService',
            'BASE_TEMPLATES_PATH',
            'ImageService',
            'FileUploader',
            'ProductService',
            'ShopService',
            'SessionValuesService',
            'SellingProductService',
            'CustomerGroupService',
            'AttributeService',
            ProductBaseFormController
        ]);
    }
);


function ProductBaseFormController($rootScope, $scope, $controller, $state, PublishStateService, ManufacturerService, UnitUnpagedService, TaxService, TagUnpagedService, ProductUnpagedService,
                                        CategoryService, RefinementProductService, MetadataSetUnpagedService, MetadataService, MetadataUnpagedService,
                                        NotificationService, BASE_TEMPLATES_PATH, ImageService, FileUploader, ProductService, ShopService, SessionValuesService, SellingProductService, CustomerGroupService, AttributeService) {

    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

    $scope.metadataObjects = [];
    $scope.availableData = {};
    $scope.selected = {};
    $scope.listState = 'root.products';
    $scope.productService = ProductService;

    $scope.productService.setCurrentPage(0).then(function () {
        $scope.products = $scope.productService.getAll();
    });


    $scope.type = $state.current.url.split('/')[2];

    $scope.selectedCrossSellingProducts = [];
    $scope.selected.productsCurrentPage = 0;
    $scope.selected.productsPageSize = 10;
    $scope.selected.productsTotalItems = 0;

    AttributeService.getTypes().then(function () {
        $scope.attributeTypes = AttributeService.getAttrTypes();
    });

    PublishStateService.findAll().then(function () {
        $scope.publishStates = PublishStateService.getAll(true);
    });

    UnitUnpagedService.findAll().then(function () {
        $scope.units = UnitUnpagedService.getAll(true).data;

        $scope.weightUnits = [];
        for (var i = 0; i < $scope.units.length; i++) {
            var unit = $scope.units[i];
            if (unit.type == "WEIGHT" || unit.type == "WEIGHT_CALCULATED") {
                $scope.weightUnits.push(unit);
            }
        }

        $scope.volumeUnits = [];
        for (var i = 0; i < $scope.units.length; i++) {
            var unit = $scope.units[i];
            if(unit.type == "VOLUME"){
                $scope.volumeUnits.push(unit);
            }
        }
    });

    TaxService.findAll().then(function () {
        $scope.taxes = TaxService.getAll(true);
    });

    ManufacturerService.findAll(true).then(function () {
        $scope.manufacturers = ManufacturerService.getAll();
    });

    MetadataSetUnpagedService.findAll().then(function () {
        $scope.metadataSets = MetadataSetUnpagedService.getAll(true).data;
    });

    CustomerGroupService.findAll(true).then(function () {
        $scope.customerGroups = CustomerGroupService.getAll();
    });

    $scope.formTabs = [
        {
            label: 'Allgemein',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-main.html',
            active: true
        },
        {
            label: 'Beschreibung',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-description.html'
        },
        {
            label: 'Preise',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-prices.html'
        },
        {
            label: 'Product Dimensions',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-product-dimensions.html'
        },
        {
            label: 'Tags',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-tags.html'
        },
        {
            label: 'Kategorien',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-categories.html'
        },
        {
            label: 'SEO',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-seo.html'
        },
        {
            label: 'Add-On-Selling',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-add-on-selling.html'
        },
        {
            label: 'Cross-Selling',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-cross-selling.html'
        },
        {
            label: 'Up-Selling',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-up-selling.html'
        },
        {
            label: 'Bilder',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-images.html'
        },
        {
            label: 'Lagerverwaltung',
            template: BASE_TEMPLATES_PATH + '/wawi/product/stock-management.html'
        },
        {
            label: 'Produktmerkmale',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-metadata.html'
        },
        {
            label: 'Druckflächen',
            template: BASE_TEMPLATES_PATH + '/wawi/product/form-refinement-products.html'
        }
    ];

    $scope.$on("afterInit", function () {
        $scope.topBarTemplate = BASE_TEMPLATES_PATH + '/wawi/product/partials/duplicate-button.html';

        var countLocales = 0;
        var countLocalesSeo = 0;
        $scope.options = [];
        if($scope.entity.locales != undefined) {
            $scope.entity.locales.sort(function(a, b){
                if ( a.localeId < b.localeId )
                    return -1;
                if ( a.localeId > b.localeId )
                    return 1;
                return 0;
            });
            for (var i=0; i < $scope.entity.locales.length; i++) {
                if ($scope.entity.locales[i].seoInformation != null && $scope.entity.locales[i].seoInformation != undefined) {
                    if ($scope.entity.locales[i].seoInformation.keywords != undefined) {
                        if ($scope.entity.locales[i].seoInformation.keywords.length > countLocalesSeo) {
                            countLocalesSeo = $scope.entity.locales[i].seoInformation.keywords.length;
                        }
                    }
                }

                if($scope.entity.locales[i].metadata != undefined) {
                    if($scope.entity.locales[i].metadata.length > countLocales) {
                        countLocales = $scope.entity.locales[i].metadata.length;
                    }
                }
                $scope.entity.locales[i].metadata.sort(function(a, b){
                    if(a.position === b.position) {
                        var x = a.identifier.toLowerCase(), y = b.identifier.toLowerCase();
                        return x < y ? -1 : x > y ? 1 : 0;
                    }
                    if ( a.position < b.position )
                        return -1;
                    if ( a.position > b.position )
                        return 1;
                    return a.position - b.position;
                });
            }

            for (var i = 0; i < countLocalesSeo; i++) {
                $scope.options.push({});
            }
        };

        if ($scope.entity.categoryIds == undefined) {
            $scope.entity.categoryIds = [];
        }

        if ($scope.entity.productAssistant == undefined) {
            $scope.entity.productAssistant = false;
        }

        if ($scope.entity.useGlobalSettings == undefined) {
            $scope.entity.useGlobalSettings = false;
        }

        if ($scope.entity.sortOrder == undefined) {
            $scope.entity.sortOrder = 9999;
        }

        if ($scope.entity.productDimensions == undefined) {
            $scope.entity.productDimensions = [];
        }

        if ($scope.entity.tagIds == undefined) {
            $scope.entity.tagIds = [];
        }

        if ($scope.entity.sellingProducts == undefined) {
            $scope.entity.sellingProducts = [];
        }

        $scope.initPrices();

        switch ($scope.type) {
            case 'atomic':
                $scope.entity.type = 'atomics';
                break;
            case 'config':
                $scope.entity.type = 'configurables';
                break;
            case 'bundle':
                $scope.entity.type = 'bundles';
                break;
            default:
                break;
        }

        if ($scope.entity.stock == undefined) {
            $scope.entity.stock = {};
        }

        $scope.params = [{ name: 'filter[order]', value: 'position ASC'}];
        MetadataUnpagedService.setAdditionalParams($scope.params);
        MetadataUnpagedService.findAll().then(function () {
            $scope.metadatas = MetadataUnpagedService.getAll(true).data;

            if (!$scope.metadatas) {
                $scope.metadatas = [];
            }

            if($scope.entityId != '') {
                $scope.getMetadataFromServer();
            } else {
                $scope.entity.showPriceSearch = true;
                $scope.entity.showPriceCatalog = true;
                $scope.entity.showPriceDetail = true;


                for (var i = 0; i < $scope.metadatas.length; i++) {
                    if($scope.metadatas[i].mandatory) {
                        for(var j=0; j < $scope.locales.length; j++) {
                            if($scope.entity.locales[j].metadata === undefined) {
                                $scope.entity.locales[j].metadata = [];
                            }
                            $scope.entity.locales[j].metadata.push({
                                metadataNameId: $scope.metadatas[i].id,
                                name: $scope.metadatas[i].name,
                                productMetadataValues: []
                            });
                            if($scope.metadatas[i].inputType == 'MULTISELECT'){
                                for(var k = 0; k < $scope.metadata.metadataValues.length; k++) {
                                    $scope.entity.locales[j].metadata[$scope.entity.locales[j].metadata.length-1].productMetadataValues.push({
                                        value: $scope.metadatas[i].metadataValues[k].value,
                                        isSet: false,
                                        metadataValueId: $scope.metadatas[i].metadataValues[k].id
                                    });
                                }
                            }

                        }
                        MetadataService.findOne($scope.metadatas[i].id).then(function () {
                            $scope.metadataObjects.push(MetadataService.getOne());
                        });
                    }
                }
            }
        });

        $scope.setSellingProducts();
        $scope.initSelectedRefinementProducts();
    });

    $scope.setSellingProducts = function () {
        $scope.entity.crossSellingProducts = [];
        $scope.entity.upSellingProducts = [];
        $scope.entity.addOnSellingProducts = [];
        if($scope.entity.sellingProducts != undefined) {
            for (var i = 0; i < $scope.entity.sellingProducts.length; i++) {
                var sellingProduct = $scope.entity.sellingProducts[i];
                if (sellingProduct.sellingType == "CROSS_SELLING") {
                    $scope.entity.crossSellingProducts.push(sellingProduct);
                } else if (sellingProduct.sellingType == "UP_SELLING") {
                    $scope.entity.upSellingProducts.push(sellingProduct);
                } else if (sellingProduct.sellingType == "ADD_ON_SELLING") {
                    $scope.entity.addOnSellingProducts.push(sellingProduct);
                }
            }
        }
    };

    $scope.mergeSellingProductsWithType = function (entity, productArray, sellingType) {
        for (var i = 0; i < productArray.length; i++) {
            var sellingProduct = productArray[i];
            var sellingProductUpdate = {};
            sellingProductUpdate.productId = sellingProduct.id;
            sellingProductUpdate.sku = sellingProduct.sku;
            sellingProductUpdate.name = sellingProduct.name;
            sellingProductUpdate.publishState = sellingProduct.publishState;
            sellingProductUpdate.sellingType = sellingType;
            if (!entity.sellingProducts) {
                entity.sellingProducts = [];
            }
            entity.sellingProducts.push(sellingProductUpdate);
        }
    };

    $scope.$on("duplicateSaved", function () {
        var countLocales = 0;
        if($scope.entity.locales != undefined) {
            $scope.entity.locales.sort(function(a, b){
                if ( a.localeId < b.localeId )
                    return -1;
                if ( a.localeId > b.localeId )
                    return 1;
                return 0;
            });
            for (var i=0; i < $scope.entity.locales.length; i++) {
                if($scope.entity.locales[i].metadata != undefined) {
                    if($scope.entity.locales[i].metadata.length > countLocales) {
                        countLocales = $scope.entity.locales[i].metadata.length;
                    }
                }
                $scope.entity.locales[i].metadata.sort(function(a, b){
                    if(a.position === b.position) {
                        var x = a.identifier.toLowerCase(), y = b.identifier.toLowerCase();
                        return x < y ? -1 : x > y ? 1 : 0;
                    }
                    if ( a.position < b.position )
                        return -1;
                    if ( a.position > b.position )
                        return 1;
                    return a.position - b.position;
                });
            }
        }
        $scope.metadataObjects = [];
        $scope.getMetadataFromServer();
    });

    $scope.$on("beforeUpdate", function () {
        $scope.beforeBaseSave();
    });

    $scope.$on("beforeCreate", function () {
        $scope.beforeBaseSave();
    });

    $scope.beforeBaseSave = function () {

        for (var i = 0; i < $scope.entity.locales.length; i++) {
            var productLocale = $scope.entity.locales[i];
            if (Object.keys(productLocale).length <= 1 && !productLocale.name) {
                //if the name is not filled out remove this locale
                $scope.entity.locales.splice(i, 1);
            }
        }

        $scope.entity.sellingProducts = [];
        $scope.entity.sellingProducts = $scope.upSellingProducts.concat($scope.crossSellingProducts).concat($scope.addOnSellingProducts);
        for (var j = 0; j < $scope.locales.length; j++) {
            if ($scope.entity.locales[j] != undefined) {
                if ($scope.entity.locales[j].metadata != undefined) {
                    for (var i = 0; i < $scope.entity.locales[j].metadata.length; i++) {
                        for (var k = 0; k < $scope.entity.locales[j].metadata[i].productMetadataValues.length; k++) {

                            if ($scope.entity.locales[j].metadata[i].productMetadataValues[k].isSet == false) {
                                $scope.entity.locales[j].metadata[i].productMetadataValues.splice(k, 1);
                                k--;
                            }

                        }
                    }
                }
            }
        }
        if ($scope.entity.variants != undefined && $scope.entity.variants.length > 0) {
            //Variants have to be save seperately in the row
            $scope.entity.variants = undefined;
        }

        if ($scope.entity.printAreas != undefined) {
            for (var i = 0; i < $scope.entity.printAreas.length; i++) {
                var printArea = $scope.entity.printAreas[i];
                delete printArea.uploader;
                for (var j = 0; printArea.selectedRefinementProductIds && j < printArea.selectedRefinementProductIds.length; j++) {
                    if (!printArea.refinementProducts) {
                        printArea.refinementProducts = [];
                    }
                    printArea.refinementProducts.push({id: printArea.selectedRefinementProductIds[j]});
                }
            }
        }

        $scope.entityService.setAdditionalParams([{
            name: 'type',
            value: $scope.entity.type
        }]);

        // delete product prices with no selling price
        if ($scope.entity.prices) {
            $scope.entity.prices = $scope.entity.prices.filter(productPrice => {
                return productPrice.sellingPrice !== undefined && productPrice.sellingPrice !== null;
            });
        }
    };

    $scope.initSelectedRefinementProducts = function(){
        if($scope.entity.printAreas != undefined) {
            for (var i = 0; i < $scope.entity.printAreas.length; i++) {
                var printArea = $scope.entity.printAreas[i];
                if (!printArea.uploader) {
                    printArea.uploader = ImageService.initFileUpload(new FileUploader(
						{
							url: '/api/v1/media/images', 
							headers: SessionValuesService.getFullSessionHeader()
						}
					));
                    printArea.uploader.removeAfterUpload = true;

                    printArea.uploadViaInput = function (index) {
                        angular.element('#file-input-' + index).trigger('click');
                    };

                    printArea.uploadImage = function (image, printArea) {
                        $scope.printAreaImageUploadId = printArea.id;
                        image.upload();
                    };

                    printArea.uploader.onSuccessItem = function (fileItem, response, status, headers) {
                        for(var i = 0; i < $scope.entity.printAreas.length; i++){
                            var printArea = $scope.entity.printAreas[i];
                            if($scope.printAreaImageUploadId == printArea.id){
                                printArea.image = {};
                                printArea.image.id = response.id;
                                break;
                           }
                        }
                    };
                }
                if (!printArea.selectedRefinementProductIds) {
                    printArea.selectedRefinementProductIds = [];
                    if(printArea.refinementProductLocales === undefined) {
                        printArea.refinementProductLocales = [];
                    }
                    for (var j = 0; printArea.refinementProductLocales && j < printArea.refinementProductLocales.length; j++) {
                        var productLocale = printArea.refinementProductLocales[j];
                        printArea.selectedRefinementProductIds.push(productLocale.id);
                    }
                }
            }
        }
    };

    $scope.$on("afterUpdate", function () {
        $scope.initSelectedRefinementProducts();
        $scope.setSellingProducts();
    });

    $scope.$on("afterCreate", function () {
        $scope.initSelectedRefinementProducts();
        $scope.setSellingProducts();
    });
    //*********** LIST SELECT HANDLING ***********

    TagUnpagedService.findAll().then(function () {
        $scope.tags = TagUnpagedService.getAll(true).data;
    });
    $scope.tagsTableOptions = {};
    $scope.tagsTableOptions.name = 'Tags';
    $scope.tagsTableOptions.field = {
        id: 'NAME',
        heading: 'Name',
        attribute: 'name'
    };

    $scope.addOnSellingTableOptions = {};
    $scope.addOnSellingTableOptions.name = 'Add On Selling Produkte';
    $scope.addOnSellingTableOptions.fields = [{
        id: 'NAME',
        heading: 'Name',
        attribute: 'name'
    },{
        id: 'SKU',
        heading: 'Sku',
        attribute: 'sku'
    }];

    $scope.crossSellingTableOptions = {};
    $scope.crossSellingTableOptions.name = 'Cross Selling Produkte';
    $scope.crossSellingTableOptions.fields = [{
            id: 'NAME',
            heading: 'Name',
            attribute: 'name'
        },{
            id: 'SKU',
            heading: 'Sku',
            attribute: 'sku'
        }];

    $scope.upSellingTableOptions = {};
    $scope.upSellingTableOptions.name = 'Up Selling Produkte';
    $scope.upSellingTableOptions.fields = [{
        id: 'NAME',
        heading: 'Name',
        attribute: 'name'
    },{
        id: 'SKU',
        heading: 'Sku',
        attribute: 'sku'
    }];

    $scope.bundleTableOptions = {};
    $scope.bundleTableOptions.fields = [{
            id: 'NAME',
            heading: 'Name',
            attribute: 'name'
        },
        {
            id: 'PRICE',
            heading: 'Einkaufspreis',
            attribute: 'basePrice'
        },
        {
            id: 'SKU',
            heading: 'Sku',
            attribute: 'sku'
        },
        {
            id: 'AMOUNT',
            heading: 'Menge',
            attribute: 'amount',
            filterDisabled: true
        }];
    $scope.bundleTableOptions.name = 'Bundleprodukte';

    //*********** LIST SELECT HANDLING ***********


    //*********** REFINEMENT PRODUCTS ***********

    $scope.refinementProductTableOptions = {};
    $scope.refinementProductTableOptions.fields = [{
            id: 'name',
            heading: 'Name',
            attribute: 'name'
        },{
            id: 'id',
            heading: 'Id',
            attribute: 'id'
        }];
    $scope.refinementProductIds = [];
    $scope.refinementProductService = RefinementProductService;

    $scope.refinementProductService.setCurrentPage(0).then(function () {
        $scope.availableData.refinemenProducts = $scope.refinementProductService.getAll();
    });

    $scope.addPrintArea = function(){
        if($scope.entity.printAreas == undefined) {
            $scope.entity.printAreas = [];
        }
        $scope.entity.printAreas.push({});
        $scope.initSelectedRefinementProducts();
    };

    $scope.removePrintArea = function(printArea){
        for(var i = 0; i < $scope.entity.printAreas.length; i++){
            var printAreaTmp = $scope.entity.printAreas[i];
            if(printAreaTmp.id == printArea.id){
                $scope.entity.printAreas.splice(i, 1);
                break;
            }
        }
    };

    //*********** REFINEMENT PRODUCTS ***********

    //*********** IMAGE HANDLING ***********
    $scope.$on("afterInit", function () {
        if($scope.entity.productImages == undefined) {
            $scope.entity.productImages = [];
        };
    });

    $scope.removeImage = function (productImage) {
        var index = $scope.entity.productImages.indexOf(productImage);
        $scope.entity.productImages.splice(index, 1);
    };

    $scope.onDrop = function ($event, $data, array) {

        $scope.entity.productImages.push({
            image: $data,
            isCatalogImage: false,
            isDetailImage: false,
            isSearchImage: false,
            isInActive: false
        });
    };
    //*********** IMAGE HANDLING ***********

    //*********** PRICE HANDLING ***********

    $scope.$on("afterInit", function () {
        if ($scope.entity.prices == undefined || $scope.entity.prices.length == 0) {
            //TODO: hard coded group id. Where can I get the group id. Is the feature groups still existent?
            $scope.entity.prices = [{fromAmount: 1, toAmount: -1, customerGroup: {id: 1}}];
        } else {
            for (index in $scope.entity.prices) {
                $scope.entity.prices.sort(function (a, b) {
                    return a.fromAmount - b.fromAmount;
                });
                $scope.entity.prices[index].fromAmount = $scope.entity.prices[index].fromAmount.toString();
                $scope.entity.prices[index].toAmount = $scope.entity.prices[index].toAmount.toString();
            }
        }
    });

    $scope.showAddButton = function () {
        if($scope.entity && $scope.entity.prices.filter(function (value) {
            if(value.customerGroup.id === $scope.availableData.customerGroupId) {
                return true;
            } else {
                return false;
            }
        }).length === 0) {
            return true
        }
        return false;
    };

    $scope.showDimensionAddButton = function () {
        if($scope.entity.productDimensions == undefined || $scope.entity.productDimensions.length === 0) {
            return true
        }
        return false;
    };

    $scope.addPrice = function (price) {
        if(price === undefined) {
            $scope.entity.prices.push({
                fromAmount: "1",
                toAmount: "-1",
                sellingPrice: 0,
                customerGroup: {
                    id: $scope.availableData.customerGroupId
                }

            });
            return;
        }
        if (parseInt(price.toAmount) >= parseInt(price.fromAmount)) {
            var fromAmountNew = (parseInt(price.toAmount) + 1).toString();
            $scope.entity.prices.push({
                fromAmount: fromAmountNew,
                toAmount: '-1',
                sellingPrice: price.sellingPrice,
                pseudoPrice: price.pseudoPrice,
                customerGroup: {
                    id: $scope.availableData.customerGroupId
                }

            });
            jQuery(".last-item input").css("border-color", "");
        } else {
            jQuery(".last-item input").css("border-color", "red");
        }
        $scope.checkRange();
    };

    $scope.addDimension = function () {
            $scope.entity.productDimensions.push({
                minValue: "1",
                maxValue: "-1",
                step: 0,
                name: "",
                unit: 0

            });
            return;

        if (parseInt(price.toAmount) >= parseInt(price.fromAmount)) {
            var fromAmountNew = (parseInt(price.toAmount) + 1).toString();
            jQuery(".last-item input").css("border-color", "");
        } else {
            jQuery(".last-item input").css("border-color", "red");
        }
        $scope.checkRange();
    };


    $scope.removeProductDimension = function (index) {
        $scope.entity.productDimensions.splice(index, 1);
    };


    $scope.removePrice = function (index) {
        $scope.entity.prices.splice(index, 1);

        var customerGroupPrices = $scope.entity.prices.filter(function (e) {
            return e.customerGroup.id === price.customerGroup.id;
        });

        if(customerGroupPrices[customerGroupPrices.length - 1] !== undefined) {
            customerGroupPrices[customerGroupPrices.length - 1].toAmount = '-1';
        }
        $scope.checkRange();
    };
    //*********** PRICE HANDLING ***********

    //*********** SELLING PRODUCTS HANDLING ***********
    $scope.$on("afterInit", function () {
        $scope.crossSellingProducts = [];
        $scope.upSellingProducts = [];
        $scope.addOnSellingProducts = [];
        if($scope.entity.sellingProducts == undefined) {
            $scope.entity.sellingProducts = [];
        }
        for(var i=0; i < $scope.entity.sellingProducts.length; i++) {
            var product = $scope.entity.sellingProducts[i];
            if(product.sellingType == "CROSS_SELLING") {
                $scope.crossSellingProducts.push(product);
            } else if(product.sellingType == "UP_SELLING"){
                $scope.upSellingProducts.push(product);
            } else if(product.sellingType == "ADD_ON_SELLING"){
                $scope.addOnSellingProducts.push(product);
            }
        }

    });
    //*********** SELLING PRODUCTS HANDLING ***********

    $scope.tinymceOptions = {
        theme: 'modern',
        height: '500',
        convert_urls: false,
        relative_urls: false,
        remove_script_host: false,
        plugins: [
            'link advlist lists image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template paste textcolor colorpicker textpattern imagetools',
            'fullpage moodiaimage'
        ],
        menubar: "insert | tools",
        toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
        toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | fontselect | code ',
        image_advtab: true
    };

    /*/-----PRODUCT IMAGE HANDLING-----/*/
    $scope.setCatalogImage = function(productImage) {
        for(var i=0; i<$scope.entity.productImages.length; i++)
        {
            if($scope.entity.productImages[i].image.id == productImage.productImage.image.id) {
                $scope.entity.productImages[i].isCatalogImage = true;
            } else {
                $scope.entity.productImages[i].isCatalogImage = false;
            }
        }
    };

    $scope.setSearchImage = function(productImage) {
        for(var i=0; i<$scope.entity.productImages.length; i++)
        {
            if($scope.entity.productImages[i].image.id == productImage.productImage.image.id) {
                $scope.entity.productImages[i].isSearchImage = true;
            } else {
                $scope.entity.productImages[i].isSearchImage = false;
            }
        }
    };

    $scope.setDetailImage = function(productImage) {
        for(var i=0; i<$scope.entity.productImages.length; i++)
        {
            if($scope.entity.productImages[i].image.id == productImage.productImage.image.id) {
                $scope.entity.productImages[i].isDetailImage = true;
            } else {
                $scope.entity.productImages[i].isDetailImage = false;
            }
        }
    };
    /*-----PRODUCT IMAGE HANDLING-----*/


    /*/-----DUPLICATE PRODUCT-----/*/
    $scope.duplicate = function () {
        NotificationService.confirm({
            statusText: 'Haben sie das aktuelle Produkt gespeichert?'
        }).then(function () {
            NotificationService.notifyWarning({
                statusText: 'Bitte passen Sie im Duplikat den Namen, den Slug und die Sku an.'
            });
            $scope.entityId = "";
            $scope.newEntity = true;

            delete $scope.entity.id;
            delete $scope.entity.stock.id;

            $scope.entity.sku = $scope.entity.sku + '-duplicate';
            $scope.entity.slug = $scope.entity.slug + '-duplicate';
            $scope.entity.publishState = 'MAINTENANCE';

            for(var i = 0; i < $scope.entity.locales.length; i++) {
                $scope.entity.locales[i].name = $scope.entity.locales[i].name;
                for(var j = 0; j < $scope.entity.locales[i].metadata.length; j++) {
                    delete $scope.entity.locales[i].metadata[j].id;
                    for(var k = 0; k < $scope.entity.locales[i].metadata[j].productMetadataValues.length; k++) {
                        delete $scope.entity.locales[i].metadata[j].productMetadataValues[k].id;
                    }
                }
            };

            for(var i = 0; i < $scope.entity.prices.length; i++) {
                delete $scope.entity.prices[i].id;
            };
        }, function () {
        });
    };
    /*-----DUPLICATE PRODUCT-----*/

    /*/-----Check price range-----/*/
    $scope.checkRange = function () {
        console.log("checkRange");

        /*for(var i=0; i<$scope.entity.prices.length; i++)
        {
            if(i > 0) {
                if(parseInt($scope.entity.prices[i].fromAmount) < parseInt($scope.entity.prices[i-1].fromAmount)) {
                    NotificationService.notifyGeneralError({
                        statusText: 'Fehler in der Preisstaffel'
                    });
                }
            }

           /* if(i == $scope.entity.prices.length-1){
                if($scope.entity.prices[i].fromAmount > $scope.entity.prices[i].toAmount && $scope.entity.prices[i].toAmount != -1){

                    NotificationService.notifyGeneralError({
                        statusText: 'Fehler in der Preisstaffel1'
                    });
                    return false;
                }
            } else {
                /* same range check to greater than from check
                if($scope.entity.prices[i].fromAmount > $scope.entity.prices[i].toAmount){
                    NotificationService.notifyGeneralError({
                        statusText: 'Fehler in der Preisstaffel2'
                    });
                    return false;
                }
            }
            if(i>=1){
                /* previous fromPrice check with current toPrice
                if($scope.entity.prices[i].fromAmount <= $scope.entity.prices[i-1].toAmount){
                    NotificationService.notifyGeneralError({
                        statusText: 'Fehler in der Preisstaffel3'
                    });
                    return false;
                }
            }

        }*/

        return true;
    };
    /*-----Check price range-----*/

    $scope.initPrices = function () {
        var shopId = SessionValuesService.getShop();

        var set = new Set();
        if($scope.entity.prices == undefined) {
            $scope.entity.prices = [];
        }
        /*for(var i=0; i < $scope.entity.prices.length; i++) {
            set.add($scope.entity.prices[i].customerGroup.id);
        }
        var list = Array.from(set);
        $scope.customerGroups = [];
        for(var j=0; j < list.length; j++) {
            for(var i=0; i < $scope.entity.prices.length; i++) {
                if(list[j] === $scope.entity.prices[i].customerGroup.id){
                    $scope.customerGroups.push($scope.entity.prices[i].customerGroup);
                    break;
                }
            }
        }
        */
        ShopService.findOne(shopId).then(function () {
            var shop = ShopService.getOne();
            $scope.availableData.customerGroupId = shop.defaultCustomerGroupId;
           /* if(!set.has($scope.customerGroupId)) {
                $scope.customerGroupId = list[0];
            }*/
        });
    };

    /*/-----Keywords-----/*/
    $scope.addKeyword = function () {
        $scope.options.push({});
    };

    $scope.removeKeyword = function (index) {
        $scope.options.splice(index, 1);
    };
    /*-----Keywords-----*/

    $scope.getEntityObject = function () {
        return $scope.entity;
    };

    $scope.getEntityObjectString = function () {
        return "entity";
    };

    $scope.validationConfig = {
        name: {
            method: "isset",
            fieldName: "Name",
            errorMsg: "Bitte geben Sie einen Namen in der Standard-Sprache an.",
            isLocaleValue: true,
            customValidate: function (entity) {
                //can be used for specific validation
                return true;
            }
        },
        taxId: {
            method: "isset",
            fieldName: "Steuersatz",
            errorMsg: "Bitte geben Sie einen Steuersatz an."
        },
        publishState: {
            method: "isset",
            fieldName: "Status",
            errorMsg: "Bitte geben Sie einen Produkt-Status an."
        },
        //TODO: validate weight as number only if it's not empty
        /*weight: {
            method: "isNumeric",
            fieldName: "Gewicht",
            errorMsg: "Bitte geben Sie ein Gewicht an. Es muss ein numerischer Wert sein."
        },*/
        sellingPrice: {
            method: "isset",
            fieldName: "Verkaufspreis",
            errorMsg: "Für den Verkaufspreis ist kein Wert hinterlegt.",
            customValidate: function (entity) {
                for (var i = 0; i < entity.prices.length; i++) {
                    if (entity.prices[i].sellingPrice !== undefined) {
                        return true
                    }
                }
                return false;
            }
        },
        metadata: {
            method: "isset",
            fieldName: "Produktmerkmale",
            errorMsg: "Nicht alle Pflichtmerkmale sind vorhanden.",
            customValidate: function (entity) {
                $scope.customValidation = true;
                for (var i = 0; i < $scope.metadatas.length; i++) {
                    if ($scope.metadatas[i].mandatory === true && $scope.customValidation) {
                        for (j = 0; j < entity.locales.length; j++) {
                            if ($scope.customValidation) {
                                for (k = 0; k < entity.locales[j].metadata.length; k++) {
                                    if ($scope.metadatas[i].id === entity.locales[j].metadata[k].metadataNameId) {
                                        if (entity.locales[j].metadata[k].productMetadataValues.length > 0) {
                                            $scope.customValidation = true;
                                        } else {
                                            $scope.customValidation = false;
                                        }
                                        break;
                                    }
                                    $scope.customValidation = false;
                                }
                            }
                        }
                    }
                }
                return $scope.customValidation;
            }
        },
        attributes: {
            method: "isset",
            fieldName: "Attribute",
            errorMsg: "Es sind keine Attribute vorhanden",
            customValidate: function (entity) {
                $scope.customValidation = true;

                if($scope.entity.type === "configurables" && $scope.entityId === '') {
                    if($scope.entity.selectedAttributes === undefined) {
                        $scope.customValidation = false;
                    } else {
                        for (var i = 0; i < $scope.entity.selectedAttributes.length; i++) {
                            if($scope.entity.selectedAttributes[i].optionIds != undefined && $scope.entity.selectedAttributes[i].optionIds.length > 0) {
                                $scope.customValidation = true;
                                break;
                            } else {
                                $scope.customValidation = false;
                            }
                        }
                    }
                }

                return $scope.customValidation;
            }
        }
    };

    /*/-----METADATA HANDLING-----/*/

    $scope.getMetaDataSet = function () {
        for(var i=0; i < $scope.metadataSets.length; i++) {
            if($scope.metadataSets[i].id == $scope.availableData.metadataSetId) {
                $scope.metadataSet = $scope.metadataSets[i];
            }
        }

        if($scope.metadataSet != undefined) {
            for(var j=0; j < $scope.locales.length; j++) {
                if($scope.entity.locales[j] == undefined) {
                    $scope.entity.locales[j] = {
                        localeId: $scope.locales[j].id
                    }
                }
                if($scope.entity.locales[j].metadata == undefined) {
                    $scope.entity.locales[j].metadata = [];
                }
                var metadatas = $scope.entity.locales[j].metadata.length;
                for(var i=0; i < $scope.metadataSet.metadataNames.length; i++) {
                    var alreadyExists = false;
                    for (var k = 0; k < $scope.entity.locales[j].metadata.length; k++) {
                        console.log(k);
                        if ($scope.entity.locales[j].metadata[k].metadataNameId == $scope.metadataSet.metadataNames[i].id) {
                            alreadyExists = true;
                        }
                    }

                    if (!alreadyExists) {
                        $scope.entity.locales[j].metadata.push({
                            metadataNameId: $scope.metadataSet.metadataNames[i].id,
                            productMetadataValues: []
                        });
                        if ($scope.metadataSet.metadataNames[i].inputType == 'MULTISELECT') {
                            for (var k = 0; k < $scope.metadataSet.metadataNames[i].metadataValues.length; k++) {
                                $scope.entity.locales[j].metadata[$scope.entity.locales[j].metadata.length-1].productMetadataValues.push({
                                    value: $scope.metadataSet.metadataNames[i].metadataValues[k].value,
                                    isSet: false
                                });
                            }
                        }
                        MetadataService.findOne($scope.metadataSet.metadataNames[i].id).then(function () {
                            $scope.metadataObjects.push(MetadataService.getOne());
                        });
                    }
                }
            }
        }
    };

    $scope.getMetaData = function () {

        for (var i = 0; i < $scope.metadatas.length; i++) {
            if ($scope.metadatas[i].id == $scope.selected.metadataId) {
                $scope.metadata = $scope.metadatas[i];
            }
        }
        for (var j = 0; j < $scope.locales.length; j++) {
            if ($scope.entity.locales[j] && !$scope.entity.locales[j].metadata) {
                $scope.entity.locales[j].metadata = [];
            }
        }

        if (!$scope.metadata) {
            return;
        }

        for (var j = 0; j < $scope.locales.length; j++) {

            if (!$scope.entity.locales[j]) {
                $scope.entity.locales[j] = {
                    localeId: $scope.locales[j].id
                }
            }
            if (!$scope.entity.locales[j].metadata) {
                $scope.entity.locales[j].metadata = [];
            }

            $scope.entity.locales[j].metadata.push({
                metadataNameId: $scope.metadata.id,
                name: $scope.metadata.name,
                inputType: $scope.metadata.inputType,
                identifier: $scope.metadata.identifier,
                productMetadataValues: []
            });

            if ($scope.metadata.inputType == 'MULTISELECT') {
                for (var k = 0; k < $scope.metadata.metadataValues.length; k++) {
                    $scope.entity.locales[j].metadata[$scope.entity.locales[j].metadata.length - 1].productMetadataValues.push({
                        value: $scope.metadata.metadataValues[k].value,
                        isSet: false,
                        metadataValueId: $scope.metadata.metadataValues[k].id
                    });
                }
            }
        }

        MetadataService.findOne($scope.metadata.id).then(function () {
            $scope.metadataObjects.push(MetadataService.getOne());
        });
    };

    $scope.getProductMetadataValueIndexByValue = function (productMetadataValues, identifier) {
        for (var i = 0; i < productMetadataValues.length; i++) {

            if (productMetadataValues[i].identifier == identifier) {
                return i;
            }
        }
    };

    $scope.$watch('entity', function(newValue, oldValue) {});
    $scope.$watch('metadataObjects', function(newValue, oldValue) {});

    $scope.getMetadataFromServer = function () {

        for(var i = $scope.metadataObjects.length; i < $scope.entity.locales[0].metadata.length; i++) {
            if($scope.entity.locales[0].metadata[i].metadataNameId != undefined) {
                MetadataService.findOne($scope.entity.locales[0].metadata[i].metadataNameId).then(function () {
                    $scope.metadataObjects.push(MetadataService.getOne());
                    if($scope.metadataObjects.length == $scope.entity.locales[0].metadata.length) {
                        for (var j = 0; j < $scope.metadataObjects.length; j++) {
                            $scope.metadataObjects[j].locales.sort(function (a, b) {
                                if ( a.localeId < b.localeId )
                                    return -1;
                                if ( a.localeId > b.localeId )
                                    return 1;
                                return 0;
                            });

                        }
                        $scope.metadataObjects.sort(function (a, b) {
                            if(a.position === b.position) {
                                var x = a.identifier.toLowerCase(), y = b.identifier.toLowerCase();
                                return x < y ? -1 : x > y ? 1 : 0;
                            }
                            if ( a.position < b.position )
                                return -1;
                            if ( a.position > b.position )
                                return 1;
                            return a.position - b.position;
                        });
                        for (var m = 0; m < $scope.metadataObjects.length; m++) {
                            if ($scope.metadataObjects[m].inputType == "MULTISELECT") {
                                for (var k = 0; k < $scope.entity.locales.length; k++) {
                                    for (var n = 0; n < $scope.metadataObjects[m].locales[k].metadataValues.length; n++) {
                                        var foundOne = false;
                                        $scope.entity.locales[k].metadata[m].productMetadataValues.sort(function (a, b) {
                                            if (a.metadataValueId < b.metadataValueId)
                                                return -1;
                                            if (a.metadataValueId > b.metadataValueId)
                                                return 1;
                                            return 0;
                                        });
                                        for (var p = 0; p < $scope.entity.locales[k].metadata[m].productMetadataValues.length; p++) {
                                            if($scope.metadataObjects[m].locales[k].metadataValues[n].id == $scope.entity.locales[k].metadata[m].productMetadataValues[p].metadataValueId) {
                                                $scope.entity.locales[k].metadata[m].productMetadataValues[p].isSet = true;
                                                foundOne = true;
                                                break;
                                            }
                                        }
                                        if(!foundOne) {
                                            var index = 0;
                                            for(var i=0; i < $scope.entity.locales[k].metadata[m].productMetadataValues.length; i++) {
                                                if($scope.metadataObjects[m].locales[k].metadataValues[n].id < $scope.entity.locales[k].metadata[m].productMetadataValues[i].metadataValueId) {
                                                    break;
                                                }
                                                index++;
                                            }
                                            $scope.entity.locales[k].metadata[m].productMetadataValues.splice(index,0,$scope.metadataObjects[m].locales[k].metadataValues[n]);
                                            $scope.entity.locales[k].metadata[m].productMetadataValues[index].isSet = false;
                                            $scope.entity.locales[k].metadata[m].productMetadataValues[index].metadataValueId = $scope.metadataObjects[m].locales[k].metadataValues[n].id;
                                        }


                                    }
                                }
                            }
                        }
                    }
                })};
        }
    };

    $scope.getProductMetadataValueIndexByValue = function(productMetadataValues, value) {
        for(var i=0; i < productMetadataValues.length; i++) {
            if(productMetadataValues[i].value == value) {
                return i;
            }
        }
    };

    $scope.deleteMetadata = function (index) {
        for (var i=0; i < $scope.entity.locales.length; i++) {
            $scope.entity.locales[i].metadata.splice(index, 1);
        }
        $scope.metadataObjects.splice(index,1);
    };

    /**
     * Metadata value identifier is deleted when user inputs in text-type field. If identifier is passed to backend,
     * it will update metadata value. We don't want to that because metadata value can be assigned to other products too.
     */
    $scope.deleteProductMetadataValueIdentifier = (productMetadataValue) => {
        if (!productMetadataValue) {
            return;
        }

        productMetadataValue.identifier = undefined;
    }

    $scope.generateIdentifierUID = function() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();

    };

    /*-----METADATA HANDLING-----*/

}
;
