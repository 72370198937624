define(
    'directives/contentConfigurator/contentSnippets/layoutSnippet/LayoutSnippet',[
        'app',
        'angular',
        'services/ContentBuilderHelper',
        'directives/contentConfigurator/contentSnippets/_snippet'
    ],
    function (app) {
        'use strict';
        app.directive('layoutSnippet', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'E',
                scope: {
                    metaIdentifier: '<',
                    snippet: "=",
                    position: "=",
                    parent: "<"
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/layoutSnippet/views/layoutSnippet.html",
                controller: ['$scope', '$controller', '$document', 'ContentBuilderHelper',
                    function ($scope, $controller, $document, ContentBuilderHelper) {

                        angular.extend(this, $controller('snippetController', {
                            $scope: $scope
                        }));

                        var ELEMENT_TYPE = $scope.ELEMENT_TYPE = ContentBuilderHelper.ELEMENT_TYPE;

                        var _init = function () {
                            $scope.snippetType = 'layout';
                            $scope.elementType = $scope.ELEMENT_TYPE.SNIPPET;



                            $scope.dragStyle = {};
                        };
                        _init();

                        $scope.getRowClasses = function (row) {
                            var rowClasses = row.classes.slice();
                            if (row.container === 'fixed') {
                                rowClasses.push('holder');
                            }
                            if (row.customClasses) {
                                rowClasses.push(row.customClasses);
                            }

                            return rowClasses;
                        }

                    }]
            }
        }]);
    }
);

