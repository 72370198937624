/**
 * Created by nmayer on 13.06.16.
 */
define('modules/crm/customerGroup/CustomerGroupFormController',[
        'modules/module',
        'modules/basemodule/controllers/EntityFormController',
        'services/ValidationService',
        'settings'
    ],
    function (module) {
        'use strict';
        module.controller('CustomerGroupFormController', ['$scope', '$controller', 'ValidationService', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, ValidationService, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityFormController', {
                    $scope: $scope
                }));

                $scope.formTabs = [
                    {
                        label: 'Allgemein',
                        template: BASE_TEMPLATES_PATH + '/crm/customergroup/form-main.html',
                        active: true
                    }
                ];

                $scope.init("CustomerGroup").then(function () {
                    if($scope.entity.useDiscount === undefined) {
                        $scope.entity.useDiscount = true;
                    }

                    if($scope.entity.discount === undefined) {
                        $scope.entity.discount = 0;
                    }
                });

                $scope.listState = 'root.customergroups';

                $scope.validate = function () {
                    return ValidationService.validate($scope.entity, {
                        name: {
                            method: "isset",
                            fieldName: "Name"
                        },
                        groupKey: {
                            method: "isset",
                            fieldName: "Kürzel"
                        }
                    });
                };

                $scope.priceHandling = [{
                        display: "Rabatt",
                        value: true
                    },
                    {
                        display: "Aufschlag",
                        value: false
                    }];

            }
        ]);
    }
);
