define(
    'services/EntityCrudUnpagedService',[
        'app',
        'modules/module',
		'services/LocalStorageService'
    ], 
    function (app, module) {
        'use strict';
        return module.factory('EntityCrudUnpagedService', [
            '$rootScope', 
            '$injector',
			'LocalStorageService',
            EntityCrudUnpagedService
        ]);
    }
);

function EntityCrudUnpagedService($rootScope, $injector) {

    var _query    = {},
        _entities = [],
        _entity,       
        _entityName,
		_additionalParams = [],
        _model;
    
    var _clear = function () {
        return _findAll();
    };

    var _findAllSuccess = function (response) {
        _entities   = response;
        $rootScope.$broadcast(_entityName + 'Changes', _entities);
        return response;
    };

    var _findAllError = function (response) {
        _entities   = [];
        $rootScope.$broadcast(_entityName + 'Error', response);
        return response;
    };

    var _findAll = function () {
        _entity = undefined;
        if(Object.keys(_query).length === 0) {
            var params = {};
        } else {
            var params = {
                q: btoa(angular.toJson(_query))
            };
        }
		
		//adding additional parameters to the call
		_additionalParams.forEach(function(param){
			params[param.name] = param.value;
		});		
		_additionalParams = [];

        return _model.findAll(params).$promise.then(_findAllSuccess, _findAllError);
    };

    return {
        clear: _clear,
        getModel: function () {
            return _model;
        },
        setType: function (type) {
            _entityName = type;
			_model = new $injector.get("ModelFactory")[type]();
            return this;
        },
        getType: function () {
            return _entityName;
        },        
		setAdditionalParams: function (params) {
			_additionalParams = params;
		},
        setQuery: function (query, findAll) {
            _query = query;
			
			if(findAll !== undefined && findAll === false){
				return;
			}
			
            return _findAll();
        },
        getAll: function(){
            return _entities;
        },
        getOne: function(){
            return _entity;
        },
        findAll: function(deactivatePaging){
            return _findAll(deactivatePaging);
        },
        findOne: function(id) {
            return _model.findOne({id: id}).$promise.then(function(response) {
                _entity = response;
            });
        },
        create: function(entity) {
            var promise = _model.create(entity).$promise;
            promise.then(_clear);
            return promise;
        },
        update: function(entity) {
            var promise = _model.update(entity).$promise;
            promise.then(_clear);
            return promise;
        },
        delete: function(id) {
            var promise = _model.delete({id: id}).$promise;
            promise.then(_clear);
            return promise;
        }
    };
};
