/**
 * Created by Nico on 25.07.2016.
 */
define('services/SocialMediaProfileTypeService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('SocialMediaProfileTypeService', [
        '$injector',
        SocialMediaProfileTypeService
    ]);
});


function SocialMediaProfileTypeService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('SocialMediaProfilType');

    angular.extend(this, crudInstance);

    return this;
};
