define('services/ShopOrderProtocolService',[
    'app',
    'modules/module'
], function (app, module) {
    'use strict';
    return module.factory('ShopOrderProtocolService', [
        '$injector',
        ShopOrderProtocolService
    ]);
});

function ShopOrderProtocolService($injector) {

    this.model = new $injector.get("ModelFactory")["ShopOrderProtocol"]();

    this.get = function (shopOrderId) {
        return this.model.get({shopOrderId: shopOrderId}).$promise;
    };

    return this;
};
