define('modules/login/controllers/LoginController',[
        'app',
        'modules/module',
        'modules/models',
        'services/UserService',
        'services/StateFaderService',
        'services/NavigationService'
    ],
    function (module) {
        'use strict';
        module.controller('LoginController', ['$rootScope', '$scope', '$state', 'UserService', 'NavigationService', function ($rootScope, $scope, $state, UserService, NavigationService) {
            $rootScope.$emit('hideNavigation');

            var state = 1;

            $scope.isLogin = true;

            $scope.reset = { emailOrUsername: "" };
            $scope.showBadUsername = false;
            $scope.showBadCredentials = false;

            $scope.login = function () {
                UserService.login($scope.user).then(function () {
                    var token = UserService.getToken();
                    if (token) {
//                            $state.go('root.home');
//                            $state.go('root.econDashboard').then(function () {
                        NavigationService.setActiveItem(NavigationService.getItems()[0]);
                        NavigationService.setActiveSubItem(NavigationService.getItems()[0].subMenu[0]);
//                            });
                        $rootScope.$emit('showNavigation');
                        $scope.showBadCredentials = false;

                    } else {
                        $scope.showBadCredentials = true;
                    }
                });
            };

            $scope.logout = function () {
                UserService.logout();
                $state.go('root.login');
            };

            $scope.passwordReset = function () {
                UserService.sendPasswordReset($scope.reset.emailOrUsername).then(function () {
                    if (UserService.isBadUsername()) {
                        $scope.showBadUsername = UserService.isBadUsername();
                    }
                });
            };

            $scope.getCurrentUser = function (args) {
                $scope.user = UserService.getUser();
                if (!$scope.user) {
                    $scope.user = {};
                }
            };

            $scope.showLoginForm = function () {
                return state === 1;
            };

            $scope.showPassResetForm = function () {
                return state === 2;
            };

            $scope.setState = function (val) {
                $scope.showBadUsername = false;
                $scope.showBadCredentials = false;
                state = val;
            };

            if (UserService.isLoggedIn()) {
                $scope.logout();
            }

            $rootScope.$on('userStatusChanged', $scope.getCurrentUser);
            $scope.getCurrentUser();

        }]);
    }
);

