define('services/ProductUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService',
], function (app, module) {
    'use strict';
    return module.factory('ProductUnpagedService', [
        '$injector',
        ProductUnpagedService
    ]);
});


function ProductUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('Product');

    angular.extend(this, crudInstance);

    return this;
};
