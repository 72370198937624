define('modules/settings/systemInformation/SystemInformationController',['modules/module',
        'services/SystemInformationService'
    ],
    function (module) {
        'use strict';
        module.controller('SystemInformationController', ['$scope', 'SystemInformationService',
            function ($scope, SystemInformationService) {
                console.log(SystemInformationService);
                console.log("SystemInformationController");
                SystemInformationService.getBackendVersion().then(
                    function (response) {
                        $scope.backendVersion = response.backEndVersion;
                        console.log(response);
                    })
            }
        ]);
    }
);

