/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/country/CountryFormController',[
        'modules/module',
        'modules/basemodule/controllers/EntityFormController',
        'settings'
    ],
    function (module) {
        'use strict';
        module.controller('CountryFormController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityFormController', {
                    $scope: $scope
                }));

                $scope.formTabs = [
                    {
                        label: 'Allgemein',
                        template: BASE_TEMPLATES_PATH + '/settings/country/form-main.html',
                        active: true
                    }
                ];
                $scope.init("Country").then(function () {

                });

                $scope.listState = 'root.countries';

            }
        ]);
    }
);
