define('services/TagUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('TagUnpagedService', [
        '$injector',
        TagUnpagedService
    ]);
});


function TagUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('Tag');

    angular.extend(this, crudInstance);

    return this;
};
