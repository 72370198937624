define('services/ReportService',[
    'app',
    'modules/module',
    'services/LocalStorageService'

], function (app, module) {
    'use strict';
    return module.factory('ReportService', [
        '$rootScope',
        '$injector',
        'LocalStorageService',
        ReportService
    ]);
});

function ReportService($rootScope, $injector, LocalStorageService) {

    /*    crudInstance = $injector.instantiate(CrudService);
     crudInstance.setType('Report');

     angular.extend(this, crudInstance);*/

    var _entityName = "Report";
    var _totalPages,_totalItems;
    this.getCurrentPage = function () {
        if (LocalStorageService.read(_entityName + 'CurrentPage') === null) {
            LocalStorageService.write(_entityName + 'CurrentPage', 0);
        }
        return (parseInt(LocalStorageService.read(_entityName + 'CurrentPage')) + 1);
    }
    this.getTotalPages = function () {
        return _totalPages;
    }
    this.getTotalItems = function () {
        return _totalItems;
    }
    this.getPageSize = function () {
        var pageSize = LocalStorageService.read(_entityName + 'PageSize');
        if (pageSize == undefined) {
            pageSize = 10;
        }
        return pageSize;
    }
    this.setPageSize = function (pageSize) {
        LocalStorageService.write(_entityName + 'PageSize', pageSize);

    }
    this.setCurrentPage = function (currentPage) {
        LocalStorageService.write(_entityName + 'CurrentPage', currentPage);
    }


    var _findAllSuccess = function (response) {
        //See if it still works wih everything else????
        _entities = response.data;
        _totalPages = response.pages;
        _totalItems = response.count;
        currentPage = response.page;

        LocalStorageService.write(_entityName + 'CurrentPage', currentPage);
        $rootScope.$broadcast(_entityName + 'Changes', _entities);
        return response;
    };

    var _findAllError = function (response) {
        _entities = [];
        _totalPages = 0;
        _totalItems = 0;
        $rootScope.$broadcast(_entityName + 'Error', response);
        return response;
    };


    var values = null;
    this.findTopProducts = function (dateObj, amount) {
        var model = new $injector.get("ModelFactory").Report();
        var params = {startDate: dateObj.start, endDate: dateObj.end, amount: amount, page: this.getCurrentPage()-1, size: this.getPageSize()};
        return model.findTopProducts(params).$promise.then(_findAllSuccess, _findAllError);
        /*        {
         values = response.data;
         }, function () {
         console.error("Error in findTopProducts (Top Products)");
         });
         }*/
    };


    this.getProfitReport = function (dateObj, amount) {
        var model = new $injector.get("ModelFactory").Report();
        var params = {startDate: dateObj.start, endDate: dateObj.end, amount: amount, page: this.getCurrentPage()-1, size: this.getPageSize()};
        return model.getProfitReport(params).$promise.then(_findAllSuccess, _findAllError);

    };


    this.getSeminarDetailReport = function (id, amount, appointmentId, filter) {
        var model = new $injector.get("ModelFactory").Report();
        var object1 = {id: id, appointmentId: appointmentId};
        const params = Object.assign(filter, object1);
        return model.getSeminarDetailReport(params).$promise.then(_findAllSuccess, _findAllError);

    };


    this.getOrderStats = function (dateObj, amount) {
        var model = new $injector.get("ModelFactory").Report();
        var params = {startDate: dateObj.start, endDate: dateObj.end, amount: amount, page: this.getCurrentPage()-1, size: this.getPageSize()};
        return model.getOrderStats(params).$promise.then(_findAllSuccess, _findAllError);
    };


    this.getSeminarReport = function (dateObj, seminarFilter, amount) {
        var model = new $injector.get("ModelFactory").Report();
        var object1 = {startDate: dateObj.start, endDate: dateObj.end, amount: amount, page: this.getCurrentPage()-1, size: this.getPageSize()};
        const params = Object.assign(seminarFilter, object1);
        return model.getSeminarReport(params).$promise.then(_findAllSuccess, _findAllError);
    };


    this.getSeminarAppointments = function (id) {
        var model = new $injector.get("ModelFactory").Report();
        return model.getSeminarAppointments({id: id}).$promise.then(_findAllSuccess, _findAllError);
    };

    this.getValues = function () {
        return values;
    };

    return this;

}
;
