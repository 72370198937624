define('services/DataFeedInputService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('DataFeedInputService', [
        '$injector',
        DataFeedInputService
    ]);
});


function DataFeedInputService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('DataFeedInput');

    angular.extend(this, crudInstance);

    this.generateDataFeed = function (dataFeedInputId) {
        return this.getModel().generateDataFeed({id: dataFeedInputId}).$promise;
    };

    return this;
};
