define('modules/crm/customer/controllers/CustomerFormController',[
    'modules/module',
    'modules/basemodule/controllers/EntityFormController',
    'modules/crm/customer/controllers/CustomerBaseFormController',
    'modules/orderManagement/controllers/OrderManagementFormController',
    'services/NavigationService',
    'settings'
],
    function (module) {
        'use strict';
        module.controller('CustomerFormController', [
            '$scope',
            '$controller',
            'CustomerService',
            'BASE_TEMPLATES_PATH',
            'NavigationService',
            'ValidationService',
            'OrderService',
            CustomerFormController
        ]);
    }
);

function CustomerFormController($scope, $controller, CustomerService, BASE_TEMPLATES_PATH, NavigationService, ValidationService, OrderService) {

    $scope.entityType = "CUSTOMER";
    $scope.listState = 'root.customers';

    angular.extend(this, $controller('CustomerBaseFormController', {
        $scope: $scope
    }));

    $scope.formTabs[$scope.formTabs.length] = {
        label: 'Organisationen',
        template: BASE_TEMPLATES_PATH + '/crm/customer/organisation-list.html'
    };

    $scope.init('Customer').then(function () {

        if(!$scope.entity.customerGroup) {
            $scope.entity.customerGroup = {"id":1,"name":"Shopkunden","groupKey":"SK","useDiscount":false,"isDeletable":false};
        }


        if(!$scope.entity.customerGroup) {
            $scope.entity.customerGroup = {"id":1,"name":"Shopkunden","groupKey":"SK","useDiscount":false,"isDeletable":false};
        }

        if(!$scope.entity.customerType){
            $scope.entity.customerType = "CUSTOMER";
        }

        if(!$scope.entity.customerNumber)
            $scope.entity.customerNumber = 3;
        if(!$scope.entity.optInStatus)
            $scope.entity.optInStatus = 0;

        if(!$scope.entity.defaultInvoiceAddress)
            $scope.entity.defaultInvoiceAddress = {};
        if(!$scope.entity.defaultShippingAddress)
            $scope.entity.defaultShippingAddress = {};

        if(!$scope.entity.customerGroup)
            $scope.entity.customerGroup = {};
        if(!$scope.entity.socialMediaProfiles)
            $scope.entity.socialMediaProfiles = [];
        if(!$scope.entity.tickets)
            $scope.entity.tickets = [];
        if(!$scope.entity.assignedOrganisations)
            $scope.entity.assignedOrganisations = [];
        if(!$scope.entity.recipientLists)
            $scope.entity.recipientLists = [];



        $scope.optInReady = !$scope.newEntity;

        /*if (!$scope.newEntity) {
            CustomerService.findOrders($scope.entity.id).then(function () {
                $scope.data.customerOrders = CustomerService.getOrders();
            });
        }*/

        if ($scope.entity.phones != undefined) {
            for (var i in $scope.entity.phones) {
                if ($scope.entity.phones[i].name == 'Telefon') {
                    $scope.phone.id = $scope.entity.phones[i].id;
                    $scope.phone.number = $scope.entity.phones[i].number;
                } else if ($scope.entity.phones[i].name == 'Mobil') {
                    $scope.mobilPhone.id = $scope.entity.phones[i].id;
                    $scope.mobilPhone.number = $scope.entity.phones[i].number;
                } else if ($scope.entity.phones[i].name == 'Fax') {
                    $scope.fax.id = $scope.entity.phones[i].id;
                    $scope.fax.number = $scope.entity.phones[i].number;
                }
            }
        }

        if ($scope.entity.contactTypes != undefined) {
            for (var i in $scope.entity.contactTypes) {
                if ($scope.entity.contactTypes[i].name == "PHONE") {
                    $scope.contact.phone = true;
                } else if ($scope.entity.contactTypes[i].name == "MOBIL_PHONE") {
                    $scope.contact.mobil = true;
                } else if ($scope.entity.contactTypes[i].name == "SMS") {
                    $scope.contact.sms = true;
                } else if ($scope.entity.contactTypes[i].name == "FAX") {
                    $scope.contact.fax = true;
                } else if ($scope.entity.contactTypes[i].name == "EMAIL") {
                    $scope.contact.email = true;
                } else if ($scope.entity.contactTypes[i].name == "WHATSAPP") {
                    $scope.contact.whatsapp = true;
                }
            }
        }
        if ($scope.entity.addresses != undefined) {
            for(var i in $scope.entity.addresses){
                if($scope.entity.addresses[i].isDefaultInvoiceAddress) {
                    $scope.defaultInvoiceAddress = $scope.entity.addresses[i];
                }
                if($scope.entity.addresses[i].isDefaultShippingAddress) {
                    $scope.defaultShippingAddress = $scope.entity.addresses[i];
                }
            }
        } else {
            $scope.entity.addresses = [];
        }
        if ($scope.entity.isOnlineAccount == undefined) {
            $scope.entity.isOnlineAccount = false;
        }

        if ($scope.entity.email == undefined) {
            $scope.entity.email = "";
        }

        if($scope.entity.tickets !== undefined) {
            for (var i = 0; i < $scope.entity.tickets.length; i++) {
                if ($scope.entity.tickets[i].orderId != undefined) {
                    var orderId = $scope.entity.tickets[i].orderId;
                    $scope.data.orderIds.indexOf(orderId) === -1 ? $scope.data.orderIds.push(orderId) : "";
                }
            }

            for(var i=0; i < $scope.data.orderIds.length; i++) {
                OrderService.findOne($scope.data.orderIds[i]).then(function () {
                    var order = OrderService.getOne();
                    $scope.data.orders.push({
                        orderId: order.id,
                        order: order
                    })
                });
            }
        }

        if ($scope.entity.password !== undefined) {
            $scope.protectedPassword = $scope.entity.password;
        }
    });

    $scope.openOrder = function(order) {
        NavigationService.changeState('root.ordermanagement', order.id);
    };

    $scope.validateOptIn = function() {
        var validationObject = {
            email: {
                method: "isEmail",
                fieldName: "E-Mail"
            },
            recipientListSelected: {
                fieldName: "Newsletter Listen",
                errorMsg: "Bitte wählen Sie mindestens eine Newsletter Liste.",
                customValidate: function (entity) {
                    return entity.recipientLists !== undefined && entity.recipientLists.length > 1;
                }
            }
        };
        return (ValidationService.validate($scope.entity, validationObject));
    };

    $scope.sendOptIn = function () {
        if (!$scope.validateOptIn()) {
            return;
        }
        this.getModel().sendOptIns($scope.entity);
    };

    $scope.dialogOrderController = function ($scope, dataToPass, $mdDialog) {
        angular.extend(this, $controller('OrderManagementFormController', {
            $scope: $scope
        }));

        $scope.order = dataToPass;

        if($scope.order.orderDate != undefined) {
            $scope.order.orderDate = new Date($scope.order.orderDate);
        }

        $scope.modalTabs = [
            {
                label: 'Übersicht',
                template: BASE_TEMPLATES_PATH + '/orderManagement/form-main.html',
                active: true
            },
            /*{
                label: 'Positionen',
                template: BASE_TEMPLATES_PATH + '/orderManagement/form-order.html'
            },*/

            {
                label: 'Versand',
                template: BASE_TEMPLATES_PATH + '/orderManagement/form-shipping.html'
            },
            {
                label: 'Status',
                template: BASE_TEMPLATES_PATH + '/orderManagement/form-status.html'
            },
            {
                label: 'Streckengeschäft',
                template: BASE_TEMPLATES_PATH + '/orderManagement/form-drop-shipping.html'
            }
        ];

        $scope.datas = {};
        $scope.productService = ProductService;
        $scope.productService.setCurrentPage(0).then(function () {
            $scope.datas.dropShippingProducts = $scope.productService.getAll();
        });

        $scope.cancelModal = function () {
            $mdDialog.cancel();
        };

        $scope.updateModal = function () {
            $mdDialog.hide($scope.data);
        };

        $scope.initMenu = function () {
            $scope.modalTabs.forEach(function (menuItem) {
                if (menuItem.active === true) {
                    $scope.order.menuItem = menuItem;
                    $scope.order.menuItem.active = true;
                }
            });
        };

        $scope.getCurrentVariantTab = function () {
            if ($scope.order.menuItem == undefined) {
                $scope.order.menuItem = $scope.modalTabs[0];
                return BASE_TEMPLATES_PATH + '/orderManagement/form-main.html';
            }
            return $scope.order.menuItem.template;
        };

        $scope.findMenuItemByNameVariant = function (name) {
            for (var i = 0; i < $scope.modalTabs.length; i++) {
                if ($scope.modalTabs[i].label === name) {
                    return $scope.modalTabs[i];
                }
            }
        };

        $scope.changeTabVariant = function (menuItem) {
            if (typeof menuItem === 'string') {
                menuItem = $scope.findMenuItemByNameVariant(menuItem);
            }
            if ($scope.order.menuItem !== undefined) {
                $scope.order.menuItem.active = false;
            }
            menuItem.active = true;
            $scope.order.menuItem = menuItem;
        };
    };

    $scope.onSuccessVariant = function (result) {
    };

    $scope.onCancelVariant = function () {
    };

    $scope.goToOrganisation = function (organisation) {
        NavigationService.changeState("root.organisation", organisation.id);
    }

};
