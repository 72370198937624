/**
 * Created by Nico on 07.07.2016.
 */
define('services/ShippingMethodUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('ShippingMethodUnpagedService', ['$injector', ShippingMethodUnpagedService

    ]);
});

function ShippingMethodUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('ShippingMethod');

    angular.extend(this, crudInstance);

    return this;
};
