define('services/TaskUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('TaskUnpagedService', [
        '$injector',
        TaskUnpagedService
    ]);
});


function TaskUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('Task');

    angular.extend(this, crudInstance);

    var _task = {};
    var _tasks = [];

    this.findOneTask =  function(customerId, id) {
        return new $injector.get("ModelFactory")['Task']().findOne({customerId: customerId,id: id}).$promise.then(function(response) {
            _task = response;
        });
    };

    this.getOneTask = function () {
        return _task;
    };

    this.saveTask = function(customerId, task) {
        if(task.id != undefined) {
            var promise = $injector.get("ModelFactory")['Task']().update({customerId: customerId}, task).$promise;
        } else {
            var promise = $injector.get("ModelFactory")['Task']().create({customerId: customerId}, task).$promise;
        }
        return promise;
    };

    this.findAllTasks = function(customerId) {
        return $injector.get("ModelFactory")['Task']().findAll({customerId: customerId}).$promise.then(function (response) {
            _tasks   = response.tasks;
            return response;
        });
    };

    this.getAllTasks = function () {
        return _tasks;
    };

    this.deleteTask = function (customerId, id) {
        var promise = $injector.get("ModelFactory")['Task']().delete({customerId: customerId, id: id}).$promise;
        return promise;
    };

    return this;
};
