/**
 * Created by nmayer on 20.05.16.
 */
define('modules/imEx/controllers/ServerProfilesController',[
        'modules/module',
        'modules/basemodule/controllers/EntityListController'
    ],
    function (module) {
        'use strict';
        module.controller('ServerProfilesController', ['$scope', '$controller',
            function ServerProfilesController($scope, $controller) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.tableOptions.fields = [
                    {
                        id: 'NAME',
                        heading: 'Name',
                        attribute: 'name'
                    },
                    {
                        id: 'HOST',
                        heading: 'Host',
                        attribute: 'host'
                    },
                    {
                        id: 'PORT',
                        heading: 'Port',
                        attribute: 'port'
                    },
                    {
                        id: 'USERNAME',
                        heading: 'Benutzername',
                        attribute: 'username'
                    },
                    {
                        id: 'PASSWORD',
                        heading: 'Passwort',
                        attribute: 'password'
                    }
                ];

                $scope.init('ServerProfile').then(function() {

                });

                $scope.formState = 'root.serverprofile';

            }
        ]);
    }
);
