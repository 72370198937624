define('services/CategoryService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('CategoryService', [
        '$injector',
        CategoryService
    ]);
});


function CategoryService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Category');

    angular.extend(this, crudInstance);

    var hierarchyResult;
    var _findAllSuccess = function (response) {
        hierarchyResult = response;
    };

    var _findAllError = function (response) {
    };

    this.getHierarchy = function (type) {
        var model = $injector.get("ModelFactory")["Category"]();
        return model.hierarchy({type: type}).$promise.then(_findAllSuccess, _findAllError);
    };

    this.getAllHierarchy = function () {
        return hierarchyResult;
    };

    this.getCategories = function (type, publishState) {
        var model = $injector.get("ModelFactory")["Category"]();
        return model.findAll({type: type, publishState: publishState}).$promise;
    };

    this.updateTree = function (categories) {
        var promise = $injector.get("ModelFactory")["Category"]().updateTree(categories).$promise;
        return promise;
    };

    return this;
};
