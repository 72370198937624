/**
 * Created by Nico on 07.07.2016.
 */
define('services/CountryService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('CountryService', [
        '$injector',
        CountryService
    ]);
});


function CountryService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Country');

    angular.extend(this, crudInstance);

    return this;
};
