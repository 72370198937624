define('modules/wawi/product/controllers/ProductConfigFormController',[
        'modules/module',
        'settings',
        'modules/wawi/product/controllers/ProductFormController',
        'modules/basemodule/controllers/EntityListController',
        'services/NotificationService',
        'services/ValidationService',
        'services/ProductService',
        'services/AttributeService',
        'services/TagVariantService',
        'services/VariantProductService',
        'services/ImageService',
        'directives/entityTable/ngEntityTable',
        'filters/RemoveSpacesAndSpecialCharacters'
    ],
    function (module) {
        'use strict';
        module.controller('ProductConfigFormController', ['$scope', '$controller', 'NotificationService', 'ValidationService', 'ProductService', 'AttributeService', 'ImageService',
            'BASE_TEMPLATES_PATH', 'VariantProductService', ProductConfigFormController
        ]);
    }
);


function ProductConfigFormController($scope, $controller, NotificationService, ValidationService, ProductService, AttributeService, ImageService, BASE_TEMPLATES_PATH, VariantProductService) {

    angular.extend(this, $controller('ProductFormController', {
        $scope: $scope
    }));

    $scope.formTabs.push({
        label: 'Variationen',
        template: BASE_TEMPLATES_PATH + '/wawi/product/form-variations.html'
    });

    $scope.hideVariants = false;

    $scope.$on("duplicateSaved", function () {
        $scope.entityId = $scope.entity.id;
        for(var i=0; i < $scope.entity.selectedAttributes.length; i++) {
            $scope.entity.selectedAttributes[i].optionIds = [];
        }
    });

    $scope.selected.productVariationsCurrentPage = 0;
    $scope.selected.productVariationsPageSize = 10;
    $scope.selected.productVariationsTotalItems = 0;
    $scope.selected.items = [];
    $scope.defaultOptionsWithAttr = [];
    $scope.selectedDefaultOptionsWithAttr = [];


    $scope.getAttributeById = function (id) {
        for (var i = 0; i < $scope.attributes.length; i++) {

            if ($scope.attributes[i].id == id) {
                return $scope.attributes[i];
            }
        }
    };


    $scope.afterInit.then(function () {

        if($scope.entity.id){
            $scope.formTabs.push({
                label: 'Product Assistant',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-product-assistant.html'
            });
        }

        AttributeService.findAll().then(function () {
            $scope.attributes = AttributeService.getAll(true);
            $scope.setDefaultOptionsWithAttr();

            if ($scope.entity.variants != undefined) {
                $scope.selected.productVariationsTotalItems = $scope.entity.totalVariants;
            }

            if($scope.entity.selectedAttributes === undefined || $scope.entity.selectedAttributes.length === 0) {
                $scope.entity.selectedAttributes = [{}]
            } else {
                for(var i=0; i < $scope.entity.selectedAttributes.length; i++) {
                    $scope.entity.selectedAttributes[i].optionIds = [];
                }

            }

            $scope.variantAlldata = {};

            if ($scope.variantData == undefined) {
                $scope.variantData = {};
            }
            if ($scope.variantData.sellingProducts == undefined) {
                $scope.variantData.sellingProducts = [];
            }
            $scope.setVariantSellingProducts();

            VariantProductService.setCurrentPage(0).then(function () {
                $scope.variantAlldata.sellingProducts = VariantProductService.getAll();
            });
        });
    });



    $scope.addAttribute = function () {
        if ($scope.entity.selectedAttributes == undefined) {
            $scope.entity.selectedAttributes = [];
        }
        $scope.entity.selectedAttributes.push({});
    };

    $scope.removeAttribute = function (index) {
        $scope.entity.selectedAttributes.splice(index, 1);
    };

    $scope.addAttributeOption = function (key) {
        if ($scope.entity.selectedAttributes[key].optionIds == undefined) {
            $scope.entity.selectedAttributes[key].optionIds = [];
        }
        $scope.entity.selectedAttributes[key].optionIds.push({});
    };

    $scope.removeAttributeOption = function (key, index) {
        $scope.entity.selectedAttributes[key].optionIds.splice(index, 1);
    };

    $scope.getOptions = function (id) {
        if (!angular.isArray($scope.attributes)) {
            return;
        }

        for (var i = 0; i < $scope.attributes.length; i++) {
            if ($scope.attributes[i].id == id) {
                return $scope.attributes[i].options;
            }
        }
    };


    /*  default option selection*/
    $scope.setDefaultOptionsWithAttr = function() {
        if (!$scope.entity.selectedAttributes) {
            return;
        }

        for(var i = 0; i < $scope.entity.selectedAttributes.length ; i++) {
            var options = $scope.getDefaultOptionsOfAttr($scope.entity.selectedAttributes[i].attributeId);
            $scope.defaultOptionsWithAttr.push({
                "attributeId" :$scope.entity.selectedAttributes[i].attributeId,
                "options": options });

            // set selected default
            var selectedObj = { "attributeId" :$scope.entity.selectedAttributes[i].attributeId, optionId: undefined };

            for(var j = 0; j < $scope.entity.defaultAttributeOptions.length ; j++) {
                if($scope.entity.defaultAttributeOptions[j].attributeId == $scope.entity.selectedAttributes[i].attributeId) {
                    for (var k = 0; k < options.length; k++) {
                        if (options[k].id == $scope.entity.defaultAttributeOptions[j].optionId) {
                            selectedObj.optionId = options[k].id;
                        }
                    }
                }
            }
            $scope.selectedDefaultOptionsWithAttr.push(selectedObj);

        }
    };

    $scope.getDefaultOptionsOfAttr = function (id) {
        var allOptions = $scope.getOptions(id);
        if (!angular.isArray(allOptions) || allOptions.length === 0) {
            return [];
        }

        var defaultOptions =  [];
        for( var i = 0; i < $scope.entity.selectedAttributes.length ; i++) {
            if($scope.entity.selectedAttributes[i].attributeId == id) {
                for (var j = 0; allOptions && j < allOptions.length; j++) {
                    for(var k = 0; k < $scope.entity.selectedAttributes[i].optionIds.length ; k++) {
                        if($scope.entity.selectedAttributes[i].optionIds[k] === allOptions[j].id) {
                            defaultOptions.push(allOptions[k]);
                        }
                    }
                }
            }
        }
        return defaultOptions;
    };

    $scope.removeDefaultSelection = function(index) {
        $scope.selectedDefaultOptionsWithAttr[index].optionId = undefined;
    };

    // send selected default option before update
    $scope.$on("beforeUpdate", function () {
        $scope.entity.defaultAttributeOptions = $scope.selectedDefaultOptionsWithAttr;
    });

    /* end default option selection*/

    $scope.getRealCurrentPage = function () {
        var cur = angular.copy(parseInt($scope.selected.productVariationsCurrentPage));
        cur = (cur === 0) ? cur : (cur - 1);
        return cur;
    };

    $scope.productVariationsSetPage = function () {
        $scope.entityService.findProduct($scope.entity.id,"configurables",$scope.getRealCurrentPage(),10).then(function () {
            $scope.entity.variants = $scope.entityService.getOne().variants;
        })
    };

    $scope.createVariant = function () {
        $scope.entity.type = 'configurables';
        var nothingToCreate = false;

        for (var i = 0; i < $scope.entity.selectedAttributes.length; i++) {
            if ($scope.entity.selectedAttributes[i].optionIds.length < 1) {
                nothingToCreate = true;
            }
        }
        if (!nothingToCreate) {
            ProductService.create($scope.entity).then(function (response) {
                if (response.error == undefined && response.errorCode == undefined && response.ok != false) {
                    NotificationService.notifySuccess({statusText: 'Erfolgreich gespeichert'});
                    $scope.entity = response;
                    for (var i = 0; i < $scope.entity.selectedAttributes.length; i++) {
                        $scope.entity.selectedAttributes[i].optionIds = [];
                    }
                } else {
                    NotificationService.notifyGeneralError({statusText: 'Speichern fehlgeschlagen'});
                }
            });
        } else {
            NotificationService.notifyWarning({statusText: 'Keine Optionen ausgewählt'});
        }
    };

    $scope.vdata = {};

    $scope.setVariantSellingProducts = function () {
        $scope.vdata.crossSellingProducts = [];
        $scope.vdata.upSellingProducts = [];
        $scope.vdata.addOnSellingProducts = [];
        if($scope.variantData.sellingProducts != undefined) {
            for (var i = 0; i < $scope.variantData.sellingProducts.length; i++) {
                var sellingProduct = $scope.variantData.sellingProducts[i];
                if (sellingProduct.sellingType == "CROSS_SELLING") {
                    $scope.vdata.crossSellingProducts.push(sellingProduct);
                } else if (sellingProduct.sellingType == "UP_SELLING") {
                    $scope.vdata.upSellingProducts.push(sellingProduct);
                } else if (sellingProduct.sellingType == "ADD_ON_SELLING") {
                    $scope.vdata.addOnSellingProducts.push(sellingProduct);
                }
            }
        }
    };

    $scope.$on('deleteVariantFromTable', function(evt, data) {
        NotificationService.confirm({
            msg: "Sind sie sicher, dass sie die Variante löschen möchten?",
            statusText: "Löschen"
        }).then(function () {
            ProductService.delete(data.id);

            for(var i=0; i < $scope.entity.variants.length; i++) {
                if($scope.entity.variants[i].id == data.id) {
                    $scope.entity.variants.splice(i, 1);
                    break;
                }
            }
        }, function () {
        });
    });

    $scope.$on('saveVariantFromTable', function(evt, data) {

        var variantToSave;
        for(var i=0; i < $scope.entity.variants.length; i++) {
            if($scope.entity.variants[i].id == data.id) {
                variantToSave = $scope.entity.variants[i];
                break;
            }
        }

        variantToSave.type = 'configurables';

        for (var j = 0; j < $scope.locales.length; j++) {
            if (variantToSave.locales[j] != undefined) {
                if (variantToSave.locales[j].metadata != undefined) {
                    for (var i = 0; i < variantToSave.locales[j].metadata.length; i++) {
                        for (var k = 0; k < variantToSave.locales[j].metadata[i].productMetadataValues.length; k++) {
                            if (variantToSave.locales[j].metadata[i].productMetadataValues[k].isSet == false) {
                                variantToSave.locales[j].metadata[i].productMetadataValues.splice(k, 1);
                                k--;
                            }
                        }
                    }
                }
            }
        }
        for (var j = 0; j < variantToSave.prices.length; j++) {
            if(variantToSave.prices[j].customerGroup === undefined) {
                variantToSave.prices[j].customerGroup = {
                    id: $scope.customerGroupId
                }
            }
            if(variantToSave.prices[j].fromAmount === undefined) {
                variantToSave.prices[j].fromAmount = 1;
            }

            if(variantToSave.prices[j].toAmount === undefined) {
                variantToSave.prices[j].toAmount = -1;
            }
        }
        if(variantToSave.printAreas != undefined) {
            for(var j = 0; j < variantToSave.printAreas.length; j++){
                var printArea = variantToSave.printAreas[j];
                delete printArea.uploader;
                for(var k = 0; printArea.selectedRefinementProductIds && k < printArea.selectedRefinementProductIds.length; k++){
                    if(!printArea.refinementProducts){
                        printArea.refinementProducts = [];
                    }
                    printArea.refinementProducts.push({id: printArea.selectedRefinementProductIds[k]});
                }
            }
        }

        if(variantToSave !== undefined) {
            ProductService.update(variantToSave).then(function (response) {
                if (response.error == undefined && response.errorCode == undefined && response.ok != false) {
                    NotificationService.notifySuccess({statusText: 'Erfolgreich gespeichert'});
                    for (var i = 0; i < $scope.entity.variants.length; i++) {
                        if ($scope.entity.variants[i].id == result.id) {
                            $scope.entity.variants[i] == result;
                            break;
                        }
                    }
                } else {
                    NotificationService.notifyGeneralError({statusText: 'Speichern fehlgeschlagen'});
                }
            });
        }
    });


    $scope.productVariantsTableOptions = {};
    $scope.productVariantsTableOptions.fields = [
        {
            id: 'NAME',
            heading: 'Name',
            template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-name-col.html',
            attribute: 'name'

        },
        {
            id: 'SKU',
            heading: 'SKU',
            template:  BASE_TEMPLATES_PATH + '/wawi/product/col/sku-col.html',
            attribute: 'sku'

        },
        {
            id: 'ATTRIBUTES',
            heading: 'Attribute',
            template:  BASE_TEMPLATES_PATH + '/wawi/product/col/attributes-col.html',
            attribute: 'attributes'

        },
        {
            id: 'PRICE',
            heading: 'Erster Verkaufspreis',
            template:  BASE_TEMPLATES_PATH + '/wawi/product/col/first-price-col.html',
            attribute: 'price'

        },
        {
            id: 'STOCK',
            heading: 'Lagerbestand',
            template:  BASE_TEMPLATES_PATH + '/wawi/product/col/stock-col.html',
            attribute: 'stock'

        },
        {
            id: 'ACTION',
            heading: 'Aktionen',
            template:  BASE_TEMPLATES_PATH + '/wawi/product/col/actions-col.html',
            searchDisabled: true,
            orderDisabled: true
        }
    ];

    $scope.preserveForDialogs = function(item, field) {
        return ((field.id != "STOCK") && (field.id != "PRICE") && (field.id != "SKU") && (field.id != "ACTION"));
    };

    /*
     Modal Dialog functions
     */
    $scope.onSuccessVariant = function (result) {

        if(result == undefined) {
            return;
        }

        for(var i=0; i < $scope.entity.variants.length; i++) {
            if($scope.entity.variants[i].id == result.id) {
                $scope.entity.variants[i] == result;
                break;
            }
        }
        result.type = 'configurables';

        for (var j = 0; j < $scope.locales.length; j++) {
            if (result.locales[j] != undefined) {
                if (result.locales[j].metadata != undefined) {
                    for (var i = 0; i < result.locales[j].metadata.length; i++) {
                        for (var k = 0; k < result.locales[j].metadata[i].productMetadataValues.length; k++) {
                            if (result.locales[j].metadata[i].productMetadataValues[k].isSet == false) {
                                result.locales[j].metadata[i].productMetadataValues.splice(k, 1);
                                k--;
                            }
                        }
                    }
                }
            }
        }

        ProductService.update(result).then(function (response) {
            if (response.error == undefined && response.errorCode == undefined && response.ok != false) {
                NotificationService.notifySuccess({statusText: 'Erfolgreich gespeichert'});
                for(var i=0; i < $scope.entity.variants.length; i++) {
                    if($scope.entity.variants[i].id == result.id) {
                        $scope.entity.variants[i] == result;
                        break;
                    }
                }
            } else {
                NotificationService.notifyGeneralError({statusText: 'Speichern fehlgeschlagen'});
            }
        });

    };

    $scope.onCancelVariant = function () {
    };

    $scope.dialogProductVariantController = function ($scope, dataToPass, $mdDialog, ImageService, FileUploader, MetadataSetUnpagedService, MetadataUnpagedService, MetadataService) {
        angular.extend(this, $controller('ProductConfigFormController', {
            $scope: $scope
        }));

        $scope.variantData = dataToPass;

        if (!$scope.data) {
            $scope.data = {};
        }

        $scope.variantData.crossSellingProducts = [];
        $scope.variantData.upSellingProducts = [];
        $scope.variantData.addOnSellingProducts = [];
        if($scope.variantData.sellingProducts == undefined) {
            $scope.variantData.sellingProducts = [];
        }
        for(var i=0; i < $scope.variantData.sellingProducts.length; i++) {
            var product = $scope.variantData.sellingProducts[i];
            if(product.sellingType == "CROSS_SELLING") {
                $scope.variantData.crossSellingProducts.push(product);
            } else if(product.sellingType == "UP_SELLING"){
                $scope.variantData.upSellingProducts.push(product);
            } else if(product.sellingType == "ADD_ON_SELLING"){
                $scope.variantData.addOnSellingProducts.push(product);
            }
        }


        if($scope.variantData.printAreas != undefined) {
            for (var i = 0; i < $scope.variantData.printAreas.length; i++) {
                var printArea = $scope.variantData.printAreas[i];
                if (!printArea.uploader) {
                    printArea.uploader = ImageService.initFileUpload(new FileUploader({url: '/api/v1/media/images'}));
                    printArea.uploader.removeAfterUpload = true;

                    printArea.uploadViaInput = function (index) {
                        angular.element('#file-input-' + index).trigger('click');
                    };

                    printArea.uploadImage = function (image, printArea) {
                        $scope.printAreaImageUploadId = printArea.id;
                        image.upload();
                    };

                    printArea.uploader.onSuccessItem = function (fileItem, response, status, headers) {
                        for(var i = 0; i < $scope.variantData.printAreas.length; i++){
                            var printArea = $scope.variantData.printAreas[i];
                            if($scope.printAreaImageUploadId == printArea.id){
                                printArea.image = {};
                                printArea.image.id = response.id;
                                break;
                            }
                        }
                    };
                }
                if (!printArea.selectedRefinementProductIds) {
                    printArea.selectedRefinementProductIds = [];
                    for (var j = 0; printArea.refinementProductLocales && j < printArea.refinementProductLocales.length; j++) {
                        var productLocale = printArea.refinementProductLocales[j];
                        printArea.selectedRefinementProductIds.push(productLocale.id);
                    }
                }
            }
        }

        if ($scope.variantData.prices == undefined || $scope.variantData.prices.length == 0) {
            $scope.variantData.prices = [];
        } else {
            for (index in $scope.variantData.prices) {
                $scope.variantData.prices.sort(function (a, b) {
                    return a.fromAmount - b.fromAmount;
                });
                $scope.variantData.prices[index].fromAmount = $scope.variantData.prices[index].fromAmount.toString();
                $scope.variantData.prices[index].toAmount = $scope.variantData.prices[index].toAmount.toString();
            }
        }

        $scope.showAddButton = function () {
            if($scope.variantData.prices.filter(function (value) {
                    if(value.customerGroup.id === $scope.variantData.customerGroupId) {
                        return true;
                    } else {
                        return false;
                    }
                }).length === 0) {
                return true
            }
            return false;
        };



        $scope.showDimensionAddButton = function () {
            if($scope.variantData.productDimensions.length === 0) {
                return true
            }
            return false;
        };


        $scope.addDimension = function () {
            $scope.variantData.productDimensions.push({
                minValue: "1",
                maxValue: "-1",
                step: 0,
                name: "",
                unit: 0,
                type: 0

            });
            return;

            if(parseInt(price.toAmount) >= parseInt(price.fromAmount)) {
                var fromAmountNew = (parseInt(price.toAmount) + 1).toString();
                jQuery(".last-item input").css("border-color", "");
            } else {
                jQuery(".last-item input").css("border-color", "red");
            }
            $scope.checkRange();
        };


        $scope.removeProductDimension = function (index) {
            $scope.variantData.productDimensions.splice(index, 1);
        };

        $scope.cancelModal = function () {
            $mdDialog.cancel();
        };

        $scope.updateModal = function () {
            $scope.variantData.sellingProducts = [];
            $scope.mergeSellingProductsWithType($scope.variantData, $scope.variantData.upSellingProducts, "UP_SELLING");
            $scope.mergeSellingProductsWithType($scope.variantData, $scope.variantData.crossSellingProducts, "CROSS_SELLING");
            $scope.mergeSellingProductsWithType($scope.variantData, $scope.variantData.addOnSellingProducts, "ADD_ON_SELLING");

            $mdDialog.hide($scope.variantData);
            $scope.setVariantSellingProducts();

        };

        $scope.hideVariants = true;

        $scope.getEntityObject = function () {
            return $scope.variantData;
        };

        $scope.getEntityObjectString = function () {
            return "variantData";
        };

        $scope.variant = {};

        $scope.modalTabs = [
            {
                label: 'Allgemein',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-main.html',
                active: true
            },
            {
                label: 'Beschreibung',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-description.html'
            },
            {
                label: 'Preise',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-prices.html'
            },
            {
                label: 'Product Dimensions',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-product-dimensions.html'
            },
            {
                label: 'Tags',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-tags.html'
            },
            {
                label: 'Add-On-Selling',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-add-on-selling.html'
            },
            {
                label: 'Cross-Selling',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-cross-selling.html'
            },
            {
                label: 'Up-Selling',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-up-selling.html'
            },
            {
                label: 'SEO',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-seo.html'
            },
            {
                label: 'Bilder',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-images.html'
            },
            {
                label: 'Lagerverwaltung',
                template: BASE_TEMPLATES_PATH + '/wawi/product/stock-management.html'
            },
            {
                label: 'Produktmerkmale',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-variant-metadata.html'
            },
            {
                label: 'Druckflächen',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-refinement-products.html'
            },
            {
                label: 'Attribute',
                template: BASE_TEMPLATES_PATH + '/wawi/product/form-attributes.html'
            }
        ];

        $scope.initMenu = function () {
            $scope.modalTabs.forEach(function (menuItem) {
                if (menuItem.active === true) {
                    $scope.variant.menuItem = menuItem;
                    $scope.variant.menuItem.active = true;
                }
            });
        };

        $scope.findMenuItemByNameVariant = function (name) {
            for (var i = 0; i < $scope.modalTabs.length; i++) {
                if ($scope.modalTabs[i].label === name) {
                    return $scope.modalTabs[i];
                }
            }
        };

        $scope.changeTabVariant = function (menuItem) {
            if (typeof menuItem === 'string') {
                menuItem = $scope.findMenuItemByNameVariant(menuItem);
            }
            if ($scope.variant.menuItem !== undefined) {
                $scope.variant.menuItem.active = false;
            }
            menuItem.active = true;
            $scope.variant.menuItem = menuItem;
        };

        $scope.addPrice = function (price) {
            if (!$scope.data.customerGroupId) {
                $scope.data.customerGroupId = $scope.availableData.customerGroupId;
            }

            if(price === undefined) {
                $scope.variantData.prices.push({
                    fromAmount: "1",
                    toAmount: "-1",
                    sellingPrice: 0,
                    customerGroup: {
                        id: $scope.data.customerGroupId
                    }
                });
                return;
            }
            if (parseInt(price.toAmount) >= parseInt(price.fromAmount)) {
                var fromAmountNew = (parseInt(price.toAmount) + 1).toString();
                $scope.variantData.prices.push({
                    fromAmount: fromAmountNew,
                    toAmount: '-1',
                    sellingPrice: price.sellingPrice,
                    customerGroup: {
                        id: $scope.data.customerGroupId
                    }
                });
                jQuery(".last-item input").css("border-color", "");
            } else {
                jQuery(".last-item input").css("border-color", "red");
            }
        };

        $scope.initSelectedRefinementProducts = function(){
            if($scope.variantData.printAreas != undefined) {
                for (var i = 0; i < $scope.variantData.printAreas.length; i++) {
                    var printArea = $scope.variantData.printAreas[i];
                    if (!printArea.uploader) {
                        printArea.uploader = ImageService.initFileUpload(new FileUploader({url: '/api/v1/media/images'}));
                        printArea.uploader.removeAfterUpload = true;

                        printArea.uploadViaInput = function (index) {
                            angular.element('#file-input-' + index).trigger('click');
                        };

                        printArea.uploadImage = function (image, printArea) {
                            $scope.printAreaImageUploadId = printArea.id;
                            image.upload();
                        };

                        printArea.uploader.onSuccessItem = function (fileItem, response, status, headers) {
                            for(var i = 0; i < $scope.variantData.printAreas.length; i++){
                                var printArea = $scope.variantData.printAreas[i];
                                if($scope.printAreaImageUploadId == printArea.id){
                                    printArea.image = {};
                                    printArea.image.id = response.id;
                                    break;
                                }
                            }
                        };
                    }
                    if (!printArea.selectedRefinementProductIds) {
                        printArea.selectedRefinementProductIds = [];
                        if(printArea.refinementProductLocales === undefined) {
                            printArea.refinementProductLocales = [];
                        }
                        for (var j = 0; printArea.refinementProductLocales && j < printArea.refinementProductLocales.length; j++) {
                            var productLocale = printArea.refinementProductLocales[j];
                            printArea.selectedRefinementProductIds.push(productLocale.id);
                        }
                    }
                }
            }
        };

        $scope.addPrintArea = function(){
            if($scope.variantData.printAreas == undefined) {
                $scope.variantData.printAreas = [];
            }
            $scope.variantData.printAreas.push({});
            $scope.initSelectedRefinementProducts();
        };

        $scope.removePrintArea = function(printArea){
            for(var i = 0; i < $scope.variantData.printAreas.length; i++){
                var printAreaTmp = $scope.variantData.printAreas[i];
                if(printAreaTmp.id == printArea.id){
                    $scope.variantData.printAreas.splice(i, 1);
                    break;
                }
            }
        };

        $scope.removePrice = function (index) {
            $scope.variantData.prices.splice(index, 1);
            $scope.variantData.prices[index - 1].toAmount = '-1';
        };

        $scope.removeImage = function (productImage) {
            var index = $scope.variantData.productImages.indexOf(productImage);
            $scope.variantData.productImages.splice(index, 1);
        };

        $scope.onDrop = function ($event, $data, array) {

            $scope.variantData.productImages.push({
                image: $data,
                isCatalogImage: false,
                isDetailImage: false,
                isSearchImage: false,
                isInActive: false
            });
        };

        $scope.getCurrentVariantTab = function () {
            if ($scope.variant.menuItem == undefined) {
                $scope.variant.menuItem = $scope.modalTabs[0];
                return BASE_TEMPLATES_PATH + '/wawi/product/form-main.html';
            }
            return $scope.variant.menuItem.template;
        };

        /*/-----METADATA HANDLING-----/*/

        $scope.metadataObjects = [];

        MetadataSetUnpagedService.findAll().then(function () {
            $scope.metadataSets = MetadataSetUnpagedService.getAll(true).data;
        });

        MetadataUnpagedService.findAll().then(function () {
            $scope.metadatas = MetadataUnpagedService.getAll(true).data;
        });

        $scope.customData = {};

        $scope.getMetaDataSet = function () {
            for(var i=0; i < $scope.metadataSets.length; i++) {
                if($scope.metadataSets[i].id == $scope.customData.metadataSetId) {
                    $scope.metadataSet = $scope.metadataSets[i];
                }
            }

            if($scope.metadataSet != undefined) {
                for(var j=0; j < $scope.locales.length; j++) {
                    if($scope.variantData.locales[j] == undefined) {
                        $scope.variantData.locales[j] = {
                            localeId: $scope.locales[j].id
                        }
                    }
                    if($scope.variantData.locales[j].metadata == undefined) {
                        $scope.variantData.locales[j].metadata = [];
                    }
                    var metadatas = $scope.variantData.locales[j].metadata.length;
                    for(var i=0; i < $scope.metadataSet.metadataNames.length; i++) {
                        var alreadyExists = false;
                        for (var k = 0; k < $scope.variantData.locales[j].metadata.length; k++) {
                            if ($scope.variantData.locales[j].metadata[k].metadataNameId == $scope.metadataSet.metadataNames[i].id) {
                                alreadyExists = true;
                            }
                        }

                        if (!alreadyExists) {
                            $scope.variantData.locales[j].metadata[i + metadatas] = {
                                metadataNameId: $scope.metadataSet.metadataNames[i].id,
                                productMetadataValues: []
                            };
                            if ($scope.metadataSet.metadataNames[i].inputType == 'MULTISELECT') {
                                for (var k = 0; k < $scope.metadataSet.metadataNames[i].metadataValues.length; k++) {
                                    $scope.variantData.locales[j].metadata[i + metadatas].productMetadataValues.push({
                                        value: $scope.metadataSet.metadataNames[i].metadataValues[k].value,
                                        isSet: false
                                    });
                                }
                            }
                            MetadataService.findOne($scope.metadataSet.metadataNames[i].id).then(function () {
                                $scope.metadataObjects.push(MetadataService.getOne());
                            });
                        }
                    }
                }
            }
        };

        $scope.getMetaData = function () {
            for(var i=0; i < $scope.metadatas.length; i++) {
                if($scope.metadatas[i].id == $scope.customData.metadataId) {
                    $scope.metadata = $scope.metadatas[i];
                }
            }
            for(var j=0; j < $scope.locales.length; j++) {
                if($scope.variantData.locales[j].metadata === undefined) {
                    $scope.variantData.locales[j].metadata = [];
                }
                for(var i=0; i < $scope.variantData.locales[j].metadata.length; i++) {
                    if($scope.variantData.locales[j].metadata[i].metadataNameId == $scope.metadata.id) {
                        return;
                    }
                }
            }

            if($scope.metadata != undefined) {
                for(var j=0; j < $scope.locales.length; j++) {

                    if($scope.variantData.locales[j] == undefined) {
                        $scope.variantData.locales[j] = {
                            localeId: $scope.locales[j].id
                        }
                    }
                    if($scope.variantData.locales[j].metadata == undefined) {
                        $scope.variantData.locales[j].metadata = [];
                    }

                    $scope.variantData.locales[j].metadata.push({
                        metadataNameId: $scope.metadata.id,
                        name: $scope.metadata.name,
                        productMetadataValues: []
                    });
                    if($scope.metadata.inputType == 'MULTISELECT'){
                        for(var k = 0; k < $scope.metadata.metadataValues.length; k++) {
                            $scope.variantData.locales[j].metadata[$scope.variantData.locales[j].metadata.length-1].productMetadataValues.push({
                                value: $scope.metadata.metadataValues[k].value,
                                isSet: false,
                                metadataValueId: $scope.metadata.metadataValues[k].id
                            });
                        }
                    }

                }
                MetadataService.findOne($scope.metadata.id).then(function () {
                    $scope.metadataObjects.push(MetadataService.getOne());
                });
            }
        };

        $scope.getProductMetadataValueIndexByValue = function (productMetadataValues, identifier) {
            for (var i = 0; i < productMetadataValues.length; i++) {
                if (productMetadataValues[i].identifier == identifier) {
                    return i;
                }
            }
        };

        $scope.getMetadataFromServer = function () {
            for(var i = $scope.metadataObjects.length; i < $scope.variantData.locales[0].metadata.length; i++) {
                if($scope.variantData.locales[0].metadata[i].metadataNameId != undefined) {
                    MetadataService.findOne($scope.variantData.locales[0].metadata[i].metadataNameId).then(function () {
                        $scope.metadataObjects.push(MetadataService.getOne());
                        if($scope.metadataObjects.length == $scope.variantData.locales[0].metadata.length) {
                            for (var j = 0; j < $scope.metadataObjects.length; j++) {
                                $scope.metadataObjects[j].locales.sort(function (a, b) {
                                    if (a.localeId < b.localeId)
                                        return -1;
                                    if (a.localeId > b.localeId)
                                        return 1;
                                    return 0;
                                });

                            }
                            $scope.metadataObjects.sort(function (a, b) {
                                if(a.position === b.position) {
                                    var x = a.identifier.toLowerCase(), y = b.identifier.toLowerCase();
                                    return x < y ? -1 : x > y ? 1 : 0;
                                }
                                if ( a.position < b.position )
                                    return -1;
                                if ( a.position > b.position )
                                    return 1;
                                return a.position - b.position;
                            });
                            for (var m = 0; m < $scope.metadataObjects.length; m++) {
                                if ($scope.metadataObjects[m].inputType == "MULTISELECT") {
                                    for (var k = 0; k < $scope.variantData.locales.length; k++) {
                                        for (var n = 0; n < $scope.metadataObjects[m].locales[k].metadataValues.length; n++) {
                                            var foundOne = false;
                                            $scope.variantData.locales[k].metadata[m].productMetadataValues.sort(function (a, b) {
                                                if (a.metadataValueId < b.metadataValueId)
                                                    return -1;
                                                if (a.metadataValueId > b.metadataValueId)
                                                    return 1;
                                                return 0;
                                            });
                                            for (var p = 0; p < $scope.variantData.locales[k].metadata[m].productMetadataValues.length; p++) {
                                                if($scope.metadataObjects[m].locales[k].metadataValues[n].id == $scope.variantData.locales[k].metadata[m].productMetadataValues[p].metadataValueId) {
                                                    $scope.variantData.locales[k].metadata[m].productMetadataValues[p].isSet = true;
                                                    foundOne = true;
                                                    break;
                                                }
                                            }
                                            if(!foundOne) {
                                                var index = 0;
                                                for(var i=0; i < $scope.variantData.locales[k].metadata[m].productMetadataValues.length; i++) {
                                                    if($scope.metadataObjects[m].locales[k].metadataValues[n].id < $scope.variantData.locales[k].metadata[m].productMetadataValues[i].metadataValueId) {
                                                        break;
                                                    }
                                                    index++;
                                                }
                                                $scope.variantData.locales[k].metadata[m].productMetadataValues.splice(index,0,$scope.metadataObjects[m].locales[k].metadataValues[n]);
                                                $scope.variantData.locales[k].metadata[m].productMetadataValues[index].isSet = false;
                                                $scope.variantData.locales[k].metadata[m].productMetadataValues[index].metadataValueId = $scope.metadataObjects[m].locales[k].metadataValues[n].id;
                                            }


                                        }
                                    }
                                }
                            }
                        }
                    })};
            }
        };

        $scope.deleteMetadata = function (index) {
            for (var i=0; i < $scope.variantData.locales.length; i++) {
                $scope.variantData.locales[i].metadata.splice(index, 1);
            }
            $scope.metadataObjects.splice(index,1);
        };

        var countLocales = 0;
        for (var i=0; i < $scope.variantData.locales.length; i++) {
            if($scope.variantData.locales[i].metadata != undefined) {
                if($scope.variantData.locales[i].metadata.length > countLocales) {
                    countLocales = $scope.variantData.locales[i].metadata.length;
                }
            }

            for(var j=0; j < $scope.variantData.locales[i].metadata.length; j++) {
                var k = $scope.variantData.locales[i].metadata[j].productMetadataValues.length;
                while (k--) {
                    if ($scope.variantData.locales[i].metadata[j].productMetadataValues[k].isSet == false) {
                        $scope.variantData.locales[i].metadata[j].productMetadataValues.splice(k, 1);
                    }
                }
            }
            $scope.variantData.locales[i].metadata.sort(function(a, b){
                if(a.position === b.position) {
                    var x = a.identifier.toLowerCase(), y = b.identifier.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                }
                if ( a.position < b.position )
                    return -1;
                if ( a.position > b.position )
                    return 1;
                return a.position - b.position;
            });
        }
        $scope.getMetadataFromServer();

        /*-----METADATA HANDLING-----*/
    };
}
;
