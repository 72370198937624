define('services/GlossaryPageService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('GlossaryPageService', [
        '$injector',
        GlossaryPageService
    ]);
});


function GlossaryPageService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('GlossaryPage');

    angular.extend(this, crudInstance);

    this.setShopGlossary = function () {
        var promise = $injector.get("ModelFactory")["GlossaryPage"]().setShopGlossary().$promise;
        return promise;
    };

    return this;
}
;
