define('modules/settings/shop/ShopController',[
        'modules/module',
        'settings',
        'modules/basemodule/controllers/EntityListController'
    ],
    function (module) {
        'use strict';
        module.controller('ShopController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.tableOptions.fields = [
                    {
                        id: 'NAME',
                        heading:    'Name',
                        attribute:  'name'
                    },
                    {
                        id: 'DESCRIPTION',
                        heading:    'Beschreibung',
                        attribute:  'description'
                    },
                    {
                        id: 'WEBSITE',
                        heading:    'Webseite',
                        template:  BASE_TEMPLATES_PATH + '/settings/shop/shop-website-col.html',
                        attribute:  'website'
                    }
                ];
                $scope.formState = 'root.shop';

                $scope.init('Shop');
            }
        ]);
    }
);
