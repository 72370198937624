define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/SectionSnippetConfig',[
        'app',
        'angular',
        'services/ContentBuilderHelper',
        'directives/contentConfigurator/contentSnippets/_snippetConfig'
    ],
    function (app) {
        'use strict';
        app.directive('sectionSnippetConfig', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'E',
                scope: {
                    metaIdentifier: '<',
                    elementIdentifier: '<',
                    // snippet: "=",
                    element: '='
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/views/sectionSnippetConfig.html",
                controller: ['$scope', '$controller', '$rootScope', 'ContentBuilderHelper',
                    function ($scope, $controller, $rootScope, ContentBuilderHelper) {

                        $scope.snippet = {
                            name: 'sectionSnippet'
                        };

                        angular.extend(this, $controller('snippetConfigController', {
                            $scope: $scope
                        }));

                        $scope.options.title = 'Section';

                        $scope.types = [
                            { id: 'popover-pinpoint', name: 'Image Pins (popover)' },
                            { id: 'popover-pinpoint-list', name: 'Image Pins List (popover)' },
                            { id: 'popup-pinpoint', name: 'Image Pins (popup)' },
                            { id: 'partners', name: 'Partner List' },
                            { id: 'slider', name: 'Image Slider' },
                            { id: 'info-slider', name: 'Info Slider' },
                            { id: 'search', name: 'Search' },
                            { id: 'depiction', name: 'Depiction' },
                            { id: 'timeline', name: 'Timeline' },
                            { id: 'info-carousel', name: 'Info Carousel' },
                            { id: 'card', name: 'Card (title, caption, link)' },
                            { id: 'grid', name: 'Grid (3x3 cards)' },
                            { id: 'breadcrumbs', name: 'Breadcrumbs' },
                            { id: 'form', name: 'Form' },
                            { id: 'jobs', name: 'Jobs' },
                            { id: 'light-gallery', name: 'Light Gallery' },
                            { id: 'documentation', name: 'Documentation Tabs' },
                            { id: 'news', name: 'News' },
                            { id: 'compare-table', name: 'Compare Table' },
                            { id: 'one-page-nav', name: 'One Page Nav' },
                            { id: 'tabs-anchor-nav', name: 'Tabs with Anchor Nav' },
                            { id: 'custom-page-styles', name: 'Custom Page Styles' },
                        ];

                        $scope.isJsonValid = false;
                        $scope.config = {
                            type: undefined,
                            data: ""
                        }



                        $scope.previousElementIdentifier = undefined;
                        var _init = function () {
                            if(typeof $scope.element.config !== "undefined") {
                                $scope.config.type = $scope.element.config.type;
                                $scope.config.data = angular.toJson($scope.element.config.data, true);
                                $scope.isJsonValid = false;
                                try {
                                    angular.fromJson($scope.config.data);
                                    $scope.isJsonValid = true;
                                } catch (e) {
                                    // do not allow to save invalid section data
                                }

                            }
                            // CategoryService.getCategories("PRODUCT", "ACTIVE").then(function (response) {
                            //     $scope.categories = response.data;
                            // });
                        };
                        _init();

                        // Track elementIdentifier change for cases when you switch between different sections, directive stays the same only data is updated
                        $scope.$watch('elementIdentifier', function (newValue, oldValue) {
                            if(newValue !== oldValue) {
                                if (typeof $scope.element.config !== "undefined") {
                                    $scope.config.type = $scope.element.config.type;
                                    $scope.config.data = angular.toJson($scope.element.config.data, true);
                                }
                            }
                        });
                        $scope.$watchCollection('config', function (newValue, oldValue) {
                            // Do not propagate config change on first load or when switching to different snippet of same type (section)
                            if ($scope.previousElementIdentifier !== $scope.elementIdentifier) {
                                $scope.previousElementIdentifier = $scope.elementIdentifier;
                            } else {
                                if (newValue === undefined && newValue === oldValue) {
                                    return;
                                }
                                var json = false;
                                try {
                                    json = angular.fromJson($scope.config.data);
                                } catch (e) {
                                    // do not allow to save invalid section data
                                }

                                $scope.isJsonValid = !!json;
                                if (json) {
                                    $scope.element.config = { // this will trigger section directive watchers
                                        type: $scope.config.type,
                                        data: json
                                    }
                                    // $rootScope.$broadcast('sectionSnippetConfigChange', {
                                    //     id: $scope.elementIdentifier
                                    // });
                                }
                            }


                        });




                    }]
            }
        }]);
    }
);

