define('modules/crm/customer/controllers/CustomerBaseFormController',[
        'modules/module',
        'modules/basemodule/controllers/EntityFormController',
        'services/NavigationService',
        'services/CustomerService',
        'services/CustomerInfoService',
        'services/CustomerGroupService',
        'services/IndustryService',
        'services/ReferenceService',
        'services/TitleService',
        'services/CountryService',
        'services/SocialMediaProfileTypeService',
        'services/ValidationService',
        'services/ClassificationService',
        'services/SystemMessageService',
        'services/NotificationService',
        'services/TaskUnpagedService',
        'services/NoteUnpagedService',
        'services/TitleUnpagedService',
        'services/CountryUnpagedService',
        'services/TicketUnpagedService',
        'services/EconRecipientListService',
        'services/UserService',
        'services/DataFeedService',
        'directives/customerInfo/CustomerInfo',
        'directives/controls/customSelect/CustomSelect',
        'directives/controls/customInput/CustomInput',
        'directives/controls/customTextarea/CustomTextarea',
        'directives/controls/customSimpleCheckbox/CustomSimpleCheckbox',
        'directives/controls/customRadio/CustomRadio',
        'directives/controls/customButton/CustomButton',
        'directives/customOnChange/customOnChange',
        'directives/fileModel/FileModel',
        'filters/GenderFilter',
        'filters/RemoveSpacesAndSpecialCharacters',
        'settings'
    ],
    function (module) {
        'use strict';
        module.controller('CustomerBaseFormController', [
            '$scope',
            '$controller',
            '$timeout',
            'BASE_TEMPLATES_PATH',
            'CustomerGroupService',
            'IndustryService',
            'ReferenceService',
            'TitleService',
            'CountryService',
            'SocialMediaProfileTypeService',
            'ValidationService',
            'ImageService',
            'ClassificationService',
            'SystemMessageService',
            'CustomerService',
            'NotificationService',
            'FileUploader',
            'TaskUnpagedService',
            'NoteUnpagedService',
            'UserService',
            'TitleUnpagedService',
            'CountryUnpagedService',
            'TicketUnpagedService',
            'EconRecipientListService',
            'DataFeedService',
            CustomerBaseFormController
        ]);
    }
);

function CustomerBaseFormController($scope, $controller, $timeout, BASE_TEMPLATES_PATH, CustomerGroupService, IndustryService, ReferenceService, TitleService, CountryService,
                                    SocialMediaProfileTypeService, ValidationService, ImageService, ClassificationService, SystemMessageService, CustomerService, NotificationService,
                                    FileUploader, TaskUnpagedService, NoteUnpagedService, UserService, TitleUnpagedService, CountryUnpagedService, TicketUnpagedService, EconRecipientListService, DataFeedService) {

    $scope.entityType = "CUSTOMER";

    $scope.act_user = UserService.getUser();

    var today = new Date();
    var month = today.getMonth() + 1;

    $scope.hideTask = true;
    $scope.hideNote = true;

    $scope.organisationCustomers = [];

    $scope.notes = [];
    $scope.tasks = [];

    $scope.phone = {};
    $scope.mobilPhone = {};
    $scope.fax = {};


    $scope.selectedAddress = null;
    $scope.contact = {};

    $scope.defaultShippingAddress = null;
    $scope.defaultInvoiceAddress = null;

    $scope.yesNoRadioValues = [{value: true, display: "Ja"}, {value: false, display: "Nein"}];
    $scope.onlineAccountRadioValues = [{value: true, display: "Aktiviert"}, {value: false, display: "Deaktiviert"}];
    $scope.approveCustomerRadioValues = [{value: true, display: "Verifiziert"}, {value: false, display: "Nicht verifiziert"}];

    $scope.customerTypes = [{value: "CUSTOMER", display: "Kunde"}, {value: "PROSPECTIVE_CUSTOMER", display: "Kaufinteressent"}, {value: "GUEST", display: "Gast"}];

    $scope.selected = {};

    $scope.data = {};
    $scope.data.orders = [];
    $scope.data.orderIds = [];

    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

    $scope.formTabs = [
        {
            label: 'Übersicht',
            template: BASE_TEMPLATES_PATH + '/crm/customer/form-main.html',
            active: true
        },
        {
            label: 'Adressen',
            template: BASE_TEMPLATES_PATH + '/crm/customer/form-addresses.html'
        },
        {
            label: 'Bestellungen',
            template: BASE_TEMPLATES_PATH + '/crm/customer/form-orders.html'
        }
    ];

    $scope.customerSources = [
        /*{
            name: "Newsletter",
            id: "NEWSLETTER"
        },*/
        {
            name: "Kauf über Shop",
            id: "PURCHASE"
        },
        {
            name: "Registrierung",
            id: "REGISTRATION"
        },
        {
            name: "Manuelle Anlange",
            id: "MANUALINPUT"
        }
    ];

    var loadDocuments = function () {
        CustomerService.loadDocuments($scope.entityId).then(function () {
            $scope.customerDocuments = CustomerService.getDocuments();
        });
    };

    if($scope.entityId != '') {
        $scope.formTabs.splice(2,0,{
            label: 'Aufgaben & Notizen',
            template: BASE_TEMPLATES_PATH + '/crm/customer/form-tasks.html'
        });

        $scope.formTabs.splice(3,0,{
            label: 'Kommunikation',
            template: BASE_TEMPLATES_PATH + '/crm/customer/form-communication.html'
        });

        loadDocuments();

        DataFeedService.findAll().then(function () {
            $scope.dataFeeds = $scope.extendForSelectValues(DataFeedService.getAll().data);
        });

        CustomerService.loadImages($scope.entityId).then(function () {
            $scope.customerImages = CustomerService.getImages();
        });

        CustomerService.findOrders($scope.entityId).then(function () {
            $scope.data.orders = CustomerService.getOrders();
        });
    }

    /*
     get Data for selects...
     */
    CustomerGroupService.findAll().then(function () {
        $scope.customerGroups = CustomerGroupService.getAll();
    });

    SocialMediaProfileTypeService.findAll().then(function () {
        $scope.socialMediaProfiles = SocialMediaProfileTypeService.getAll();
    });

    IndustryService.findAll().then(function () {
        $scope.industries = IndustryService.getAll();
    });

    SystemMessageService.findAll().then(function () {
        $scope.systemMessages = SystemMessageService.getAll();
    });

    ClassificationService.findAll().then(function () {
        $scope.classifications = ClassificationService.getAll();
    });

    ReferenceService.findAll().then(function () {
        $scope.references = ReferenceService.getAll();
    });

    TitleUnpagedService.findAll(true).then(function () {
        $scope.titles = TitleUnpagedService.getAll().data;
    });

    CountryUnpagedService.findAll(true).then(function () {
        $scope.countries = CountryUnpagedService.getAll().data;
    });

    EconRecipientListService.findAll(true).then(function () {
        var tempRecipientLists = EconRecipientListService.getAll();

        if ($scope.entity) {
            $scope.entity.recipientLists = [];
        }
        $scope.recipientListOptions = {};

        for (var rl = 0; rl < tempRecipientLists.length; rl++) {
            $scope.recipientListOptions[tempRecipientLists[rl].id] = tempRecipientLists[rl];
        }

    });

    $scope.genders = [
        {
            "name":"Herr",
            "value":"MALE"
        },
        {
            "name":"Frau",
            "value":"FEMALE"
        }

    ];



    $scope.loadTasks = function () {
        TaskUnpagedService.findAllTasks($scope.entityId).then(function () {
            $scope.tasks = TaskUnpagedService.getAllTasks();
            if($scope.tasks != undefined) {
                for (var i=0; i < $scope.tasks.length; i++) {
                    if($scope.tasks[i].dueDate != undefined) {
                        var tmpMonth = new Date($scope.tasks[i].dueDate).getMonth()+1;
                        var tmp = new Date($scope.tasks[i].dueDate).getDate() + '.' + tmpMonth + '.' + new Date($scope.tasks[i].dueDate).getFullYear();
                        $scope.tasks[i].dueDate =  tmp;
                    }
                }
            }
        });
        $scope.hideTask = true;
    };

    $scope.loadNotes = function () {
        NoteUnpagedService.findAllNotes($scope.entityId).then(function () {
            $scope.notes = NoteUnpagedService.getAllNotes();
            if($scope.notes != undefined) {
                for (var i=0; i < $scope.notes.length; i++) {
                    if($scope.notes[i].createdAt != undefined) {
                        var tmpMonth = new Date($scope.notes[i].createdAt).getMonth()+1;
                        var tmp = new Date($scope.notes[i].createdAt).getDate() + '.' + tmpMonth + '.' + new Date($scope.notes[i].createdAt).getFullYear();
                        $scope.notes[i].createdAt =  tmp;
                    }
                }
            }
        });
        $scope.hideNote = true;
    };

    if ($scope.entityId.length > 0 ) {
        $scope.loadTasks();
        $scope.loadNotes();
    }

    /*
     functions for working with addresses
     */
    $scope.setSelectedAddress = function(address) {
        $scope.selectedAddress = address;
    };

    $scope.setDefaultInvoiceAddress = function(index) {
        for(var i in $scope.entity.addresses){
            $scope.entity.addresses[i].isDefaultInvoiceAddress = false;
        }
        // $scope.defaultInvoiceAddress = $scope.selectedAddress;
        // $scope.selectedAddress.isDefaultInvoiceAddress = true;
        $scope.defaultInvoiceAddress = $scope.entity.addresses[index];
        $scope.entity.addresses[index].isDefaultInvoiceAddress = true;
    };

    $scope.setDefaultShippingAddress = function(index) {
        for(var i in $scope.entity.addresses){
            $scope.entity.addresses[i].isDefaultShippingAddress = false;
        }
        // $scope.defaultShippingAddress = $scope.selectedAddress;
        // $scope.selectedAddress.isDefaultShippingAddress = true;
        $scope.defaultShippingAddress = $scope.entity.addresses[index];
        $scope.entity.addresses[index].isDefaultShippingAddress = true;
    };

    $scope.removeAddress = function (index) {
        $scope.entity.addresses.splice(index, 1);
    };

    $scope.getAddressCountry = function (id) {
        for (var i in $scope.countries) {
            if($scope.countries[i].id == id) {
                return $scope.countries[i].name;
            }
        }
    };

    /*
     image
     */

    $scope.uploader = ImageService.initFileUpload(new FileUploader({url: '/api/v1/media/images'}));
    $scope.uploader.removeAfterUpload = true;

    $scope.uploadViaInput = function () {
        angular.element('#file-input').trigger('click');
    };

    $scope.uploadImage = function(image) {
        image.formData.push({
            type: "CUSTOMER_IMAGE"
        });
        image.upload();
    };

    $scope.uploader.onSuccessItem = function(fileItem, response, status, headers) {
        $scope.entity.imageId = response.id;
    };


    /*
     generate Password
     */
    $scope.getRandomPassword = function (length) {
        var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
        var pass = "";
        for (var x = 0; x < length; x++) {
            var i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        return pass;
    };

    $scope.generatePassword = function () {
        $scope.entity.password = $scope.getRandomPassword(10);
    };

    /*
     Set something on entity before saving it.
     */
    $scope.$on("beforeUpdate", function () {
        $scope.save();
    });

    $scope.$on("beforeCreate", function () {
        //if($scope.entity.password == undefined || $scope.entity.password == "") {
        //    $scope.generatePassword();
        //}

        $scope.save();
    });

    $scope.$on("afterUpdate", function () {
        if ($scope.entity.password !== undefined) {
            $scope.protectedPassword = $scope.entity.password;
            $scope.entity.password = undefined;
        }
        $scope.formatDataForFrontend();
    });

    $scope.$on("afterCreate", function () {
        $scope.formatDataForFrontend();
    });

    $scope.$on("afterInit", function () {
        $scope.formatDataForFrontend();
    });

    $scope.save = function () {
        $scope.setPhones();
        $scope.setContactTypes();
        $scope.saveDocuments();
    };

    $scope.saveDocuments = function() {
        CustomerService.saveDocuments($scope.entity.id, $scope.customerDocuments).then(function () {
            $scope.customerDocuments = CustomerService.getDocuments();
        });
    };

    $scope.setPhones = function () {
        $scope.entity.phones = [];
        if($scope.phone != undefined && $scope.phone != null){
            if($scope.phone.number != "" && $scope.phone.number != undefined && $scope.phone.number != null) {
                $scope.entity.phones.push({
                    id: $scope.phone.id,
                    name: 'Telefon',
                    number: $scope.phone.number
                });
            }
        }

        if($scope.mobilPhone != undefined && $scope.mobilPhone != null){
            if($scope.mobilPhone.number != "" && $scope.mobilPhone.number != undefined && $scope.mobilPhone.number != null) {
                $scope.entity.phones.push({
                    id: $scope.mobilPhone.id,
                    name: 'Mobil',
                    number: $scope.mobilPhone.number
                });
            }
        }

        if($scope.fax != undefined && $scope.fax != null){
            if($scope.fax.number != "" && $scope.fax.number != undefined && $scope.fax.number != null) {
                $scope.entity.phones.push({
                    id: $scope.fax.id,
                    name: 'Fax',
                    number: $scope.fax.number
                });
            }
        }
    };

    $scope.setContactTypes = function () {
        $scope.entity.contactTypes = [];
        if ($scope.contact.phone) {
            $scope.entity.contactTypes.push({
                name: "PHONE"
            });
        }
        if ($scope.contact.fax) {
            $scope.entity.contactTypes.push({
                name: "FAX"
            });
        }
        if ($scope.contact.mobil) {
            $scope.entity.contactTypes.push({
                name: "MOBIL_PHONE"
            });
        }
        if ($scope.contact.email) {
            $scope.entity.contactTypes.push({
                name: "EMAIL"
            });
        }
        if ($scope.contact.sms) {
            $scope.entity.contactTypes.push({
                name: "SMS"
            });
        }
        if ($scope.contact.whatsapp) {
            $scope.entity.contactTypes.push({
                name: "WHATSAPP"
            });
        }
    };

    $scope.validate = function () {
        var validationObject = {
            email: {
                method: "isEmail",
                fieldName: "E-Mail"
            },
            username: {
                method: "isset",
                fieldName: "Benutzername"
            }
        };

        if ($scope.entityName === 'Customer') {
            validationObject.firstName = {
                method: "isset",
                fieldName: "Vorname"
            };

            validationObject.lastName = {
                method: "isset",
                fieldName: "Nachname"
            };
        }

        if ($scope.entity.isOnlineAccount) {
            // validate password and password confirm if entity is new
            if (!$scope.entity.id) {
                validationObject.password = {
                    method: "isset",
                    fieldName: "Passwort"
                };

                validationObject.passwordCheck = {
                    fieldName: "Passwort wiederholen",
                    errorMsg: "Passwörter stimmen nicht überein.",
                    customValidate: function (customer) {
                        return customer.password && customer.passwordCheck && customer.password === customer.passwordCheck;
                    }
                };
            }
        } else {
            delete validationObject.username;
        }

        var addressesValid = true;
        var validationObjectAddress = {
            email: {
                method: "isEmail",
                fieldName: "E-Mail"
            }
        };
        if($scope.entity.addresses === undefined) {
            $scope.entity.addresses = [];
        }

        if (!$scope.entity.username) {
            $scope.entity.username = $scope.entity.email;
        }

        for(var i=0; i < $scope.entity.addresses.length; i++) {
            if(!ValidationService.validate($scope.entity.addresses[i], validationObjectAddress)){
                if($scope.entity.addresses[i].email != undefined && $scope.entity.addresses[i].email != "") {
                    NotificationService.notifyValidationErrors(ValidationService.getErrors());
                    addressesValid = false;
                }
            }
        }
        return (ValidationService.validate($scope.entity, validationObject) && addressesValid);
    };

    /*
     Modal Dialog functions
     */
    $scope.onSuccessAddress = function(result) {
        if ($scope.entity.addresses == undefined) {
            $scope.entity.addresses = [];
        }
        $scope.entity.addresses.push(result);
    };

    $scope.onCancelAddress = function() {
    };

    $scope.dialogCustomerAddressController = function($scope, dataToPass, $mdDialog) {
        $scope.data = {};
        $scope.countries = dataToPass.countries;
        $scope.titles = dataToPass.titles;
        $scope.genders = dataToPass.genders;
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
        $scope.addAddress = function () {
            $mdDialog.hide($scope.data);
        };
    };

    $scope.onSuccessSocialMedia = function(result) {
        if(!result) {
            return;
        }

        if ($scope.entity.socialMediaProfiles == undefined) {
            $scope.entity.socialMediaProfiles = [];
        }

        $scope.entity.socialMediaProfiles.push(result);
        console.debug($scope.entity.socialMediaProfiles);
    };

    $scope.onCancelSocialMedia = function() {
    };

    $scope.dialogCustomerSocialMediaController = function($scope, dataToPass, $mdDialog) {
        if(dataToPass.currentProfile !== undefined) {
            $scope.data = dataToPass.currentProfile;
        } else {
            $scope.data = {type: '', url: ''};
            $scope.newProfile = true;
        }
        $scope.profiles = dataToPass.profiles;
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
        $scope.addSocialMediaProfile = function () {
            $mdDialog.hide($scope.data);
        };
    };

    /* TASKS + NOTES */
    $scope.selectedTask = {};
    $scope.selectedNote = {};

    $scope.taskOptions = [{name: 'Termin'}, {name: 'Anruf'}, {name: 'Mahnung'}, {name: 'Angebot schicken'}, {name: 'Rücksprache'}]

    $scope.addTask =  function () {
        $scope.hideNote = true;
        $scope.selectedTask = {
            createdAt: today.getFullYear() + '-' + month + '-' + today.getDate(),
            author: $scope.act_user.username
        };
        $scope.hideTask = false;
    };

    $scope.removeTask = function (id) {
        $scope.hideNote = true;
        TaskUnpagedService.deleteTask($scope.entityId, id).then(function () {
            $scope.loadTasks();
        });
        $scope.selectedTask = {};
        $scope.hideTask = true;
    };

    $scope.setTask = function (id) {
        $scope.hideNote = true;
        TaskUnpagedService.findOneTask($scope.entityId, id).then(function () {
            $scope.selectedTask = TaskUnpagedService.getOneTask();
            $scope.selectedTask.dueDate = new Date($scope.selectedTask.dueDate);
            $scope.selectedTask.createdAt = new Date($scope.selectedTask.createdAt);
        });
        $scope.hideTask = false;
    };

    $scope.saveTask = function () {
        if($scope.selectedTask.email != undefined && $scope.selectedTask.email != '') {
            if($scope.validateTask()) {
                $scope.hideNote = true;
                TaskUnpagedService.saveTask($scope.entityId, $scope.selectedTask).then(function () {
                    $scope.loadTasks();
                });
                $scope.selectedTask = {};
                $scope.hideTask = true;
            } else {
                NotificationService.notifyValidationErrors(ValidationService.getErrors());
            }
        } else {
            $scope.hideNote = true;
            TaskUnpagedService.saveTask($scope.entityId, $scope.selectedTask).then(function () {
                $scope.loadTasks();
            });
            $scope.selectedTask = {};
            $scope.hideTask = true;
        }
    };

    $scope.validateTask = function () {
        var validationObject = {
            email: {
                method: "isEmail",
                fieldName: "E-Mail"
            }
        };
        return ValidationService.validate($scope.selectedTask, validationObject);
    };

    $scope.addNote =  function () {
        $scope.hideTask = true;
        $scope.selectedNote = {
            createdAt:  today.getFullYear() + '-' + month + '-' + today.getDate(),
            author: $scope.act_user.username
        };
        $scope.hideNote = false;
    };

    $scope.removeNote = function (id) {
        $scope.hideTask = true;
        NoteUnpagedService.deleteNote($scope.entityId, id).then(function () {
            $scope.loadNotes();
        });
        $scope.selectedNote = {};
        $scope.hideNote = true;
    };

    $scope.setNote = function (id) {
        $scope.hideTask = true;
        NoteUnpagedService.findOneNote($scope.entityId, id).then(function () {
            $scope.selectedNote = NoteUnpagedService.getOneNote();
            $scope.selectedNote.createdAt = new Date($scope.selectedNote.createdAt);
        });
        $scope.hideNote = false;
    };

    $scope.saveNote = function () {
        $scope.hideTask = true;
        NoteUnpagedService.saveNote($scope.entityId, $scope.selectedNote).then(function () {
            $scope.loadNotes();
        });
        $scope.selectedNote = {};
        $scope.hideNote = true;
    };


    // -----------------------------------------------------------------------------------
    // TransferDialogs
    // -----------------------------------------------------------------------------------
    $scope.transferImageController = function($rootScope, $scope, dataToPass, $mdDialog) {

        $scope.outerScope = dataToPass;

        $scope.transferImage = function() {
            CustomerService.transferImage($scope.outerScope.entityId, $scope.customerFile);
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    };

    $scope.transferDataFeedController = function($rootScope, $scope, dataToPass, $mdDialog) {

        $scope.outerScope = dataToPass;

        $scope.transferDataFeed = function() {
            DataFeedService.transferDataFeed($scope.outerScope.entityId, $scope.customerFile, $scope.dataFeedDisplayName);
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    };

    $scope.transferDocumentController = function($rootScope, $scope, dataToPass, $mdDialog) {

        $scope.documentType = "OTHER";
        $scope.documentVisibility = "ADMIN";

        $scope.outerScope = dataToPass;

        $scope.transferDocument = function() {
            CustomerService.transferDocument($scope.outerScope.entityId, $scope.customerDocument, $scope.documentType, $scope.documentVisibility).then(
                function () {
                    loadDocuments();
                }
            );
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    };

    $scope.onTransferImageSuccess = function() {
        $timeout(CustomerService.loadImages($scope.entityId).then(function () {
            $scope.customerImages = CustomerService.getImages();
        }),1000);
    };

    $scope.onTransferDocumentSuccess = function() {
        $timeout(CustomerService.loadDocuments($scope.entityId).then(function () {
            $scope.customerDocuments = CustomerService.getDocuments();
        }),1000);
    };

    $scope.extendForSelectValues = function (dataFeeds) {
        for (var i = 0; i < dataFeeds.length; i++) {
            var dataFeed = dataFeeds[i];
            dataFeed.display = dataFeed.displayName + " (" + dataFeed.name + ")";
            dataFeed.value = dataFeed.id;
        }
        return dataFeeds;
    };

    $scope.onTransferDataFeedSuccess = function () {
        $timeout(DataFeedService.findAll().then(function () {
            $scope.dataFeeds = $scope.extendForSelectValues(DataFeedService.getAll().data);
        }), 1000);
    };

    $scope.onTransferFail = function() {
        NotificationService.notifyGeneralError({statusText: 'Upload fehlgeschlagen'})
    };

    // -----------------------------------------------------------------------------------
    // MEDIA
    // -----------------------------------------------------------------------------------
    $scope.deleteImage = function (imageId) {
        NotificationService.confirm({
            statusText: 'Wollen sie dieses Bild wirklich löschen?',
            msg: ""/*msgs.join('; ')*/
        }).then(function () {
            CustomerService.deleteImage($scope.entityId, imageId).then(function () {
                CustomerService.loadImages($scope.entityId).then(function () {
                    $scope.customerImages = CustomerService.getImages();
                })
            });
        }, function () {
        });
    };

    $scope.deleteDocument = function (documentId) {
        NotificationService.confirm({
            statusText: 'Wollen sie dieses Dokument wirklich löschen?',
            msg: ""/*msgs.join('; ')*/
        }).then(function () {
            CustomerService.deleteDocument($scope.entityId, documentId).then(function () {
                CustomerService.loadDocuments($scope.entityId).then(function () {
                    $scope.customerDocuments = CustomerService.getDocuments();
                })
            });
        }, function () {
        });
    };

    $scope.deleteDataFeed = function (dataFeedId) {
        for (var i = 0; i < $scope.entity.dataFeeds.length; i++) {
            var dataFeed = $scope.entity.dataFeeds[i];
            if (dataFeed.id == dataFeedId) {
                $scope.entity.dataFeeds.splice(i, 1);
            }
        }
    };

    $scope.addDataFeed = function () {
        for (var i = 0; i < $scope.dataFeeds.length; i++) {
            var dataFeed = $scope.dataFeeds[i];
            if (dataFeed.value == $scope.selected.choosedDataFeedId) {
                $scope.entity.dataFeeds.push(dataFeed);
                break;
            }
        }
    };

    /*/-----EMAIL RESET-----/*/
    $scope.initEmailReset = function () {
        if (!$scope.entity.id) {
            NotificationService.notifyGeneralError({
                statusText: 'Bitte speichern Sie den Kunden zunächst.'
            });
            return;
        }

        NotificationService.confirm({
            statusText: 'Wollen sie die Email-Adresse dieses Kunden wirklich zurücksetzen?'
        }).then(function() {
            CustomerService.initEmailReset($scope.entity.email).then(function() {
                NotificationService.notifySuccess({
                    statusText: 'Es wurden Anweisungen zum Zurücksetzen der E-Mail gesendet.'
                });
            }, function() {
                NotificationService.notifyGeneralError({
                    statusText: 'Anweisungen zum Zurücksetzen der E-Mail konnten nicht gesendet werden. Siehe Protokoll für Details.'
                });
            });
        });
    };
    /*-----PASSWORD RESET-----*/

    /*/-----PASSWORD RESET-----/*/
    $scope.initPasswordReset = function () {
        if (!$scope.entity.id) {
            NotificationService.notifyGeneralError({
                statusText: 'Bitte speichern Sie den Kunden zunächst.'
            });
            return;
        }

        NotificationService.confirm({
            statusText: 'Wollen sie das Passwort dieses Kunden wirklich zurücksetzen?'
        }).then(function () {
            CustomerService.initPasswordReset($scope.entity.email).then(function() {
                NotificationService.notifySuccess({
                    statusText: 'Anweisungen zum Zurücksetzen des Passworts wurden an die E-Mail gesendet.'
                });
            }, function() {
                NotificationService.notifyGeneralError({
                    statusText: 'Es konnten keine Anweisungen zum Zurücksetzen des Kennworts gesendet werden. Siehe Protokoll für Details.'
                });
            });
        });
    };
    /*-----PASSWORD RESET-----*/

    /*/-----TICKET SYSTEM-----/*/
    $scope.setTicketStatus = function (status, ticket) {
        ticket.status = status;
        TicketUnpagedService.update(ticket);
    };

    $scope.addCommunication = function (ticket) {
        if(ticket.communications == undefined) {
            ticket.communications = [];
        }
        ticket.communications.unshift({
            author: $scope.shop.name + " Team",
            content: "",
            createdAt: new Date(),
            new: true
        })
    };

    $scope.saveCommunication = function (ticket) {
        for(var i=0; i < ticket.communications.length; i++) {
            delete ticket.communications[i].new;
        }
        var ticket1;
        TicketUnpagedService.saveTicket(ticket).then(function (response) {
            ticket1 = response;
            for(var i=0; i < $scope.entity.tickets.length; i++) {
                if($scope.entity.tickets[i].id == ticket1.id) {
                    $scope.entity.tickets[i] = ticket1;
                    break;
                }
            }
        });
    };

    $scope.removeCommunication = function (ticket) {
        ticket.communications.shift();
    };

    $scope.createTicket = function (customerId) {
        $scope.newTicket = {
            author: $scope.shop.name + " Team",
            createdAt: new Date(),
            name: "",
            customerId: customerId,
            status: "OPEN",
            communications: [{
                author: $scope.shop.name + " Team",
                content: "",
                createdAt: new Date()
            }]
        };
    };

    $scope.saveNewTicket = function () {
        var ticket;
        TicketUnpagedService.saveTicket($scope.newTicket).then(function (response) {
            ticket = response;
            $scope.entity.tickets.unshift(ticket);
            $scope.newTicket = undefined;
        });
    };

    $scope.tinymceOptions = {
        onChange: function (e) {},
        inline: false,
        plugins: 'advlist autolink link image lists charmap print preview code',
        skin: 'lightgray',
        theme: 'modern'
    };

    $scope.getOrderNumberById = function (orderId) {
        for(var i=0; i < $scope.data.orders.length; i++) {
            if($scope.data.orders[i].orderId == orderId) {
                return $scope.data.orders[i].order.orderConfirmationNumber;
            }
        }
    };
    /*-----TICKET SYSTEM-----*/

    /*/-----Customer Assignment-----/*/
    $scope.formatDataForBackend = function () {
        for (var i = 0; i < $scope.entity.assignedCustomers.length; i++) {
            var assignedCustomer = $scope.entity.assignedCustomers[i];
            assignedCustomer.permissions = [];
            if (assignedCustomer.READ_TICKETS) {
                assignedCustomer.permissions.push("READ_TICKETS");
            }
            if (assignedCustomer.WRITE_TICKETS) {
                assignedCustomer.permissions.push("WRITE_TICKETS");
            }
            if (assignedCustomer.READ_DOCUMENTS) {
                assignedCustomer.permissions.push("READ_DOCUMENTS");
            }
            if (assignedCustomer.UPLOAD_DOCUMENTS) {
                assignedCustomer.permissions.push("UPLOAD_DOCUMENTS");
            }
            if (assignedCustomer.READ_ORDERS) {
                assignedCustomer.permissions.push("READ_ORDERS");
            }
        }
    };

    $scope.formatDataForFrontend = function () {
        $scope.convertPermissionsForFrontend($scope.entity.assignedOrganisations);
        $scope.convertPermissionsForFrontend($scope.entity.assignedCustomers);
    };

    $scope.convertPermissionsForFrontend = function(customerArray){

        if (!customerArray) {
            return;
        }

        for (var i = 0; i < customerArray.length; i++) {
            var customer = customerArray[i];
            if (customer.permissions.indexOf("READ_TICKETS") != -1) {
                customer.READ_TICKETS = true;
            }
            if (customer.permissions.indexOf("WRITE_TICKETS") != -1) {
                customer.WRITE_TICKETS = true;
            }
            if (customer.permissions.indexOf("READ_DOCUMENTS") != -1) {
                customer.READ_DOCUMENTS = true;
            }
            if (customer.permissions.indexOf("UPLOAD_DOCUMENTS") != -1) {
                customer.UPLOAD_DOCUMENTS = true;
            }
            if (customer.permissions.indexOf("READ_ORDERS") != -1) {
                customer.READ_ORDERS = true;
            }
        }
    };

    $scope.addCustomerSelectEntry = function (customer) {

        if(!customer){
            return;
        }

        for (var i = 0; i < $scope.availableCustomers.length; i++) {
            var availableCustomer = $scope.availableCustomers[i];
            if (availableCustomer.value == customer.id) {
                //do not add items twice
                return;
            }
        }

        if (customer.id == $scope.entity.id) {
            //do not add yourself
            return;
        }

        $scope.availableCustomers.push({
            value: customer.id,
            display: customer.id + " | " + customer.firstName + " " + customer.lastName + " | " + customer.email
        });
    };

    $scope.addAvailableCustomersFromEntity = function () {
        for (var i = 0; i < $scope.entity.assignedCustomers.length; i++) {
            var customer = $scope.entity.assignedCustomers[i].customer;
            $scope.addCustomerSelectEntry(customer);
        }
    };
    /*/-----Customer Assignment-----/*/
}
;
