define(
    'directives/contentConfigurator/contentSnippets/accordionSnippet/AccordionSnippetConfig',[
        'app',
        'angular',
        'services/ContentBuilderHelper',
        'directives/contentConfigurator/contentSnippets/_snippet'
    ],
    function (app) {
        'use strict';
        app.directive('accordionSnippetConfig', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'E',
                scope: {
                    metaIdentifier: '<',
                    elementIdentifier: '<',
                    element: '='
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/accordionSnippet/views/accordionSnippetConfig.html",
                controller: ['$scope', '$controller', 'ContentBuilderHelper',
                    function ($scope, $controller, ContentBuilderHelper) {

                        angular.extend(this, $controller('snippetConfigController', {
                            $scope: $scope
                        }));

                        $scope.options.title = 'Accordion';

                        $scope.config = {
                            items: [
                            ],
                        }

                        $scope.previousElementIdentifier = undefined;
                        var _init = function () {
                            if (typeof $scope.element.config !== "undefined") {
                                $scope.config = $scope.element.config;
                            }
                        };

                        // Track elementIdentifier change for cases when you switch between different sections, directive stays the same only data is updated
                        $scope.$watch('elementIdentifier', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                if (typeof $scope.element.config !== "undefined") {
                                    $scope.config = $scope.element.config;
                                }
                            }
                        });

                        $scope.$watch('config', function (newValue, oldValue) {
                            // Do not propagate config change on first load or when switching to different snippet of same type (section)
                            if ($scope.previousElementIdentifier !== $scope.elementIdentifier) {
                                $scope.previousElementIdentifier = $scope.elementIdentifier;
                            } else {
                                if (newValue === undefined && newValue === oldValue) {
                                    return;
                                }

                                $scope.element.config = { // this will trigger section directive watchers
                                    items: $scope.config.items
                                }
                            }
                        }, true);
                        _init();

                        $scope.addItem = function () {
                            $scope.config.items.push({ title: "", text: "" })
                        }
                        $scope.removeItem = function (index) {
                            $scope.config.items.splice(index, 1);
                        }
                    }]
                }
            }]);
        }
        );


