define(
	'directives/rules/rules',[
		'app',
        'settings',
   	], 
    
   	function (app) {
		'use strict';
		app.directive('ngRules', ['BASE_DIRECTIVES_PATH',
    		function(BASE_DIRECTIVES_PATH) {
        		return{
                    scope: {
                        rules: "=",
                    },
	        		templateUrl: BASE_DIRECTIVES_PATH + "/rules/views/rules.html",
	        		controller: ['$scope', '$rootScope', function($scope, $rootScope) {
                        
                        $scope.getConditionById = function(id, rule) {
                            var ret;
                            rule.conditions.forEach(function (condition) {
                                if(condition.id == id){
                                    ret = condition;
                                }
                            });
                            return ret;
                        };

                        $scope.printCondition = function (id, rule) {
                            var condition = $scope.getConditionById(id, rule);
                            return [
                                condition.name,
                                condition.operator,
                                condition.value + condition.unit,
                            ].join(' ');
                        };

                        $scope.addRule = function ($index) {
                            $scope.rules.splice(($index + 1), 0, $scope.createNewRule());
                        };

                        $scope.removeRule = function ($index) {
                            $scope.rules.splice($index, 1);
                        }

                        $scope.createNewRule = function() {
                            return {
                                "conditions": [
                                    {
                                        "id": 1,
                                        "name": "",
                                        "operator": "",
                                        "value": 0,
                                        "unit": "",
                                    },
                                    {
                                        "id": 2,
                                        "value": 0,
                                        "unit": "",
                                        "name": "",
                                        "operator": "",
                                    }
                                ],
                                "join": {
                                    "name": "",
                                    "conditions": [1,2],
                                    "multiplier": 0.00,
                                }
                            };
                        }

                        /**
                         * @todo: delme
                         */
                        if(!$scope.rules.length){
                            $scope.rules.push($scope.createNewRule())
                        }
	        		}],
	            }
    		}	
        ]);					
	}
);

