define(
    'directives/entityListSelect/EntityListSelect',[
        'app',
        'settings'
    ],
    function (app) {
        'use strict';
        app.directive('ngEntityListSelect', [
            'BASE_DIRECTIVES_PATH',
            NgEntityListSelect
        ]);
    }
);
function NgEntityListSelect(BASE_DIRECTIVES_PATH) {
    return{
        scope: {
            name: "=",
            columns: "=",
            entityService: "=",
            selectedItems: "=",
            rightSideItemClick: "=?"
        },
        templateUrl: BASE_DIRECTIVES_PATH + "/entityListSelect/views/view.html",
        controller: [
            '$scope',
            NgEntityListSelectController
        ]
    };
}
function NgEntityListSelectController($scope) {

    $scope.query = {};
    $scope.query.filters = {};
    $scope.firstPageLoaded = false;
    $scope.fromFilters = {}
    $scope.toFilters = {}

    // custom filter comparator to properly work when expected is empty and actual is undefined
    $scope.filterComparator = function (actual, expected) {
        // catch when searched input is emptied
        if(expected === "") {
            return true;
        }
        // when object has no property it will be undefined
        if(typeof actual === "undefined") {
            return false
        }
        return actual.toLowerCase().indexOf(expected.toLowerCase()) !== -1
    }

    $scope.$watch("items", function (newValue, oldValue) {
        if ($scope.firstPageLoaded || !$scope.entityService) {
            return;
        } else {
            $scope.entityService.setCurrentPage(0).then(function () {
                $scope.items = $scope.entityService.getAll();
                $scope.totalItems = $scope.entityService.getTotalItems();
            });
            $scope.firstPageLoaded = true;
        }
    });

    $scope.searchInputChanged = function () {
        if ($scope.filterTimer) {
            clearTimeout($scope.filterTimer);
        }
        var textField = this;
        $scope.filterTimer = setTimeout(function () {
            var columnAttribute = textField.column.attribute;
            $scope.query.filters[columnAttribute] = textField.fromFilters[columnAttribute];
            if (textField.fromFilters[columnAttribute]==="") {
                delete textField.fromFilters[columnAttribute];
            }
            $scope.entityService.setQuery($scope.query).then(function () {
                $scope.items = $scope.entityService.getAll();
                $scope.totalItems = $scope.entityService.getTotalItems();
            });
        }, 500);
    };

    $scope.listSelectPageChanged = function () {
        $scope.entityService.setCurrentPage($scope.selectedPage - 1).then(function () {
            $scope.items = $scope.entityService.getAll();
        });
    };

    $scope.$watch("items", function (newValue, oldValue) {
        if (newValue === undefined || newValue == null) {
            return;
        }
        $scope.selectItems();
    });

    $scope.selectItems = function () {
        if (!$scope.selectedItems) {
            console.error("The attribute selected-items='X' is not set. Please set it to use the list control.");
            return;
        }
        for (var i = 0; i < $scope.items.length; i++) {
            var item = $scope.items[i];

            for (var j = 0; j < $scope.selectedItems.length; j++) {
                var selectedItem = $scope.selectedItems[j];
                if (item.id == selectedItem.id) {
                    if (!item[$scope.name]) {
                        item[$scope.name] = {};
                    }
                    item[$scope.name].selected = false;
                    item[$scope.name].added = true;
                }
            }
        }
    };

    $scope.addItems = function () {
        for (var i = 0; i < $scope.items.length; i++) {
            if ($scope.items[i][$scope.name] && $scope.items[i][$scope.name].selected && !$scope.items[i][$scope.name].added) {
                var tmp = {};
                tmp.id = $scope.items[i].id;
                $scope.columns.forEach(function(col) {
                    tmp[col.attribute] = $scope.items[i][col.attribute];
                })

                tmp[$scope.name] = {};

                $scope.selectedItems.push(tmp);

                $scope.items[i][$scope.name].added = true;
                $scope.items[i][$scope.name].selected = false;
            }
        }
    };

    $scope.removeItems = function () {
        for (var i = 0; i < $scope.selectedItems.length; i++) {
            if ($scope.selectedItems[i][$scope.name] && $scope.selectedItems[i][$scope.name].selected) {
                for (var j = 0; j < $scope.items.length; j++) {
                    if ($scope.items[j].id == $scope.selectedItems[i].id) {
                        $scope.items[j][$scope.name].added = false;
                        $scope.items[j][$scope.name].selected = false;
                    }
                }

                $scope.selectedItems.splice(i, 1);
                i--;
            }
        }
    };

    $scope.itemsLeftSelected = function () {
        if ($scope.items != undefined) {
            for (var i = 0; i < $scope.items.length; i++) {
                if ($scope.items[i][$scope.name] && $scope.items[i][$scope.name].selected && !$scope.items[i][$scope.name].added) {
                    return true;
                }
            }
            return false;
        }
        return false;
    };

    $scope.itemsRightSelected = function () {
        for (var i = 0; i < $scope.selectedItems.length; i++) {
            if ($scope.selectedItems[i][$scope.name] && $scope.selectedItems[i][$scope.name].selected) {
                return true;
            }
        }
        return false;
    };
}
;
