define(
    'directives/errorsToHtml/errorsToHtml',[
        'app',
        'settings',
    ],
    function (app) {
        'use strict';
        app.directive('ngErrorsToHtml', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        errors: "="
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + "/errorsToHtml/views/errorsToHtml.html",
                    controller: ['$scope', function ($scope) {

                    }]
                };
            }
        ]);
    }
);

