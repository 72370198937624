define('filters/DateFilter',[
	'app'
	],	
	function (app){
		'use strict';
		app.filter('dateFilter', function(){
			return function(isoDate){
				var a = new Date(isoDate);
				var months = ['Jan','Feb','Mär','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'];
				var year = a.getFullYear();
				var month = months[a.getMonth()];
				var date = a.getDate();

				var time = date + ' ' + month + ' ' + year;
				if(isNaN(date)){
					return "";
				}
				return time;

			}
		});
	}
);
