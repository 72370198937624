define('directives/shippingConditionGroups/shippingConditionGroups',[
        'app',
        'settings',
        'services/ShippingRuleService'
    ],
    function (app) {
        'use strict';
        app.directive('ngShippingConditionGroups', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        shippingConditionGroups: "="
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + "/shippingConditionGroups/views/shippingconditiongroups.html",
                    controller: ['$scope', '$rootScope', 'ShippingRuleService', function ($scope, $rootScope, ShippingRuleService) {

                        $scope.transformedInputOptions = [{}];


                        ShippingRuleService.loadAvailableFieldTypes().then(function () {
                            $scope.availableFieldTypes = ShippingRuleService.getAvailableFieldTypes();
                            $scope.availableFieldTypeNames = [];
                            $scope.availableFieldTypes.forEach(function (fieldType) {
                                $scope.availableFieldTypeNames.push({
                                    id: fieldType.name,
                                    name: fieldType.name
                                });
                            });
							
							if($scope.shippingConditionGroups.length === 0){
								$scope.addConditionGroup(0);
							}
							
                            for (var i = 0; i < $scope.shippingConditionGroups.length; i++) {
                                for (var j = 0; j < $scope.shippingConditionGroups[i].shippingConditions.length; j++) {
                                    var fieldType = $scope.shippingConditionGroups[i].shippingConditions[j].fieldType;
                                    $scope.transformedInputOptions[j] = {};
                                    $scope.transformedInputOptions[j].unitsType = [];
                                    if (fieldType) {
                                        $scope.availableFieldTypes.forEach(function (item) {
                                            if (item.name === fieldType.name) {
                                                $scope.transformedInputOptions[j].unitsType.push(item.units[0].unitType);
                                            }
                                        });
                                    }
                                }
                            }

                        });

                        ShippingRuleService.loadAvailableOperators().then(function () {
                            $scope.availableOperators = $scope.toObjectArray(ShippingRuleService.getAvailableOperators());
                        });


                        $scope.toObjectArray = function (arr) {
                            var results = [];
                            arr.forEach(function (string) {
                                results.push({
                                    id: string,
                                    name: string
                                });
                            });
                            return results;
                        };

                        $scope.addConditionGroup = function ($index) {
                            $scope.shippingConditionGroups.splice(($index + 1), 0, {
                                shippingConditions: [{}]
                            });
                        };

                        $scope.removeConditionGroup = function ($index) {
                            $scope.shippingConditionGroups.splice($index, 1);
                        };

                        $scope.addCondition = function (conditionGroup, $index) {
                            conditionGroup.shippingConditions.splice(($index + 1), 0, {});
                        };

                        $scope.removeCondition = function (conditionGroup, $index) {
                            conditionGroup.shippingConditions.splice($index, 1);
                        };

                        $scope.fieldTypeChanged = function (fieldType, $index) {
                            $scope.transformedInputOptions[$index] = {};
                            $scope.transformedInputOptions[$index].unitsType = [];

                            $scope.availableFieldTypes.forEach(function (item) {
                                if (item.name === fieldType.name) {
                                    $scope.transformedInputOptions[$index].unitsType.push(item.units[0].unitType);

                                }
                            });
                        };
                    }]
                };
            }
        ]);
    });

