define('services/SeminarService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('SeminarService', [
        '$injector',
        SeminarService
    ]);
});


function SeminarService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Seminar');

    angular.extend(this, crudInstance);

    this.convertDates = function (entity) {
        if(entity.appointments !== undefined) {
            for(var i=0; i < entity.appointments.length; i++){
                if(entity.appointments[i].subAppointments !== undefined && entity.appointments[i].subAppointments.length > 0) {
                    for(var j=0; j < entity.appointments[i].subAppointments.length; j++) {
                        entity.appointments[i].subAppointments[j].appointedDay = new Date (entity.appointments[i].subAppointments[j].appointedDay);
                    }
                    entity.appointments[i].subAppointments.sort(function (a,b) {
                        return a.appointedDay - b.appointedDay;
                    });
                }

            }
        }

        return entity;
    };
    
    return this;
};
