define('services/OrganisationService',[
    'app',
    'modules/module',
    'services/EntityCrudService',
    'services/NotificationService',
    'services/CustomerService'
], function (app, module) {
    'use strict';
    return module.factory('OrganisationService', [
        '$injector',
        'NotificationService',
        OrganisationService
    ]);
});

function OrganisationService($injector, NotificationService) {
    var customerServiceInstance = $injector.instantiate(CustomerService);
    angular.extend(this, customerServiceInstance);
    customerServiceInstance.setType('Organisation');

    this.findOrganisationCustomers = function (organisationId) {
        return this.getModel().findOrganisationCustomers({id: organisationId}).$promise;
    };

    return this;
};
