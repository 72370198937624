define('filters/CronFilter',[
        'app',
        'settings'
    ],
    function (app) {
        'use strict';
        app.filter('cronFilter', function () {
            return function (cronExpression) {

                if(cronExpression == "0 0/1 * 1/1 * ? *") {
                    return "jede Minute";
                }

                if(cronExpression == "0 0/5 * 1/1 * ? *") {
                    return "alle 5 min.";
                }

                if (cronExpression == "0 0/30 * 1/1 * ? *") {
                    return "alle 30 min.";
                }

                if (cronExpression == "0 0/60 * 1/1 * ? *") {
                    return "jede Stunde";
                }

                if (cronExpression == "0 0/300 * 1/1 * ? *") {
                    return "alle 5 Std.";
                }

                if (cronExpression == "0 0 12 1/1 * ? *") {
                    return "täglich";
                }

                if (cronExpression == "0 0 12 ? * MON *") {
                    return "wöchentlich";
                }

                if (cronExpression == "0 0 12 1 1/1 ? *") {
                    return "monatlich";
                }
            };
        });
    }
);
