/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/priceRule/PriceRuleListController',[
        'modules/module',
        'settings',
        'modules/basemodule/controllers/EntityListController',
        'services/PriceRuleService',
        'directives/controls/customInput/CustomInput',
        'filters/DateFilter'
    ],
    function (module) {
        'use strict';
        module.controller('PriceRuleListController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH', 'PriceRuleService',
            function ($scope, $controller, BASE_TEMPLATES_PATH, PriceRuleService) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.tableOptions.fields = [
                    {
                        id: 'ID',
                        heading: 'Id',
                        attribute: 'id'
                    },
                    {
                        id: 'NAME',
                        heading: 'Name',
                        attribute: 'name'
                    },
                    {
                        id: 'Status',
                        heading: 'Status',
                        template: BASE_TEMPLATES_PATH + '/settings/priceRule/col-status.html',
                        attribute: ''
                    },
                    {
                        id: 'PRIORITY',
                        heading: 'Priotität',
                        template: BASE_TEMPLATES_PATH + '/settings/priceRule/col-order-number.html',
                        searchDisabled: true,
                        attribute: ''
                    },
                    {
                        id: 'VALIDFROM',
                        heading: 'Gültig von',
                        template: BASE_TEMPLATES_PATH + '/settings/priceRule/col-create-date.html',
                        attribute: 'validFrom'
                    },
                    {
                        id: 'VALIDTO',
                        heading: 'Gültig bis',
                        template: BASE_TEMPLATES_PATH + '/settings/priceRule/col-update-date.html',
                        attribute: 'validTo'
                    }
                ];
                $scope.formState = 'root.pricerule';

                $scope.init('PriceRule');
                
                $scope.$on('Priority', function (evt, data) {
                        PriceRuleService.update(data);
                });
                
                /*
                    do not add ng-click to column priority
                */
                $scope.preserveForDialogs = function(item, field) {
                    return field.id != "PRIORITY";
                };
            }
        ]);
    }
);


