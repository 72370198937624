/**
 * Created by Nico on 05.07.2016.
 */
define('services/LocaleService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('LocaleService', ['$injector', LocaleService

    ]);
});

function LocaleService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Locale');

    angular.extend(this, crudInstance);

    return this;
};
