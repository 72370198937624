/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/user/UserFormController',[
        'modules/module',
        'modules/basemodule/controllers/EntityFormController',
        'services/RoleService',
        'services/ShopService',
        'services/ValidationService',
        'settings',
        'underscore'
    ],
    function (module) {
        'use strict';
        module.controller('UserFormController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH', 'RoleService', 'ShopService', 'ValidationService',
            function ($scope, $controller, BASE_TEMPLATES_PATH, RoleService, ShopService, ValidationService) {

                angular.extend(this, $controller('EntityFormController', {
                    $scope: $scope
                }));

                $scope.formTabs = [
                    {
                        label: 'Allgemein',
                        template: BASE_TEMPLATES_PATH + '/settings/user/form-main.html',
                        active: true
                    },
                    {
                        label: 'Rollen',
                        template: BASE_TEMPLATES_PATH + '/settings/user/form-roles.html'
                    }
                ];

                $scope.listState = 'root.users';
                $scope.data = { };

                $scope.init("User").then(function () {

                    ShopService.findAll().then(function(){
                        $scope.data.shops = ShopService.getAll(true);
                            $scope.data.shopId = $scope.data.shops[0].id;
                    });

                    RoleService.findAll().then(function(){
                        $scope.data.totalRoles = RoleService.getAll(true);

                        $scope.buildUIFromEntity();
                    });

                    if($scope.entity.email == undefined) {
                        $scope.entity.email = "";
                    }
                });

                $scope.buildEntityFromUI = function() {
                    $scope.entity.roles = [ ];

                    for(var i in $scope.data.shops) {
                        var shop = $scope.data.shops[i];

                        for(var j in $scope.data.totalRoles) {

                            var curRole = $scope.data.totalRoles[j];

                            if($scope.data.roles[shop.id] !== undefined &&
                               $scope.data.roles[shop.id][curRole.id]) {

                                var role = angular.copy(curRole);
                                role.shopId = shop.id;

                                $scope.entity.roles.push(role);
                            }
                        }
                    }
                };

                $scope.buildUIFromEntity = function() {
                    $scope.data.roles = { };

                    for(var i in $scope.entity.roles) {
                        var role = $scope.entity.roles[i];
                        if($scope.data.roles[role.shopId] === undefined) {
                            $scope.data.roles[role.shopId] = { };
                        }

                        $scope.data.roles[role.shopId][role.id] = true;
                    }
                };

                $scope.onSave = function() {
                    $scope.buildEntityFromUI();
                };

                $scope.$on("beforeCreate", $scope.onSave);
                $scope.$on("beforeUpdate", $scope.onSave);

                $scope.validate = function () {
                if($scope.entityId.length <  1) {
                    return ValidationService.validate($scope.entity, {
                        username: {
                            method: "isset",
                            fieldName: "Benutzername"
                        },
                        firstName: {
                            method: "isset",
                            fieldName: "Vorname"
                        },
                        lastName: {
                            method: "isset",
                            fieldName: "Nachname"
                        },
                        password: {
                            method: "isset",
                            fieldName: "Passwort"
                        },
                        email: {
                            method: "isEmail",
                            fieldName: "E-Mail"
                        }
                    });
                } else {
                    return ValidationService.validate($scope.entity, {
                        username: {
                            method: "isset",
                            fieldName: "Benutzername"
                        },
                        firstName: {
                            method: "isset",
                            fieldName: "Vorname"
                        },
                        lastName: {
                            method: "isset",
                            fieldName: "Nachname"
                        },
                        email: {
                            method: "isEmail",
                            fieldName: "E-Mail"
                        }
                    });
                }


                };

            }
        ]);
    }
);


