/**
 * Created by Nico on 11.07.2016.
 */
define('modules/cms/nodes/NodeController',[
        'modules/module',
        'settings',
        'modules/basemodule/controllers/EntityListController'
    ],
    function (module) {
        'use strict';
        module.controller('NodeController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));
                

                $scope.tableOptions.fields = [
                    {
                        id: 'STATE',
                        heading:    'Status'
                        //attribute:  ''
                    },
                    {
                        id: 'DESCRIPTION',
                        heading:    'Bezeichnung'
                    },
                    {
                        id: 'POSITION',
                        heading:    'Position'
                    },
                    {
                        id: 'CREATED',
                        heading:    'Angelegt am'
                    },
                    {
                        id: 'MODIFIED',
                        heading:    'Zuletzt geändert am'
                    }
                ];
                $scope.formState = 'root.node';

                $scope.init('Node');
            }
        ]);
    }
);

