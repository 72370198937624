define('modules/settings/shop/ShopFormController',[
        'modules/module',
        'modules/basemodule/controllers/EntityFormController',
        'services/CustomerGroupService',
        'services/PublishStateService',
        'services/CurrencyService',
        'services/LocaleService',
        'services/PaymentMethodService',
        'services/ShippingMethodService',
        'services/ShippingMethodUnpagedService',
        'services/CountryService',
        'services/CountryUnpagedService',
        'services/ShopService',
        'services/DropShippingService',
        'services/OrderStateUnpagedService',
        'services/ImageService',
        'services/ValidationService',
        'services/NotificationService',
        'services/CopyProductsService',
        'services/NavigationService',
        'services/EconSettingService',
        'services/TaxService',
        'services/MetadataUnpagedService',
        'services/AttributeUnpagedService',
        'services/CategoryService',
        'services/LocalStorageService',
        'services/UnitService',
        'services/OrganisationService',
        'services/ProductUnpagedService',
        'services/BankAccountService',
        'services/OrderManagementService',
        'services/SystemMessageUnpagedService',
        'directives/dndList/dir-dnd',
        'services/SessionValuesService',
        'settings',
        'directives/controls/customTextarea/CustomTextarea'
    ],
    function (module) {
        'use strict';
        module.controller('ShopFormController', ['ProductService', '$scope', '$injector', 'OrderStateUnpagedService', '$controller', 'BASE_TEMPLATES_PATH', 'TaxService', 'NotificationService', 'CopyProductsService', 'NavigationService', 'CustomerGroupService', 'PublishStateService', 'CurrencyService', 'LocaleService', 'PaymentMethodService', 'CountryService', 'CountryUnpagedService', 'ShippingMethodService', 'ShippingMethodUnpagedService', 'ShopService', 'ImageService', 'ValidationService', 'ShippingLabelService', 'EconSettingService', 'DropShippingService', 'AttributeUnpagedService', 'CategoryService', 'LocalStorageService', 'MetadataUnpagedService', 'UnitService', 'OrganisationService', '$window', 'SessionValuesService', 'BankAccountService', 'ProductUnpagedService', '$rootScope', 'OrderManagementService', 'SystemMessageService', 'PRODUCT_FILTER_OPTIONS', 'SystemMessageUnpagedService',
            function (ProductService, $scope, $injector, OrderStateUnpagedService, $controller, BASE_TEMPLATES_PATH, TaxService, NotificationService, CopyProductsService, NavigationService, CustomerGroupService, PublishStateService, CurrencyService, LocaleService, PaymentMethodService, CountryService, CountryUnpagedService, ShippingMethodService, ShippingMethodUnpagedService, ShopService, ImageService, ValidationService, ShippingLabelService, EconSettingService, DropShippingService, AttributeUnpagedService, CategoryService, LocalStorageService, MetadataUnpagedService, UnitService, OrganisationService, $window, SessionValuesService, BankAccountService, ProductUnpagedService, $rootScope, OrderManagementService, SystemMessageService, PRODUCT_FILTER_OPTIONS, SystemMessageUnpagedService) {

                angular.extend(this, $controller('EntityFormController', {
                    $scope: $scope
                }));

                $scope.$on('filtersChanges', function (e, filters, sorting, id) {
                    if ($scope.getService() != undefined) {
                        $scope.getService().setQuery({
                            filters: filters,
                            sorting: sorting
                        }).then(function () {
                            $scope.markSelectedLocales();
                        });
                    }

                    //for copyProducts
                    if (id.substr(0, 7) === "Product") {
                        var params = [];
                        for (var obj in filters) {
                            params.push({
                                name: 'filter[' + obj + ']',
                                value: filters[obj]
                            });
                        }
                        params.push({
                            name: 'filter[order]',
                            value: sorting
                        });
                        $scope.copyProductsEntityService.setAdditionalParams(params);

                        $scope.copyProductsFilterProducts(false);
                    }
                });

                $scope.getService = function () {
                    for (var i = 0; i < $scope.formTabs.length; i++) {
                        if ($scope.formTabs[i].active == true) {
                            return $scope.formTabs[i].service
                        }
                    }
                };

                $scope.OrderManagement = OrderManagementService;

                $scope.deliveryMethods = [{
                    name: "Land",
                    value: "DESTINATION"
                }, {
                    name: "Gewicht",
                    value: "SHOPPINGCARTWEIGHT"
                }, {
                    name: "Volumen",
                    value: "SHOPPINGCARTVOLUME"
                }, {
                    name: "Gewicht & Volumen",
                    value: "SHOPPINGCARTWEIGHTVOLUME"
                }];

                $scope.data = {};
                $scope.data.tmpweightCountries = [];
                $scope.data.tmpvolumeCountries = [];

                $scope.selectedCountries = [];
                $scope.selectedCurrencies = [];
                $scope.selectedLocales = [];
                $scope.wtf = {selectedLocales: []};
                $scope.dropShippings = [];
                $scope.selectedDropShippings = [];
                $scope.image = {};
                $scope.pdfHeaderImage = {};
                $scope.defaultProductImage = {};
                $scope.dropShippingListView = true;
                $scope.dropShippingModes = [{
                    id: "JUST_IN_TIME",
                    display: "Bestellungen sofort versenden"
                }, {id: "COLLECT_ORDER", display: "Bestellungen sammeln"}];
                $scope.shippingAddressInfos = [{value: "ALL", display: "Alle Lieferanten"}, {
                    value: "LAST_SUPPLIER",
                    display: "Letzter Lieferant"
                }];
                $scope.surchargeUnits = [{id: "PERCENT", display: "Prozentual"}, {
                    id: "AMOUNT_OF_MONEY",
                    display: "Betrag"
                }];
                $scope.dropShippingProductsQuery = {};
                $scope.newCurrencyBankAccountAssignment = {};

                $scope.dropShippingEntity = null;

                $scope.shippingLabelProvider = [{display: "DPD", constant: "DPD"}, {display: "DHL", constant: "DHL"}];
                $scope.labelSizeValues = [{display: "DIN A6", value: "A6"}, {display: "DIN A4", value: "A4"}];
                $scope.yesNoRadioValues = [{display: "Ja", value: true}, {display: "Nein", value: false}];
                $scope.commissioningValues = [{
                    display: "Shopinhaber",
                    value: "SHOP_OWNER"
                }, {display: "Letzter Lieferant", value: "LAST_ORGANISATION"}];
                $scope.shippingTypes = [{display: "Neutral", value: "NEUTRAL"}, {display: "Branded", value: "BRANDED"}];
                $scope.deliveryTime = [{display: "Classic", value: "Classic"}, {
                    display: "Express_830",
                    value: "Express_830"
                }, {display: "Express_10", value: "Express_10"}, {
                    display: "Express_12",
                    value: "Express_12"
                }, {display: "Express_18", value: "Express_18"}];
                $scope.dhlExpress = [{display: "None", value: "None"}, {
                    display: "Express_900",
                    value: "Express_900"
                }, {display: "Express_1000", value: "Express_1000"}];
                $scope.changeLabelProvider = function () {
                };

                $scope.userActivationRadioValues = [{value: true, display: "Manual"}, {
                    value: false,
                    display: "Automatisch"
                }];


                $scope.datas = {};
                $scope.productService = ProductService;
                $scope.productService.setCurrentPage(0).then(function () {
                    $scope.datas.dropShippingProducts = $scope.productService.getAll();
                });

                $scope.formTabs = [
                    {
                        label: 'Allgemein',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-main.html',
                        active: true
                    },/*
                     {
                     label: 'Kundengruppen',
                     template: BASE_TEMPLATES_PATH + 'settings/shop/form-customer-groups.html',
                     service: CustomerGroupService
                     },
                     {
                     label: 'Länder',
                     template: BASE_TEMPLATES_PATH + 'settings/shop/form-countries.html',
                     service: CountryService
                     },*/
                    {
                        label: 'Währungen',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-currencies.html',
                        service: CurrencyService
                    },
                    {
                        label: 'Locale',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-locales.html',
                        service: LocaleService
                    },
                    {
                        label: 'Bezahlmethoden',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-payment-methods.html'
                    },
                    {
                        label: 'Versandmethoden',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-shipping-methods.html',
                        service: ShippingMethodService
                    },
                    {
                        label: 'Bild',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-image.html'
                    },
                    {
                        label: 'Smtp Einstellungen',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-smtp-settings.html'
                    },
                    {
                        label: 'Slug Einstellungen',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-slug-settings.html'
                    },
                    {
                        label: 'B2B',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-b2b.html'
                    },
                    {
                        label: 'JanoLaw Einstellungen',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-janoLaw-settings.html'
                    },
                    {
                        label: 'Globale Einstellungen',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-global-settings.html'
                    },
                    {
                        label: 'Rechtstexte',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-legal-pages.html'
                    },
                    {
                        label: 'SEO ',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-seo-settings.html'
                    },
                    {
                        label: 'Sucheinstellungen ',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-search-settings.html'
                    },
                    {
                        label: 'Systemmeldungen ',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-system-messages.html'
                    },
                    {
                        label: 'Produkte kopieren',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-copy-products.html'
                    }
                ];

                $scope.selected = {};

                $scope.selected.systemMessageId = 0;
                $scope.selected.countriesCurrentPage = 0;
                $scope.selected.countriesPageSize = 5;
                $scope.selected.countriesTotalItems = 0;
                $scope.selected.currenciesCurrentPage = 0;
                $scope.selected.currenciesPageSize = 5;
                $scope.selected.currenciesTotalItems = 0;
                $scope.selected.localesCurrentPage = 0;
                $scope.selected.localesPageSize = 5;
                $scope.selected.localesTotalItems = 0;

                $scope.selected.copyProductsCurrentPage = 0;
                $scope.selected.copyProductsPageSize = 10;
                $scope.selected.copyProductsTotalItems = 0;

                $scope.selected.dropShippingsTotalItems = 10;
                $scope.selected.dropShippingsPageSize = 10;
                $scope.selected.dropShippingsCurrentPage = 0;

                OrganisationService.findAll().then(function () {
                    $scope.organisations = OrganisationService.getAll();
                });

                $scope.dropShippingProductsTableOptions = {};
                $scope.dropShippingProductsTableOptions.name = 'Produkte';
                $scope.dropShippingProductsTableOptions.fields = [{
                    //id: 'NAME',
                    heading: 'Name',
                    attribute: 'name'
                }, {
                    //id: 'SKU',
                    heading: 'Sku',
                    attribute: 'sku'
                }];

                $scope.addDropShipping = function () {
                    $scope.dropShippingListView = false;
                    $scope.dropShippingEntity = {};
                    $scope.dropShippingEntity.selectedProducts = [];
                    $scope.dropShippingEntity.organisationSequences = [{}];
                    $scope.dropShippingEntity.productIds = [];
                };

                $scope.deleteDropShippings = function () {
                    NotificationService.confirm({
                        statusText: 'Wollen sie dieses Streckengeschäft inklusive der Streckengeschäftsaufträge löschen?',
                        msg: ""
                    }).then(function () {
                        var dropShippingIds = [];
                        for (var i = 0; i < $scope.selectedDropShippings.length; i++) {
                            dropShippingIds.push($scope.selectedDropShippings[i].id);
                        }
                        DropShippingService.bulkDelete(dropShippingIds).then(function () {
                            DropShippingService.findAll().then(function () {
                                $scope.dropShippings = DropShippingService.getAll();
                            });
                        });
                    }, function () {
                    });
                };

                CountryService.setCurrentPage($scope.selected.countriesCurrentPage, false);
                CountryService.setPageSize($scope.selected.countriesPageSize, false);
                CurrencyService.setCurrentPage($scope.selected.currenciesCurrentPage, false);
                CurrencyService.setPageSize($scope.selected.currenciesPageSize, false);
                LocaleService.setCurrentPage($scope.selected.localesCurrentPage, false);
                LocaleService.setPageSize($scope.selected.localesPageSize, false);

                TaxService.findAll().then(function () {
                    $scope.taxes = TaxService.getAll(true);
                });

                //$scope.weightCountries = [];
                // $scope.volumeCountries = [];

                $scope.initExistingShop = function () {
                    $scope.formTabs.push({
                        label: 'Versandlabels',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-shipping-labels.html',
                    });

                    $scope.formTabs.push({
                        label: 'Streckengeschäft',
                        template: BASE_TEMPLATES_PATH + '/settings/shop/form-drop-shipping.html',
                        service: DropShippingService
                    });

                    $scope.OrderManagement = OrderManagementService;
                    OrderManagementService.loadSettings();
                };

                $scope.loadCustomerGroups = function () {
                    CustomerGroupService.findAll(true).then(function () {
                        $scope.customerGroups = CustomerGroupService.getAll(true);
                    });
                };

                $scope.init("Shop").then(function () {

                    if ($scope.newEntity) {
                        $scope.entity.isDefault = false;
                        $scope.entity.manualUserActivation = false;
                        $scope.entity.smallestPriceInCatalog = false;
                        $scope.entity.showPricesOnlyWhenLoggedIn = false;
                    }

                    if ($scope.entity.shopSeoSettings == undefined) {
                        $scope.entity.shopSeoSettings = {};
                    }

                    if ($scope.entity.shopConfiguration == undefined) {
                        $scope.entity.shopConfiguration = {};
                    }

                    $scope.entity.smtpSettings = $scope.entity.smtpSettings || {};
                    $scope.entity.smtpSettings.name = $scope.entity.smtpSettings.name || 'SERVICE_MAIL';

                    $scope.entity = ShopService.shippingMethods($scope.entity);
                    //$scope.weightCountries = $scope.entity.weightCountries ;
                    //$scope.volumeCountries = $scope.entity.volumeCountries ;

                    $scope.getUpdatedShippingMethods();

                    if (!$scope.newEntity) {
                        $scope.initExistingShop();
                    }

                    $scope.loadCustomerGroups();

                    CountryService.findAll().then(function () {
                        $scope.countries = CountryService.getAll(true);
                        $scope.selected.countriesTotalItems = CountryService.getTotalItems();
                        $scope.selected.countriesPageSize = CountryService.getPageSize();
                        $scope.markSelectedCountries();
                    });

                    CountryUnpagedService.findAll().then(function () {
                        $scope.allCountries = CountryUnpagedService.getAll(true).data;
                        $scope.selectCountries = angular.copy($scope.allCountries);
                    });

                    PublishStateService.findAll().then(function () {
                        $scope.publishStates = PublishStateService.getAll(true);
                    });

                    CurrencyService.findAll().then(function () {
                        $scope.currencies = CurrencyService.getAll(true);
                        $scope.selected.currenciesTotalItems = CurrencyService.getTotalItems();
                        $scope.selected.currenciesPageSize = CurrencyService.getPageSize();
                        $scope.markSelectedCurrencies();
                    });

                    BankAccountService.findAll().then(function () {
                        $scope.bankAccounts = BankAccountService.getAll();
                        for (var i = 0; i < $scope.bankAccounts.length; i++) {
                            var bankAccount = $scope.bankAccounts[i];
                            bankAccount.display = bankAccount.bankName + " " + bankAccount.bankIban;
                        }
                    });

                    DropShippingService.findAll().then(function () {
                        $scope.dropShippings = DropShippingService.getAll();
                    });

                    LocaleService.findAll().then(function () {
                        $scope.locales = LocaleService.getAll(true);
                        $scope.selected.localesTotalItems = LocaleService.getTotalItems();
                        $scope.selected.localesPageSize = LocaleService.getPageSize();
                        $scope.markSelectedLocales();
                    });

                    ShopService.findAll().then(function () {
                        $scope.shops = ShopService.getAll(true);
                    });


                    OrderStateUnpagedService.findAllOrderStates("ORDER").then(function () {
                        $scope.orderStates = OrderStateUnpagedService.getAllOrderStates().data;
                    });

                    if ($scope.entity.shopConfiguration.imageId != undefined && $scope.entity.shopConfiguration.imageId != null) {
                        ImageService.loadImage($scope.entity.shopConfiguration.imageId).then(function () {
                            $scope.image = ImageService.getImage();
                        });
                    }

                    if ($scope.entity.shopConfiguration.pdfHeaderImageId != undefined && $scope.entity.shopConfiguration.pdfHeaderImageId != null) {
                        ImageService.loadImage($scope.entity.shopConfiguration.pdfHeaderImageId).then(function () {
                            $scope.pdfHeaderImage = ImageService.getImage();
                        });
                    }

                    if ($scope.entity.shopConfiguration.defaultProductImageId != undefined && $scope.entity.shopConfiguration.defaultProductImageId != null) {
                        ImageService.loadImage($scope.entity.shopConfiguration.defaultProductImageId).then(function () {
                            $scope.defaultProductImage = ImageService.getImage();
                        });
                    }

                    if ($scope.entity.shopConfiguration.phones != undefined) {
                        for (var i = 0; i < $scope.entity.shopConfiguration.phones.length; i++) {
                            $scope.selectedPhones.push($scope.entity.shopConfiguration.phones[i]);
                        }
                    }

                    $scope.model = [];
                    $scope.modelSearch = [];

                    var usedMetas = {};
                    $scope.source = [];
                    $scope.sourceSearch = [];

                    if ($scope.entity.shopSearchSettings === undefined) {
                        $scope.entity.shopSearchSettings = {};
                    }
                    if (!$scope.entity.shopSearchSettings.title) {
                        $scope.sourceSearch.push({"id": 1, "value": "title"});
                    } else {
                        $scope.modelSearch.push({"id": 1, "value": "title"});
                    }
                    if (!$scope.entity.shopSearchSettings.shortDescription) {
                        $scope.sourceSearch.push({"id": 2, "value": "short description"});
                    } else {
                        $scope.modelSearch.push({"id": 2, "value": "short description"});
                    }
                    if (!$scope.entity.shopSearchSettings.longDescription) {
                        $scope.sourceSearch.push({"id": 3, "value": "long description"});
                    } else {
                        $scope.modelSearch.push({"id": 3, "value": "long description"});
                    }
                    if (!$scope.entity.shopSearchSettings.metadatas) {
                        $scope.sourceSearch.push({"id": 4, "value": "metadatas"});
                    } else {
                        $scope.modelSearch.push({"id": 4, "value": "metadatas"});
                    }


                    if ($scope.entity.shopSlugSettings === undefined) {
                        $scope.entity.shopSlugSettings = {};
                    }
                    if ($scope.entity.shopSlugSettings.slugSettingsFieldList === undefined) {
                        $scope.entity.shopSlugSettings.slugSettingsFieldList = [];
                    }

                    for (var i = 0; i < $scope.entity.shopSlugSettings.slugSettingsFieldList.length; i++) {
                        if ($scope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue == "Name") {
                            $scope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId = null;
                        }

                        if ($scope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue == "Untertitel") {
                            $scope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId = null;
                        }

                        if ($scope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue == "Sku") {
                            $scope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId = null;
                        }
                        if ($scope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue == "Hersteller") {
                            $scope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId = null;
                        }

                        $scope.model.push({
                            "id": $scope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId,
                            "value": $scope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue
                        });
                        usedMetas[$scope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId] = $scope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue;
                    }

                    MetadataUnpagedService.findAll().then(function () {
                        $scope.metadatas = MetadataUnpagedService.getAll(true).data;
                        for (var j = 0; j < $scope.metadatas.length; j++) {
                            if (!($scope.metadatas[j].id in usedMetas))
                                $scope.source.push({
                                    "id": $scope.metadatas[j].id,
                                    "value": $scope.metadatas[j].identifier
                                });
                        }
                    });

                    if (!$scope.checkSlugExist($scope.model, "Untertitel")) {
                        $scope.source.push({"id": null, "value": "Untertitel"});
                    }

                    if (!$scope.checkSlugExist($scope.model, "Hersteller")) {
                        $scope.source.push({"id": null, "value": "Hersteller"});
                    }

                    if (!$scope.checkSlugExist($scope.model, "Name")) {
                        $scope.source.push({"id": null, "value": "Name"});
                    }

                    if (!$scope.checkSlugExist($scope.model, "Sku")) {
                        $scope.source.push({"id": null, "value": "Sku"});
                    }

                    if ($scope.entity.isGross === undefined) {
                        $scope.entity.isGross = true;
                    }


                $scope.cookieArray = [...$scope.entity.shopScriptBlocks];
                
                $scope.cookieArray.map(x => x.active = false);

                $scope.cookieArray[0].active = true;
                });

                $scope.$watch("model", function (value) {
                }, true);
                // watch, use 'true' to also receive updates when values
                // change, instead of just the reference
                $scope.$watch("source", function (value) {

                }, true);

                $scope.productService.setCurrentPage(0).then(function () {
                    $scope.products = $scope.productService.getAll();
                });

                $scope.markSelectedCountries = function () {
                    for (var key in $scope.countries) {
                        if ($scope.entity.shopCountries != undefined) {
                            for (var i = 0; i < $scope.entity.shopCountries.length; i++) {
                                if ($scope.entity.shopCountries[i].countryId == $scope.countries[key].id) {
                                    $scope.countries[key].selected = true;
                                }
                            }
                        }
                    }
                };

                $scope.markSelectedCurrencies = function () {
                    for (var key in $scope.currencies) {
                        if ($scope.entity.currencyIds != undefined) {
                            for (var i = 0; i < $scope.entity.currencyIds.length; i++) {
                                if ($scope.entity.currencyIds[i] == $scope.currencies[key].id) {
                                    $scope.currencies[key].selected = true;
                                }
                            }
                        }
                    }
                };

                $scope.markSelectedLocales = function () {
                    $scope.selectedLocales = [];
                    for (var key in $scope.locales) {
                        if ($scope.entity.localeIds != undefined) {
                            for (var i = 0; i < $scope.entity.localeIds.length; i++) {
                                if ($scope.entity.localeIds[i] == $scope.locales[key].id) {
                                    $scope.locales[key].selected = true;
                                    $scope.selectedLocales.push($scope.locales[key]);
                                }
                            }
                        }
                    }
                };

                SystemMessageUnpagedService.findAll().then(function () {
                    $scope.systemMessages = SystemMessageUnpagedService.getAll(true).data;
                });

                $scope.addSystemMessage = function () {
                    if (!$scope.entity.systemMessageIds) {
                        $scope.entity.systemMessageIds = [];
                    }
                    if (!$scope.entity.systemMessageIds.includes($scope.selected.systemMessageId))
                        $scope.entity.systemMessageIds.push($scope.selected.systemMessageId);
                    else {
                        NotificationService.notifyGeneralError({statusText: 'System Message is already selected!'})
                    }
                };


                $scope.removeSystemMessages = function (index) {
                    $scope.entity.systemMessageIds.splice(index, 1);
                };


                $scope.addCurrency = function () {
                    if (!$scope.entity.shopCurrencyConfigs) {
                        $scope.entity.shopCurrencyConfigs = []
                    }
                    $scope.entity.shopCurrencyConfigs.push({});
                };

                $scope.addBankAccount = function (currencyConfig) {
                    if (!$scope.entity.shopCurrencyConfigs) {
                        $scope.entity.shopCurrencyConfigs = []
                    }
                    $scope.entity.shopCurrencyConfigs.push({currencyId: currencyConfig.currencyId});
                };

                $scope.removeCurrencyConfig = function (index) {
                    $scope.entity.shopCurrencyConfigs.splice(index, 1);
                };

                $scope.listState = 'root.shops';

                $scope.$on("beforeUpdate", function () {
                    $scope.save();
                });

                $scope.$on("beforeCreate", function () {
                    $scope.save();
                });

                $scope.$on("afterCreate", function () {
                    $scope.initExistingShop();
                    $scope.loadCustomerGroups();

                    $rootScope.$emit('shopsChanged');
                });

                $scope.save = function () {

                    OrderManagementService.saveSettings();

                    if (!$scope.newEntity) {
                        if (!$scope.saveDropShipping()) {
                            return;
                        }
                    }
                    $scope.setSelectedSlugs();
                    $scope.setSelectedSearchSettings();
                    $scope.setSelectedCountries();
                    $scope.setSelectedCurrencies();
                    $scope.setSelectedLocales();
                    $scope.setSelectedPhones();

                    if ($scope.entity.email) {
                        delete $scope.entity.email;
                    }
                    delete $scope.entity.smtpEmail;
                    for (var i = 0; i < $scope.entity.shopShippingMethods.length; i++) {
                        if ($scope.entity.shopShippingMethods[i].tax === undefined) {
                            $scope.entity.shopShippingMethods[i].tax = {};
                        }
                        $scope.entity.shopShippingMethods[i].tax.id = $scope.entity.shopShippingMethods[0].tax.id;

                        for (var j = 0; j < $scope.entity.shopShippingMethods[i].deliveryServices.length; j++) {
                            if ($scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod != undefined) {
                                if ($scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodType != undefined) {

                                    if ($scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodType == "DESTINATION") {

                                        $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodConfigurations = [];
                                        $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodConfigurations = $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.countryConfigurations;
                                        $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.countryConfigurations = [];
                                    }

                                    if ($scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodType == "SHOPPINGCARTWEIGHT") {
                                        var basicConfig = [];
                                        var count = 0;
                                        if ($scope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs) {
                                            for (var l = 0; l < $scope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs.length; l++) {
                                                for (var m = 0; m < $scope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs[l].countries.length; m++) {
                                                    for (var n = 0; n < $scope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs[l].configurations.length; n++) {
                                                        basicConfig[count] = angular.copy($scope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs[l].configurations[n]);
                                                        basicConfig[count].groupId = $scope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs[l].groupId;
                                                        basicConfig[count].valueId = n + 1;
                                                        basicConfig[count].country = $scope.getCountryById($scope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs[l].countries[m]);
                                                        if ($scope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs[l].configurations[n].weightUnit != undefined) {
                                                            basicConfig[count].weightUnit = $scope.getUnitById($scope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs[l].configurations[n].weightUnit.id);
                                                        }
                                                        count++;
                                                    }
                                                }
                                            }
                                        }
                                        $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodConfigurations = [];
                                        $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodConfigurations = basicConfig;
                                    }


                                    if ($scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodType == "SHOPPINGCARTVOLUME") {
                                        var basicConfig = [];
                                        var count = 0;
                                        if ($scope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs) {
                                            for (var l = 0; l < $scope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs.length; l++) {
                                                for (var m = 0; m < $scope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs[l].countries.length; m++) {
                                                    for (var n = 0; n < $scope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs[l].configurations.length; n++) {
                                                        basicConfig[count] = angular.copy($scope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs[l].configurations[n]);
                                                        basicConfig[count].groupId = $scope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs[l].groupId;
                                                        basicConfig[count].valueId = n + 1;
                                                        basicConfig[count].country = $scope.getCountryById($scope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs[l].countries[m]);
                                                        if ($scope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs[l].configurations[n].volumeUnit != undefined) {
                                                            basicConfig[count].volumeUnit = $scope.getUnitById($scope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs[l].configurations[n].volumeUnit.id);
                                                        }
                                                        count++;
                                                    }
                                                }
                                            }
                                        }
                                        $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodConfigurations = [];
                                        $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodConfigurations = basicConfig;
                                    }


                                    if ($scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodType == "SHOPPINGCARTWEIGHTVOLUME") {
                                        var basicConfig = [];
                                        var count = 0;
                                        if ($scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs) {
                                            for (var l = 0; l < $scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs.length; l++) {
                                                for (var m = 0; m < $scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs[l].countries.length; m++) {
                                                    for (var n = 0; n < $scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs[l].configurations.length; n++) {
                                                        basicConfig[count] = angular.copy($scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs[l].configurations[n]);
                                                        basicConfig[count].groupId = $scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs[l].groupId;
                                                        basicConfig[count].valueId = n + 1;
                                                        basicConfig[count].country = $scope.getCountryById($scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs[l].countries[m]);
                                                        if ($scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs[l].configurations[n].weightUnit != undefined) {
                                                            basicConfig[count].weightUnit = $scope.getUnitById($scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs[l].configurations[n].weightUnit.id);
                                                        }
                                                        if ($scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs[l].configurations[n].volumeUnit != undefined) {
                                                            basicConfig[count].volumeUnit = $scope.getUnitById($scope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs[l].configurations[n].volumeUnit.id);
                                                        }
                                                        count++;
                                                    }
                                                }
                                            }
                                        }
                                        $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodConfigurations = [];
                                        $scope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodConfigurations = basicConfig;
                                    }

                                }
                            }
                        }
                    }

                };

                $scope.countriesTableOptions = {};
                $scope.countriesTableOptions.fields = [
                    {
                        heading: 'Name',
                        attribute: 'name'
                    },
                    {
                        heading: 'ISO',
                        attribute: 'countryIso2'
                    }
                ];

                $scope.currenciesTableOptions = {};
                $scope.currenciesTableOptions.fields = [
                    {
                        heading: 'Name',
                        attribute: 'name'
                    },
                    {
                        heading: 'ISO Code',
                        attribute: 'isoCode'
                    },
                    {
                        heading: 'Unicode Symbol',
                        attribute: 'unicodeSymbol'
                    }
                ];

                $scope.dropShippingsTableOptions = {};
                $scope.dropShippingsTableOptions.fields = [
                    {
                        heading: 'Id',
                        attribute: 'id'
                    },
                    {
                        heading: 'Name',
                        attribute: 'name'
                    },
                    {
                        heading: 'Aktiv',
                        attribute: 'active'
                    }
                ];

                $scope.localesTableOptions = {};
                $scope.localesTableOptions.fields = [
                    {
                        heading: 'Sprache',
                        attribute: 'language'
                    },
                    {
                        heading: 'Kürzel',
                        attribute: 'locale'
                    }
                ];

                $scope.dropShippingOnItemClick = function (item, field) {
                    $scope.productService.findByIdsIn(item.productIds).then(function (products) {
                        $scope.dropShippingListView = false;
                        item.selectedProducts = products;
                        $scope.dropShippingEntity = item;
                    });
                };

                $scope.addOrganisationSequence = function () {
                    $scope.dropShippingEntity.organisationSequences.push({});
                };

                $scope.removeOrganisationSequence = function (index) {
                    $scope.dropShippingEntity.organisationSequences.splice(index, 1);
                };

                $scope.saveDropShipping = function () {

                    if ($scope.dropShippingEntity == null) {
                        return true;
                    }

                    var validateConfig = {
                        name: {
                            method: "isset",
                            fieldName: "Name"
                        },
                        organisationSequences: {
                            method: "isset",
                            fieldName: "Lieferantenabfolge"
                        },
//                                dropShippingMode: {
//                                    method: "isset",
//                                    fieldName: "Verfahrensart"
//                                },
                        processStartOrderStateId: {
                            method: "isset",
                            fieldName: "Prozessstart bei"
                        },
                        shippingSurcharge: {
                            method: "isset",
                            fieldName: "Versandkostenaufschlag"
                        },
                        surchargeUnit: {
                            method: "isset",
                            fieldName: "Versandkostenaufschlagseinheit"
                        },
//                                passTrackingNumber: {
//                                    method: "isset",
//                                    fieldName: "Weitergabe Trackingnummer"
//                                },
                        commissioning: {
                            method: "isset",
                            fieldName: "Kommisionierung"
                        },
                        shippingType: {
                            method: "isset",
                            fieldName: "Versandart"
                        }
                    };


                    if (!ValidationService.validate($scope.dropShippingEntity, validateConfig)) {
                        NotificationService.notifyValidationErrors(ValidationService.getErrors());
                        return false;
                    }

                    $scope.dropShippingListView = true;
                    if ($scope.dropShippingEntity.id) {
                        DropShippingService.update($scope.dropShippingEntity).then(function () {
                            DropShippingService.findAll().then(function () {
                                $scope.dropShippings = DropShippingService.getAll();
                            });
                        });
                    } else {
                        DropShippingService.create($scope.dropShippingEntity).then(function () {
                            DropShippingService.findAll().then(function () {
                                $scope.dropShippings = DropShippingService.getAll();
                            });
                        });
                    }
                    return true;
                };

                $scope.setSelectedCountries = function () {
                    for (var j = 0; j < $scope.countries.length; j++) {
                        if ($scope.countries[j].selected) {
                            var isAlreadyShopCountry = false;
                            for (var i = 0; i < $scope.entity.shopCountries.length; i++) {
                                if ($scope.entity.shopCountries[i].countryId == $scope.countries[j].id) {
                                    $scope.entity.shopCountries[i].isActive = true;
                                    isAlreadyShopCountry = true;
                                    break;
                                }
                            }
                            if (!isAlreadyShopCountry) {
                                $scope.countries[j].isActive = true;
                                $scope.countries[j].countryId = $scope.countries[j].id;
                                $scope.entity.shopCountries.push($scope.countries[j]);
                            }
                        } else {
                            for (var i = 0; $scope.entity.shopCountries && i < $scope.entity.shopCountries.length; i++) {
                                if ($scope.entity.shopCountries[i].countryId == $scope.countries[j].id) {
                                    $scope.entity.shopCountries.splice(i, 1);
                                }
                            }
                        }
                    }
                };


                $scope.setSelectedSlugs = function () {
                    if ($scope.entity.shopSlugSettings === undefined) {
                        $scope.entity.shopSlugSettings = {};
                    }
                    $scope.entity.shopSlugSettings.slugSettingsFieldList = [];

                    var count = 1;
                    $scope.model.forEach(function (model) {
                        $scope.entity.shopSlugSettings.slugSettingsFieldList.push({
                            "fieldValue": model.value,
                            "metaDataNameId": model.id,
                            "sortOrder": count
                        });
                        count++;
                    });
                };

                $scope.setSelectedSearchSettings = function () {
                    $scope.modelSearch.forEach(function (model) {
                        if (model.value === "title") {
                            $scope.entity.shopSearchSettings.title = true;
                        }
                        if (model.value === "short description") {
                            $scope.entity.shopSearchSettings.shortDescription = true;
                        }
                        if (model.value === "long description") {
                            $scope.entity.shopSearchSettings.longDescription = true;
                        }
                        if (model.value === "metadatas") {
                            $scope.entity.shopSearchSettings.metadatas = true;
                        }
                    });
                    $scope.sourceSearch.forEach(function (model) {
                        if (model.value === "title") {
                            $scope.entity.shopSearchSettings.title = false;
                        }
                        if (model.value === "short description") {
                            $scope.entity.shopSearchSettings.shortDescription = false;
                        }
                        if (model.value === "long description") {
                            $scope.entity.shopSearchSettings.longDescription = false;
                        }
                        if (model.value === "metadatas") {
                            $scope.entity.shopSearchSettings.metadatas = false;
                        }
                    });
                }


                $scope.checkSlugExist = function check(arr, name) {
                    var id = arr.length + 1;
                    var found = arr.some(function (el) {
                        return el.value === name;
                    });
                    return (found);
                };

                $scope.setSelectedCurrencies = function () {
                    $scope.entity.currencyIds = [];
                    $scope.selectedCurrencies.forEach(function (currency) {
                        $scope.entity.currencyIds.push(currency.id);
                    });
                };

                $scope.setSelectedLocales = function () {
                    $scope.entity.localeIds = [];
                    $scope.selectedLocales.forEach(function (locale) {
                        $scope.entity.localeIds.push(locale.id);
                    });
                };

                $scope.countriesSetPage = function () {
                    CountryService.setCurrentPage($scope.getRealCurrentPage($scope.selected.countriesCurrentPage));
                };

                $scope.currenciesSetPage = function () {
                    CurrencyService.setCurrentPage($scope.getRealCurrentPage($scope.selected.currenciesCurrentPage));
                };

                $scope.dropShippingsSetPage = function () {
                    DropShippingService.setCurrentPage($scope.getRealCurrentPage($scope.selected.dropShippingsCurrentPage));
                };

                $scope.localesSetPage = function () {
                    LocaleService.setCurrentPage($scope.getRealCurrentPage($scope.selected.localesCurrentPage));
                };

                $scope.getRealCurrentPage = function (currentPage) {
                    var cur = angular.copy(parseInt(currentPage));
                    cur = (cur === 0) ? cur : (cur - 1);
                    return cur;
                };

                $scope.setTableDataCountries = function () {
                    $scope.selected.countriesPageSize = CountryService.getPageSize();
                    $scope.selected.countriesTotalItems = CountryService.getTotalItems();

                    $scope.countriesTotalPages = CountryService.getTotalPages();

                    if ($scope.countriesTotalPages === 0) {
                        //If there are no items the total pages size should be displayed as 1
                        $scope.countriesTotalPages = 1;
                    }

                    $scope.selected.countriesCurrentPage = CountryService.getCurrentPage();
                    $scope.countries = CountryService.getAll();
                    $scope.$broadcast("tableDataRefreshed");
                };

                $scope.setTableDataCurrencies = function () {
                    $scope.selected.currenciesPageSize = CurrencyService.getPageSize();
                    $scope.selected.currenciesTotalItems = CurrencyService.getTotalItems();

                    $scope.currenciesTotalPages = CurrencyService.getTotalPages();

                    if ($scope.currenciesTotalPages === 0) {
                        //If there are no items the total pages size should be displayed as 1
                        $scope.currenciesTotalPages = 1;
                    }

                    $scope.selected.currenciesCurrentPage = CurrencyService.getCurrentPage();
                    $scope.currencies = CurrencyService.getAll();
                    $scope.$broadcast("tableDataRefreshed");
                };

                $scope.setTableDataLocales = function () {
                    $scope.selected.localesPageSize = LocaleService.getPageSize();
                    $scope.selected.localesTotalItems = LocaleService.getTotalItems();

                    $scope.localesTotalPages = LocaleService.getTotalPages();

                    if ($scope.localesTotalPages === 0) {
                        //If there are no items the total pages size should be displayed as 1
                        $scope.localesTotalPages = 1;
                    }

                    $scope.selected.localesCurrentPage = LocaleService.getCurrentPage();
                    $scope.locales = LocaleService.getAll();
                    $scope.$broadcast("tableDataRefreshed");
                };

                $scope.selectedPhones = [];

                $scope.addPhone = function () {
                    $scope.selectedPhones.push({});
                };

                $scope.removePhone = function (index) {
                    $scope.selectedPhones.splice(index, 1);
                };

                $scope.setSelectedPhones = function () {
                    $scope.entity.shopConfiguration.phones = $scope.selectedPhones;
                };

                $scope.onDrop = function ($event, $data, image) {
                    $scope.image = $data;
                    $scope.entity.shopConfiguration.imageId = $scope.image.id;
                };

                $scope.removeImage = function () {
                    $scope.image = undefined;
                    $scope.entity.shopConfiguration.imageId = null;
                };

                $scope.onDropPdfHeader = function ($event, $data, image) {
                    $scope.pdfHeaderImage = $data;
                    $scope.entity.shopConfiguration.pdfHeaderImageId = $scope.pdfHeaderImage.id;
                };

                $scope.removeImagePdfHeader = function () {
                    $scope.pdfHeaderImage = undefined;
                    $scope.entity.shopConfiguration.pdfHeaderImageId = null;
                };

                $scope.onDropDefaultProduct = function ($event, $data, image) {
                    $scope.defaultProductImage = $data;
                    $scope.entity.shopConfiguration.defaultProductImageId = $scope.defaultProductImage.id;
                };

                $scope.removeImageDefaultProduct = function () {
                    $scope.defaultProductImage = undefined;
                    $scope.entity.shopConfiguration.defaultProductImageId = null;
                };

                $scope.$on(CountryService.getType() + 'Changes', function (event, data) {
                    $scope.setTableDataCountries();
                });

                $scope.$on(CurrencyService.getType() + 'Changes', function (event, data) {
                    $scope.setTableDataCurrencies();
                });

                $scope.$on(DropShippingService.getType() + 'Changes', function (event, data) {
                    $scope.dropShippings = DropShippingService.getAll();
                });

                $scope.$on(LocaleService.getType() + 'Changes', function (event, data) {
                    $scope.setTableDataLocales();
                });

                $scope.systemMessageChanged = function () {
                    $scope.defaultValueChanged($scope.systemMessages, $scope.selectedCurrencies, $scope.entity.defaultCurrencyId);
                };

                $scope.defaultCurrencyChanged = function () {
                    $scope.defaultValueChanged($scope.currencies, $scope.selectedCurrencies, $scope.entity.defaultCurrencyId);
                };

                $scope.defaultLocaleChanged = function () {
                    $scope.defaultValueChanged($scope.locales, $scope.selectedLocales, $scope.entity.defaultLocaleId);
                };

                $scope.defaultValueChanged = function (allValuesArray, selectedValues, selectedId) {
                    allValuesArray.forEach(function (item) {
                        item.disabled = false;
                        if (item.id == selectedId) {
                            var containsItem = false;
                            selectedValues.forEach(function (selectedItem) {
                                if (selectedItem.id == item.id) {
                                    containsItem = true;
                                    item.disabled = true;
                                    item.selected = true;
                                }
                            });
                            if (!containsItem) {
                                selectedValues.push(item);
                            }
                        }
                    });
                };

                /*/-----SHIPPING METHODS-----/*/

                $scope.$on("afterUpdate", function () {
                    $scope.entity = ShopService.shippingMethods($scope.entity);
                    //$scope.weightCountries = $scope.entity.weightCountries ;
                    //$scope.volumeCountries = $scope.entity.volumeCountries ;

                });

                $scope.changeDeliveryMethod = function (deliveryService) {

                    NotificationService.notifyWarning({
                        statusText: 'If you change the shipping method, previous settings will be removed',
                        msg: ""
                    });
                    if (deliveryService.deliveryMethod.countryConfigurations == undefined) {
                        deliveryService.deliveryMethod.countryConfigurations = [];
                    }

                    if (deliveryService.deliveryMethod.deliveryMethodType != "DESTINATION") {
                        //  deliveryService.deliveryMethod.countryConfigurations = [];
                        deliveryService.deliveryMethod.deliveryMethodConfigurations = [];

                    }

                };

                $scope.goToPriceRules = function () {
                    NotificationService.confirm({
                        statusText: 'Haben sie die Änderungen im Shop gespeichert',
                        msg: ""
                    }).then(function () {
                        NavigationService.changeState('root.pricerules');
                    }, function () {
                    });
                };

                $scope.validate = function () {
                    $scope.entity.email = $scope.entity.shopConfiguration.email;
                    $scope.entity.companyName = $scope.entity.shopConfiguration.companyName;

                    if ($scope.entity.shopConfiguration.companyAddress) {
                        $scope.entity.street = $scope.entity.shopConfiguration.companyAddress.street;
                        $scope.entity.zipCode = $scope.entity.shopConfiguration.companyAddress.zipCode;
                        $scope.entity.city = $scope.entity.shopConfiguration.companyAddress.city;
                    }

                    if ($scope.entity.smtpSettings) {
                        $scope.entity.smptEmail = $scope.entity.smtpSettings.emailAddress;
                        $scope.entity.smptHost = $scope.entity.smtpSettings.smtpHost;
                        $scope.entity.smptUser = $scope.entity.smtpSettings.smtpUser;
                        $scope.entity.smtpPass = $scope.entity.smtpSettings.smtpPass;
                        $scope.entity.smtpPort = $scope.entity.smtpSettings.smtpPort;
                        $scope.entity.smtpReturnPath = $scope.entity.smtpSettings.smtpReturnPath;
                    }

                    if ($scope.entity.shopShippingMethods && $scope.entity.shopShippingMethods[0] && $scope.entity.shopShippingMethods[0].tax) {
                        $scope.entity.taxId = $scope.entity.shopShippingMethods[0].tax.id;
                    }

                    var validationResult = ValidationService.validate($scope.entity, {
                        name: {
                            method: "isset",
                            fieldName: "Name"
                        },
                        baseUrl: {
                            method: "isset",
                            fieldName: "Shop Url"
                        },
                        email: {
                            method: "isEmail",
                            fieldName: "E-Mail",
                            errorMsg: "Das Feld E-Mail im Tab Allgemein muss eine gültige E-Mail Adresse sein."
                        },
                        companyName: {
                            method: "isset",
                            fieldName: "Unternehmen"
                        },
                        defaultCurrencyId: {
                            method: "isset",
                            fieldName: "Standard Währung",
                            errorMsg: "Das Feld Standard Währung auf der Registerkarte Währungen darf nicht leer sein."
                        },
                        defaultLocaleId: {
                            method: "isset",
                            fieldName: "Standard Lokale",
                            errorMsg: "Das Feld Standard Lokale auf der Registerkarte Locale darf nicht leer sein."
                        },
                        localeIds: {
                            method: "isset",
                            fieldName: "Lokale",
                            errorMsg: "Wählen Sie mindestens ein Lokale in der Registerkarte Lokale.",
                            customValidate: function () {
                                return $scope.selectedLocales !== undefined && $scope.selectedLocales.length > 0;
                            }
                        },
                        street: {
                            method: "isset",
                            fieldName: "Straße"
                        },
                        zipCode: {
                            method: "isset",
                            fieldName: "Postleitzahl"
                        },
                        city: {
                            method: "isset",
                            fieldName: "Stadt"
                        },
                        taxId: {
                            method: "isset",
                            fieldName: "Steuersatz",
                            errorMsg: "Der Steuersatz für die Versandart darf nicht leer sein."
                        },
                    });

                    delete $scope.entity.taxId;

                    return validationResult;
                };

                $scope.setDefaultShippingMethod = function (shippingMethod) {
                    for (var i = 0; i < $scope.entity.shopShippingMethods.length; i++) {
                        if ($scope.entity.shopShippingMethods[i].id == shippingMethod.shopShippingMethod.id) {
                            $scope.entity.shopShippingMethods[i].isDefault = true;
                        } else {
                            $scope.entity.shopShippingMethods[i].isDefault = false;
                        }
                    }
                };

                $scope.setDefaultPaymentMethod = function (paymentMethod) {
                    for (var i = 0; i < $scope.entity.shopPaymentMethods.length; i++) {
                        if ($scope.entity.shopPaymentMethods[i].id == paymentMethod.paymentMethod.id) {
                            $scope.entity.shopPaymentMethods[i].isDefault = true;
                        } else {
                            $scope.entity.shopPaymentMethods[i].isDefault = false;
                        }
                    }
                };


                $scope.data = {
                    multipleSelect: []
                };

                $scope.getUnitById = function (id) {
                    for (var i = 0; i < $scope.units.length; i++) {
                        if ($scope.units[i].id == id) {
                            return $scope.units[i];
                        }
                    }
                };

                $scope.getShippingMethodById = function (id) {
                    for (var i = 0; i < $scope.shippingMethods.length; i++) {
                        if ($scope.shippingMethods[i].id == id) {
                            return $scope.shippingMethods[i];
                        }
                    }
                };

                $scope.getCountryById = function (id) {
                    for (var i = 0; i < $scope.allCountries.length; i++) {
                        if ($scope.allCountries[i].id == id) {
                            return $scope.allCountries[i];
                        }
                    }
                };

                /*--country--*/

                $scope.country = {};
                $scope.addCountry = function (configurations, countryId) {
                    if (countryId == undefined) {
                        return;
                    }
                    for (var i = 0; i < configurations.length; i++) {
                        if (configurations[i].country.id == countryId) {
                            return;
                        }
                    }
                    configurations.push({
                        value: 0,
                        surcharge: 0,
                        country: $scope.getCountryById(countryId)
                    })
                };

                $scope.deleteCountry = function (configurations, index) {
                    configurations.splice(index, 1);
                };


                UnitService.findAll().then(function () {
                    $scope.units = UnitService.getAll(true);
                    $scope.weightUnits = UnitService.getUnitsByType('WEIGHT');
                    $scope.volumeUnits = UnitService.getUnitsByType('VOLUME');

                });


                $scope.addWeightCountries = function (service) {

                    var alreadyPresent = [];
                    if (service.weightConfigs == undefined) {
                        service.weightConfigs = [];
                    }
                    for (var i = 0; i < $scope.data.tmpweightCountries.length; i++) {
                        for (var j = 0; j < service.weightConfigs.length; j++) {
                            for (var k = 0; k < service.weightConfigs[j].countries.length; k++) {
                                if (service.weightConfigs[j].countries[k] == $scope.data.tmpweightCountries[i]) {
                                    alreadyPresent.push(i);
                                }
                            }
                        }
                    }
                    if (alreadyPresent.length > 0) {
                        var i = alreadyPresent.length;
                        while (i--) {
                            $scope.data.tmpweightCountries.splice(alreadyPresent[i], 1);
                        }
                    }

                    if ($scope.data.tmpweightCountries.length > 0) {
                        var weightConf = {};
                        var countries = [];
                        var configs = [];

                        var groupId = 1;
                        if (service.weightConfigs.length > 0) {
                            groupId = Math.max.apply(Math, service.weightConfigs.map(function (o) {
                                return o.groupId;
                            }))
                        }
                        weightConf['groupId'] = groupId + 1;

                        countries = $scope.data.tmpweightCountries;
                        weightConf['countries'] = countries;
                        configs.push({
                            value: 0,
                            surcharge: 0,
                            weight: 0,
                            weightUnitId: 0
                        });

                        weightConf['configurations'] = configs;
                        service.weightConfigs.push(weightConf);
                    } else {
                        return;
                    }


                    //service.countries[service.weightCountries.length] = $scope.data.tmpweightCountries;
                    var i = Object.keys($scope.selectCountries).length;
                    while (i--) {
                        for (var m = 0; m < $scope.data.tmpweightCountries.length; m++) {
                            if ($scope.selectCountries[i].id == $scope.data.tmpweightCountries[m]) {
                                //delete $scope.selectCountries[i];
                            }
                        }
                    }

                    $scope.data.tmpweightCountries = [];

                };

                $scope.addWeight = function (config) {

                    config.push({
                        value: 0,
                        surcharge: 0,
                        weight: 0,
                        weightUnitId: 0
                    });

                };

                $scope.deleteWeightCountry = function (config, index, configs) {
                    config.configurations.splice(index, 1);
                    if (config.configurations.length == 0) {

                        var grpIndex = configs.map(function (o) {
                            return o.groupId;
                        }).indexOf(config.groupId);

                        configs.splice(grpIndex, 1);

                    }
                    // deliveryService.weightCountries.splice(index,1);
                };


                /* ----- Volume ----*/

                $scope.addVolumeCountries = function (service) {

                    var alreadyPresent = [];
                    if (service.volumeConfigs == undefined) {
                        service.volumeConfigs = [];
                    }
                    for (var i = 0; i < $scope.data.tmpvolumeCountries.length; i++) {
                        for (var j = 0; j < service.volumeConfigs.length; j++) {
                            for (var k = 0; k < service.volumeConfigs[j].countries.length; k++) {
                                if (service.volumeConfigs[j].countries[k] == $scope.data.tmpvolumeCountries[i]) {
                                    alreadyPresent.push(i);
                                }
                            }
                        }
                    }
                    if (alreadyPresent.length > 0) {
                        var i = alreadyPresent.length;
                        while (i--) {
                            $scope.data.tmpvolumeCountries.splice(alreadyPresent[i], 1);
                        }
                    }

                    if ($scope.data.tmpvolumeCountries.length > 0) {
                        var volumeConf = {};
                        var countries = [];
                        var configs = [];

                        var groupId = 1;
                        if (service.volumeConfigs.length > 0) {
                            groupId = Math.max.apply(Math, service.volumeConfigs.map(function (o) {
                                return o.groupId;
                            }))
                        }
                        volumeConf['groupId'] = groupId + 1;

                        countries = $scope.data.tmpvolumeCountries;
                        volumeConf['countries'] = countries;
                        configs.push({
                            value: 0,
                            surcharge: 0,
                            volume: 0,
                            width: 0,
                            height: 0,
                            depth: 0,
                            volumeUnitId: 0
                        });

                        volumeConf['configurations'] = configs;
                        service.volumeConfigs.push(volumeConf);

                    } else {
                        return;
                    }

                    var i = Object.keys($scope.selectCountries).length;
                    while (i--) {
                        for (var m = 0; m < $scope.data.tmpvolumeCountries.length; m++) {
                            if ($scope.selectCountries[i].id == $scope.data.tmpvolumeCountries[m]) {
                                //  delete $scope.selectCountries[i];
                            }
                        }
                    }


                    $scope.data.tmpvolumeCountries = [];


                };

                $scope.addvolume = function (configurations) {

                    configurations.push({
                        value: 0,
                        surcharge: 0,
                        volume: 0,
                        width: 0,
                        height: 0,
                        depth: 0,
                        volumeUnitId: 0
                    });
                };

                $scope.deleteVolumeCountry = function (config, index, configs) {
                    config.configurations.splice(index, 1);
                    if (config.configurations.length == 0) {

                        var grpIndex = configs.map(function (o) {
                            return o.groupId;
                        }).indexOf(config.groupId);

                        configs.splice(grpIndex, 1);

                    }
                    // deliveryService.weightCountries.splice(index,1);
                };


                /*--weight and volume--*/

                $scope.addWeightVolumeCountries = function (service) {
                    var alreadyPresent = [];
                    if (service.weightVolumeConfigs == undefined) {
                        service.weightVolumeConfigs = [];
                    }
                    for (var i = 0; i < $scope.data.tmpweightVolumeCountries.length; i++) {
                        for (var j = 0; j < service.weightVolumeConfigs.length; j++) {
                            for (var k = 0; k < service.weightVolumeConfigs[j].countries.length; k++) {
                                if (service.weightVolumeConfigs[j].countries[k] == $scope.data.tmpweightVolumeCountries[i]) {
                                    alreadyPresent.push(i);
                                }
                            }
                        }
                    }
                    if (alreadyPresent.length > 0) {
                        var i = alreadyPresent.length;
                        while (i--) {
                            $scope.data.tmpweightVolumeCountries.splice(alreadyPresent[i], 1);
                        }
                    }

                    if ($scope.data.tmpweightVolumeCountries.length > 0) {
                        var weightVolumeConf = {};
                        var countries = [];
                        var configs = [];

                        var groupId = 1;
                        if (service.weightVolumeConfigs.length > 0) {
                            groupId = Math.max.apply(Math, service.weightVolumeConfigs.map(function (o) {
                                return o.groupId;
                            }))
                        }
                        weightVolumeConf['groupId'] = groupId + 1;

                        countries = $scope.data.tmpweightVolumeCountries;
                        weightVolumeConf['countries'] = countries;
                        configs.push({
                            value: 0,
                            surcharge: 0,
                            weight: 0,
                            weightUnitId: 0,
                            volume: 0,
                            width: 0,
                            height: 0,
                            depth: 0,
                            volumeUnitId: 0
                        });

                        weightVolumeConf['configurations'] = configs;
                        service.weightVolumeConfigs.push(weightVolumeConf);
                    } else {
                        return;
                    }

                    var i = Object.keys($scope.selectCountries).length;
                    while (i--) {
                        for (var m = 0; m < $scope.data.tmpweightVolumeCountries.length; m++) {
                            if ($scope.selectCountries[i].id == $scope.data.tmpweightVolumeCountries[m]) {
                                //  delete $scope.selectCountries[i];
                            }
                        }
                    }

                    $scope.data.tmpweightVolumeCountries = [];

                };

                $scope.addWeightVolume = function (configurations) {

                    configurations.push({
                        value: 0,
                        surcharge: 0,
                        weight: 0,
                        weightUnitId: 0,
                        volume: 0,
                        width: 0,
                        height: 0,
                        depth: 0,
                        volumeUnitId: 0
                    });
                };

                $scope.deleteWeightVolumeCountry = function (config, index, configs) {
                    config.configurations.splice(index, 1);
                    if (config.configurations.length == 0) {

                        var grpIndex = configs.map(function (o) {
                            return o.groupId;
                        }).indexOf(config.groupId);

                        configs.splice(grpIndex, 1);

                    }
                    // deliveryService.weightCountries.splice(index,1);
                };


                /* SEO Settings */


                $scope.gtmShow = true;

                $scope.sitemapGeneraionInterval = [{
                    name: 'Daily',
                    value: 'DAILY'
                },
                    {
                        name: 'Weekly',
                        value: 'WEEKLY'
                    },
                    {
                        name: 'Monthly',
                        value: 'MONTHLY'
                    }];

                $scope.seoChangeState = function (seoState) {
                    $scope.gtmActive = false;
                    $scope.sitemapActive = false;
                    $scope.menuItem3Active = false;
                    $scope.coolkiesActive = false;
                    $scope.gtmShow = false;
                    $scope.sitemapShow = false;
                    $scope.cookiesShow = false;


                    if (seoState == "gtmTag") {
                        $scope.gtmShow = true;
                        $scope.gtmActive = "active";
                    }
                    if (seoState == "sitemap") {
                        $scope.sitemapShow = true;
                        $scope.sitemapActive = "active";
                    }
                    if(seoState == "cookies") {
                        $scope.cookiesShow = true;
                        $scope.cookiesActive = "active";
                    }
                };


                $scope.generateSiteMap = function () {
                    ShopService.generateSitemap().then(function () {
                        // notify
                    })
                };

                $scope.downloadSiteMap = function () {
                    $window.open("/api/v1/core/seo-settings/download-sitemap/?shopId=" + SessionValuesService.getShop());
                };


                /*-----SHIPPING METHODS-----*/


                /* ADD New Shipping MEthod*/

                $scope.showAddShipping = false;
                $scope.showCreateShopShipping = false;
                $scope.showAddShippingButton = true;
                $scope.data.selectedShippingMethodId = null;

                $scope.newShippingMethod = {
                    name: "",
                    description: ""
                }


                $scope.addShippingMethodToShop = function () {
                    $scope.showAddShipping = false;
                    $scope.showAddShippingButton = true;

                    $scope.selectedShippingMethod = $scope.getShippingMethodById($scope.data.selectedShippingMethodId);

                    $scope.entity.shopShippingMethods.push({
                        id: $scope.selectedShippingMethod.id,
                        name: $scope.selectedShippingMethod.name,
                        description: $scope.selectedShippingMethod.description,
                        deliveryServices: $scope.selectedShippingMethod.deliveryServices,
                        sortOrder: 99,
                        isActive: true,
                        isDefault: false
                    });

                    $scope.shippingMethodsDropDown = $scope.shippingMethodsDropDown.filter(function (obj) {
                        return obj.id !== $scope.selectedShippingMethod.id;
                    });

                }

                $scope.removeShopShippingMethod = function (index) {

                    NotificationService.confirm({
                        statusText: 'Are you sure want to delete shipping method from shop?',
                        msg: ""
                    }).then(function () {
                        $scope.shippingMethodsDropDown.push($scope.getShippingMethodById($scope.entity.shopShippingMethods[index].id));
                        $scope.entity.shopShippingMethods.splice(index, 1);
                    }, function () {
                    });


                }

                $scope.getUpdatedShippingMethods = function () {
                    ShippingMethodService.findAll().then(function () {
                        $scope.shippingMethods = ShippingMethodService.getAll(true);
                        $scope.shippingMethodsDropDown = ShippingMethodService.getAll(true);
                        for (var i = 0; $scope.entity.shopShippingMethods && i < $scope.entity.shopShippingMethods.length; i++) {
                            $scope.shippingMethodsDropDown = $scope.shippingMethodsDropDown.filter(function (obj) {
                                return obj.id !== $scope.entity.shopShippingMethods[i].id;
                            });
                        }

                    });
                };


                /*   $scope.showCreateShippingMethodForm = function () {
                 $scope.showCreateShopShippingShow = true;
                 }*/

                $scope.createShippingMethod = function () {

                    if ($scope.newShippingMethod === undefined || $scope.newShippingMethod.name == '') {
                        NotificationService.notifyGeneralError({statusText: 'Empty shipping method name'})
                    } else {
                        ShippingMethodService.create($scope.newShippingMethod).then(function () {
                            $scope.getUpdatedShippingMethods();
                            $scope.newShippingMethod = {
                                name: "",
                                description: ""
                            };
                            $scope.showCreateShopShipping = false;
                        });
                    }
                }

                /* End Add Shipping Method*/


                $scope.searchTerm;
                $scope.clearSearchTerm = function () {
                    $scope.searchTerm = '';
                };

                $scope.onSearchChange = function ($event) {
                    $event.stopPropagation();
                };

                $scope.createDropShipping = function () {
                    DropShippingService.create({
                        processStartOrderStateId: 8,
                        commissioning: true,
                        passTrackingNumber: true,
                        dropShippingMode: "JUST_IN_TIME",
                        shippingSurcharge: 10.0,
                        productIds: [136],
                        organisationSequences: [{organisationId: 5173, position: 1, localeId: 1}]
                    });
                };


                /* Shop Shipping rules */

                $scope.getUnitfactor = function (parentIndex, index) {
                    if ($scope.entity.shippingRules[parentIndex].ruleConditions[index].unitId != undefined) {
                        var unit = $scope.getUnitById($scope.entity.shippingRules[parentIndex].ruleConditions[index].unitId);
                        if (unit) {
                            $scope.entity.shippingRules[parentIndex].ruleConditions[index].factor = unit.factor;
                        }
                    }
                }

                $scope.addShippingRule = function () {
                    if ($scope.entity.shippingRules == undefined) {
                        $scope.entity.shippingRules = [];
                    }
                    $scope.entity.shippingRules.push(
                        {
                            ruleConditions: [{}],
                            ruleActions: [{}],
                            active: true,
                            conditionEvaluationType: "ALL_TRUE",
                            sortOrder: 1,
                        });

                };


                $scope.addShippingCondition = function (index) {
                    if ($scope.entity.shippingRules[index].ruleConditions == undefined) {
                        $scope.entity.shippingRules[index].ruleConditions = [];
                    }
                    $scope.entity.shippingRules[index].ruleConditions.push({});
                };

                $scope.removeShippingCondition = function (parentIndex, index) {
                    $scope.entity.shippingRules[parentIndex].ruleConditions.splice(index, 1);
                };

                $scope.removeShippingRule = function (index) {
                    $scope.entity.shippingRules.splice(index, 1);
                };


                $scope.conditionOperators = [{
                    name: '=',
                    value: 'EQUAL'
                },
                    {
                        name: '!=',
                        value: 'NOT_EQUAL'
                    },
                    {
                        name: '<',
                        value: 'LESS_THAN'
                    },
                    {
                        name: '>',
                        value: 'GREATER_THAN'
                    },
                    {
                        name: '<=',
                        value: 'LESS_THAN_OR_EQUAL'
                    },
                    {
                        name: '>=',
                        value: 'GREATER_THAN_OR_EQUAL'
                    }];


                $scope.ShippingConditionTypes = [{
                    name: 'Width Greater Than',
                    value: 'ShopShippingConditionWidthGreaterThan'
                },
                    {
                        name: 'Sepecific Products With Amount Condition',
                        value: 'ShopShippingConditionSpecificItemsAmountCheck'
                    }];

                $scope.ShippingActionTypes = [{
                    name: 'Select Shipping Method',
                    value: 'ShopShippingActionSelectSpecificShippingMethod'
                }];


                /* End Shop Shipping rules */


                /* Shop Global Settings */

                $scope.conditionTypes = [{
                    name: 'Immer',
                    value: 'GlobalSettingConditionAlways'
                },
                    {
                        name: 'Produkt hat kein Bild',
                        value: 'GlobalSettingConditionParentHasNoImage'
                    },
                    {
                        name: 'Produkt hat keinen Preis',
                        value: 'GlobalSettingConditionParentHasNoPrice'
                    }];

                $scope.actionTypes = [{
                    name: 'zufällige Variante',
                    value: 'GlobalSettingActionRandomVariant'
                },
                    {
                        name: 'günstigeste Variante',
                        value: 'GlobalSettingActionCheapestVariant'
                    }];


                /* End Shop Global Settings */


                $scope.tinymceOptions = {
                    theme: 'modern',
                    height: '500',
                    convert_urls: false,
                    relative_urls: false,
                    remove_script_host: false,
                    plugins: [
                        'link advlist lists image charmap print preview hr anchor pagebreak',
                        'searchreplace wordcount visualblocks visualchars code fullscreen',
                        'insertdatetime media nonbreaking save table contextmenu directionality',
                        'emoticons template paste textcolor colorpicker textpattern imagetools',
                        'fullpage moodiaimage shortcodes'
                    ],
                    menubar: "insert | tools",
                    toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
                    toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | fontselect | code | shortcodes',
                    image_advtab: true
                };

                //objects and methods for Copy Products (CopyProducts)

                $scope.copyProductsEntityName = "Product";
                $scope.copyProductsSingle = "Product";

                $scope.copyProductsTableOptions = {};

                $scope.copyProductsTableOptions.fields = [
                    {
                        id: 'SORT_ORDER',
                        heading: 'Sortierung',
                        attribute: 'sortOrder',
                        template: BASE_TEMPLATES_PATH + '/wawi/product/col/sort-order-col.html',
                        orderDisabled: true

                    },
                    {
                        id: 'THUMB_IMG',
                        heading: 'Bild',
                        template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-thumb-col.html',
                        searchDisabled: true,
                        orderDisabled: true
                    },
                    {
                        id: 'STATUS',
                        heading: 'Status',
                        attribute: 'publishState',
                        searchDisabled: true
                    },
                    {
                        id: 'PRODUCT_TYPE',
                        heading: 'Produktart',
                        attribute: 'productType',
                        searchDisabled: true,
                        orderDisabled: true
                    },
                    {
                        id: 'NAME',
                        heading: 'Name',
                        template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-name-col.html',
                        attribute: 'name'
                    },
                    {
                        id: 'SKU',
                        heading: 'SKU',
                        attribute: 'sku'
                    },
                    {
                        id: 'SLUG',
                        heading: 'Slug',
                        attribute: 'slug'
                    },
                    {
                        id: 'CATEGORY',
                        heading: 'Kategorien',
                        template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-category-col.html',
                        attribute: 'category'
                    },
                    {
                        id: 'ORIGIN',
                        heading: 'Ist der Ursprung',
                        attribute: 'origin'
                    },
                    {
                        id: 'COPY_MODE',
                        heading: 'Kopiermodus',
                        attribute: 'copyMode'
                    },
                    {
                        id: 'SYNC_FIELDS',
                        heading: 'Synchronisationsfelder',
                        template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-sync-field-col.html',
                        attribute: 'syncFields',
                        searchDisabled: true,
                        orderDisabled: true
                    }
                ];

                $scope.copyProductsTableOptions.optionalFields = [
                    {
                        id: 'DESCRIPTION',
                        heading: 'Langbeschreibung',
                        attribute: 'description',
                        searchDisabled: true,
                        orderDisabled: true
                    }
                ];

                $scope.copyProductsSelected = {
                    items: [],
                    allProducts: true
                };

                $scope.copyProductsEntityService = ProductService;

                $scope.copyProductsSetPageSize = function () {
                    $scope.copyProductsEntityService.setPageSize($scope.selected.copyProductsPageSize, false);
                    $scope.copyProductsFilterProducts(true);
                };

                $scope.copyProductsSetPage = function (currentPage) {
                    if (currentPage) {
                        $scope.selected.copyProductsCurrentPage = currentPage;
                    }
                    $scope.copyProductsEntityService.setCurrentPage($scope.copyProductsGetRealCurrentPage(), false);
                    $scope.copyProductsFilterProducts(false);
                };

                $scope.copyProductsAddFilter = function () {
                    if ($scope.copyProductsFilters === undefined) {
                        $scope.copyProductsFilters = [];
                    }
                    $scope.copyProductsFilters.push({});
                };

                $scope.copyProductsShowCurrentlyDisplayed = function () {
                    var from = parseInt($scope.selected.copyProductsPageSize) * parseInt($scope.copyProductsGetRealCurrentPage());
                    from = from + 1;

                    if ($scope.copyProductsItems === undefined || $scope.copyProductsItems.length === 0) {
                        from = 0;
                    }

                    var to = ((from + parseInt($scope.selected.copyProductsPageSize)) < $scope.selected.copyProductsTotalItems) ? (from + parseInt($scope.selected.copyProductsPageSize) - 1) : $scope.selected.copyProductsTotalItems;

                    return (from) + ' - ' + to;
                };

                $scope.copyProductsGetRealCurrentPage = function () {
                    var cur = angular.copy(parseInt($scope.selected.copyProductsCurrentPage));
                    cur = (cur === 0) ? cur : (cur - 1);
                    return cur;
                };

                $scope.copyProductsRemoveFilter = function (index) {
                    $scope.copyProductsFilters.splice(index, 1);
                };

                $scope.copyProductsFilterProducts = function (reset) {
                    if (reset) {
                        LocalStorageService.write('ProductCurrentPage', 0);
                    }
                    var filters = [];
                    for (var i = 0; i < $scope.copyProductsFilters.length; i++) {
                        if ($scope.copyProductsFilters[i].filterType != undefined && $scope.copyProductsFilters[i].filterType != "" &&
                            $scope.copyProductsFilters[i].filterCond != undefined && $scope.copyProductsFilters[i].filterCond != "" &&
                            $scope.copyProductsFilters[i].filterValue != undefined && $scope.copyProductsFilters[i].filterValue != "") {
                            filters.push($scope.copyProductsFilters[i]);
                        }
                    }

                    if (!$scope.copyProductsData.filterIsActive) {
                        filters = [];
                    }

                    $scope.copyProductsEntityService.filterForBulkUpdate(filters, $scope.copyProductsEntityService.getAdditionalParams(), true).then(function () {
                        $scope.copyProductsItems = $scope.copyProductsEntityService.getBulkUpdateProducts().data;

                        $scope.selected.copyProductsTotalItems = $scope.copyProductsEntityService.getBulkUpdateProducts().count;
                        $scope.selected.copyProductsTotalPages = $scope.copyProductsEntityService.getBulkUpdateProducts().pages;

                        if ($scope.selected.copyProductsTotalPages === 0) {
                            //If there are no items the total pages size should be displayed as 1
                            $scope.selected.copyProductsTotalPages = 1;
                        }

                        $scope.selected.copyProductsCurrentPage = $scope.copyProductsEntityService.getBulkUpdateProducts().page + 1;

                    });
                };

                $scope.copyProductsEntityService.setQuery({}, false);

                if ($scope.copyProductsEntityService.getPageSize() == null) {
                    $scope.selected.copyProductsPageSize = 10;
                    $scope.copyProductsSetPageSize();
                }

                $scope.copyModes = [
                    {"name": "Kopieren", "value": "COPIED"},
                    {"name": "Synchronisieren", "value": "SYNCHRONIZED"}
                ];

                $scope.initCopyProducts = function() {
                    $scope.copyProducts = {
                        mode: 'COPIED',
                        copyFields: {
                            copyPrices: true,
                            copyLocales: true,
                            copyImages: true,
                            copyStock: true,
                            copyCategories: true
                        }
                    };
                };

                $scope.initCopyProducts();

                $scope.copyProductsSubmit = function () {
                    var copyProductsDTO = {allProducts: $scope.copyProductsSelected.allProducts};

                    copyProductsDTO.productIds = [];
                    for (var i = 0; i < $scope.copyProductsSelected.items.length; i++) {
                        copyProductsDTO.productIds.push($scope.copyProductsSelected.items[i].id);
                    }
                    copyProductsDTO.shopId = $scope.entity.id;
                    copyProductsDTO.customerGroupId = $scope.copyProducts.customerGroupId;
                    if ($scope.copyProducts.copyCategories === true) {
                        copyProductsDTO.copyCategories = true;
                    }
                    copyProductsDTO.copyMode = $scope.copyProducts.mode;
                    if ($scope.copyProducts.mode === "SYNCHRONIZED") {
                        copyProductsDTO.syncFields = [];
                        if ($scope.copyProducts.syncPrices === true) {
                            copyProductsDTO.syncFields.push("PRICES");
                        }
                        if ($scope.copyProducts.syncLocales === true) {
                            copyProductsDTO.syncFields.push("LOCALES");
                        }
                        if ($scope.copyProducts.syncImages === true) {
                            copyProductsDTO.syncFields.push("IMAGES");
                        }
                        if ($scope.copyProducts.syncStock === true) {
                            copyProductsDTO.syncFields.push("STOCK");
                        }
                        if ($scope.copyProducts.syncCategories === true) {
                            copyProductsDTO.syncFields.push("CATEGORIES");
                        }
                    }

                    if ($scope.copyProducts.mode === "SYNCHRONIZED" || $scope.copyProducts.mode === "COPIED") {
                        copyProductsDTO.copyFields = [];
                        if ($scope.copyProducts.copyFields.copyPrices === true) {
                            copyProductsDTO.copyFields.push("PRICES");
                        }
                        if ($scope.copyProducts.copyFields.copyLocales === true) {
                            copyProductsDTO.copyFields.push("LOCALES");
                        }
                        if ($scope.copyProducts.copyFields.copyImages === true) {
                            copyProductsDTO.copyFields.push("IMAGES");
                        }
                        if ($scope.copyProducts.copyFields.copyStock === true) {
                            copyProductsDTO.copyFields.push("STOCK");
                        }
                        if ($scope.copyProducts.copyFields.copyCategories === true) {
                            copyProductsDTO.copyFields.push("CATEGORIES");
                        }
                    }
                    var validateConfig = {
                        copyMode: {
                            method: "isset",
                            fieldName: "Modus",
                        },
                        productIds: {
                            method: "isset",
                            fieldName: "Produkte",
                            errorMsg: "Wählen Sie mindestens ein Produkt.",
                            customValidate: function () {
                                return $scope.copyProductsSelected.items !== undefined && $scope.copyProductsSelected.items.length > 0 || $scope.copyProductsSelected.allProducts;
                            }
                        }
                    };

                    if (!ValidationService.validate(copyProductsDTO, validateConfig)) {
                        NotificationService.notifyValidationErrors(ValidationService.getErrors());
                        return;
                    }

                    CopyProductsService.copyProducts(copyProductsDTO).then(
                        function (response) {
                            if (response.error === undefined && response.errorCode === undefined && response.ok) {
                                NotificationService.notifySuccess({statusText: 'Produkte wurden zum Kopieren eingereicht'});
                                $scope.initCopyProducts();
                                for (var i = 0; i < $scope.copyProductsSelected.items.length; i++) {
                                    $scope.copyProductsSelected.items[i].selected = false;
                                }
                                $scope.copyProductsSelected.items = [];
                                $scope.copyProductsFilterProducts(false);
                            }
                        });
                };

                $scope.copyProductsCancelSync = function () {
                    var copyProductsDTO = {};

                    copyProductsDTO.productIds = [];
                    for (var i = 0; i < $scope.copyProductsSelected.items.length; i++) {
                        copyProductsDTO.productIds.push($scope.copyProductsSelected.items[i].id);
                    }

                    CopyProductsService.copyProductsCancelSync(copyProductsDTO).then(
                        function (response) {
                            if (response.error === undefined && response.errorCode === undefined && response.ok) {
                                NotificationService.notifySuccess({statusText: 'Die Synchronisation wurde abgebrochen.'});
                                $scope.initCopyProducts();
                                for (var i = 0; i < $scope.copyProductsSelected.items.length; i++) {
                                    $scope.copyProductsSelected.items[i].selected = false;
                                }
                                $scope.copyProductsSelected.items = [];
                                $scope.copyProductsFilterProducts(false);
                            }
                        });
                }

                $scope.copyProductsData = {filter: false, filterIsActive: true};

                $scope.copyProductsSearchTerm = "";

                $scope.copyProductsFilters = $scope.copyProductsEntityService.getFilters();

                $scope.copyProductsAvailableFilterOptions = PRODUCT_FILTER_OPTIONS;

                MetadataUnpagedService.findAll().then(function () {
                    $scope.copyProductsMetadatas = MetadataUnpagedService.getAll(true).data;
                });

                AttributeUnpagedService.findAll().then(function () {
                    $scope.copyProductsAttributes = AttributeUnpagedService.getAll(true).data;
                });

                CategoryService.findAll().then(function () {
                    $scope.copyProductsCategories = CategoryService.getAll().data;
                    $scope.copyProductsSuggestCategories = CategoryService.getAll().data;
                    $rootScope.$broadcast("$suggestSearchList", $scope.copyProductsSuggestCategories, "category");
                });

                $scope.copyProductsGetSelectOptions = function (type) {
                    for (var i = 0; i < $scope.copyProductsAvailableFilterOptions.length; i++) {
                        if ($scope.copyProductsAvailableFilterOptions[i].id === type) {
                            return $scope.copyProductsAvailableFilterOptions[i].types
                        }
                    }
                };

                $scope.copyProductsGetMetadataById = function (metadataNameId) {
                    if (metadataNameId != undefined) {
                        for (var i = 0; i < $scope.copyProductsMetadatas.length; i++) {
                            if (metadataNameId == $scope.copyProductsMetadatas[i].id) {
                                return $scope.copyProductsMetadatas[i];
                            }
                        }
                    }
                };

                $scope.copyProductsGetAttributeById = function (attributeNameId) {
                    if (attributeNameId != undefined) {
                        for (var i = 0; i < $scope.copyProductsAttributes.length; i++) {
                            if (attributeNameId == $scope.copyProductsAttributes[i].id) {
                                return $scope.copyProductsAttributes[i];
                            }
                        }
                    }
                };

                $scope.copyProductsActivateFilters = function () {
                    if ($scope.copyProductsData.filterIsActive) {
                        if ($scope.copyProductsFilters.length > 0) {
                            $scope.copyProductsFilterProducts(false, true);
                        }
                    }
                };

                $scope.copyProductsSetFilter = function () {
                    $scope.copyProductsData.filter = !$scope.copyProductsData.filter;
                };

                $scope.copyProductsOnSearchChange = function ($event) {
                    $event.stopPropagation();
                };

                $scope.$watch('copyProductsFilters', function (newVal, oldVal) {
                    if (newVal === undefined) {
                        return;
                    }

                    for (var i = 0; i < $scope.copyProductsFilters.length; i++) {
                        if (oldVal[i] != undefined) {
                            if (newVal[i].filterValue !== oldVal[i].filterValue) {
                                $scope.copyProductsFilters[i].filterOptions = [];
                            }
                        }
                    }

                    $scope.copyProductsEntityService.setFilters($scope.copyProductsFilters);
                }, true);

                //COOKIES
            
                $scope.cookieChangeState = (id) => {
                    for(let item of $scope.cookieArray) {
                        item.active = false;
                    }

                    $scope.cookieArray[id].active = true;
                }

                $scope.setScriptToEntity = () => {
                    $scope.cookieArray.map(x => {
                        $scope.entity.shopScriptBlocks.find(y => y.id == x.id).script = x.script;
                    })
                }

                //COOKIES



                $scope.copyProductsFilterProducts(true);

                //end of objects and methods for Copy Products (CopyProducts)
            }
        ]);
    }
);


