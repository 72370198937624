define('services/NavigationService',[
    'modules/module',
    'services/StateFaderService',
    'settings'
], function (module) {
    'use strict';
    return module.factory('NavigationService', [
        'StateFader',
        'MENU_LEFT',
        'MENU_RIGHT',
        NavigationService
    ]);
});

function NavigationService(StateFader, MENU_LEFT, MENU_RIGHT) {

    var _active = undefined,
        _activeSubitem = undefined,
        _leftMenu = MENU_LEFT,
        _rightMenu = MENU_RIGHT;

    var _setActiveItemByStateName = function (name) {

        for (var counter = 0; counter < _leftMenu.concat(_rightMenu).length; counter++) {
            var menuItem = _leftMenu.concat(_rightMenu)[counter];
            if (menuItem.subMenu) {
                for (var j = 0; j < menuItem.subMenu.length; j++) {
                    var subItem = menuItem.subMenu[j];
                    if (subItem.states.indexOf(name) != -1) {
                        _active = menuItem;
                        _activeSubitem = subItem;
                        subItem.active = true;
                        return;
                    }
                }
            }
        }
    };

    var _setActiveItem = function (item, subItem) {
        if (_active !== undefined) {
            _active.subMenu.forEach(function (subItem) {
                subItem.active = false;
            });
            _active.active = false;
        }
        _active = item;
        _active.active = true;
        if (subItem === undefined) {
            _active.subMenu[0].active = true;
            _activeSubitem = _active.subMenu[0];
            StateFader.go(_active.subMenu[0].states[0]);
        } else {
            _setActiveSubItem(subItem);
            _activeSubitem = subItem;
        }
    };

    var _setActiveSubItem = function (item) {
        _active.subMenu.forEach(function (subItem) {
            if (subItem.states.indexOf(item.states[0]) != -1) {
                subItem.active = true;
                StateFader.go(subItem.states[0]);
            }
        })
    };

    var _changeState = function (state, id) {

        var finished = false;
        var mergedMenu = _leftMenu.concat(_rightMenu);
        mergedMenu.forEach(function (menuItem) {
            if (menuItem.subMenu !== undefined && !finished) {
                for (var i = 0; i < menuItem.subMenu.length; i++) {
                    if (menuItem.subMenu[i].states.indexOf(state) != -1) {
                        _setActiveItem(menuItem, menuItem.subMenu[i]);
                        finished = true;
                    }
                }
            }
        });

        StateFader.go(state, id);

        return false;
    };

    var _changeStateWithParams = function (state, obj) {

        var finished = false;
        var mergedMenu = _leftMenu.concat(_rightMenu);
        mergedMenu.forEach(function (menuItem) {
            if (menuItem.subMenu !== undefined && !finished) {
                for (var i = 0; i < menuItem.subMenu.length; i++) {
                    if (menuItem.subMenu[i].states.indexOf(state) != -1) {
                        _setActiveItem(menuItem, menuItem.subMenu[i]);
                        finished = true;
                    }
                }
            }
        });
        StateFader.goWithObject(state, obj);

        return false;
    };

    return {
        getItems: function () {
            return _leftMenu;
        },
        getItemsRight: function () {
            return _rightMenu;
        },
        getActiveItem: function () {
            return _active;
        },
        getActiveSubitem: function () {
            return _activeSubitem;
        },
        setActiveItem: function (item) {
            _setActiveItem(item);
        },
        setActiveItemByStateName: function (name) {
            _setActiveItemByStateName(name);
        },
        setActiveSubItem: function (item) {
            _setActiveSubItem(item);
        },
        changeState: function (state, id) {
            _changeState(state, id);
        },
        changeStateWithObject: function (state, obj) {
            _changeStateWithParams(state, obj);
        },
        getSaveOrCreate: function () {
            if (window.location.href.slice(-1) == '/' && _active.label != 'System') {
                return 'erstellen'
            } else if (parseInt(window.location.href.slice(-1))>0 && _active.label != 'System'){
                return 'bearbeiten'
            } else {
                return;
            }
        }
    };
}
;
