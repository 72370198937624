/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/classification/ClassificationListController',[
        'modules/module',
        'settings',
        'modules/basemodule/controllers/EntityListController'
    ],
    function (module) {
        'use strict';
        module.controller('ClassificationListController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.tableOptions.fields = [
                    {
                        id: 'NAME',
                        heading:    'Name',
                        attribute:  'name'
                    }
                ];
                $scope.formState = 'root.classification';

                $scope.init('Classification');
            }
        ]);
    }
);


