define('modules/cms/controllers/GlossaryPageController',[
    'modules/module',
    'settings',
    'modules/basemodule/controllers/EntityListController',
    'services/GlossaryPageService',
    'services/NotificationService'

],
        function (module) {
            'use strict';
            module.controller('GlossaryPageController', [
                '$scope',
                '$controller',
                'GlossaryPageService',
                'NotificationService',
                'BASE_TEMPLATES_PATH',
                GlossaryPageController
            ]);
        }
);

function GlossaryPageController($scope, $controller, GlossaryPageService, NotificationService, BASE_TEMPLATES_PATH) {
    angular.extend(this, $controller('EntityListController', {
        $scope: $scope
    }));

    $scope.tableOptions.fields = [
        {
            heading: 'Bezeichnung',
            attribute: 'name'
        },
        {
            heading: 'URL',
            attribute: 'slug'
        }
    ];
    
    $scope.formState = 'root.glossaryPage';
    $scope.init('GlossaryPage');

    $scope.topBarTemplate = BASE_TEMPLATES_PATH + '/cms/partials/glossary-update-button.html';

    $scope.setShopGlossary = function () {
        NotificationService.confirm({
            statusText: 'Wollen sie das Glossar updaten?',
            msg: "Ein update des gesamten Glossars kann die Performance ihres Shops beeinträchtigen. Es wird jede Nacht ein automatisches Update durchgeführt."
        }).then(function () {
            GlossaryPageService.setShopGlossary().then(function (response) {
                if(response.error == undefined && response.errorCode == undefined && response.ok != false) {
                    NotificationService.notifySuccess({
                        statusText: 'Glossar update wurde gestartet. Der Update kann mehrere Minuten in Anspruch nehmen.',
                        msg: ""
                    });
                } else {
                    NotificationService.notifyGeneralError({
                        statusText: 'Glossar updatet fehlgeschlagen.',
                        msg: response.error
                    });
                }
            });

        }, function () {
        });

    };
};
