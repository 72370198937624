define('services/SessionValuesService',[
    'app',
    'modules/module',
    'services/LocalStorageService'
], function (app, module) {
    'use strict';
    return module.factory('SessionValuesService', ['LocalStorageService', 'localStorageService', function (LocalStorageService, localStorageService) {
            return {
                getSessionID: function () {
                    var sessionID = LocalStorageService.read("sessionID");
                    if (!sessionID) {
                        var s4 = function () {
                            return Math.floor((1 + Math.random()) * 0x10000)
                                .toString(16)
                                .substring(1);
                        };
                        sessionID = s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                            s4() + '-' + s4() + s4() + s4();
                        LocalStorageService.write("sessionID", sessionID);
                    }
                    return sessionID;
                },
                getToken: function () {
                    return LocalStorageService.read("_token");
                },
                getLocale: function () {
                    return LocalStorageService.read("_locale") != null ? LocalStorageService.read("_locale") : "";
                },
                getShop: function () {
                    return LocalStorageService.read("_shop") != null ? LocalStorageService.read("_shop") : "";
                },
                getFullSessionHeader: function (currencyIso) {
                    var tmpLocale = localStorageService.get("_locale");
                    var locale = (tmpLocale != null) ? tmpLocale : '';

                    var tmpShop = localStorageService.get("_shop");
                    var shop = (tmpShop != null) ? tmpShop : '';
                    var tmpToken = localStorageService.get("_token");
                    var token = (tmpToken != null) ? tmpToken : '';

                    var result = {
                        'Current-Locale': locale,
                        'Current-Shop': shop,
                        'client-session-id': this.getSessionID()
                    };

                    if (currencyIso) {
                        result["Current-Currency"] = currencyIso;
                    }

                    if(token.length !== 0 && token != null) {
                        result.Authorization = 'Bearer ' + token;
                    }
                    return result;
                }
            };
        }]);
});

