define('services/ShippingGlobalService',[
    'app',
    'modules/module'
], function (app, module) {
    'use strict';
    return module.factory('ShippingGlobalService', ['$q', '$rootScope', '$cookies', 'ShippingGlobal', 'ShippingCountries',
        function($q, $rootScope, $cookies, ShippingGlobal, ShippingCountries) {

            var _shippingGlobals = [],
                _shippingGlobal,
                _totalPages,
                _totalItems,
                _availableCountries;

            if($cookies.get('_shippingGlobalPageSize') === undefined) {
                $cookies.put('_shippingGlobalPageSize', 10);
            }

            if($cookies.get('_shippingGlobalCurrentPage') === undefined) {
                $cookies.put('_shippingGlobalCurrentPage', 0);
            }

            var _loadShippingGlobals = function () {
                var ref = $q.defer();
                ShippingGlobal.findAll({
                    page: $cookies.get('_shippingGlobalCurrentPage'),
                    size: $cookies.get('_shippingGlobalPageSize')
                }).$promise.then(function (response) {
                    _shippingGlobals = response.content;
                    $cookies.put('_shippingGlobalCurrentPage', response.actualPage);
                    _totalPages = response.totalPages;
                    _totalItems = response.totalElements;
                    ref.resolve();
                    $rootScope.$broadcast('shippingGlobalsChanges', _shippingGlobals);
                });
                return ref.promise;
            }

            return  {
                getCurrentPage: function () {
                    return (parseInt($cookies.get('_shippingGlobalCurrentPage')) + 1);
                },
                getTotalPages: function () {
                    return _totalPages;
                },
                getTotalItems: function () {
                    return _totalItems;
                },
                getPageSize: function () {
                    return $cookies.get('_shippingGlobalPageSize');
                },
                setPageSize: function (size) {
                    $cookies.put('_shippingGlobalPageSize', size);
                },
                setCurrentPage: function (page) {
                    $cookies.put('_shippingGlobalCurrentPage', page);
                },
                getShippingGlobals: function() {
                    return _shippingGlobals;
                },
                getShippingGlobal: function () {
                    return _shippingGlobal;
                },
                loadShippingGlobal: function(id) {
                    var ref = $q.defer();
                    ShippingGlobal.findOne({id: id}).$promise.then(function (response) {
                        _shippingGlobal = response;
                        ref.resolve();
                    });
                    return ref.promise;
                },
                saveShippingGlobal: function (shippingGlobal) {
                    var ref = $q.defer();
                    ShippingGlobal.save(shippingGlobal).$promise.then(function (response) {
                        ref.resolve(response);
                    });
                    return ref.promise;
                },
                deleteShippingGlobal: function (id) {
                    var ref = $q.defer();
                    ShippingGlobal.delete({id: id}).$promise.then(function (response) {
                        ref.resolve(response);
                    });
                    return ref.promise;
                },
                loadShippingGlobals: _loadShippingGlobals,
                loadAvailableCountries: function () {
                    var ref = $q.defer();
                    ShippingCountries.findAll().$promise.then(function(response) {
                        _availableCountries = response.content;
                        ref.resolve();
                    });
                    return ref.promise;
                },
                getAvailableCountries: function () {
                    return _availableCountries;
                }
            }

    }]);
});
