define('services/MetadataSetUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService',
], function (app, module) {
    'use strict';
    return module.factory('MetadataSetUnpagedService', [
        '$injector',
        MetadataSetUnpagedService
    ]);
});


function MetadataSetUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('MetaDataSet');

    angular.extend(this, crudInstance);

    return this;
};
