define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/news/news',[
        'app',
        'settings',
        'services/ContentBuilderHelper'
    ],
    function (app) {
        'use strict';
        app.directive('ngNews', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        inputData: "<data",
                        stateEditing: "<"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/sectionSnippet/directives/news/views/index.html',
                    controller: ['$scope', '$timeout', '$element', '$compile', 'ContentBuilderHelper', function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/news/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customStyleFake = path + "styleFake.css";
                        $scope.customJs = path + "js.html";
                        $scope.customJs2 = path + "js2.html";
                        $scope.templatePath = path + "template.html";
                        $scope.includeVisibility = true;

                        var wrapper = $($element).find('[data-content-block="news"]');

                        var template = '<div class="news__container" ng-include="templatePath"></div>';


                        function _init() {
                            $scope.data = angular.merge({}, $scope.inputData);
                            _refresh();
                        }

                        _init();

                        function _refresh() {
                            try {
                                wrapper.news('destroy');
                            } catch (e) { }
                            wrapper.find('.news__container').remove();
                            wrapper.append($compile(template)($scope));

                            $scope.includeVisibility = false;
                            $timeout(function () {
                                $scope.$apply();
                                $scope.includeVisibility = true;
                            }, 100);
                        }

                        $scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                        $scope.$watch('stateEditing', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                    }]
                };
            }
        ]);
    }
);

