define('modules/cms/controllers/CmsFormBaseController',[
        'modules/module',
        'settings',
        'services/TemplatePageService',
        'services/ContentPageService',
        'services/NavigationService',
        'services/CmsTagUnpagedService',
        'services/ContentBuilderHelper',
        'services/CmsTagService',
        'directives/formatDate/formatDate',
        'directives/contentConfigurator/_contentConfigurator/ContentConfigurator',
        'directives/controls/customInputTime/CustomInputTime'
    ],
    function (module) {
        'use strict';
        module.controller('CmsFormBaseController', [
            '$scope',
            '$rootScope',
            '$injector',
            '$q',
            '$controller',
            '$timeout',
            'PublishStateService',
            'CmsTagUnpagedService',
            'CmsTagService',
            'ContentBuilderHelper',
            'BASE_TEMPLATES_PATH',
            CmsFormBaseController
        ]);
    }
);

function CmsFormBaseController($scope, $rootScope, $injector, $q, $controller, $timeout, PublishStateService, CmsTagUnpagedService, CmsTagService, ContentBuilderHelper, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

    $scope.contentPage = { name: '', layout: {  }};

    $scope.$on("beforeUpdate", function () {
        $scope.prepareSave();
    });

    $scope.$on("beforeCreate", function () {
        $scope.prepareSave();
    });

    $scope.$on("afterUpdate", function () {
        ContentBuilderHelper.toggleEditGlobal(true);
    });

    $scope.$on("afterCreate", function () {
        ContentBuilderHelper.toggleEditGlobal(true);
    });

    $scope.prepareSave = function() {

    };

    $scope.prepareSaveDelayed = function () {
        return $q(
            function (resolve, reject) {
                ContentBuilderHelper.toggleEditGlobal(false, true);

                $timeout(function () {
                    ContentBuilderHelper.saveGlobal();
                    for (var i = 0; i < $scope.entity.locales.length; i++) {
                        var def = i;
                        if (!$scope.entity.locales[i].longDescription || !$scope.entity.locales[i].shortDescription) {
                            def = 0;
                        }
                        if (typeof $scope.entity.locales[def].longDescription.json === 'object') {
                            $scope.entity.locales[def].longDescription.json = angular.toJson($scope.entity.locales[def].longDescription.json);
                        }
                        if (typeof $scope.entity.locales[def].shortDescription.json === 'object') {
                            $scope.entity.locales[def].shortDescription.json = angular.toJson($scope.entity.locales[def].shortDescription.json);
                        }
                    }
                    resolve();

                }, 1000);
            });
    };


    $scope.prepareCreateDelayed = $scope.prepareSaveDelayed;

    $scope.onSuccessTemplateSelection = function (result) {
        $scope.entityService = $injector.get('EntityCrudService');
        $scope.entityService.setType($scope.entityType);
    };

    $scope.onCancelTemplateSelection = function () {
        $scope.entityService = $injector.get('EntityCrudService');
        $scope.entityService.setType($scope.entityType);
    };

    $scope.handler$closeModal = $rootScope.$on('removeModal', function() {
        $scope.entityService = $injector.get('EntityCrudService');
        $scope.entityService.setType($scope.entityType);
    });

    $scope.$on('destroy', function () {
        $scope.handler$closeModal();
    });

    /* ****************************** SELECT TEMPLATE DIALOG CONTROLLER *********************************** */
    $scope.templateSelectionModalController = ['$scope', 'dataToPass', '$mdDialog', function ($controllerScope, dataToPass, $mdDialog) {
        angular.extend(this, $controller('TemplatePageController', {
            $scope: $controllerScope
        }));

        $controllerScope.selectedItem = undefined;

        $controllerScope.update = function (item) {
            $controllerScope.selectedItem = item;
        };

        $controllerScope.data = dataToPass;

        $controllerScope.cancel = function() {
            $mdDialog.cancel();
        };

        $controllerScope.backToList = function() {
            $controllerScope.selectedItem = undefined;
        };

        $controllerScope.returnReplace = function (item) {
            $controllerScope.data.json = JSON.parse(item.layout.json);

            $controllerScope.data.stamp = -13;
            $mdDialog.hide();
        };

        $controllerScope.returnFront = function (item) {
            var temp = JSON.parse(item.layout.json);
            $controllerScope.data.json.root = temp.root.concat($controllerScope.data.json.root);

            $controllerScope.data.stamp = -13;
            $mdDialog.hide();
        };

        $controllerScope.returnBack = function (item) {
            var temp = JSON.parse(item.layout.json);
            $controllerScope.data.json.root = $controllerScope.data.json.root.concat(temp.root);

            $controllerScope.data.stamp = -13;
            $mdDialog.hide();
        };

    }];

    /* ****************************SAVE TEMPLATE DIALOG CONTROLLER *********************************** */
    $scope.templateSaveModalController = ['$scope', 'dataToPass', '$mdDialog', function ($controllerScope, dataToPass, $mdDialog) {
        $controllerScope.preventInit = true;

        angular.extend(this, $controller('EntityFormController', {
            $scope: $controllerScope
        }));

        $controllerScope.data = dataToPass;

        $controllerScope.cancel = function() {
            $mdDialog.cancel();
        };

        $controllerScope.$on("beforeCreate", function () {
            $controllerScope.beforeUpdateCreate();
        });

        $controllerScope.$on("beforeCreate", function () {
            $controllerScope.beforeUpdateCreate();
        });

        $controllerScope.$on("afterCreate", function () {
            $controllerScope.afterUpdateCreate();
        });

        $controllerScope.beforeUpdateCreate = function () {
        };

        $controllerScope.afterUpdateCreate = function () {
            $controllerScope.entity.layout.json = $controllerScope.swap;

            $mdDialog.hide();
        };

        $controllerScope.formTabs = [
            {
                label: 'Inhalt',
                template: BASE_TEMPLATES_PATH + '/cms/partials/_form-template-pages-content.html',
                active: true
            }
        ];

        $controllerScope.validationConfig = {
            name: {
                method: "isset",
                fieldName: "Name"
            }
        };

        $controllerScope.init("TemplatePage").then(function () {
            if ($controllerScope.newEntity) {
                $controllerScope.entity.layout = {};
                $controllerScope.entity.layout.json = angular.toJson($controllerScope.data.json);
                $controllerScope.entity.layout.html = $controllerScope.data.html;
                $controllerScope.entity.name = undefined;
            }

        });

    }];

}
;
