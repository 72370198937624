define('services/OrderStateUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService',
], function (app, module) {
    'use strict';
    return module.factory('OrderStateUnpagedService', [
        '$injector',
        OrderStateUnpagedService
    ]);
});


function OrderStateUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('OrderState');

    angular.extend(this, crudInstance);

    var _orderStates = [];

    var _orderFormScope;

    this.findAllOrderStates =  function(stateType) {
        return new $injector.get("ModelFactory")['OrderState']().findAll({stateType: stateType}).$promise.then(function(response) {
            _orderStates = response;
        });
    };

    this.getAllOrderStates = function () {
        return _orderStates;
    };

    return this;
};
