define('modules/cms/controllers/ContentPageFormController',[
        'modules/module',
        'settings',
        'services/TemplatePageService',
        'services/ContentPageService',
        'services/NavigationService',
        'services/CmsTagUnpagedService',
        'services/ContentBuilderHelper',
        'services/CmsTagService',
        'directives/formatDate/formatDate',
        'directives/contentConfigurator/_contentConfiguratorFullscreen/ContentConfiguratorFullscreen',
        'directives/contentConfigurator/_contentConfigurator/ContentConfigurator',
        'directives/controls/customInputTime/CustomInputTime'
    ],
    function (module) {
        'use strict';
        module.controller('ContentPageFormController', [
            '$scope',
            '$q',
            '$controller',
            '$timeout',
            'PublishStateService',
            'CmsTagUnpagedService',
            'CmsTagService',
            'ContentBuilderHelper',
            'BASE_TEMPLATES_PATH',
            ContentPageFormController
        ]);
    }
);

function ContentPageFormController($scope, $q, $controller, $timeout, PublishStateService, CmsTagUnpagedService, CmsTagService, ContentBuilderHelper, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('CmsFormBaseController', {
        $scope: $scope
    }));

    $scope.tag = {};

    $scope.formTabs = [
        {
            label: 'Allgemein',
            template: BASE_TEMPLATES_PATH + '/cms/partials/_form-content-pages.html',
            active: true
        },
        {
            label: 'Inhalt',
            template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-content.html'
        },
        {
            label: 'Tags',
            template: BASE_TEMPLATES_PATH + '/cms/partials/_form-content-pages-tags.html'
        },
        {
            label: 'SEO',
            template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-seo.html'
        }
    ];

    $scope.validationConfig = {
        publishStateId: {
            method: "isset",
            fieldName: "Satus"
        },
        name: {
            method: "isset",
            fieldName: "Name",
            isLocaleValue: true
        }
    };

    $scope.tinymceOptions = {
        theme: 'modern',
        height: '500',
        convert_urls: false,
        relative_urls: false,
        remove_script_host: false,
        plugins: [
            'link advlist lists image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template paste textcolor colorpicker textpattern imagetools',
            'fullpage moodiaimage shortcodes'
        ],
        menubar: "insert | tools",
        toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
        toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | fontselect | code | shortcodes',
        image_advtab: true
    };

    $scope.publishStates = [];
    $scope.tags = [];
    $scope.selectedItems = {};
    $scope.listState = 'root.contentPages';
    $scope.entityType = "ContentPage";

    $scope.init("ContentPage").then(function () {
        PublishStateService.findAll().then(function () {
            $scope.publishStates = PublishStateService.getAll();
        });
        CmsTagUnpagedService.findAll().then(function () {
            $scope.tags = CmsTagUnpagedService.getAll(true).data;
        });

        if ($scope.newEntity) {
            $scope.entity.tagIds = [];
        }

        $scope.options = [];
        var countLocales = 0;
        if ($scope.entity.locales != undefined) {
            for (var i = 0; i < $scope.entity.locales.length; i++) {
                if ($scope.entity.locales[i].seoInformation != null && $scope.entity.locales[i].seoInformation != undefined) {
                    if ($scope.entity.locales[i].seoInformation.keywords != undefined) {
                        if ($scope.entity.locales[i].seoInformation.keywords.length > countLocales) {
                            countLocales = $scope.entity.locales[i].seoInformation.keywords.length;
                        }
                    }
                }
            }
            for (var i = 0; i < countLocales; i++) {
                $scope.options.push({});
            }
        }
    });

    CmsTagUnpagedService.findAll().then(function () {
        $scope.tags = CmsTagUnpagedService.getAll(true).data;
    });
    $scope.tagsTableOptions = {};
    $scope.tagsTableOptions.name = 'Tags';
    $scope.tagsTableOptions.field = {
        id: 'NAME',
        heading: 'Name',
        attribute: 'name'
    };

    $scope.addKeyword = function () {
        $scope.options.push({});
    };

    $scope.removeKeyword = function (index) {
        $scope.options.splice(index, 1);
    };

    $scope.saveTag = function () {
        if($scope.tag.locales[0].name != undefined && $scope.tag.locales[0].name != "") {
            CmsTagService.create($scope.tag).then(function () {
                CmsTagUnpagedService.findAll().then(function () {
                    $scope.tags = CmsTagUnpagedService.getAll(true).data;
                });
                $scope.tag = {};
            });
        }
    }
}
;
