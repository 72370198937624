define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/form/form',[
        'app',
        'settings',
        'services/ContentBuilderHelper'
    ],
    function (app) {
        'use strict';
        app.directive('ngForm', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        inputData: "<data",
                        stateEditing: "<"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/sectionSnippet/directives/form/views/index.html',
                    controller: ['$scope', '$timeout', '$element', '$compile', 'ContentBuilderHelper', function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/form/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customStyleFake = path + "styleFake.css";
                        $scope.customJs = path + "js.html";
                        $scope.customJs2 = path + "js2.html";
                        $scope.includeVisibility = true;
                        var wrapper = $($element).find('[data-content-block="form"]');
                        var contentWrapper = wrapper.find('.form__content');
                        $scope.isAdminEnd = true;

                        function _init() {
                            $scope.data = angular.merge({}, $scope.inputData);
                            _refresh();
                        }
                        _init();

                        function _refresh() {
                            try {
                                wrapper.form('destroy');
                            } catch (e) { }
                            contentWrapper.find('.form__form__container').remove();
                            contentWrapper.append('<div class="form__form__container"></div>');

                            $scope.step = {
                                count: $scope.data.count,
                                first: $scope.data.items[0].model,
                                ids: []
                            };
                            // fill step ids using numbers from 1...count
                            for (var i = 0; i < $scope.step.count; ++i) {
                                $scope.step.ids.push(i + 1);
                            }
                            // wrapper.append($compile(template)($scope));
                            $scope.includeVisibility = false;
                            $timeout(function () {
                                $scope.$apply();
                                $scope.includeVisibility = true;
                            }, 100);
                        }

                        $scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                        $scope.$watch('stateEditing', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                    }]
                };
            }
        ]);
    }
);

