define('services/SmtpSettingService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('SmtpSettingService', [
        '$injector',
        SmtpSettingService
    ]);
});


function SmtpSettingService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('SmtpSetting');

    angular.extend(this, crudInstance);

    return this;
};
