define(
    'directives/controls/customButton/CustomButton',[
        'app',
        'settings'
    ],
    function (app) {
        'use strict';
        app.directive('ngCustomButton', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        iconClass: "@",
                        display: "@",
                        onClick: "&",
                        class: "@",
                        label: "@",
                        disable: "="
                    },
                    link: function (scope, iElement, iAttrs) {
                        scope.btnClicked = function (arg) {
                            scope.onClick(arg);
                        };
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + "/controls/customButton/views/CustomButton.html",
                    controller: ['$scope', function ($scope) {}]
                };
            }
        ]);
    });
