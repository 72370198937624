define('modules/orderManagement/controllers/OrderManagementSelectProductController',[
    'modules/module',
    'modules/basemodule/controllers/EntityListController',
    'modules/wawi/product/controllers/ProductController',
    'services/OrderStateUnpagedService'
	], 
	function (module) {
        'use strict';
		module.controller('OrderManagementSelectProductController', [
            '$scope',
            '$controller',
            'OrderStateUnpagedService',
            'BASE_TEMPLATES_PATH',
            OrderManagementSelectProductController
        ]);
    }
);


function OrderManagementSelectProductController ($scope, $controller, OrderStateUnpagedService, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('ProductController', {
        $scope: $scope
    }));

    $scope.tableOptions.fields = [
        {
            id: 'THUMB_IMG',
            heading: 'Bild',
            template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-thumb-col.html',
            searchDisabled: true,
            orderDisabled: true
        },
        {
            id: 'STATUS',
            heading: 'Status',
            attribute: 'publishState',
            searchDisabled: true
        },
        {
            id: 'PRODUCT_TYPE',
            heading: 'Produktart',
            attribute: 'productType',
            searchDisabled: true,
            orderDisabled: true
        },
        {
            id: 'NAME',
            heading: 'Name',
            template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-name-col.html',
            attribute: 'name'
        },
        {
            id: 'SKU',
            heading: 'SKU',
            attribute: 'sku'
        },
        {
            id: 'SLUG',
            heading: 'Slug',
            attribute: 'slug'
        },
        {
            id: 'CATEGORY',
            heading: 'Kategorien',
            template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-category-col.html',
            attribute: 'category',
            searchDisabled: true,
            orderDisabled: true
        }
    ];

    $scope.tableOptions.optionalFields = [];

	$scope.formState = "root.product";

    $scope.wrapIntoOrder = function(product) {
        return {
            editable: true,
            amount: 1,
            displayedAmount: 1,
            orderPrintAreas: [],
            originPrice: product.sellingPrice,
            originTotalPrice: product.sellingPrice,
            price: product.basePrice,
            product: product,
            taxValue: product.tax !== undefined ? product.tax.value : 0,
            productDiscount: 0
        };
    };

    $scope.init('Product').then(function(){
	});
};
