define(
    'directives/contentConfigurator/contentSnippets/tinySnippet/compile',[
        'app',
        'angular'
    ],
    function (app) {
      'use strict';
      app.directive('compile', ['$sce', '$compile', function ($sce, $compile) {
        return function (scope, element, attrs) {
          scope.$watch(
            function (scope) {
              // watch the 'compile' expression for changes
              return $sce.getTrustedHtml(scope.$eval(attrs.compile));
            },
            function (value) {
              // when the 'compile' expression changes
              // assign it into the current DOM
              element.html(value);

              // compile the new DOM and link it to the current
              // scope.
              // NOTE: we only compile .childNodes so that
              // we don't get into infinite loop compiling ourselves
              $compile(element.contents())(scope);
            }
          );
        };
      }]);
    }
);

