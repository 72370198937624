define('services/CmsTagService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('CmsTagService', [
        '$injector',
        CmsTagService
    ]);
});


function CmsTagService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('CmsTag');

    angular.extend(this, crudInstance);

    return this;
};
