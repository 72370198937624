define('modules/econ/controllers/EconMailtemplateFormController',[
    'modules/module',
    'settings',
    'services/EconMailtemplateService',
    'services/BeePluginService',
    'services/NavigationService',
    'bee_plugin_blop',
    'bee_plugin_filesaver',
    'bee_plugin',
    'jquery',
    'directives/beeWrapper/beeWrapper'

],
    function (module) {
        'use strict';
        module.controller('EconMailtemplateFormController', [
            '$scope',
            'NavigationService',
            '$controller',
            'EconMailtemplateService',
            'BeePluginService',
            '$timeout',
            '$compile',
            'BASE_TEMPLATES_PATH',
            '$rootScope',
            EconMailtemplateFormController
        ]);
    }
);

function EconMailtemplateFormController($scope, NavigationService, $controller, EconMailtemplateService, BeePluginService, $timeout, $compile, BASE_TEMPLATES_PATH, $rootScope) {
    $scope.initFinished = false;
    $scope.previewEmail = [];
    $scope.emailArray = [];

    $scope.formTabs = [
        {
            label: 'Allgemein',
            template: BASE_TEMPLATES_PATH + '/econ/partials/_beePlugin.html',
            active: true
        }
    ];

    $scope.validationConfig = {
        author: {
            method: "isset",
            fieldName: "Autor"
        },
        name: {
            method: "isset",
            fieldName: "Bezeichnung"
        },
        sender: {
            method: "isset",
            fieldName: "Absender E-Mail"
        },
        subject: {
            method: "isset",
            fieldName: "Betreff"
        }
    };

    $scope.hideSaveButtons = true;
    $scope.topBarTemplateLeft = BASE_TEMPLATES_PATH + '/beeWrapper/bee-form.html';

    $scope.listState = 'root.econMailtemplates';

    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

    $scope.duplicate = function () {
        EconMailtemplateService.duplicate($scope.entity.id).then(function (response) {
            NavigationService.changeState("root.econMailtemplate", response.id);
        })
    };

    $scope.init("Mailtemplate").then(function () {
        var emptyTemplate = function () {
            $scope.entity.jsonContent = JSON.stringify(EconMailtemplateService.getEmptyTemplate());
        };
        $scope.initFinished = true;
        if ($scope.entity.jsonContent == "" || $scope.entity.jsonContent == undefined) {
            $timeout(emptyTemplate, 0);
        }

        if(!$scope.newEntity){
            $scope.topBarTemplate = BASE_TEMPLATES_PATH + '/econ/partials/duplicate-button.html';
        }
    });

    $scope.beeSave = function(changeState) {
        $scope.$broadcast('beeSave', [changeState]);
    };

    $scope.beeCreate = function(changeState) {
        $scope.$broadcast('beeCreate', [changeState]);
    };

    $scope.$on('saveBeeToDatabase', function(event, args) {
        $scope.update(args[0]);
    });

    $scope.$on('createBeeCallback', function(event, args) {
        $scope.create(args[0]);
    });

    $scope.$on('autosaveBee', function(event, args) {
        if($scope.newEntity)
            $scope.create(args[0], true);
        else {
            $scope.update(args[0], true);
        }
    });


    $scope.tinymceOptions = {
        theme: 'modern',
        height: '500',
        convert_urls: false,
        relative_urls: false,
        remove_script_host: false,
        plugins: [
            'link advlist lists image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template paste textcolor colorpicker textpattern imagetools',
            'fullpage moodiaimage ecshortcodes'
        ],
        menubar: "insert | tools",
        link_list: [
            {title: 'Abmelden', value: '[UNSUBSCRIBE_URL]'},
            {title: 'Opt In', value: '[OPT_IN_URL]'}
        ],
        toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
        toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | ecshortcodes | code',
        image_advtab: true,
        cleanup : false,
        verify_html : false
    };

    $scope.sendPreview = function () {
        if ($scope.previewEmail.length < 1) {
            return;
        }else{
            for(i = 0; i < $scope.previewEmail.length; i++){
                $scope.emailArray.push($scope.previewEmail[i].text);
            }

            EconMailtemplateService.sendPreview($scope.entity.id, $scope.emailArray).then(function (data) {
                if (data.success == true) {
                    alert("E-Mail's erfolgreich versandt!");
                }
             });

            $scope.emailArray =  [];
        }

    };
}
;
