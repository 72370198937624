define(
	'directives/imageUpload/imageUpload',[
		'app',
        'settings',
        'services/ImageService',
        'services/SessionValuesService'
   	], 
   	function (app) {
		'use strict';
		app.directive('ngImageUpload', ['BASE_DIRECTIVES_PATH',
    		function(BASE_DIRECTIVES_PATH) {
        		return{
                    scope: {
						mode: "=?"
					},
	        		templateUrl: BASE_DIRECTIVES_PATH + "/imageUpload/views/imageupload.html",
	        		controller: ['$scope', '$rootScope', '$timeout', 'ImageService', 'FileUploader', 'SessionValuesService',
                        function($scope, $rootScope, $timeout, ImageService, FileUploader, SessionValuesService) {
                        
                        $scope.uploader = ImageService.initFileUpload(new FileUploader({
                            url: '/api/v1/media/images',
                            headers: SessionValuesService.getFullSessionHeader()
                        }));

                        $scope.uploadImage = function(item) {
                        	console.log("item");
                        	console.log(item);
                        	if(item.formData === undefined) {
                        	    item.formData = [];
                            }
                            if(item.formData[0] === undefined) {
                                item.formData.push({});
                            }
                            if($scope.mode == 'customer'){
                                item.formData[0].type = "CUSTOMER_IMAGE";
                            } else {
                                item.formData[0].type = "SHOP_IMAGE";
                            }
                            console.log(item);
                        	ImageService.uploadImage(item);
                        };

                        $scope.uploadViaInput = function () {
							//why timeout?
//                            $timeout(function () {
                                angular.element('#file-input').trigger('click');    
//                            }, 100);
                        };

                        ImageService.loadDirectories(0, 1).then(function (response) {
                            $scope.directories = response.children;
                        });

                        $scope.fileTypes = [{
                        	id: "SHOP_IMAGE",
							name: "Shopbild"
						},{
                            id: "CUSTOMER_IMAGE",
                            name: "Kundenbild"
                        },{
                            id: "CUSTOMER_DOCUMENT",
                            name: "Kundendokument"
                        }];

                        $scope.uploadAllImages = function (items) {
                        	for(var i=0; i < items.length; i++) {
                        	    if(items[i].formData[0] == undefined) {
                                    items[i].formData[0] = {}
                                }
                                if($scope.mode == 'customer'){
                                    items[i].formData[0].type = "CUSTOMER_IMAGE";
                                } else {
                                    items[i].formData[0].type = "SHOP_IMAGE";
                                }
							}
                            ImageService.uploadMultipleImages(items);
                        };

						$scope.getView = function () {
							if($scope.mode == 'customer'){
								return BASE_DIRECTIVES_PATH + '/imageUpload/views/imageupload-customer.html'
							} else {
								return BASE_DIRECTIVES_PATH + '/imageUpload/views/imageupload-standard.html'
							}
						}

	        		}]
	            };
    		}	
        ]);					
	}
);

