define('services/MetadataUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService',
], function (app, module) {
    'use strict';
    return module.factory('MetadataUnpagedService', [
        '$injector',
        MetadataUnpagedService
    ]);
});


function MetadataUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('MetaData');

    angular.extend(this, crudInstance);

    return this;
};
