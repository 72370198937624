/**
 * Created by Nico on 04.08.2016.
 */
define('modules/crm/organisation/controllers/OrganisationListController',[
        'modules/module',
        'modules/basemodule/controllers/EntityListController'
    ], function (module) {
        'use strict';
        module.controller('OrganisationListController', ['$controller', '$scope', 'BASE_TEMPLATES_PATH',
            function ($controller, $scope, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.tableOptions.fields = [
                    {
                        id: 'NAME',
                        heading: 'Name',
                        attribute: 'companyName'
                    },
                    {
                        id: "EMAIL",
                        heading: 'E-Mail',
                        attribute: 'email'
                    },
                    {
                        id: "CUSTOMERSOURCE",
                        heading: 'Kontaktquelle',
                        template:  BASE_TEMPLATES_PATH + '/crm/customer/customer-source-col.html',
                        searchDisabled: true,
                        orderDisabled: true,
                        attribute: ''
                    },
                    {
                        id: "CONTACT",
                        heading: 'Kontakt',
                        template:  BASE_TEMPLATES_PATH + '/crm/customer/customer-contact-col.html',
                        searchDisabled: true,
                        orderDisabled: true,
                        attribute: ''
                    }
                ];

                $scope.formState = 'root.organisation';

                $scope.init("Organisation");


            }]);
    }
);
