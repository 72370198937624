define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/infoCarousel/infoCarousel',[
        'app',
        'settings',
        'services/ContentBuilderHelper'
    ],
    function (app) {
        'use strict';
        app.directive('ngInfoCarousel', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        inputData: "<data",
                        stateEditing: "<"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/sectionSnippet/directives/infoCarousel/views/index.html',
                    controller: ['$scope', '$timeout', '$element', '$compile', 'ContentBuilderHelper', function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/infoCarousel/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customStyleFake = path + "styleFake.css";
                        $scope.customJs = path + "js.html";
                        $scope.customJs2 = path + "js2.html";
                        $scope.includeVisibility = true;

                        var wrapper = $($element).find('[data-content-block="info-carousel"]');
                        var template = '<div class="info-carousel-container"><div class="info-carousel-info"><div class="line"><span class="info-carousel-header">{{data.items[0].header}}</span></div><h3>{{data.items[0].title}}</h3><div class="info-carousel-text" ng-bind-html="data.items[0].text"></div></div><div class="info-carousel-content flexslider carousel"><ul class="slides"><li ng-class="{selected: $index === 0}" ng-repeat="item in data.items" data-index="{{$index}}" data-header="{{item.header}}" data-title="{{item.title}}" data-text="{{item.text}}"><div class="slide-content"><img class="slider-image" ng-src="{{item.thumb}}" alt="{{item.caption}}" /><a href="{{item.image}}" ng-attr-data-poster="{{item.video ? item.thumb : undefined}}">{{data.prompt_text}}<i class="{{data.prompt_icon}}"></i></a></div></li></ul</div></div>';
                        //  data-url="{{item.url}}" <a ng-href="{{item.url}}">{{data.items[0].title}}</a>

                        function _init() {
                            $scope.data = angular.merge({}, $scope.inputData);
                            ContentBuilderHelper.subscribeSizeChanged($scope, function (e, args) {
                                _refresh(); // refresh flexslider on fake viewport change
                            });
                            _refresh();
                        }

                        _init();

                        function _refresh() {
                            try {
                                wrapper.infoCarousel('destroy');
                            } catch(e) {}
                            wrapper.find('.info-carousel-container').remove();
                            wrapper.append($compile(template)($scope));
                            $scope.includeVisibility = false;
                            $timeout(function () {
                                $scope.$apply();
                                $scope.includeVisibility = true;
                            }, 100);
                        }

                        $scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                        $scope.$watch('stateEditing', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                    }]
                };
            }
        ]);
    }
);

