define('services/CustomerInfoService',[
	'app',
	'modules/module',
	'services/NotificationService',
	'services/EntityCrudService'
], function (app, module) {
	'use strict';
	return module.factory('CustomerInfoService', ['$injector', '$cookies','$rootScope',
		function ($injector, $cookies, $rootScope) {
			angular.extend(this, $injector.instantiate(CrudService));
			this.setType('CustomerInfo');
			return this;
		}
	]);
});
