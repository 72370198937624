define('directives/infinity/Infinity',[
	'app'
	],	
	function (app){
		'use strict';
		app.directive('ngInfinity', function(){
			return {
				require: 'ngModel',
				link: function (scope, element, attrs, modelCtrl) {

					//convert data from view format to model format
					modelCtrl.$parsers.push(function (inputValue) {
						var transformedInput;
						if(inputValue == 'Beliebig') {
							transformedInput = '-1';
						} else {
							transformedInput = inputValue;
						}

						return transformedInput;
					});

					//convert data from model format to view format
					modelCtrl.$formatters.push(function (data) {

						var transformedInput;
						if(data == '-1') {
							transformedInput = 'Beliebig';
						} else {
							transformedInput = data;
						}

						/*if (transformedInput != inputValue) {
						 modelCtrl.$setViewValue(transformedInput);
						 modelCtrl.$render();
						 }*/

						return transformedInput;
					});
				}
			}
		});
	}
);
