define('services/RefinementProductService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('RefinementProductService', [
        '$injector',
        RefinementProductService
    ]);
});


function RefinementProductService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('RefinementProduct');

    angular.extend(this, crudInstance);
    
    return this;
};
