define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/infoSlider/infoSlider',[
        'app',
        'settings',
        'services/ContentBuilderHelper',
        'filters/TrustedHtml'
    ],
    function (app) {
        'use strict';
        app.directive('ngInfoSlider', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        inputData: "<data",
                        stateEditing: "<"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/sectionSnippet/directives/infoSlider/views/index.html',
                    controller: ['$scope', '$timeout', '$element', '$compile', 'ContentBuilderHelper', function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/infoSlider/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customStyleFake = path + "styleFake.css";
                        $scope.customJs = path + "js.html";
                        $scope.includeVisibility = true;

                        var wrapper = $($element).find('[data-content-block="info-slider"]');

                        var template = '<div class="flexslider carousel"><ul class="slides"><li ng-repeat="item in data.items"><div class="info-slider__slide"><div class="info-slider__gradient"><div class="info-slider__gradient__container"><div class="info-slider__shape"></div></div><div class="info-slider__text"><h3>{{item.title}}</h3><p ng-bind-html="item.text|trustedHtml"></p></div></div><div class="info-slider__image"><img ng-src="{{item.image}}" alt="{{item.title}}"/></div></div></div></li></ul</div>';

                        function _init() {
                            $scope.data = angular.merge({}, $scope.inputData);
                            ContentBuilderHelper.subscribeSizeChanged($scope, function (e, args) {
                                _refresh(); // refresh flexslider on fake viewport change
                            });
                            _refresh();
                        }

                        _init();

                        function _refresh() {
                            wrapper.find('.flexslider').remove();
                            wrapper.append($compile(template)($scope));
                            $scope.includeVisibility = false;
                            $timeout(function () {
                                $scope.$apply();
                                $scope.includeVisibility = true;
                            }, 100);
                        }

                        $scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                    }]
                };
            }
        ]);
    }
);

/* sample data
{
    "slides": [
        {
            "url": "https://avago.de",
            "default": "/asset/images/content/partners/avago.png",
            "caption": "Avago",
            "subcaption": "Avago"
        },
        {
            "url": "https://digilent.de",
            "default": "/asset/images/content/partners/digilent.png",
            "caption": "DIGILENT",
            "subcaption": "DIGILENT"
        },
        {
            "url": "https://texas-instruments.de",
            "default": "/asset/images/content/partners/texas_instruments.png",
            "caption": "Texas Instruments",
            "subcaption": "Texas Instruments"
        },
        {
            "url": "https://tyco.de",
            "default": "/asset/images/content/partners/tyco.png",
            "caption": "TYCO",
            "subcaption": "TYCO"
        },
        {
            "url": "https://pseg.de",
            "default": "/asset/images/content/partners/pseg.png",
            "caption": "PSEG",
            "subcaption": "PSEG"
        }
    ]
}
*/
;
