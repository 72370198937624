define(
    'interceptors',[
        'services/UserService',
        'services/NotificationService'
    ], function () {
    'use strict';
    return {
        initInterceptors: function ($httpProvider) {
            $httpProvider.interceptors.push(['$location', '$injector', 'NotificationService', function ($location, $injector, NotificationService) {
                    return {
                        'responseError': function (response) {

                            if (response.data && response.data.userError) {
                                NotificationService.notifyGeneralError({msg: response.data.userError});
                                return response;
                            }

                            if (response.data && response.data.validationErrors && response.data.validationErrors.length > 0) {
                                NotificationService.notifyValidationErrors(response.data.validationErrors);
                                return response;
                            }

                            if (response.data && response.data.success == false || response.data && response.data.ok == false) {
                                NotificationService.notifyRequestError(response);
                            }
                            if (response.status == 401 || response.status == 503 || response.status == 403 || response.status == 409) {
                                var UserService = $injector.get('UserService');
                                UserService.removeLogin();
                                $injector.get('$state').go("root.login");
                            }

                            return response;
                        }
                    };
                }])
        }
    }
});

