/**
 * Created by Nico on 18.07.2016.
 */
define('services/IndustryService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('IndustryService', [
        '$injector',
        IndustryService
    ]);
});


function IndustryService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Industry');

    angular.extend(this, crudInstance);

    return this;
};
