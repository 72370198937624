define('services/ContentCssHelper',[
    'app',
    'modules/module',
    'services/ContentBuilderHelper'
], function (app, module) {
    'use strict';
    return module.factory('ContentCssHelper', [ '$rootScope', 'ContentBuilderHelper',
        function($rootScope, ContentBuilderHelper) {
            // Stateless service to wrap css style enums and helper functions

            var VIEWPORT_SIZE = ContentBuilderHelper.VIEWPORT_SIZE;
            var FONT_STYLES = Object.freeze(
                {
                    DEFAULT: { _: 0, val: 'NONE' },
                    NONE: { _: 1, style: {fontWeight: 'unset', textDecoration: 'unset', fontStyle: 'unset'} },
                    BOLD: { _: 2, style: {fontWeight: 'bold'} },
                    ITALIC: { _: 3, style: {fontStyle: 'italic'}},
                    UNDERLINE: { _: 4, excludes: [5], style: {textDecoration: 'underline'}},
                    LINE_THROUGH: { _: 5, excludes: [4], style: {textDecoration: 'line-through'} }
                }
            );
            var HORIZONTAL_ALIGNMENTS = Object.freeze(
                {
                    DEFAULT: { _: 1, val: 'LEFT' },
                    LEFT: { _: 1, style: {textAlign: 'left'} },
                    CENTER: { _: 2, style: {textAlign: 'center'} },
                    RIGHT: { _: 3, style: {textAlign: 'right'}}
                }
            );
            var WRAP_MODES = Object.freeze({FILL: 'fill', DOUBLE: 'double', SINGLE: 'single' });
            var WRAP_SETUPS = Object.freeze({
                FILL: {
                    "1": 8.333333333333,
                    "2": 16.666666666666,
                    "3": 25,
                    "4": 33.3333333333,
                    "5": 41.666666666666,
                    "6": 50,
                    "7": 58.33333333333333,
                    "8": 66.6666666666,
                    "9": 75,
                    "10": 83.33333333333,
                    "11": 91.66666666666666,
                    "12": 100
                },
                DOUBLE: {
                    "1": 0,
                    "2": 0,
                    "3": 0,
                    "4": 50,
                    "5": 0,
                    "6": 50,
                    "7": 0,
                    "8": 50,
                    "9": 0,
                    "10": 0,
                    "11": 0,
                    "12": 100
                },
                SINGLE: {
                    "1": 0,
                    "2": 0,
                    "3": 0,
                    "4": 100,
                    "5": 0,
                    "6": 100,
                    "7": 0,
                    "8": 100,
                    "9": 0,
                    "10": 0,
                    "11": 0,
                    "12": 100
                }
             });
            // var POSITION_PROPS = [
            //     'top', 'right', 'bottom', 'left'
            // ];
            var _setStyle = function (target, styles, group) {
                Object.assign(target, group[group.DEFAULT.val].style);
                if (styles !== undefined) {
                    if (styles.length !== undefined) {
                        for (var i = 0; i < styles.length; i++) {
                            Object.assign(target, styles[i].style);
                        }
                    } else {
                        Object.assign(target, styles.style);
                    }
                }
            };

            var _initViewportSetups = function () {
                var setups = [];

                for (var att in WRAP_MODES) {
                    var setup = {
                        id: att,
                        max: WRAP_MODES[att],
                        style: WRAP_SETUPS[att]
                    };
                    // (function (set, setId) {
                    //     $.getJSON('/app/directives/contentConfigurator/responsiveCss/css/' + setId + '.json', function (json) {
                    //         set.style = json;
                    //     });
                    // })(setup, att);
                    setups.push(setup);
                }
                Object.freeze(setups);
                return setups;
            };

            var initViewportDimensions = function () {
                var dimensions = [];

                // Create a dims object for each Viewport size and put them in an ordered array
                for (var att in VIEWPORT_SIZE) {
                    var dims = {
                        id: att,
                        max: VIEWPORT_SIZE[att]
                    };
                    for (var i = 0; i <= dimensions.length; i++) {
                        if (i === dimensions.length) {
                            dimensions.push(dims);
                            break;
                        } else if (dims.max > dimensions[i].max) {
                            dimensions.splice(i, 0, dims);
                            break;
                        }
                    }
                }

                for (var i = 0; i < dimensions.length; i++) {
                    if (i < dimensions.length - 1) {
                        dimensions[i].min = dimensions[i + 1].max + 1 + 'px';
                    }
                    dimensions[i].max = dimensions[i].max > 9999 ? '100%' : dimensions[i].max + 'px';
                }

                return dimensions;
            };

            var _wrapSetups = _initViewportSetups();
            var _viewportDimensions = initViewportDimensions();

            // These objects buffer the inputs for fields like background position and background size.
            var _initDimBuffer = function (styleObj, styleField, literalValues, defaultLiteral, defaultDimValue, defaultRationLock, defaultDimUnit) {
                var matchValue = new RegExp('([0-9])+', 'g');
                var lastMatch = {};
                var valueMatches = [];
                while (lastMatch !== null) {
                    lastMatch = matchValue.exec(styleObj[styleField]);
                    if (lastMatch !== null && lastMatch.length > 0 && lastMatch[0] !== undefined) {
                        valueMatches.push(lastMatch[0]);
                    }
                }

                var matchUnit = new RegExp('(px|%)+', 'g');
                var unitMatches = [];
                lastMatch = {};
                while (lastMatch !== null) {
                    lastMatch = matchUnit.exec(styleObj[styleField]);
                    if (lastMatch !== null && lastMatch.length > 0 && lastMatch[0] !== undefined) {
                        unitMatches.push(lastMatch[0]);
                    }
                }

                var buffer = {
                    type: valueMatches[0] !== '' ? 'numerical' : literalValues.indexOf(styleObj[styleField]) > 0 ? styleObj[styleField] : defaultLiteral,
                    value: {
                        x: typeof valueMatches[0] === 'number' ? valueMatches[0] : defaultDimValue,
                        y: typeof valueMatches[1] === 'number' > 1 ? valueMatches[1] : defaultDimValue,
                        unitX: unitMatches[0] !== '' && typeof unitMatches[0] === 'string' ? unitMatches[0] : defaultDimUnit,
                        unitY: unitMatches[1] !== '' && typeof unitMatches[1] === 'string' ? unitMatches[1] : defaultDimUnit
                    }
                };
                buffer.value.lockRatio = defaultRationLock &&
                    buffer.value.x === buffer.value.y &&
                    buffer.value.unitX === buffer.value.unitY;

                return buffer;
            };

            var _initSurroundBuffer = function (styleObj, styleField, defaultDimValue, defaultDimUnit) {
                var matchValue = new RegExp('([0-9])+', 'g');
                var lastMatch = {};
                var valueMatches = [];
                while (lastMatch !== null) {
                    lastMatch = matchValue.exec(styleObj[styleField]);
                    if (lastMatch !== null && lastMatch.length > 0 && lastMatch[0] !== undefined) {
                        valueMatches.push(lastMatch[0]);
                    }
                }

                var matchUnit = new RegExp('(px|%)+', 'g');
                var unitMatches = [];
                lastMatch = {};
                while (lastMatch !== null) {
                    lastMatch = matchUnit.exec(styleObj[styleField]);
                    if (lastMatch !== null && lastMatch.length > 0 && lastMatch[0] !== undefined) {
                        unitMatches.push(lastMatch[0]);
                    }
                }

                var validValueMatches = [];
                var validUnitMatches = [];
                if (styleObj[styleField] !== undefined && styleObj[styleField] !== '' && valueMatches.length > 0 && valueMatches[0] !== null) {
                    var validEntryAmount = valueMatches.length;
                    switch (validEntryAmount) {
                        case 0: {
                            validValueMatches = [defaultDimValue, defaultDimValue, defaultDimValue, defaultDimValue];
                            validUnitMatches = [defaultDimUnit, defaultDimUnit, defaultDimUnit, defaultDimUnit];
                            break;
                        }
                        case 1: {
                            validValueMatches = [valueMatches[0], valueMatches[0], valueMatches[0], valueMatches[0]];
                            for (var i = 0; i < 4; i++) {
                                validUnitMatches.push(unitMatches.length > 0 && typeof unitMatches[0] === 'string' && unitMatches[0] !== '' ? unitMatches[0] : defaultDimUnit);
                            }
                            break;
                        }
                        case 2: {
                            validValueMatches = [valueMatches[0], valueMatches[0], valueMatches[1], valueMatches[1]];
                            for (var i = 0; i < 2; i++) {
                                validUnitMatches.push(unitMatches.length > 0 && typeof unitMatches[0] === 'string' && unitMatches[0] !== '' ? unitMatches[0] : defaultDimUnit);
                            }
                            for (var i = 0; i < 2; i++) {
                                validUnitMatches.push(unitMatches.length > 1 && typeof unitMatches[1] === 'string' && unitMatches[1] !== '' ? unitMatches[1] : defaultDimUnit);
                            }
                            break;
                        }
                        case 3: {
                            validValueMatches = [valueMatches[0], valueMatches[2], valueMatches[1], valueMatches[1]];
                            validUnitMatches.push(unitMatches.length > 0 && typeof unitMatches[0] === 'string' && unitMatches[0] !== '' ? unitMatches[0] : defaultDimUnit);
                            validUnitMatches.push(unitMatches.length > 2 && typeof unitMatches[2] === 'string' && unitMatches[2] !== '' ? unitMatches[2] : defaultDimUnit);
                            validUnitMatches.push(unitMatches.length > 1 && typeof unitMatches[1] === 'string' && unitMatches[1] !== '' ? unitMatches[1] : defaultDimUnit);
                            validUnitMatches.push(unitMatches.length > 1 && typeof unitMatches[1] === 'string' && unitMatches[1] !== '' ? unitMatches[1] : defaultDimUnit);
                            break;
                        }
                        case 4: {
                            validValueMatches = [valueMatches[0], valueMatches[2], valueMatches[1], valueMatches[3]];
                            for (var i = 0; i < 4; i++) {
                                validUnitMatches.push(unitMatches.length > i && typeof unitMatches[i] === 'string' && unitMatches[i] !== '' ? unitMatches[i] : defaultDimUnit);
                            }
                            break;
                        }
                    }
                } else {
                    validValueMatches = [defaultDimValue, defaultDimValue, defaultDimValue, defaultDimValue];
                    validUnitMatches = [defaultDimUnit, defaultDimUnit, defaultDimUnit, defaultDimUnit];
                }
                for (var i = 0; i < validUnitMatches.length; i++) {
                    validValueMatches[i] = parseInt(validValueMatches[i], 10);
                    if (isNaN(validValueMatches[i])) {
                        validValueMatches[i] = defaultDimValue;
                    }
                }

                var buffer = {
                    sync: {
                        vertical: true,
                        horizontal: true
                    },
                    value: {
                        top: validValueMatches[0], top_unit: validUnitMatches[0],
                        bottom: validValueMatches[1], bottom_unit: validUnitMatches[1],
                        right: validValueMatches[2], right_unit: validUnitMatches[2],
                        left: validValueMatches[3], left_unit: validUnitMatches[3]
                    }
                };
                buffer.sync.vertical = buffer.value.top === buffer.value.bottom;
                buffer.sync.horizontal = buffer.value.left === buffer.value.right;

                return buffer;
            };

            var _initBackgroundBuffer = function (styleObj) {
                var buffer = {};
                if (styleObj.backgroundImage) {
                    buffer.backgroundImageUrl = styleObj.backgroundImage.replace('url(', '');
                    if (buffer.backgroundImageUrl.charAt(buffer.backgroundImageUrl.length - 1) === ')') {
                        buffer.backgroundImageUrl = buffer.backgroundImageUrl.slice(0, buffer.backgroundImageUrl.length - 1);
                    }
                }
                return buffer;
            };

            var _findSetup = function (setupId) {
                for (var i = 0; i < _wrapSetups.length; i++) {
                    if (_wrapSetups[i].id === setupId) {
                        return _wrapSetups[i];
                    }
                }
                return undefined;
            };

            var _findDimensions = function (setupId) {
                for (var i = 0; i < _viewportDimensions.length; i++) {
                    if (_viewportDimensions[i].id === setupId) {
                        return _viewportDimensions[i];
                    }
                }
                return undefined;
            };
            // var _initPositionPropsBuffer = function (styleObj) {
            //     var buffer = {
            //         top: { v: 0, u: 'px', on: false },
            //         right: { v: 0, u: 'px', on: false },
            //         bottom: { v: 0, u: 'px', on: false },
            //         left: { v: 0, u: 'px', on: false },
            //     };
            //     var valueUnitRegex = new RegExp('([0-9]+)(px|%)$', 'g');
            //     POSITION_PROPS.forEach(function(positionPropName) {
            //         if(styleObj.hasOwnProperty(positionPropName)) {

            //             var matchedValueUnit = valueUnitRegex.exec(styleObj[positionPropName]);
            //             if (matchedValueUnit !== null && matchedValueUnit.length === 3) {
            //                 buffer[positionPropName] = {
            //                     v: +matchedValueUnit[1],
            //                     u: matchedValueUnit[2],
            //                     on: true
            //                 };
            //             }
            //         }
            //     });
            //     return buffer;
            // }
            return {
                FONT_STYLES: FONT_STYLES,
                ALIGNMENTS: HORIZONTAL_ALIGNMENTS,
                WRAP_MODES: WRAP_MODES,
                assignStyle: _setStyle,
                wrapSetups: _wrapSetups,
                getWrapSetup: function (setupId) {
                    return _findSetup(setupId);
                },
                getViewportDimensions: function (view) {
                    return _findDimensions(view);
                },
                initDimBuffer: _initDimBuffer,
                initSurroundBuffer: _initSurroundBuffer,
                initBackgroundBuffer: _initBackgroundBuffer,
                // initPositionPropsBuffer: _initPositionPropsBuffer,
            }

        }]);
});

