define('services/ParticipantTypesService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('ParticipantTypesService', [
        '$injector',
        ParticipantTypesService
    ]);
});


function ParticipantTypesService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('ParticipantType');

    angular.extend(this, crudInstance);

    return this;
};
