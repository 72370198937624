define(
    'directives/controls/customSimpleCheckbox/CustomSimpleCheckbox',[
        'app',
        'settings'
    ],
    function (app) {
        'use strict';
        app.directive('ngCustomSimpleCheckbox', ['BASE_DIRECTIVES_PATH', function(BASE_DIRECTIVES_PATH) {
            return {
                scope: {
                    display: "@",
                    result: "=",
                    label: "@",
                    outerLabel: "=",
                    placeholder: "@",
                    disable: "=",
                    click: "=",
                    outerLabelClass: "@",
                    change: "="/*,
                    check: "="*/
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/controls/customSimpleCheckbox/views/CustomSimpleCheckbox.html",
                controller: ['$scope', function($scope) {

                }]
            }
        }
        ])});

/*define(
 [
 'app',
 'settings'
 ],
 function (app) {
 'use strict';
 app.directive('ngCustomSimpleCheckbox', ['BASE_DIRECTIVES_PATH', function(BASE_DIRECTIVES_PATH) {
 return {
 scope: {
 display: "@",
 result: "=",
 label: "@",
 outerLabel: "=",
 placeholder: "@",
 disable: "=",
 onClick: "=",
 onChange: "=",
 onCheck: "&"
 },
 templateUrl: BASE_DIRECTIVES_PATH + "/controls/customSimpleCheckbox/views/CustomSimpleCheckbox.html",
 controller: ['$scope', function($scope) {

 }]
 }
 }
 ])});*/;
