/**
 * Created by mrafiq on 16.04.18.
 */


define('modules/home/controllers/ReportController',[
        'modules/module',
        'services/ReportService',
        'services/SessionValuesService',
        'services/NavigationService'
    ],
    function (app, module) {
        'use strict';
        module.controller('ReportController', [
            '$controller',
            '$scope',
            '$rootScope',
            'ReportService',
            '$window',
            'SessionValuesService',
            '$state',
            'NavigationService',
            ReportController
        ]);
    }
);

function ReportController($controller, $scope, $rootScope, ReportService, $window, SessionValuesService, $state, NavigationService) {


    $scope.currentPage = ReportService.getCurrentPage();
    $scope.pageSize = ReportService.getPageSize();
    $scope.totalItems = ReportService.getTotalItems();
    $scope.totalPages = ReportService.getTotalPages();
    $scope.reportType = "seminar";
    $scope.data = {};
    $scope.data.selectedReportColumns = '';
    $scope.downloadFileType = "pdf";

    var dateObj = {};

    $scope.setPageSizeUpdate = function () {
        $scope.setPageSize();
        $scope.getReport($scope.date);
    };

    $scope.setPageUpdate = function () {
        $scope.setPage();
        $scope.getReport($scope.date);

    };

    $scope.listPropertyNames = {};

    $scope.changeReportType = function () {
        var obj = {startDate: $scope.date.start, endDate: $scope.date.end, reportType: $scope.reportType};
        NavigationService.changeStateWithObject('root.reports', obj);
        //$scope.getReport($scope.date);
    };


    $scope.seminarFilter = {
        filterSeminarName: "",
        filterLocation: ""
    }


    $scope.date = {
        start: new Date(new Date().setMonth(new Date().getMonth() - 3)),
        end: new Date(new Date().setDate(new Date().getDate()))
    };

    if ($state.params.startDate) {
        $scope.date = {
            start: new Date($state.params.startDate),
            end: new Date($state.params.endDate)
        };
    }
    if ($state.params.reportType) {
        $scope.reportType = $state.params.reportType;
    }

    if (!$state.params.startDate && !$state.params.reportType) {
        var obj = {startDate: $scope.date.start, endDate: $scope.date.end, reportType: "seminar"};
        NavigationService.changeStateWithObject('root.reports', obj);
    }


    $scope.setPageSize = function () {
        ReportService.setPageSize($scope.pageSize);
    };

    $scope.setPage = function () {
        ReportService.setCurrentPage($scope.getRealCurrentPage());
    };

    $scope.getRealCurrentPage = function () {
        var cur = angular.copy(parseInt($scope.currentPage));
        cur = (cur === 0) ? cur : (cur - 1);
        return cur;
    };

    $scope.showCurrentlyDisplayed = function () {
        var from = parseInt($scope.pageSize) * parseInt($scope.getRealCurrentPage());
        var to = ((from + parseInt($scope.pageSize)) < $scope.totalItems) ? (from + parseInt($scope.pageSize)) : $scope.totalItems;
        return (from + 1) + ' - ' + to;
    };


    $scope.setPageVariables = function () {
        $scope.currentPage = ReportService.getCurrentPage();
        $scope.pageSize = ReportService.getPageSize();
        $scope.totalItems = ReportService.getTotalItems();
        $scope.totalPages = ReportService.getTotalPages();
    }

    $scope.downloadReport = function () {

        if ($scope.downloadFileType == "pdf")
            $window.open("/api/v1/core/report/get-pdf?type=" + $scope.reportType + "&startDate=" + dateObj.start + "&endDate=" + dateObj.end + "&shopId=" + SessionValuesService.getShop() + "&localeId=" + SessionValuesService.getLocale() + "&columns=" + $scope.data.selectedReportColumns);
        else if ($scope.downloadFileType == "excel")
            $window.open("/api/v1/core/report/get-excel?type=" + $scope.reportType + "&startDate=" + dateObj.start + "&endDate=" + dateObj.end + "&shopId=" + SessionValuesService.getShop() + "&localeId=" + SessionValuesService.getLocale() + "&columns=" + $scope.data.selectedReportColumns, "_blank");
        else {
            //alert('invalid type');
        }
    };


    $scope.onSuccess = function (result) {
    };

    $scope.onCancel = function () {
    };

    $scope.dialogReportController = function ($scope, dataToPass, $mdDialog, $rootScope) {

        $scope.date = {
            start: dataToPass.start,
            end: dataToPass.end
        };

        $scope.data = dataToPass;
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.getReport = function () {
            $rootScope.$emit('getReport', $scope.date);
            $rootScope.$emit('closeModal');
        };
    };

    $rootScope.$on('getReport', function (event, data) {
        $scope.getReport(data);
    });

    $scope.changeSelectedColumns = function () {
    }


    $scope.onSeminarItemClick = function (id) {
        NavigationService.changeState('root.seminarDetailReport', id);

    }

    $scope.onSeminarExcelClick = function (id) {
        $window.open("/api/v1/core/report/get-seminar-detail-excel?id=" + id + "&shopId=" + SessionValuesService.getShop() + "&columns=" + $scope.data.selectedReportColumns, "_blank");

    }

    $scope.onSeminarPdfClick = function (id) {
        $window.open("/api/v1/core/report/get-seminar-detail-pdf?id=" + id + "&shopId=" + SessionValuesService.getShop() + "&localeId=" + SessionValuesService.getLocale() + "&columns=" + $scope.data.selectedReportColumns);

    }


    $scope.seminarFilterChange = function () {
        $scope.getReport($scope.date);
    };

    $scope.getReport = function (date) {


        if (date == undefined) {
            date = {
                start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
            };
        }

        var monthStart = date.start.getMonth() + 1;
        var monthEnd = date.end.getMonth() + 1;

        dateObj.start = date.start.getFullYear() + '-' + monthStart + '-' + date.start.getDate();
        dateObj.end = date.end.getFullYear() + '-' + monthEnd + '-' + date.end.getDate();

        $scope.date = date;

        if (!$scope.reportType) {
            $scope.reportType = "seminar";
        }

        if ($scope.reportType == "topProducts") {

            ReportService.findTopProducts(dateObj, $scope.pageSize).then(function (response) {
                if (response.data) {
                    $scope.topProducts = response.data;
                    $scope.setPageVariables();

                    $scope.listPropertyNames = Object.keys($scope.topProducts[0]);

                    for (var i = 0; i < $scope.listPropertyNames.length; i++) {
                        var result = $scope.listPropertyNames[i].replace(/([A-Z])/g, " $1");
                        $scope.listPropertyNames[i] = result.charAt(0).toUpperCase() + result.slice(1);
                    }
                }

            });
        }


        if ($scope.reportType == "revenueProfit") {
            ReportService.getProfitReport(dateObj, $scope.pageSize).then(function (response) {
                if (response.data) {
                    $scope.profitReport = response.data;
                    $scope.setPageVariables();

                    $scope.listPropertyNames = Object.keys($scope.profitReport[0]);

                    for (var i = 0; i < $scope.listPropertyNames.length; i++) {
                        var result = $scope.listPropertyNames[i].replace(/([A-Z])/g, " $1");
                        $scope.listPropertyNames[i] = result.charAt(0).toUpperCase() + result.slice(1);
                    }
                }

            });
        }


        if ($scope.reportType == "orderStats") {
            ReportService.getOrderStats(dateObj, $scope.pageSize).then(function (response) {
                if (response.data) {
                    $scope.orderStatsReport = response.data;
                    $scope.setPageVariables();

                    $scope.listPropertyNames = Object.keys($scope.orderStatsReport[0]);

                    for (var i = 0; i < $scope.listPropertyNames.length; i++) {
                        var result = $scope.listPropertyNames[i].replace(/([A-Z])/g, " $1");
                        $scope.listPropertyNames[i] = result.charAt(0).toUpperCase() + result.slice(1);
                    }
                }
            });
        }

        if ($scope.reportType == "seminar") {
            ReportService.getSeminarReport(dateObj, $scope.seminarFilter, $scope.pageSize).then(function (response) {
                if (response.data) {
                    $scope.seminarReport = response.data;
                    $scope.setPageVariables();
                    if ($scope.seminarReport[0]) {
                        $scope.listPropertyNames = Object.keys($scope.seminarReport[0]);

                        for (var i = 0; i < $scope.listPropertyNames.length; i++) {
                            var result = $scope.listPropertyNames[i].replace(/([A-Z])/g, " $1");
                            $scope.listPropertyNames[i] = result.charAt(0).toUpperCase() + result.slice(1);
                        }
                    }
                }
            });
        }

    };

    $scope.getReport($scope.date);


}
;
