define('modules/settings/dataFeedInput/DataFeedInputController',[
        'modules/module',
        'settings',
        'modules/basemodule/controllers/EntityListController'
    ],
    function (module) {
        'use strict';
        module.controller('DataFeedInputController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.tableOptions.fields = [
                    {
                        id: 'TYPE',
                        heading:    'Type',
                        attribute:  'fileType',
                        orderDisabled: true
                    },
                    {
                        id: 'FILE_NAME',
                        heading:    'Dateiname',
                        attribute:  'fileName',
                        orderDisabled: true
                    },
                    {
                        id: 'SHOP',
                        heading:    'Shop',
                        attribute:  'shop',
                        orderDisabled: true
                    },
                    {
                        id: 'LOCALE',
                        heading:    'Sprache',
                        attribute:  'locale',
                        orderDisabled: true
                    },
                    {
                        id: 'CURRENCY',
                        heading:    'Währung',
                        attribute:  'currency',
                        orderDisabled: true
                    },
                    {
                        id: 'CUSTOMER_GROUP',
                        heading:    'Kundengruppe',
                        attribute:  'customerGroup',
                        orderDisabled: true
                    },
                    {
                        id: 'LAST_GENERATION',
                        heading:    'Letzte Generierung',
                        attribute:  'lastGeneration',
                        orderDisabled: true
                    },
                    {
                        id: 'INCLUDE_ALL_PRODUCTS',
                        heading:    'Beinhaltet alle Produkte',
                        template:  BASE_TEMPLATES_PATH + '/settings/dataFeedInput/col/include-all-products-col.html',
                        attribute:  'includeAllProducts',
                        searchDisabled: true,
                        orderDisabled: true
                    }
                ];
                $scope.formState = 'root.dataFeedInput';

                $scope.init('DataFeedInput');
            }
        ]);
    }
);


