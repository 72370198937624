define('services/AttributeUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('AttributeUnpagedService', [
        '$injector',
        AttributeUnpagedService
    ]);
});


function AttributeUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('Attribute');

    angular.extend(this, crudInstance);

    return this;
};
