define(
	'directives/languageForm/languageForm',[
		'app',
	    'uiBootstrap',
        'filters/RemoveSpacesAndSpecialCharacters',
	    'settings'
   	],
   	function (app) {
		'use strict';
		app.directive('ngLanguageForm', ['BASE_DIRECTIVES_PATH',
    		function(BASE_DIRECTIVES_PATH) {
        		return {
	        		templateUrl: BASE_DIRECTIVES_PATH + "/languageForm/views/view.html",
                    link: function($scope, element, attr) {
                        $scope.$watch(attr.result, function(newValue, oldValue) {
                            if(newValue === undefined || newValue == null) {
                                return;
                            }

                            if($scope[attr.result].locales === undefined) {
                                $scope[attr.result].locales = [ ];
                            }
                        });

                        $scope.$watch(attr.locales, function(newValue, oldValue) {
                            if($scope[attr.locales] != undefined) {
                                $scope[attr.locales].sort(function(a, b){
                                    if ( a.id < b.id )
                                        return -1;
                                    if ( a.id > b.id )
                                        return 1;
                                    return 0;
                                });
                            }

                            if(newValue === undefined || newValue == null) {
                                return;
                            }
                            for(var i=0; i < $scope[attr.locales].length; i++) {
                                if($scope[attr.locales][i].isDefault) {
                                    $scope.selectedLocale = angular.copy($scope[attr.locales][i]);
                                }
                            }

                        });

                        $scope.r = function() {
                            if ($scope.selectedLocale === undefined) {
                                return null;
                            }

                            var result = null;
                            var resultTmp = $scope[attr.result];
                            if(resultTmp == undefined){
                                return;
                            }
                            for(var i in resultTmp.locales) {
                                if($scope[attr.result].locales[i].localeId == $scope.selectedLocale.id) {
                                    result = $scope[attr.result].locales[i];
                                }
                            }


                            if(result == null) {
                                result = {};
                                result.localeId = $scope.selectedLocale.id;
                                $scope[attr.result].locales.push(result);
                            }

                            return result;

                        };

                        $scope.$watch('selectedLocale', function(newValue, oldValue) {
                            $scope.localeContent = $scope.r();
                            if ($scope.localeContent === null || $scope.localeContent === undefined) {
                                $scope.localeContent = {};
                            }

                            if(attr.type === 'product') {
                                $scope.localeContent.shortDescription = $scope.localeContent.shortDescription || "";
                                $scope.localeContent.longDescription = $scope.localeContent.longDescription || "";
                            } else {
                                $scope.localeContent.shortDescription = $scope.localeContent.shortDescription || {
                                    html: "",
                                    json: {}
                                };
                                $scope.localeContent.longDescription = $scope.localeContent.longDescription || {
                                    html: "",
                                    json: {}
                                };
                            }
                        }, true);

                        $scope.rOption = function() {
                            if ($scope.selectedLocale === undefined) {
                                return null;
                            }
                            var result = null;
                            for(var i in $scope[attr.result].locales) {
                                if($scope[attr.result].locales[i].localeId == $scope.selectedLocale.id) {
                                    var options = $scope[attr.options];
                                    if ($scope[attr.result].locales[i].options == undefined) {
                                        $scope[attr.result].locales[i].options = [];
                                    }
                                    result = $scope[attr.result].locales[i].options;
                                }
                            }
                            if(result == null) {
                                result = {};
                                result.localeId = $scope.selectedLocale.id;
                                result.localeId.options = [];
                                $scope[attr.result].locales.push(result);
                            }
                            return result;
                        };

                        $scope.rMetadataValue = function() {
                            if ($scope.selectedLocale === undefined) {
                                return null;
                            }
                            var result = null;
                            for(var i in $scope[attr.result].locales) {
                                if($scope[attr.result].locales[i].localeId == $scope.selectedLocale.id) {
                                    var options = $scope[attr.options];
                                    if ($scope[attr.result].locales[i].metadataValues == undefined) {
                                        $scope[attr.result].locales[i].metadataValues = [];
                                    }
                                    result = $scope[attr.result].locales[i].metadataValues;
                                }
                            }

                            for(var j in result) {
                                if(result[j].identifier) {   
                                    for(let obj of $scope[attr.result].locales) {
                                        if(obj.metadataValues[j]) {
                                            obj.metadataValues[j]['identifier'] = result[j].identifier;
                                        } else {
                                            obj.metadataValues.push({identifier: result[j].identifier,value: ""});
                                        }
                                    }
                                }
                            }

                            if(result == null) { 
                                result = {};
                                result.localeId = $scope.selectedLocale.id;
                                result.metadataValues = [];
                                $scope[attr.result].locales.push(result);
                            }
                            return result;
                        };

                        $scope.rKeyword = function() {
                            if ($scope.selectedLocale === undefined) {
                                return null;
                            }
                            var result = null;
                            for(var i in $scope[attr.result].locales) {
                                if($scope[attr.result].locales[i].localeId == $scope.selectedLocale.id) {
                                    if($scope[attr.result].locales[i].seoInformation == undefined) {
                                        $scope[attr.result].locales[i].seoInformation = {};
                                    }
                                    if ($scope[attr.result].locales[i].seoInformation.keywords == undefined) {
                                        $scope[attr.result].locales[i].seoInformation.keywords = [];
                                    }
                                    result = $scope[attr.result].locales[i].seoInformation.keywords;
                                }
                            }
                            if(result == null) {
                                result = {};
                                result.localeId = $scope.selectedLocale.id;
                                result.localeId.seoInformation.keywords = [];
                                $scope[attr.result].locales.push(result);
                            }
                            return result;
                        };

                        $scope.getLanguageFormTemplateUrl = function() {
                            return attr.templateUrl;
                        };

                        $scope.changeTab = function (locale) {
                            if ($scope.selectedLocale !== undefined) {
                                $scope.selectedLocale = locale;
                            }
                        };

                        $scope.isTabContentBordered = attr.isTabContentBordered === undefined ? false :
                            attr.isTabContentBordered;
                    }
	            }
    		}
        ]);
	}
);

