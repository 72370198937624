define('filters/CustomerSourceFilter',[
        'app'
    ],
    function (app){
        'use strict';
        app.filter('customerSourceFilter', function(){
            return function(customerSource) {
                if (customerSource == 'NEWSLETTER') {
                    customerSource = 'Newsletter';
                } else if (customerSource == 'PURCHASE') {
                    customerSource = 'Kauf über Shop';
                } else if (customerSource == 'REGISTRATION') {
                    customerSource = 'Registrierung';
                } else if (customerSource == 'MANUALINPUT') {
                    customerSource = 'Manuelle Anlange';
                }

                return customerSource;
            };
        });
    }
);
