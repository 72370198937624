define('modules/cms/controllers/RatingSelectCustomerController',[
        'modules/module',
        'modules/basemodule/controllers/EntityListController',
        'modules/crm/customer/controllers/CustomerController',
        'services/OrderStateUnpagedService',
        'services/OrderService'
    ],
    function (module) {
        'use strict';
        module.controller('RatingSelectCustomerController', [
            '$scope',
            '$controller',
            'OrderStateUnpagedService',
            'OrderService',
            'BASE_TEMPLATES_PATH',
            RatingSelectCustomerController
        ]);
    }
);


function RatingSelectCustomerController ($scope, $controller, OrderStateUnpagedService, OrderService, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('CustomerController', {
        $scope: $scope
    }));

    $scope.customer;

    $scope.tableOptions.fields = [
        {
            id: "COMPANY",
            heading: 'Firma',
            attribute: 'companyName'
        },
        {
            id: "FIRSTNAME",
            heading: 'Vorname',
            attribute: 'firstName'
        },
        {
            id: "LASTNAME",
            heading: 'Nachname',
            attribute: 'lastName'
        },
        {
            id: "ZIPCODE",
            heading: 'PLZ',
            template:  BASE_TEMPLATES_PATH + '/crm/customer/customer-zipcode-col.html',
            attribute: 'zipCode'
        },
        {
            id: "CITY",
            heading: 'Stadt',
            template:  BASE_TEMPLATES_PATH + '/crm/customer/customer-city-col.html',
            attribute: 'city'
        }
    ];

   /* $scope.setCustomer = function (customer) {
        $scope.customer = customer;
        $scope.addSelectedCustomerAndBack = function (inv, ship) {

            if ($scope.selected.items.length == 1) {
                $scope.addSelectedCustomer();
                $mdDialog.hide($scope.customer);
                cosnole.log($scope.customer);
            }
        }
    };*/

    $scope.backToSelection = function () {
        $scope.customer = undefined;
    };

    $scope.formState = "root.customer";

  /*  $scope.$watch('customer', function (newValue, oldValue) {
        if (newValue === undefined) {
            return;
        }

        $scope.invoiceAddressId = -1;
        $scope.defaultInvoiceIndex = -1;
        $scope.shippingAddressId = -1;
        $scope.defaultShippingIndex = -1;
        for (var i = 0; i < $scope.customer.addresses.length; i++) {
            if ($scope.customer.addresses[i].isDefaultInvoiceAddress) {
                $scope.invoiceAddressId = $scope.customer.addresses[i].id;
                $scope.defaultInvoiceIndex = $scope.customer.addresses[i].id;
            }
            if ($scope.customer.addresses[i].isDefaultShippingAddress) {
                $scope.shippingAddressId = $scope.customer.addresses[i].id;
                $scope.defaultShippingIndex = $scope.customer.addresses[i].id;
            }
        }
        if ($scope.invoiceAddressId === -1 && $scope.customer.addresses.length > 0) {
            $scope.defaultInvoiceIndex = $scope.customer.addresses[0].id;
        }
        if ($scope.shippingAddressId === -1 && $scope.customer.addresses.length > 0) {
            $scope.defaultShippingIndex = $scope.customer.addresses[0].id;
        }
    });*/

 /*   $scope.shippingSame = true;

    $scope.carryOverShipping = function(invoiceAddressId, same) {
        if (same) {
            $scope.shippingAddressId = invoiceAddressId;
        }
    };

    $scope.setAddresses = function() {
        var invoiceAddress;
        var shippingAddress;
        for (var i = 0; i < $scope.customer.addresses.length; i++) {
            if ($scope.invoiceAddressId === $scope.customer.addresses[i].id) {
                invoiceAddress = $scope.customer.addresses[i];
            }
            if ($scope.shippingAddressId === $scope.customer.addresses[i].id) {
                shippingAddress = $scope.customer.addresses[i];
            }
        }

        OrderService.setCustomerInOrderForm({
            id: $scope.customer.id,
            firstName: $scope.customer.firstName,
            lastName: $scope.customer.lastName,
            invoiceAddress: invoiceAddress,
            shippingAddress: shippingAddress
        });

    };*/

    $scope.init('Customer').then(function(){
    });
};
