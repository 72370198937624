define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/lightGallery/lightGallery',[
        'app',
        'settings',
        'services/ContentBuilderHelper'
    ],
    function (app) {
        'use strict';
        app.directive('ngLightGallery', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        inputData: "<data",
                        stateEditing: "<"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/sectionSnippet/directives/lightGallery/views/index.html',
                    controller: ['$scope', '$timeout', '$element', '$compile', 'ContentBuilderHelper', function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/lightGallery/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customStyleFake = path + "styleFake.css";
                        $scope.customJs = path + "js.html";
                        $scope.includeVisibility = true;

                        var wrapper = $($element).find('[data-content-block="light-gallery"]');

                        var template = '<div class="flexslider"><ul class="slides"><li ng-repeat="slide in data.items"><div class="slide-content"><a href="{{slide.image}}" ng-attr-data-poster="{{slide.video ? slide.thumb : undefined}}"><img class="slider-image" ng-src="{{slide.thumb}}" alt="{{slide.caption}}" /><div class="slider-fullscreen">{{data.prompt_text}}<i class="{{data.prompt_icon}}"></i></div></a></div></li></ul</div>';

                        function _init() {
                            $scope.data = angular.merge({}, $scope.inputData);
                            ContentBuilderHelper.subscribeSizeChanged($scope, function (e, args) {
                                _refresh(); // refresh flexslider on fake viewport change
                            });
                            _refresh();
                        }

                        _init();

                        function _refresh() {
                            wrapper.find('.flexslider').remove();
                            wrapper.append($compile(template)($scope));
                            $scope.includeVisibility = false;
                            $timeout(function () {
                                $scope.$apply();
                                $scope.includeVisibility = true;
                            }, 100);
                        }

                        $scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                        $scope.$watch('stateEditing', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                    }]
                };
            }
        ]);
    }
);

/* sample data
{
  "prompt_text": "verfrößern",
  "prompt_icon": "fa fa-arrows-alt",
  "items": [
    {
      "image": "https://via.placeholder.com/1920x1080",
      "video": true,
      "thumb": "https://via.placeholder.com/300x240",
      "caption": "Title 1",
      "subcaption": "Subtitle 1"
    },
    {
      "image": "https://via.placeholder.com/1920x1080",
      "thumb": "https://via.placeholder.com/300x240",
      "caption": "Title 2",
      "subcaption": "Subtitle 2"
    },
    {
      "image": "https://via.placeholder.com/1920x1080",
      "thumb": "https://via.placeholder.com/300x240",
      "caption": "Title 3",
      "subcaption": "Subtitle 3"
    },
    {
      "image": "https://via.placeholder.com/1920x1080",
      "thumb": "https://via.placeholder.com/300x240",
      "caption": "Title 1",
      "subcaption": "Subtitle 1"
    }
  ]
}
*/
;
