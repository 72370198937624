define('modules/econ/controllers/EconNewsletterFormController',[
        'modules/module',
        'settings',
        'services/EconMailtemplateService',
        'services/BeePluginService',
        'bee_plugin_blop',
        'bee_plugin_filesaver',
        'bee_plugin',
        'jquery',
        'directives/beeWrapper/beeWrapper',
        'services/EconRecipientListService',
        'services/EconNewsletterService',
        'services/NotificationService',
        'services/NavigationService'
    ],
    function (module) {
        'use strict';
        module.controller('EconNewsletterFormController', [
            '$scope',
            'EconNewsletterService',
            '$controller',
            'EconMailtemplateService',
            'EconRecipientListService',
            '$timeout',
            'NotificationService',
            'BASE_TEMPLATES_PATH',
            'NavigationService',
            EconNewsletterFormController
        ]);
    }
);

function EconNewsletterFormController($scope, EconNewsletterService, $controller, EconMailtemplateService, EconRecipientListService, $timeout, NotificationService, BASE_TEMPLATES_PATH, NavigationService) {
    $scope.initFinished = false;
    $scope.previewEmail = [];
    $scope.emailArray = [];
    $scope.sendNewsletter = false;
    $scope.selection = {};

    $scope.formTabs = [
        {
            label: 'Allgemein',
            template: BASE_TEMPLATES_PATH + '/econ/partials/_newsletter.html',
            active: true
        }
    ];

    $scope.validationConfig = {
        author: {
            method: "isset",
            fieldName: "Autor"
        },
        name: {
            method: "isset",
            fieldName: "Bezeichnung"
        },
        sender: {
            method: "isset",
            fieldName: "Absender E-Mail"
        },
        subject: {
            method: "isset",
            fieldName: "Betreff"
        }
    };

    $scope.hideSaveButtons = true;
    $scope.topBarTemplateLeft = BASE_TEMPLATES_PATH + '/beeWrapper/bee-form.html';
    $scope.listState = 'root.econNewsletters';
    $scope.recipientListHeadlineText = "Bitte wählen Sie die Empfängerlisten aus, die einen Newsletter erhalten sollen.";

    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

    $scope.$on("beforeUpdate", function () {
        $scope.beforeUpdateCreate();
    });

    $scope.$on("beforeCreate", function () {
        $scope.beforeUpdateCreate();
    });

    $scope.$on("afterUpdate", function () {
        $scope.afterUpdateCreate();
    });

    $scope.$on("afterCreate", function () {
        $scope.afterUpdateCreate();
    });

    $scope.$on("createError", function () {
        $scope.onError();
    });

    $scope.$on("updateError", function () {
        $scope.onError();
    });

    $scope.onError = function () {
        $scope.sendNewsletter = false;
    };

    $scope.afterUpdateCreate = function () {
        if (!$scope.sendNewsletter) {
            $scope.initData();
            return;
        }

        var diff = new Date() - $scope.sendNewsletter;
        diff = diff / 100;

        if (diff > 180) {
            //Too much diff between save and send process. Maybe there is something wrong.
            NotificationService.notifyGeneralError({
                statusText: 'Es ist ein Fehler beim Versand der Newsletter aufgetreten. Bitte versuchen Sie es nochmal.'
            });
            return;
        }

        if ($scope.sendNewsletter) {
            $scope.sendNewsletter = false;
            EconNewsletterService.send($scope.entity.id).then(function (newsletter) {
                $scope.entity = newsletter;
            });
        }

        $scope.initData();
    };

    $scope.beforeUpdateCreate = function () {
        $scope.entity.recipientLists = [];
        $scope.recipientLists.forEach(function (recipientList) {
            if (recipientList.selected) {
                delete recipientList.selected;
                delete recipientList.color;
                delete recipientList.createdAt;
                delete recipientList.name;
                delete recipientList.recipients;
                $scope.entity.recipientLists.push(recipientList);
            }
        });
    };

    $scope.duplicate = function () {
        EconNewsletterService.duplicate($scope.entity.id).then(function (response) {
            NavigationService.changeState("root.econNewsletter", response.id);
        })
    };

    $scope.initData = function () {
        EconRecipientListService.findAll(true).then(function () {
            $scope.recipientLists = EconRecipientListService.getAll();
            $scope.recipientLists.forEach(function (recipientList) {
                recipientList.selected = false;
            });
            if (!$scope.newEntity && $scope.entity.recipientLists) {
                $scope.entity.recipientLists.forEach(function (selectedRecipientList) {
                    $scope.recipientLists.forEach(function (recipientList) {
                        if (selectedRecipientList.id === recipientList.id) {
                            recipientList.selected = true;
                        }
                    });
                });
            }
        });

        EconMailtemplateService.findAll(true).then(function () {
            $scope.mailTemplates = EconMailtemplateService.getAll();
        });

        if(!$scope.newEntity){
            $scope.topBarTemplate = BASE_TEMPLATES_PATH + '/econ/partials/duplicate-button.html';
        }
    };


    $scope.init("Newsletter").then(function () {
        var emptyTemplate = function () {
            $scope.entity.jsonContent = JSON.stringify(EconMailtemplateService.getEmptyTemplate());
        };
        $scope.initFinished = true;
        if ($scope.entity.jsonContent == "" || $scope.entity.jsonContent == undefined) {
            $timeout(emptyTemplate, 0);
        }
        $scope.initData();
    });

    $scope.beeSave = function (changeSate) {
        $scope.$broadcast('beeSave', [changeSate]);
    };

    $scope.beeCreate = function (changeSate) {
        $scope.$broadcast('beeCreate', [changeSate]);
    };

    $scope.$on('saveBeeToDatabase', function(event, args) {
        $scope.update(args[0]);
    });

    $scope.$on('createBeeCallback', function(event, args) {
        $scope.create(args[0]);
    });

    $scope.saveAndSend = function () {
        $scope.sendNewsletter = new Date();
        if ($scope.newEntity) {
            $scope.beeCreate()
        } else {
            $scope.beeSave();
        }
    };

    $scope.applyTemplate = function () {
        var selectedTemplate;
        for (var i = 0; i < $scope.mailTemplates.length; i++) {
            var mailTemplate = $scope.mailTemplates[i];
            if ($scope.selection.templateId == mailTemplate.id) {
                selectedTemplate = mailTemplate;
                break;
            }
        }

        $scope.entity.mailTemplateContent = mailTemplate.jsonContent;
        $scope.entity.author = mailTemplate.author;
        $scope.entity.htmlContent = mailTemplate.htmlContent;
        $scope.entity.name = mailTemplate.name;
        $scope.entity.sender = mailTemplate.sender;
        $scope.entity.subject = mailTemplate.subject;
    };
}
;
