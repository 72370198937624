define('services/ManufacturerService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('ManufacturerService', [
        '$injector',
        ManufacturerService
    ]);
});


function ManufacturerService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Manufacturer');

    angular.extend(this, crudInstance);

    return this;
};
