define(
	'directives/components/common/restrict/Restrict',[
		'app',
		'settings'
	],
	function (app) {
		'use strict';
		app.directive("restrict", function () {

            var allowedControlKeyCombinationChars = ["v", "c", "V", "C"];
			var allowedSystemKeyCodes = [8, 9, 13, 16, 18, 20, 27, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46];

			return {
				scope: false,
				link: function (scope, element, attrs) {
					var restrict = attrs["restrict"];
                    if(restrict === undefined || restrict == null || restrict == '') {
                        return;
                    }

					element.on("keypress", function (event) {

						var code = event.keyCode ? event.keyCode : event.which;
						if (allowedSystemKeyCodes.indexOf(code) != -1) {
							return;
						}

						var character = String.fromCharCode(code);
						if (event.ctrlKey && allowedControlKeyCombinationChars.indexOf(character) != -1) {
							return;
						}

						var restrictExpression = new RegExp(restrict);

						if (!(character) || !(restrictExpression.test(character))) {
							event.preventDefault();
							event.stopImmediatePropagation();
						}
					});
				}
			};
		});
	}
);
