define(
    'modules/basemodule/controllers/EntityListController',[
        'modules/module',
        'services/EntityCrudService',
        'services/NotificationService',
        'services/NavigationService',
        'services/ShopService',
        'directives/entityTable/ngEntityTable'
    ],
    function (module) {
        'use strict';
        module.controller('EntityListController', [
            '$scope',
            '$rootScope',
            '$q',
            '$injector',
            'NotificationService',
            'NavigationService',
            'ShopService',
            ListController
        ]);
    }
);

function ListController($scope, $rootScope, $q, $injector, NotificationService, NavigationService, ShopService) {

    $scope.initialization = $q.defer();
    $scope.entityName = '';
    $scope.search = {};
    $scope.tableOptions = {};
    $scope.selected = {
        items: []
    };
    $scope.headline = NavigationService.getActiveSubitem().label;
    $scope.single = NavigationService.getActiveSubitem().single;

    $scope.state = {
        ids: []
    }

    $scope.clearIDs = () => {$scope.state.ids = []};

    $scope.initialization.promise.then(function () {
        $scope.$on('filtersChanges', function (e, filters, sorting) {
            var params = [];
            for (var obj in filters) {
                params.push({
                    name: 'filter[' + obj + ']',
                    value: filters[obj]
                });
            }
            params.push({
                name: 'filter[order]',
                value: sorting
            });
            $scope.entityService.setAdditionalParams(params);
            $scope.getEntities().then(function (response) {
                $scope.setTableData(response);
            });
        });
    });

    $scope.$on('$destroy', function () {
        //will be called on controller destroy
        $scope.unregisterShopChanged();
    });

    $scope.unregisterShopChanged = $rootScope.$on('ShopChanged', function () {
        $scope.init($scope.entityName);
        $scope.getEntities().then(function (response) {
            $scope.setTableData(response);
        });
    });

    $scope.init = function (entity) {
        if (typeof entity === 'string') {
            $scope.entityService = $injector.get('EntityCrudService');
            $scope.entityService.setType(entity);
        } else {
            console.error("Please init via model string and not via Service");
            $scope.entityService = entity;
        }

        $scope.getEntities = $scope.entityService.findAll;

        //reset query if there is one
        $scope.entityService.setQuery({}, false).then(function (response) {
            $scope.setTableData(response);
        });

        $scope.entityName = $scope.entityService.getType();

        if ($scope.entityService.getPageSize() == null) {
            $scope.pageSize = 10;
            $scope.setPageSize(false);
        }

        ShopService.findDefaultCurrencyId().then(function () {
            ShopService.findDefaultCurrency().then(function () {
                $scope.currency =  ShopService.getDefaultCurrency();
            });
        });

        if($scope.entityName == 'Order') {
            var params = [];
            params.push({
                name: 'filter[order]',
                value: 'orderDate DESC'
            });
            $scope.entityService.setAdditionalParams(params);
        }

        $scope.initialization.resolve();
        return $scope.initialization.promise;
    };

    $scope.setTableData = function (response) {
        $scope.pageSize = $scope.entityService.getPageSize();
        $scope.totalItems = $scope.entityService.getTotalItems();
        $scope.totalPages = $scope.entityService.getTotalPages();

        if ($scope.totalPages === 0) {
            //If there are no items the total pages size should be displayed as 1
            $scope.totalPages = 1;
        }

        $scope.currentPage = $scope.entityService.getCurrentPage();
        $scope.items = $scope.entityService.getAll();
        $scope.$broadcast("tableDataRefreshed");
    };

    $scope.getRealCurrentPage = function () {
        var cur = angular.copy(parseInt($scope.currentPage));
        cur = (cur === 0) ? cur : (cur - 1);
        return cur;
    };

    $scope.showCurrentlyDisplayed = function () {
        var from = parseInt($scope.pageSize) * parseInt($scope.getRealCurrentPage());
        from = from + 1;

        if ($scope.items === undefined || $scope.items.length === 0) {
            from = 0;
        }

        var to = ((from + parseInt($scope.pageSize)) < $scope.totalItems) ? (from + parseInt($scope.pageSize)-1) : $scope.totalItems;

        return (from) + ' - ' + to;
    };

    $scope.setPageSize = function (findAll) {
        $scope.entityService.setPageSize($scope.pageSize, findAll).then(function (response) {
            $scope.setTableData(response);
        });
    };

    $scope.setPage = function (currentPage) {
        if (currentPage) {
            $scope.currentPage = currentPage;
        }
        $scope.entityService.setCurrentPage($scope.getRealCurrentPage()).then(function (response) {
            $scope.setTableData(response);
        });
    };

    $scope.update = function (item) {
        $scope.$broadcast("beforeUpdate");
        NavigationService.changeState($scope.formState, item.id);
    };

    $scope.create = function () {
        $scope.$broadcast("beforeCreate");
        $scope.entityService.clear().then(function (response) {
            $scope.setTableData(response);
        });
        NavigationService.changeState($scope.formState);
    };

    $scope.delete = function () {
        
        if ($scope.selected.items <= 0) {
            return;
        }

        NotificationService.confirm({
            statusText: 'Wollen sie die selektierten Objekte wirklich löschen?',
            msg: ""/*msgs.join('; ')*/
        }).then(function () {
            if ($scope.selected.items.length == 1) {
                var deleted = [];
                $scope.selected.items.forEach(function (item) {
                    deleted.push($scope.entityService.delete(item.id));
                });
                $q.all(deleted).then(function () {
                    $scope.selected.items = [];
                    $scope.setTableData();
                });
            } else {
                $scope.selected.items.forEach(function (item) {
                    $scope.state.ids.push(item.id);
                });
                $scope.entityService.bulkDelete($scope.state.ids).then(function () {
                    $scope.selected.items = [];
                    $scope.setTableData();
                });
                $scope.clearIDs();
            }
            $rootScope.$emit("DeleteTableData");
            $scope.$emit("NotifyWaitingMessageCustomerGroup");
        }, function () {
        });
    };
}
;
