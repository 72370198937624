/**
 * Created by Nico on 04.08.2016.
 */
define('services/ImExTypeService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('ImExTypeService', [
        '$injector',
        ImExTypeService
    ]);
});


function ImExTypeService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('ImExType');

    angular.extend(this, crudInstance);

    return this;
};
