define(
    'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/card/card',[
        'app',
        'settings',
        'services/ContentBuilderHelper'
    ],
    function (app) {
        'use strict';
        app.directive('ngCard', ['BASE_DIRECTIVES_PATH',
            function (BASE_DIRECTIVES_PATH) {
                return {
                    scope: {
                        inputData: "<data",
                        stateEditing: "<"
                    },
                    templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/sectionSnippet/directives/card/views/index.html',
                    controller: ['$scope', '$timeout', '$element', '$compile', 'ContentBuilderHelper', function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sectionSnippet/directives/card/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customStyleFake = path + "styleFake.css";
                        // $scope.customJs = path + "js.html";
                        // $scope.includeVisibility = true;

                        // var wrapper = $($element).find('[data-content-block="partners"]');
                        // var template = '<div class="flexslider carousel"><ul class="partners-list slides"><li ng-repeat="partner in data.partners"><a ng-href="{{partner.url}}"><img ng-src="{{partner.image}}" alt="{{partner.title}}"></a></li></ul</div>';

                        function _init() {
                            $scope.data = angular.merge({}, $scope.inputData);
                            // ContentBuilderHelper.subscribeSizeChanged($scope, function (e, args) {
                            //     _refresh(); // refresh flexslider on fake viewport change
                            // });
                            // _refresh();
                        }

                        _init();

                        // function _refresh() {
                        //     // wrapper.find('.flexslider').remove();
                        //     // wrapper.append($compile(template)($scope));
                        //     $scope.includeVisibility = false;
                        //     $timeout(function () {
                        //         // $scope.$apply();
                        //         $scope.includeVisibility = true;
                        //     }, 100);
                        // }

                        $scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                // _refresh();
                            }
                        });
                    }]
                };
            }
        ]);
    }
);


/* sample data
{
    "paragraphs": [
        {
            "title": "1 Lorem Ipsum dolor sit",
            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam."
        },
        {
            "title": "2 Lorem Ipsum dolor sit",
            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam."
        },
        {
            "title": "3 Lorem Ipsum dolor sit",
            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam."
        }
    ],
    "partners": [
        {
            "url": "https://avago.de",
            "image": "/asset/images/content/partners/avago.png",
            "title": "Avago"
        },
        {
            "url": "https://digilent.de",
            "image": "/asset/images/content/partners/digilent.png",
            "title": "DIGILENT"
        },
        {
            "url": "https://texas-instruments.de",
            "image": "/asset/images/content/partners/texas_instruments.png",
            "title": "Texas Instruments"
        },
        {
            "url": "https://tyco.de",
            "image": "/asset/images/content/partners/tyco.png",
            "title": "TYCO"
        },
        {
            "url": "https://pseg.de",
            "image": "/asset/images/content/partners/pseg.png",
            "title": "PSEG"
        }
    ]
}
*/
;
