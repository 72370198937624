define('services/SystemInformationService',[
    'app',
    'modules/module'
], function (app, module) {
    'use strict';
    return module.factory('SystemInformationService', [
        '$injector',
        SystemInformationService
    ]);
});


function SystemInformationService($injector) {


    this.getBackendVersion = function () {
        var model = $injector.get("ModelFactory").SystemInformation();
        return model.findOne().$promise;
    };

    return this;
};
