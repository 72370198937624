/**
 * Created by Nico on 04.08.2016.
 */
define('services/ImExProfileService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('ImExProfileService', [
        '$injector',
        ImExProfileService
    ]);
});


function ImExProfileService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('ImExProfile');

    angular.extend(this, crudInstance);

    return this;
};
