define(
    'services/EntityCrudService',[
        'app',
        'modules/module',
        'services/LocalStorageService'
    ],
    function (app, module) {
        'use strict';
        return module.factory('EntityCrudService', [
            '$rootScope',
            '$injector',
            'LocalStorageService',
            'localStorageService',
            '$q',
            CrudService
        ]);
    }
);

function CrudService($rootScope, $injector, LocalStorageService, localStorageService, $q) {

    var _query = {},
        _currency = {},
        _currencyId,
        _entities = [],
        _entity,
        _totalPages,
        _totalItems,
        _entityName,
        _additionalParams = [],
        _specificParams = [],
        _model,
        _shopLocales;

    var _requestId = 0;
    var _requestIdDeactivated = false;

    var _clear = function () {
        _additionalParams = [];
        return _findAll();
    };

    $rootScope.$on('$stateChangeStart', function(event, toState, fromState) {
        _additionalParams = [];
    });

    var _findAllSuccess = function (response) {
        //See if it still works with everything else????
        if (response === undefined || response.data === undefined) {
            return;
        }

        _entities = response.data;
        _totalPages = response.pages;
        _totalItems = response.count;
        currentPage = response.page;

        LocalStorageService.write(_entityName + 'CurrentPage', currentPage);
        $rootScope.$broadcast(_entityName + 'Changes', _entities);
        return response;
    };

    var _findAllError = function (response) {
        _entities = [];
        _totalPages = 0;
        _totalItems = 0;
        $rootScope.$broadcast(_entityName + 'Error', response);
        return response;
    };

    var _findAll = function (deactivatePaging) {
        _entity = undefined;

        var pageSize = LocalStorageService.read(_entityName + 'PageSize');
        if (pageSize == null || pageSize == undefined) {
            LocalStorageService.write(_entityName + 'PageSize', 10);
        }

        var currentPage= LocalStorageService.read(_entityName + 'CurrentPage');
        if (currentPage == null || currentPage == undefined) {
            LocalStorageService.write(_entityName + 'CurrentPage', 0);
        }

        if(Object.keys(_query).length === 0) {
            var params = {
                page: LocalStorageService.read(_entityName + 'CurrentPage'),
                size: LocalStorageService.read(_entityName + 'PageSize')
            };
        } else {
            var params = {
                page: LocalStorageService.read(_entityName + 'CurrentPage'),
                size: LocalStorageService.read(_entityName + 'PageSize')
            };

            for(var key in _query.filters){
                params['filter[' + key + ']'] = _query.filters[key];
            }
        }

        if (deactivatePaging) {
            params = {page: 0, size: 10000000};
        }

        //adding additional parameters to the call
        _additionalParams.forEach(function (param) {
            params[param.name] = param.value;
        });
        //adding specific parameters to the call
        _specificParams.forEach(function (param) {
            params[param.name] = param.value;
        });

        //_additionalParams = [];


        _requestId++;
        var snap = _requestId;

        params.shopId = localStorageService.get('_shop');

        var ref = $q.defer();
        _model.findAll(params).$promise
            .then(
                function (response) {
                    if (_requestIdDeactivated === true) {
                        return response;
                    }

                    if (snap === _requestId) {
                        return response;
                    }
                },
                function (response) {
                    if (_requestIdDeactivated === true) {
                        return response;
                    }

                    if (snap === _requestId) {
                        return response;
                    }
                }
            ).then(function (response) {
                _findAllSuccess(response);
                ref.resolve(response);
            }, function (response) {
                _findAllError(response);
                ref.reject(response);
            }
        );
        return ref.promise;
    };

    var _findShopLocales = function () {
        _shopLocales = undefined;

        var currentShop = localStorageService.get('_shop');
        if(currentShop == undefined || currentShop == null){
            currentShop = -1;
        }

        var params = {
            id: currentShop
        };
        return _model.shopLocales(params).$promise.then(_findShopLocalesSuccess, _findShopLocalesError);
    };

    var _findDefaultCurrencyId = function () {
        var currentShop = localStorageService.get('_shop');
        if(currentShop == undefined || currentShop == null){
            currentShop = -1;
        }

        var params = {
            id: currentShop
        };
        return $injector.get("ModelFactory")['Shop']().findOne(params).$promise.then(function (response) {
            _currencyId = response.defaultCurrencyId;

        }, _findDefaultCurrencyError);
    };

    var _findDefaultCurrency = function() {
        var params = {
            id: _currencyId
        };
        return $injector.get("ModelFactory")['Currency']().findOne(params).$promise.then(function (response) {
            _currency = response;
        }, _findDefaultCurrencyError);
    };

    var _findDefaultCurrencyError = function (response) {
        $rootScope.$broadcast(_entityName + 'Error', response);
        return response;
    };

    var _findShopLocalesSuccess = function (response) {
        _shopLocales = response;
        return response;
    };

    var _findShopLocalesError = function (response) {
        $rootScope.$broadcast(_entityName + 'Error', response);
        return response;
    };

    return {
        clear: _clear,
        abandonRequests: function() {
            _requestId = -1;
            },
        getModel: function () {
            return _model;
        },
        setType: function (type) {
            _entityName = type;
            _model = new $injector.get("ModelFactory")[type]();
            return this;
        },
        getType: function () {
            return _entityName;
        },
        getCurrentPage: function () {
            if (LocalStorageService.read(_entityName + 'CurrentPage') === null) {
                LocalStorageService.write(_entityName + 'CurrentPage', 0);
            }
            return (parseInt(LocalStorageService.read(_entityName + 'CurrentPage')) + 1);
        },
        getTotalPages: function () {
            return _totalPages;
        },
        getTotalItems: function () {
            return _totalItems;
        },
        getPageSize: function () {
            var pageSize = LocalStorageService.read(_entityName + 'PageSize');
            if (pageSize == undefined) {
                pageSize = 10;
            }
            return pageSize;
        },
        setPageSize: function (pageSize, findAll) {
            LocalStorageService.write(_entityName + 'PageSize', pageSize);

            if (findAll !== undefined && findAll === false) {
                return;
            }

            return _findAll();
        },
        setCurrentPage: function (currentPage, findAll) {
            LocalStorageService.write(_entityName + 'CurrentPage', currentPage);
            if (findAll !== undefined && findAll === false) {
                return;
            }
            return _findAll();
        },
        setAdditionalParams: function (params) {
            _additionalParams = params;
        },
        setViewSpecificParams: function (params) {
            _specificParams = params;
        },
        getAdditionalParams: function() {
            return _additionalParams;
        },
        setQuery: function (query, findAll) {
            _query = query;

            if (findAll !== undefined && findAll === false) {
                return {
                    then: function () {
                        //dummy
                    }
                };
            }

            return _findAll();
        },
        getAll: function () {
            return _entities;
        },
        getOne: function () {
            return _entity;
        },
        getShopLocales: function () {
            return _shopLocales;
        },
        findShopLocales: function () {
            return _findShopLocales();
        },
        findAll: function (deactivatePaging) {
            return _findAll(deactivatePaging);
        },
        findAllSuccess: function (response) {
            return _findAllSuccess(response);
        },
        findAllError: function (response) {
            return _findAllError(response);
        },
        findOne: function (id) {
            return _model.findOne({id: id}).$promise.then(function (response) {
                _entity = response;
            });
        },
        findProduct: function (id, type, page, size) {
            if(page == null) {
                page = 0;
            }
            if(size == null) {
                size = 10;
            }
            var params = {
                id: id,
                page: page,
                size: size
            };
            return _model.findOne(params, {type: type}).$promise.then(function (response) {
                _entity = response;
            });
        },
        findDefaultCurrencyId: function () {
            return _findDefaultCurrencyId();
        },
        findDefaultCurrency: function () {
            return _findDefaultCurrency();
        },
        getDefaultCurrency: function () {
            return _currency;
        },
        create: function (entity) {
            var promise = _model.create(entity).$promise;
            promise.then(_clear);
            return promise;
        },
        update: function (entity) {
            var promise = _model.update(entity).$promise;
            promise.then(_clear);
            return promise;
        },
        delete: function (id) {
            var promise = _model.delete({id: id}).$promise;
            return promise.then(_clear);
        },
        bulkDelete: function (ids) {
            var promise = _model.bulkDelete({ids: ids}).$promise;
            return promise.then(_clear);
        },
        removePagingData: function () {
            LocalStorageService.remove(_entityName + 'PageSize');
            LocalStorageService.remove(_entityName + 'CurrentPage');
        },
        deactivateRequestId: function () {
            _requestIdDeactivated = true;
        }
    };
}
;
