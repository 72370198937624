define('filters/productTypeFilter',[
	'app'
	],	
	function (app){
		'use strict';
		app.filter('productTypeFilter', function(){
			return function(item){
				if(item === 'ATOMIC'){
				    return "Standard Produkt";
				}
				if(item === 'BUNDLE'){
                    return "Bundle Produkt";
                }
                if(item === 'CONFIGURABLE'){
                    return "Konfigurierbares Produkt";
                }

				return item;
			}
		});
	}
);
