define(
    'directives/contentConfigurator/contentSnippets/sliderSnippet/SliderSnippet',[
        'app',
        'angular',
        'directives/productSlider/ProductSlider',
        'services/ContentBuilderHelper',
        'directives/contentConfigurator/contentSnippets/_snippet'
    ],
    function (app) {
        'use strict';
        app.directive('sliderSnippet', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'EA',
                scope: {
                    metaIdentifier: '<',
                    elementIdentifier: '<',
                    snippet: "=",
                    position: "=",
                    parent: "<"
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/sliderSnippet/views/sliderSnippet.html",
                controller: ['$scope', '$controller', '$rootScope', '$timeout', 'ContentBuilderHelper', 'CategoryService',
                    function ($scope, $controller, $rootScope, $timeout, ContentBuilderHelper, CategoryService) {

                        angular.extend(this, $controller('snippetController', {
                            $scope: $scope
                        }));

                        $scope.edit = true;
                        $scope.final = false;
                        $scope.selected = {};
                        $scope.config = {};

                        var _init = function () {
                            $scope.snippetType = 'product_slider';
                            $scope.elementType = $scope.ELEMENT_TYPE.SNIPPET;

                            $scope.categories = [];
                            CategoryService.getCategories("PRODUCT", "ACTIVE").then( function (response) {
                                $scope.categories = response.data;
                            });

                            ContentBuilderHelper.subscribeEditModeToggled($scope, function(e, args) {
                                if (args.identifier === $scope.metaIdentifier || args.identifier === "GLOBAL") {
                                    $scope.edit = args.edit;
                                    $scope.final = args.preSave || false;
                                }
                            });
                        };
                        _init();

                        $rootScope.$on('sliderSnippetConfigChange', function (event, args) {
                            if (args.id === $scope.snippet.identifier) {
                                $scope.config = args;
                            }
                        });

                    }]
            }
        }]);
    }
);

