define('modules/econ/controllers/EconRecipientController',[
        'modules/module',
        'settings',
        'modules/basemodule/controllers/EntityListController',
        'services/EconRecipientService'
    ],
    function (module) {
        'use strict';
        module.controller('EconRecipientController', [
            '$scope',
            '$controller',
            'NotificationService',
            'EconRecipientService',
            'BASE_TEMPLATES_PATH',
            RecipientController
        ]);
    }
);

function RecipientController($scope, $controller, NotificationService, EconRecipientService, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('EntityListController', {
        $scope: $scope
    }));

    $scope.tableOptions.fields = [
        {
            id: 'LAST_NAME',
            heading: 'Name',
            attribute: 'lastName'
        },
        {
            id: 'FIRST_NAME',
            heading: 'Vorname',
            attribute: 'firstName'
        },
        {
            id: 'EMAIL',
            heading: 'E-Mail',
            attribute: 'email'
        },
        {
            id: 'RECIPIENT_LISTS',
            heading: 'Empfängerlisten',
            template: BASE_TEMPLATES_PATH + '/econ/partials/_recipient-recipient-list.html',
            searchDisabled: true
        },
        {
            id: 'STATE',
            heading: 'Status (1, 2, 3 oder 4)',
            template: BASE_TEMPLATES_PATH + '/econ/partials/_status-col.html',
            attribute: 'status',
            inputType: 'number'
        }

    ];

    $scope.formState = 'root.econRecipient';

    $scope.topBarTemplate = BASE_TEMPLATES_PATH + '/econ/partials/resend-opt-in.html';

    $scope.resendOptIn = function () {

        if ($scope.selected.items <= 0) {
            NotificationService.notifyGeneralError({
                statusText: 'Bitte wählen Sie ein oder mehrere Empfänger aus.'
            });
            return;
        }

        for (var i = 0; i < $scope.selected.items.length; i++) {
            var recipient = $scope.selected.items[i];
            if (recipient.recipientLists.length == 0) {
                NotificationService.notifyGeneralError({
                    statusText: 'Der Empfänger ' + recipient.email + ' wurde keiner Empfängerliste zugeordnet.'
                });
                return;
            }
        }

        NotificationService.confirm({
            statusText: 'Wollen sie an die selektierten Empfänger wirklich erneut einen Opt-in versenden?',
            msg: ""
        }).then(function () {
            var ids = [];
            $scope.selected.items.forEach(function (item) {
                ids.push(item.id);
            });
            EconRecipientService.sendBulkOptIns(ids);
            $scope.selected.items = [];
        }, function () {
        });
    };

    $scope.init('Recipient').then(function () {
    });
};
