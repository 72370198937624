/**
 * Created by Mujtaba on 18.04.2017.
 */
define('services/PriceRuleService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('PriceRuleService', [
        '$injector',
        PriceRuleService
    ]);
});


function PriceRuleService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('PriceRule');

    angular.extend(this, crudInstance);

    return this;
};
