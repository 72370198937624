define('settings',[
    'app'
], function (app) {
    'use strict';
    return app.constant('BASE_DIRECTIVES_PATH', '/app/directives')
        .constant('BASE_MODULES_PATH', '/app/modules')
        .constant('BASE_TEMPLATES_PATH', '/template/')
        .constant('EU_COUNTRIES', ["AUT", "BEL", "BGR", "HRV", "CYP", "CZE", "DNK", "EST", "FIN", "FRA", "DEU", "GRC", "HUN", "IRL", "ITA", "LVA", "LTU", "LUX", "MLT", "NLD", "POL", "PRT", "ROU", "SVK", "SVN", "ESP", "SWE", "GBR"])
        .constant('MENU_LEFT', [
            {
                label: 'Dashboard',
                icon: 'fa-bar-chart-o',
                subMenu: [
                    {
                        label: 'Dashboard',
                        icon: 'fa-tachometer',
                        states: ['root.home'],
                        single: 'Dashboard'
                    },
                    {
                        label: 'Reports',
                        icon: 'fa-table',
                        states: ['root.reports', 'root.seminarDetailReport'],
                        single: 'Report'
                    }
                ]
            },
            {
                label: 'WaWi',
                icon: 'fa-shopping-cart',
                subMenu: [
                    {
                        label: 'Bestellungen',
                        icon: 'fa-file-text',
                        states: ['root.ordermanagements', 'root.ordermanagement', 'root.ordermanagementsetting'],
                        single: 'Bestellung'
                    },
                    {
                        label: 'Produkte',
                        icon: 'fa-cubes',
                        states: ['root.products', 'root.productAtomic', 'root.productBundle', 'root.productConfig'],
                        single: 'Produkt'
                    },
                    {
                        label: 'Veredelungsprodukte',
                        icon: 'fa-sticky-note',
                        states: ['root.refinementProducts', 'root.refinementProduct'],
                        single: 'Veredelungsprodukt'
                    },
                    {
                        label: 'Seminare',
                        icon: 'fa-users',
                        states: ['root.seminars', 'root.seminar'],
                        single: 'Seminar'
                    },
                    {
                        label: 'Kategorien',
                        icon: 'fa-list',
                        states: ['root.categories', 'root.category'],
                        single: 'Kategorie'
                    },
                    {
                        label: 'Tags',
                        icon: 'fa-tag',
                        states: ['root.tags', 'root.tag'],
                        single: 'Tag'
                    },
                    {
                        label: 'Attribute',
                        icon: 'fa-sitemap fa-rotate-90',
                        states: ['root.attributes', 'root.attribute'],
                        single: 'Attribut'
                    }
                ]
            },
            {
                label: 'Crm',
                icon: 'fa-user',
                subMenu: [
                    {
                        label: 'Personen',
                        icon: 'fa-users',
                        states: ['root.customers', 'root.customer'],
                        single: 'Person'
                    },
                    {
                        label: 'Organisationen',
                        icon: 'fa-industry',
                        states: ['root.organisations', 'root.organisation'],
                        single: 'Organisation'
                    },
                    {
                        label: 'Kundengruppen',
                        icon: 'fa-users',
                        states: ['root.customergroups', 'root.customergroup'],
                        single: 'Kundengruppe'
                    },
                    {
                        label: 'Tickets',
                        icon: 'fa-ticket',
                        states: ['root.tickets', 'root.ticket'],
                        single: 'Ticket'
                    }
                ]
            },
            {
                label: 'Cms',
                icon: 'fa-pencil',
                subMenu: [
                    {
                        label: 'Templates',
                        icon: 'fa-window-maximize',
                        states: ['root.templatePages', 'root.templatePage'],
                        single: 'Template'
                    },
                    {
                        label: 'Inhaltsseite',
                        icon: 'fa-address-card-o',
                        states: ['root.contentPages', 'root.contentPage'],
                        single: 'Filterseite'
                    },
                    {
                        label: 'Filterseiten',
                        icon: 'fa-filter',
                        states: ['root.filterPages', 'root.filterPage'],
                        single: 'Filterseite'
                    },
                    {
                        label: 'Glossarseite',
                        icon: 'fa-address-card-o',
                        states: ['root.glossaryPages', 'root.glossaryPage'],
                        single: 'Filterseite'
                    },
                    {
                        label: 'Landingpages',
                        icon: 'fa-address-card-o',
                        states: ['root.landingPages', 'root.landingPage'],
                        single: 'Landingpage'
                    },
                    {
                        label: 'Tags',
                        icon: 'fa-tag',
                        states: ['root.cmstags', 'root.cmstag'],
                        single: 'Tag'
                    },
                    {
                        label: 'Ratings',
                        icon: 'fa-star',
                        states: ['root.productRatings', 'root.productRating'],
                        single: 'Ratings'
                    },
                    {
                        label: 'Faq',
                        icon: 'fa-star',
                        states: ['root.productFaqs', 'root.productFaq'],
                        single: 'Faq'
                    }
                ]
            },
            {
                label: 'ECON',
                icon: 'fa-envelope',
                subMenu: [
                    {
                        label: 'Dashboard',
                        icon: 'fa-tachometer',
                        states: ['root.econDashboard'],
                        single: 'Dashboard'
                    },
                    {
                        label: 'Empfänger',
                        icon: 'fa-user',
                        states: ['root.econRecipients', 'root.econRecipient'],
                        single: 'Empfänger'
                    },
                    {
                        label: 'Empfängerlisten',
                        icon: 'fa-users',
                        states: ['root.econRecipientLists', 'root.econRecipientList'],
                        single: 'Empfängerliste'
                    },
                    {
                        label: 'Vorlagen',
                        icon: 'fa-file-code-o',
                        states: ['root.econMailtemplates', 'root.econMailtemplate'],
                        single: 'Newslettervorlage'
                    },
                    {
                        label: 'Newsletter',
                        icon: 'fa-file-text',
                        states: ['root.econNewsletters', 'root.econNewsletter'],
                        single: 'Newsletter'
                    },
                    {
                        label: 'Automatisierungen',
                        icon: 'fa-magic',
                        states: ['root.econAutomations', 'root.econAutomation'],
                        single: 'Automatisierung'
                    },
                    {
                        label: 'Kampagnen',
                        icon: 'fa-caret-square-o-right',
                        states: ['root.econCampaigns', 'root.econCampaign'],
                        single: 'Kampagne'
                    }
                ]
            }
        ])
        .constant('MENU_RIGHT', [
            {
                label: 'Shopauswahl',
                icon: 'fa-home'
            },
            {
                label: 'Einstellungen',
                icon: 'fa-gears',
                subMenu: [
                    {
                        label: 'Shops',
                        icon: 'fa-shopping-cart',
                        states: ['root.shops', 'root.shop'],
                        single: 'Shop'
                    },
                    {
                        label: 'Benutzer',
                        icon: 'fa-user',
                        states: ['root.users', 'root.user'],
                        single: 'Benutzer'
                    },
                    {
                        label: 'Steuersätze',
                        icon: 'fa-university',
                        states: ['root.taxes', 'root.tax'],
                        single: 'Steuersatz'
                    },
                    {
                        label: 'Import & Export',
                        icon: 'fa-arrow-circle-up',
                        states: ['root.importExportCSV'],
                        single: 'Import & Export'
                    },
                    {
                        label: 'Data Feeds',
                        icon: 'fa-database',
                        states: ['root.dataFeedInputs'],
                        single: 'Data Feed'
                    },
                    {
                        label: 'Einheiten',
                        icon: 'fa-arrows-h',
                        states: ['root.units', 'root.unit'],
                        single: 'Einheit'
                    },
                    {
                        label: 'Währungen',
                        icon: 'fa-money',
                        states: ['root.currencies', 'root.currency'],
                        single: 'Währung'
                    },
                    {
                        label: 'Bankkonten',
                        icon: 'fa-usd',
                        states: ['root.bankAccounts', 'root.bankAccount'],
                        single: 'Bankkonto'
                    },
                    {
                        label: 'Branchen',
                        icon: 'fa-industry',
                        states: ['root.branches', 'root.branch'],
                        single: 'Branche'
                    },
                    {
                        label: 'Systemmeldungen',
                        icon: 'fa-envelope-o',
                        states: ['root.systemMessages', 'root.systemMessage'],
                        single: 'Systemmeldung'
                    },
                    {
                        label: 'Klassifizierungen',
                        icon: 'fa-thumb-tack',
                        states: ['root.classifications', 'root.classification'],
                        single: 'Klassifizierung'
                    },
                    {
                        label: 'eConnect',
                        icon: 'fa-envelope',
                        states: ['root.econSettings', 'root.econSettings'],
                        single: 'eConnect'
                    },
                    {
                        label: 'Produktmerkmalsets',
                        icon: 'fa-list',
                        states: ['root.metadatasets', 'root.metadataset'],
                        single: 'Produktmerkmalset'
                    },
                    {
                        label: 'Produktmerkmale',
                        icon: 'fa-info',
                        states: ['root.metadatas', 'root.metadata'],
                        single: 'Produktmerkmal'
                    },
                    {
                        label: 'Preisregeln',
                        icon: 'fa-cart-arrow-down',
                        states: ['root.pricerules', 'root.pricerule'],
                        single: 'Preisregel'
                    },
                    {
                        label: 'Rabattcodes',
                        icon: 'fa-money',
                        states: ['root.couponcodes', 'root.couponcode'],
                        single: 'Rabattcode'
                    },
                    {
                        label: 'Logs',
                        icon: 'fa-info',
                        states: ['root.logs'],
                        single: 'Log'
                    },
                    {
                        label: 'Systeminformationen',
                        icon: 'fa-laptop',
                        states: ['root.systemInformation'],
                        single: 'Systeminformationen'
                    },
                    {
                        label: 'Cache',
                        icon: 'fa-eraser',
                        states: ['root.cache'],
                        single: 'Cache'
                    },
                    {
                        label: 'Backup',
                        icon: 'fas fa-copy',
                        states: ['root.backup'],
                        single: 'Backup'
                    }
                

                ]
            },
            {
                label: 'Log Out',

                icon: 'fa-sign-out',
                states: ['root.login']
            }
        ])
        .constant('PRODUCT_FILTER_OPTIONS', [
            {
                'id': 'productNumber',
                'name': "Artikelnummer",
                'types': [{
                    'id': 'equals',
                    'type': "ist gleich"
                }, {
                    'id': 'contains',
                    'type': "enthält"
                }]
            },
            {
                'id': 'productId',
                'name': "Produkt Id",
                'types': [{
                    'id': '<=',
                    'type': "<="
                }, {
                    'id': '>=',
                    'type': ">="
                },
                    {
                        'id': '=',
                        'type': "="
                    }
                ]
            },
            {
                'id': 'productTitle',
                'name': "Artikelbezeichnung",
                'types': [{
                    'id': 'contains',
                    'type': "enthält"
                },
                    {
                        'id': 'equals',
                        'type': "ist gleich"
                    },
                    {
                        'id': 'startsWith',
                        'type': "beginnt mit"
                    },
                    {
                        'id': 'endsWith',
                        'type': "endet mit"
                    }
                ]
            },
            {
                'id': 'productPrice',
                'name': "Artikelpreis",
                'types': [{
                    'id': '<=',
                    'type': "<="
                }, {
                    'id': '>=',
                    'type': ">="
                },
                    {
                        'id': '=',
                        'type': "="
                    }
                ]
            },
            {
                'id': 'productMetaTag',
                'name': "Artikelmerkmal",
                'types': [{
                    'id': 'contains',
                    'type': "enthält"
                }, {
                    'id': 'notContains',
                    'type': "enthält nicht"
                }]
            },
            {
                'id': 'productAttribute',
                'name': "Artikelattribut",
                'types': [{
                    'id': 'contains',
                    'type': "enthält"
                }, {
                    'id': 'notContains',
                    'type': "enthält nicht"
                }]
            },
            {
                'id': 'productCategory',
                'name': "Kategorie",
                'types': [{
                    'id': 'equals',
                    'type': "ist gleich"
                }]
            }

        ])
        .constant('PRIMARKETS_EVENTS', [
            {
                type: "SYSTEM_EVENT",
                title: "User Password Reset Before",
                key: "USER_PASSWORD_RESET_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Password Reset Before",
                key: "PASSWORD_RESET_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Password Reset After",
                key: "PASSWORD_RESET_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Reset Email Before",
                key: "EMAIL_RESET_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Reset Email After",
                key: "EMAIL_RESET_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Email Activation Before",
                key: "EMAIL_ACTIVATION_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Email Activation After",
                key: "EMAIL_ACTIVATION_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Send Ticket to Customer Before",
                key: "TICKET_TO_CUSTOMER_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Send Ticket to Customer After",
                key: "TICKET_TO_CUSTOMER_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Send Ticket to Admin Before",
                key: "TICKET_TO_ADMIN_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Send Ticket to Admin After",
                key: "TICKET_TO_ADMIN_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Organisation Invitation Before",
                key: "ORGANISATION_INVITATION_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Organisation Invitation After",
                key: "ORGANISATION_INVITATION_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Account Activation Before",
                key: "ACCOUNT_ACTIVATION_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Account Activation After",
                key: "ACCOUNT_ACTIVATION_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Pending Account Activation Before",
                key: "PENDING_ACCOUNT_ACTIVATION_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Pending Account Activation After",
                key: "PENDING_ACCOUNT_ACTIVATION_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Drop Shipping Organisation Before",
                key: "DROP_SHIPPING_ORGANISATION_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Drop Shipping Organisation AFTER",
                key: "DROP_SHIPPING_ORGANISATION_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Document Upload Notification to User Before",
                key: "DOCUMENT_NOTIFICATION_USER_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Document Upload Notification to User After",
                key: "DOCUMENT_NOTIFICATION_USER_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Document Upload Notification to Admin Before",
                key: "DOCUMENT_NOTIFICATION_ADMIN_BEFORE"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Document Upload Notification to Admin After",
                key: "DOCUMENT_NOTIFICATION_ADMIN_AFTER"
            },
            {
                type: "SYSTEM_EVENT",
                title: "Shop order cancelled after",
                key: "SHOP_ORDER_CANCELED_AFTER"
            }
        ]);
});

