define('modules/crm/controllers/SelectCustomerController',[
        'modules/module',
        'modules/basemodule/controllers/EntityListController',
        'modules/crm/customer/controllers/CustomerController',
        'settings'
    ],
    function (module) {
        'use strict';
        module.controller('SelectCustomerController', [
            '$scope',
            '$controller',
            'BASE_TEMPLATES_PATH',
            SelectCustomerController
        ]);
    }
);


function SelectCustomerController ($scope, $controller, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('CustomerController', {
        $scope: $scope
    }));

    $scope.customer;

    $scope.tableOptions.fields = [
        {
            id: "COMPANY",
            heading: 'Firma',
            attribute: 'companyName'
        },
        {
            id: "FIRSTNAME",
            heading: 'Vorname',
            attribute: 'firstName'
        },
        {
            id: "LASTNAME",
            heading: 'Nachname',
            attribute: 'lastName'
        },
        {
            id: "ZIPCODE",
            heading: 'PLZ',
            template:  BASE_TEMPLATES_PATH + '/crm/customer/customer-zipcode-col.html',
            attribute: 'zipCode'
        },
        {
            id: "CITY",
            heading: 'Stadt',
            template:  BASE_TEMPLATES_PATH + '/crm/customer/customer-city-col.html',
            attribute: 'city'
        }
    ];

    $scope.backToSelection = function () {
        $scope.customer = undefined;
    };

    $scope.formState = "root.customer";

    $scope.init('Customer').then(function(){
    });
}
;
