define('directives/components/common/filters/Comma2DecimalFilter',[
        'app'
    ],
    function (app){
        'use strict';
        app.filter('comma2decimal', function() {
            return function (input) {
                var ret = (input) ? input.toString().trim().replace(",", ".") : null;
                return parseFloat(ret);
            };
        });
    }
);
