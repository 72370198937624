define('modules/wawi/category/controllers/CategoryFormController',[
    'modules/module',
    'settings',
    'modules/basemodule/controllers/EntityFormController',
    'services/CategoryService',
    'directives/inputSelect/inputSelect',
    'directives/imageUpload/imageUpload',
    'directives/imageList/imageList',
    'services/NavigationService',
    'services/ImageService',
    'services/ValidationService',
    'services/EconMailtemplateService'
],
    function (module) {
        'use strict';
        module.controller('CategoryFormController', [
            '$scope',
            '$timeout',
            '$controller',
            '$filter',
            'CategoryService',
            'NavigationService',
            '$rootScope',
            'BASE_TEMPLATES_PATH',
            'LocaleService',
            'PublishStateService',
            'ImageService',
            'ValidationService',
            'EconMailtemplateService',
            CategoryFormController
        ]);
    }
);

function CategoryFormController($scope, $timeout, $controller, $filter, CategoryService, NavigationService, $rootScope, BASE_TEMPLATES_PATH, LocaleService, PublishStateService, ImageService,
                                ValidationService, EconMailtemplateService) {

    $scope.formTabs = [
        {
            label: 'Allgemein',
            template: BASE_TEMPLATES_PATH + '/wawi/category/form-main.html',
            active: true
        },
        {
            label: 'Beschreibung',
            template: BASE_TEMPLATES_PATH + '/wawi/category/form-description.html'
        },
        {
            label: 'Media',
            template: BASE_TEMPLATES_PATH + '/wawi/category/form-images.html'
        }
    ];

    $scope.types = [{
        name: "PRODUCT",
        description: "Produkt"
    },{
        name: "REFINEMENT",
        description: "Veredelungsprodukt"
    }];

    $scope.listState = "root.categories";
    $scope.image = {};

/*    $scope.hideSaveButtons = true;
    $scope.topBarTemplateLeft = BASE_TEMPLATES_PATH + '/beeWrapper/bee-form.html';*/

    angular.extend(this, $controller('EntityFormController', {
        $scope: $scope
    }));

    $scope.sortTypes = [
        {
            name: 'Manuelle Sortierung',
            value: 'sortOrder ASC',
            available: true
        },
        {
            name: 'Name aufsteigend',
            value: 'name.raw ASC',
            available: true
        },
        {   name: 'Name absteigend',
            value: 'name.raw DESC',
            available: true
        },
        {   name: 'Preis aufsteigend',
            value: 'sellingPrice ASC',
            available: true
        },
        {   name: 'Preis absteigend',
            value: 'sellingPrice DESC',
            available: true
        },
        {   name: 'Id aufsteigend',
            value: 'productId ASC',
            available: true

        },
        {   name: 'Id absteigend',
            value: 'productId DESC',
            available: true
        }
    ];

    $scope.oldSelectedSortType = [];

  //  $scope.newSelectedSortType = [];

    $scope.updateSortType = function(index, newVal) {
        for(var j = 0; j < $scope.sortTypes.length; j++) {
            if($scope.sortTypes[j].value === newVal) {
                $scope.oldSelectedSortType[index] = angular.copy($scope.sortTypes[j]);
                //$scope.sortTypes.splice(j,1);
            }
        }
        // remove selected options
        //     $scope.remove(newVal);


    };

    $scope.removeSortOrder = function(index){
        $scope.oldSelectedSortType.splice(index,1);
    };

    $scope.addSortOrder = function() {
        $scope.oldSelectedSortType.push({});
    };


    /*   $scope.remove = function(item) {
     var index = $scope.sortTypes.indexOf(item);
     $scope.sortTypes.splice(index, 1);
     }
     */
    $scope.init('Category').then(function () {

        CategoryService.findAll().then(function () {
            var categoriesTmp = CategoryService.getAll();
            $scope.categories = [];
            $scope.categories.push({
                display: "Keine Elternkategorie",
                value: null
            });
            categoriesTmp.forEach(function (item) {
                if (item.id != $scope.entity.id) {
                    $scope.categories.push({
                        display: item.name,
                        value: item.id
                    });
                }
            });

            for(var i = 0; i < $scope.entity.categorySortOrder.length; i++){
                for(var j = 0; j < $scope.sortTypes.length; j++){
                    if($scope.entity.categorySortOrder[i].sortType == $scope.sortTypes[j].value){
                        $scope.sortTypes[j].available = false;
                        $scope.oldSelectedSortType.push(angular.copy($scope.sortTypes[j]));
                      //  $scope.newSelectedSortType.push(angular.copy($scope.sortTypes[j]));

                    }
                }
                /*var obj = {};
                 obj.name = "";
                 //console.log($scope.entity.categorySortOrder[i].sortType);

                 obj.value =  $scope.entity.categorySortOrder[i].sortType;*/
            }
            console.log($scope.oldSelectedSortType);
            //console.log($scope.newSelectedSortType)


        });

        var emptyTemplate = function (obj) {
            obj = JSON.stringify(EconMailtemplateService.getEmptyTemplate());
        };

        if($scope.entity.locales == undefined) {
            $scope.entity.locales = [];
        }


        for(var i=0; i < $scope.entity.locales.length; i++) {
            if ($scope.entity.locales[i].categoryTopDescriptionJson == "" || $scope.entity.locales[i].categoryTopDescriptionJson == undefined) {
                //$timeout(emptyTemplate($scope.entity.locales[i].categoryTopDescriptionJson), 0);
                $scope.entity.locales[i].beeTop = {
                    jsonContent: JSON.stringify(EconMailtemplateService.getEmptyTemplate()),
                    content: ""
                };
            } else {
                console.log("Hallo");
                $scope.entity.locales[i].beeTop = {
                    jsonContent: $scope.entity.locales[i].categoryTopDescriptionJson,
                    content: $scope.entity.locales[i].topDescription
                };
            }

            if ($scope.entity.locales[i].categoryFooterDescriptionJson == "" || $scope.entity.locales[i].categoryFooterDescriptionJson == undefined) {
                //$timeout(emptyTemplate($scope.entity.locales[i].categoryFooterDescriptionJson), 0);
                $scope.entity.locales[i].beeFooter = {
                    jsonContent: JSON.stringify(EconMailtemplateService.getEmptyTemplate()),
                    content: ""
                };
            } else {
                console.log("Hallo2");
                $scope.entity.locales[i].beeFooter = {
                    jsonContent: $scope.entity.locales[i].categoryFooterDescriptionJson,
                    content: $scope.entity.locales[i].footerDescription
                };
            }
        }


        PublishStateService.findAll().then(function () {
            $scope.publishStates = PublishStateService.getAll(true);
        });


        if ($scope.entity.imageId != undefined && $scope.entity.imageId != null) {
            ImageService.loadImage($scope.entity.imageId).then(function () {
                $scope.image = ImageService.getImage();
            });
        }

        if($scope.entity.showInNavigation === undefined) {
            $scope.entity.showInNavigation = false;
        }
    });

    $scope.onDrop = function ($event, $data, $y) {
        $scope.image = $data;
        $scope.entity.imageId = $data.id;
    };

    $scope.removeImage = function () {
        $scope.image = undefined;
        $scope.entity.imageId = null;
    };

   /* $scope.beeSave = function(changeState) {
        $scope.$broadcast('beeSave', [changeState]);
    };

    $scope.beeCreate = function(changeState) {
        $scope.$broadcast('beeCreate', [changeState]);
    };

    $scope.$on('saveBeeToDatabase', function(event, args) {
        $scope.update(args[0]);
    });

    $scope.$on('createBeeCallback', function(event, args) {
        $scope.create(args[0]);
    });

    $scope.$on('autosaveBee', function(event, args) {
        if($scope.newEntity)
            $scope.create(args[0], true);
        else {
            $scope.update(args[0], true);
        }
    });*/

    $scope.validate = function () {
        var checkVariables = ValidationService.validate($scope.entity, {
            publishState: {
                method: "isset",
                fieldName: "Status"
            },
            type: {
                method: "isset",
                fieldName: "Typ"
            },
            name: {
                method: "isset",
                fieldName: "Name",
                errorMsg: "Das Feld Name darf in der Standardsprache des Shops nicht leer sein",
                isLocaleValue: true
            }
        });
        if (checkVariables) {
            return true;
        } else {
            return false;
        }
    };

//    $scope.setParentCategory = function () {
//        if ($scope.selection.parent) {
//            $scope.categories.forEach(function (category) {
//                if ($scope.selection.parent === category.value) {
//                    $scope.entity.parent_id = category.value;
//                }
//            });
//        } else {
//            $scope.entity.parent = null;
//        }
//    };


    $scope.beforeSave = function () {
        if ($scope.oldSelectedSortType != undefined){
            $scope.entity.categorySortOrder = [];
            for (var i = 0; i < $scope.oldSelectedSortType.length; i++) {
                $scope.entity.categorySortOrder.push({sortType: $scope.oldSelectedSortType[i].value, sortOrder: i + 1})
            }
        }
        console.log($scope.entity.categorySortOrder);
    }

    $scope.$on("beforeUpdate", function () {
        $scope.beforeSave();
    });

    $scope.$on("beforeCreate", function () {
        $scope.beforeSave();
    });
    /*

     $scope.visibilities = {
     droparea: false
     };

     $scope.getVisibility = function () {
     if ($scope.visibilities.droparea) {
     return 'visible';
     }
     return 'invisible';
     };

     $scope.showDropArea = function () {
     $scope.visibilities.droparea = true;
     $scope.$apply();
     };

     $scope.hideDropArea = function () {
     $scope.visibilities.droparea = false;
     $scope.$apply();
     };

     $rootScope.$on('ANGULAR_DRAG_START', function () {
     $scope.showDropArea();
     });

     $rootScope.$on('ANGULAR_DRAG_END', function () {
     $scope.hideDropArea();
     });*/

    $scope.tinymceOptions = {
        theme: 'modern',
        height: '500',
        convert_urls: false,
        relative_urls: false,
        remove_script_host: false,
        plugins: [
            'link advlist lists image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template paste textcolor colorpicker textpattern imagetools',
            'fullpage moodiaimage'
        ],
        menubar: "insert | tools",
        toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
        toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | code',
        image_advtab: true
    };

}
;
