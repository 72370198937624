define('services/OrderManagementService',[
    'app',
    'modules/module',
    'services/SessionValuesService',
    'services/NotificationService'
], function (app, module) {
    'use strict';
    return module.factory('OrderManagementService', ['$q', '$rootScope', '$injector', 'SessionValuesService', 'NotificationService', 'LocalStorageService',
        function ($q, $rootScope, $injector, SessionValuesService, NotificationService, LocalStorageService) {

            this.isLoggedIn = false;
            this.username = '';
            this.password = '';
            this.shops = [];

            // default values
            this.settings = {
                selectedShop: null,

                useCodPayments: false,
                selectedCodPaymentMethods: [],
                createInvoiceForCod: false,

                carrier: null,
                useSandbox: true,
                splitOrder: false,
                useDpdPredict: false,
                dpdLabelFormat: 'A6',
                customWidth: null,
                customHeight: null,
                useForAllShippingMethods: true,
                shippingMethods: [],
                printManyOnOne: false,
                weightUnit: 'kg',
                fixWeight: null,

                useExpress: false,
                expressSignatures: "eBay Shipping - DPD Express\nAmazon Shipping - Exp",
                expressTime: null,
            };

            this.carriers = [{
                display: 'Bitte auswählen',
                value: null
            }, {
                display: 'DPD (Classic, Classic C.O.D.)',
                value: 'DPD'
            }, {
                display: 'DHL',
                value: 'DHL'
            }, {
                display: 'Eiltrans',
                value: 'EILTRANS'
            }];
            this.dpdLabelFormats = [{
                display: 'DIN A6',
                value: 'A6'
            }, {
                display: 'DIN A4',
                value: 'A4'
            }];
            this.weightUnits = [{
                display: 'Kg',
                value: 'kg'
            }, {
                display: 'Gramm',
                value: 'g'
            }];
            //TODO: rendered options are not sorted
            this.expressTimes = [{
                display: 'Please select',
                value: null
            }, {
                display: '8:30 (DPD)',
                value: 830
            }, {
                display: '9:00 (DHL, UPS)',
                value: 900
            }, {
                display: '10:00 (DPD)',
                value: 1000
            }, {
                display: '12:00 (DPD, DHL, UPS)',
                value: 1200
            }, {
                display: '18:00 (DPD)',
                value: 1800
            }];

            this.loadSettings = function () {
                var _self = this;
                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();

                OrderManagementModel.loadSettings({
                    shopId: SessionValuesService.getShop()
                }).$promise.then(function (response) {
                    if (angular.isString(response.error)) {
                        NotificationService.notifyGeneralError({statusText: response.error});
                    } else if ('settings' in response) {
                        if (angular.isString(response.username) && response.username.length > 0) {
                            _self.isLoggedIn = true;
                            _self.username = response.username;
                        }

                        if (Array.isArray(response.shops)) {
                            _self.setShops(response.shops);
                        }

                        for (var key in response.settings) {
                            if (response.settings.hasOwnProperty(key) && _self.settings.hasOwnProperty(key)) {
                                var value = response.settings[key];

                                if (Array.isArray(_self.settings[key])) {
                                    value = value.split(',');

                                    if (Array.isArray(value) && value.length > 0) {
                                        _self.settings[key] = value;
                                    }
                                } else if (value !== null && value !== '') {
                                    _self.settings[key] = value;
                                }
                            }
                        }
                    }
                });
            };

            this.login = function () {
                var _self = this;
                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();

                OrderManagementModel.login({
                    shopId: SessionValuesService.getShop(),
                    username: this.username,
                    password: this.password
                }).$promise.then(function (response) {
                    if (typeof response.error === 'string') {
                        NotificationService.notifyGeneralError({statusText: response.error});
                    } else if ('shops' in response && Array.isArray(response.shops)) {
                        _self.settings.selectedShop = null;
                        _self.setShops(response.shops);
                        _self.isLoggedIn = true;
                    }
                });
            };

            this.register = function () {
                var _self = this;
                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();

                OrderManagementModel.register({
                    shopId: SessionValuesService.getShop(),
                    username: this.username,
                    password: this.password
                }).$promise.then(function (response) {
                    if (typeof response.error === 'string') {
                        NotificationService.notifyGeneralError({statusText: response.error});
                    } else if (response.ok) {
                        _self.shops = [{
                            display: 'No shops, create a new one',
                            value: null
                        }];
                        _self.settings.selectedShop = null;
                        _self.isLoggedIn = true;
                    }
                });
            };

            this.logOut = function () {
                this.isLoggedIn = false;
                this.username = '';
                this.password = '';
                this.shops = [];
                this.settings.selectedShop = null;

                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();
                OrderManagementModel.logOut({
                    shopId: SessionValuesService.getShop()
                }).$promise.then(function (response) {
                    if (typeof response.error === 'string') {
                        NotificationService.notifyGeneralError({statusText: response.error});
                    }
                });
            };

            this.setShops = function (shops) {
                this.shops = [];

                var nOfShops = shops.length;

                this.shops.push({
                    display: nOfShops === 0 ? 'No shops, create a new one' : 'Select shop',
                    value: null
                });

                for (var i = 0; i < shops.length; i++) {
                    this.shops.push({
                        display: shops[i].name,
                        value: shops[i].id
                    });
                }
            };

            this.saveSettings = function () {
                var requestSettings = {};

                for (var key in this.settings) {
                    if (this.settings.hasOwnProperty(key)) {
                        var value = this.settings[key];

                        if (Array.isArray(value)) {
                            requestSettings[key] = value.join(',');
                        } else if (value != null) {
                            requestSettings[key] = value;
                        }
                    }
                }

                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();
                OrderManagementModel.saveSettings(
                    {shopId: SessionValuesService.getShop()},
                    requestSettings
                ).$promise.then(function (response) {
                    if (typeof response.error === 'string') {
                        NotificationService.notifyGeneralError({statusText: response.error});
                    }
                });
            };

            this.isAuthFormValid = function () {
                return this.username.length > 0 && this.password.length > 0 && this.isEmailValid(this.username);
            };

            this.isEmailValid = function (value) {
                var emailRegExp = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
                return emailRegExp.test(value);
            };

            var OrderManagementService = this;

            this.createShopModalController = function ($scope, dataToPass, $mdDialog) {
                $scope.newShopName = '';
                $scope.createShop = OrderManagementService.createShop;
            };

            this.configureShopModalController = function ($scope, dataToPass, $mdDialog) {
                $scope.carriers = dataToPass.carriers;
                $scope.shopName = dataToPass.name;

                $scope.isBoolean = function (value) {
                    return value === 'true' || value === 'false';
                };

                $scope.booleanSelectValues = [{
                    display: 'Ja',
                    value: 'true'
                }, {
                    display: 'Nein',
                    value: 'false'
                }];

                $scope.getParameterByName = function (parameterName, carrierParameters) {
                    var parameter = null;

                    for (var i = 0; i < carrierParameters.length; i++) {
                        if (carrierParameters[i].name === parameterName) {
                            parameter = carrierParameters[i];
                        }
                    }

                    return parameter;
                };

                $scope.checkDependencies = function (parameter, carrierParameters) {
                    var areDependenciesResolved = false;
                    if (parameter.name === 'use' && !('dependsOn' in parameter)) {
                        areDependenciesResolved = true;
                    } else {
                        if ('dependsOn' in parameter) {
                            var parentParameterName = parameter.dependsOn.name;
                            var parentParameter = $scope.getParameterByName(parentParameterName, carrierParameters);

                            if (parentParameter === null || !$scope.isBoolean(parentParameter.value)) {
                                areDependenciesResolved = true;
                            } else {
                                areDependenciesResolved = parentParameter.value === 'true';

                                if ('dependsOn' in parentParameter) {
                                    areDependenciesResolved = areDependenciesResolved && $scope.checkDependencies(parentParameter, carrierParameters);
                                }
                            }
                        }
                    }

                    parameter.hidden = !areDependenciesResolved;

                    return areDependenciesResolved;
                };

                $scope.setShopParameters = function () {
                    var shopParameters = {
                        name: $scope.shopName,
                        carrierParams: {}
                    };

                    for (var carrierCode in $scope.carriers) {
                        if ($scope.carriers.hasOwnProperty(carrierCode)) {
                            shopParameters.carrierParams[carrierCode] = {};

                            var carrierParameters = $scope.carriers[carrierCode];

                            for (var i in carrierParameters) {
                                if (carrierParameters.hasOwnProperty(i)) {
                                    var parameter = carrierParameters[i];
                                    if (!parameter.hidden) {
                                        shopParameters.carrierParams[carrierCode][parameter.name] = parameter.value;
                                    }
                                }
                            }
                        }
                    }

                    var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();

                    OrderManagementModel.setShopParameters(
                        {
                            shopId: SessionValuesService.getShop(),
                            orderManagementShopId: OrderManagementService.settings.selectedShop
                        },
                        shopParameters
                    ).$promise.then(function (response) {
                        //TODO: notifications in modal
                        OrderManagementService.closeModal();

                        if (angular.isString(response.error)) {
                            NotificationService.notifyGeneralError({statusText: response.error});
                        } else if ('name' in response && 'id' in response) {
                            for (var i in OrderManagementService.shops) {
                                if (OrderManagementService.shops.hasOwnProperty(i) &&
                                    OrderManagementService.shops[i].value === response.id) {
                                    OrderManagementService.shops[i].display = response.name;
                                    break;
                                }
                            }

                            NotificationService.notifySuccess({statusText: "Shop's parameters were updated."});
                        }
                    });
                }
            };

            this.createShop = function (newShopName) {
                var _self = OrderManagementService;
                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();

                OrderManagementModel.createShop(
                    {shopId: SessionValuesService.getShop()},
                    {name: newShopName}
                ).$promise.then(function (response) {
                    _self.closeModal();

                    if (angular.isString(response.error)) {
                        NotificationService.notifyGeneralError({statusText: response.error});
                    } else if ('newShopId' in response && 'shops' in response) {
                        _self.setShops(response.shops);
                        _self.settings.selectedShop = response.newShopId;

                        NotificationService.notifySuccess({statusText: 'New shop was created.'});
                    }
                });
            };

            this.closeModal = function () {
                $rootScope.$emit('closeModal');
            };

            this.showConfigureShopModalWrapper = function () {
                var _self = OrderManagementService;
                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();

                OrderManagementModel.getShopParameters({
                    shopId: SessionValuesService.getShop(),
                    orderManagementShopId: _self.settings.selectedShop
                }).$promise.then(function (response) {
                    if (angular.isString(response.error)) {
                        NotificationService.notifyGeneralError({statusText: response.error});
                    } else if ('carriers' in response) {
                        _self.showConfigureShopModal(response);
                    }
                });
            };

            this.createShippingLabels = function (ordersToPrecess, allOrders, customSettings) {
                this.clearOrderNotifications();

                for (var i = 0; i < ordersToPrecess.length; i++) {
                    var order = ordersToPrecess[i];
                    this.createShippingLabel(order, allOrders, customSettings);
                }
            };

            this.createShippingLabel = function (order, allOrders, customSettings) {
                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();

                OrderManagementModel.createShippingLabel(
                    {shopId: SessionValuesService.getShop()},
                    {
                        orderId: order.id,
                        customSettings: customSettings
                    }
                ).$promise.then(function (response) {
                    if ('error' in response) {
                        if (Array.isArray(response.error)) {
                            for (var i in response.error) {
                                if (response.error.hasOwnProperty(i)) {
                                    OrderManagementService.notifyOrderError(response.error[i], order);
                                }
                            }
                        } else if (angular.isString(response.error)) {
                            OrderManagementService.notifyOrderError(response.error, order);
                        }
                    } else if (angular.isString(response.trackingNumber)) {
                        allOrders.forEach(function (shopOrder) {
                            if (shopOrder.orderNumber === order.orderNumber) {
                                shopOrder.trackingNumber = response.trackingNumber;
                            }
                        });
                    } else {
                        OrderManagementService.notifyOrderError("Invalid response from server.", order);
                    }
                });
            };

            this.printShippingLabel = function (trackingNumbers) {
                this.clearOrderNotifications();

                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();
                var ref = $q.defer();
                OrderManagementModel.printShippingLabel(
                    {shopId: SessionValuesService.getShop()},
                    trackingNumbers
                ).$promise.then(function (response) {
                    if (angular.isString(response.error)) {
                        NotificationService.notifyGeneralError({msg: response.error});
                    } else if (!angular.isString(response.data)) {
                        NotificationService.notifyGeneralError({
                            statusText: 'Error while printing labels: ',
                            msg: 'Invalid response from server'
                        });
                    } else {
                        ref.resolve(response.data);
                    }
                });

                return ref.promise;
            };

            this.completeShipping = function (fromDate, toDate) {
                this.clearOrderNotifications();

                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();
                var ref = $q.defer();
                OrderManagementModel.completeShipping(
                    {
                        shopId: SessionValuesService.getShop(),
                        from: fromDate,
                        to: toDate
                    }
                ).$promise.then(function (response) {
                    if (angular.isString(response.error)) {
                        NotificationService.notifyGeneralError({msg: response.error});
                    } else if (!angular.isString(response.data)) {
                        NotificationService.notifyGeneralError({
                            statusText: 'Error while printing delivery note: ',
                            msg: 'Invalid response from server'
                        });
                    } else {
                        ref.resolve(response.data);
                    }
                });

                return ref.promise;
            };

            this.cancelShippingLabel = function (order, carrier) {
                var OrderManagementModel = new $injector.get("ModelFactory").OrderManagement();
                var ref = $q.defer();

                var requestData = {
                    shopId: SessionValuesService.getShop(),
                    orderId: order.id,
                    trackingNumber: order.trackingNumber
                };

                if (carrier !== null) {
                    requestData.carrier = carrier;
                }

                OrderManagementModel.cancelShippingLabel(requestData).$promise.then(function (response) {
                    if (angular.isString(response.error)) {
                        OrderManagementService.notifyOrderError(response.error, order);
                    } else if ('ok' in response && response.ok){
                        ref.resolve(order);
                    }
                });

                return ref.promise;
            };

            this.messagesWrapperId = 'order-messages';

            this.notifyOrderError = function (errorMessage, order) {
                var messagesWrapper = jQuery('#' + OrderManagementService.messagesWrapperId);
                if (messagesWrapper.length === 0) {
                    messagesWrapper = jQuery('<div/>', {id: OrderManagementService.messagesWrapperId}).insertBefore('[ng-entity-table]');
                }
                messagesWrapper.append(jQuery('<div/>', {class: 'error', html: '<b>' + order.orderNumber + ':</b> ' + errorMessage}));
            };

            this.clearOrderNotifications = function () {
                jQuery('#' + OrderManagementService.messagesWrapperId).remove();
            };

            this.getObjectURLFromEncodedString = function (encodedString) {
                var data = atob(encodedString);
                var length = data.length;
                var ab = new ArrayBuffer(length);
                var ua = new Uint8Array(ab);
                for (var i = 0; i < length; i++) {
                    ua[i] = data.charCodeAt(i);
                }

                var file = new Blob([ab], {type: 'application/pdf'});
                return URL.createObjectURL(file);
            };

            return this;
        }]);
});
