define(
    'directives/contentConfigurator/_styleMenu/StyleMenu',[
        'app',
        'angular',
        'services/ContentBuilderHelper',
        'services/ContentCssHelper'
    ],
    function (app) {
        'use strict';
        app.directive('styleMenu', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'EA',
                scope: {
                    elementId: '<',
                    styleObj: '='
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/_styleMenu/views/styleMenu.html",
                controller: ['$scope', '$rootScope', '$timeout', 'ContentCssHelper', 'ContentBuilderHelper',
                    function ($scope, $rootScope, $timeout, ContentCssHelper, ContentBuilderHelper) {

                        var FONT_STYLES = $scope.FONT_STYLES = ContentCssHelper.FONT_STYLES;
                        var ALIGNMENTS = $scope.ALIGNMENTS = ContentCssHelper.ALIGNMENTS;
                        // var POSITION_PROPS = ContentCssHelper.POSITION_PROPS;
                        var defaultStyles = function() {
                            return Object.assign(
                                {},
                                FONT_STYLES[FONT_STYLES.DEFAULT.val].style,
                                ALIGNMENTS[ALIGNMENTS.DEFAULT.val].style,
                                {
                                    backgroundColor: 'transparent',
                                    backgroundSize: 'auto',
                                    backgroundAttachment: 'scroll',
                                    backgroundRepeat: 'repeat'
                                },
                                {
                                    attrId: '',
                                    display: 'block',
                                    // position: 'static'
                                }
                            )
                        };

                        var _init = function () {
                            if (!$scope.styleObj) {$scope.styleObj = {};}

                            $scope.fontStyles = [];
                            $scope.objDisplay = { value: '', changed: false };
                            $scope.alignment = ALIGNMENTS.DEFAULT;
                            $scope.padding = ContentCssHelper.initSurroundBuffer($scope.styleObj, 'padding', 0, 'px');
                            // $scope.positionProps = ContentCssHelper.initPositionPropsBuffer($scope.styleObj);
                            $scope.backgroundBuffer = {};

                            Object.assign($scope.styleObj, Object.assign(defaultStyles(), $scope.styleObj));
                            $scope.backgroundBuffer = ContentCssHelper.initBackgroundBuffer($scope.styleObj);
                            $scope.backgroundSizeBuffer = ContentCssHelper.initDimBuffer($scope.styleObj, 'backgroundSize', ['auto', 'contain', 'cover', 'numerical'], 'auto', 100, true, '%');
                            $scope.backgroundPositionBuffer = ContentCssHelper.initDimBuffer($scope.styleObj, 'backgroundPosition', ['top', 'bottom', 'left', 'right', 'center', 'numerical'], 'unset', 0, false, '%');
                        };
                        _init();

                        $scope.setStyle = function (s, current, group) {
                            // Set style from a group
                            // Selecting an active style deselects it
                            if (current.length != undefined) {
                                // Stacking options
                                var e = current.findIndex(function (e) { return e._ === s._;});
                                if (e !== -1) {
                                    current.splice(e, 1);
                                } else {
                                    if (s.excludes !== undefined) {
                                        for (var i = 0; i < current.length; i++) {
                                            var ex = current.findIndex(function (e) { return e._ === s.excludes[i];});
                                            if (ex !== -1) {
                                                i--;
                                                current.splice(ex, 1);
                                            }
                                        }
                                    }
                                    current.push(s);
                                }
                                ContentCssHelper.assignStyle($scope.styleObj, current, group);
                            } else {
                                // Exclusive options
                                if (s._ === current) {
                                    current = group.DEFAULT._;
                                    ContentCssHelper.assignStyle($scope.styleObj, group.DEFAULT, group);
                                } else {
                                    current = s._;
                                    ContentCssHelper.assignStyle($scope.styleObj, s, group);
                                }
                            }
                            return current;
                        };

                        $scope.isFontStyleSelected = function (s) {
                            return $scope.fontStyles.findIndex(function (e) {return e._ === s;}) > -1;
                        };

                        $scope.setFontStyle = function (s) {
                            $scope.fontStyles = $scope.setStyle(s, $scope.fontStyles, FONT_STYLES);
                        };

                        $scope.setAlignment = function (s) {
                            $scope.alignment = $scope.setStyle(s, $scope.alignment, ALIGNMENTS);
                        };

                        $scope.paddingChanged = function () {
                            if ($scope.padding.sync.vertical) {
                                $scope.padding.value.bottom = $scope.padding.value.top;
                            }
                            if ($scope.padding.sync.horizontal) {
                                $scope.padding.value.right = $scope.padding.value.left;
                            }

                            $scope.padding.value.top = $scope.padding.value.top > 0 ? $scope.padding.value.top : 0;
                            $scope.padding.value.right = $scope.padding.value.right > 0 ? $scope.padding.value.right : 0;
                            $scope.padding.value.bottom = $scope.padding.value.bottom > 0 ? $scope.padding.value.bottom : 0;
                            $scope.padding.value.left = $scope.padding.value.left > 0 ? $scope.padding.value.left : 0;

                            $scope.styleObj.padding =
                                $scope.padding.value.top + '' + $scope.padding.value.top_unit + ' ' +
                                $scope.padding.value.right + '' + $scope.padding.value.right_unit + ' ' +
                                $scope.padding.value.bottom + '' + $scope.padding.value.bottom_unit + ' ' +
                                $scope.padding.value.left + '' + $scope.padding.value.left_unit;
                        };

                        // $scope.positionChanged = function () {

                        //     // remove top, right, bottom, left properties if position is set to static
                        //     if ($scope.styleObj.position === 'static') {
                        //         POSITION_PROPS.forEach(function (positionPropName) {
                        //             delete $scope.styleObj[positionPropName];
                        //         });
                        //     }
                        // }
                        // $scope.positionPropChanged = function (direction, toggleDirection) {
                        //     var positionProp = $scope.positionProps[direction];
                        //     if(toggleDirection) {
                        //         positionProp.on = !positionProp.on;
                        //     }
                        //     if(positionProp.on) {
                        //         $scope.styleObj[direction] = positionProp.v + positionProp.u;
                        //     } else {
                        //         delete $scope.styleObj[direction];
                        //     }
                        // }

                        $scope.buildDimensions = function (buffer) {
                            var value = buffer.value;
                            if (buffer.value.lockRatio) {
                                return value.x.toString() + value.unitX;
                            } else {
                                return value.x.toString() + value.unitX + ' ' + value.y.toString() + value.unitY;
                            }
                        };

                        /*
                        $scope.onChangedObjDisplay = function () {
                            try {
                                var t = JSON.parse('{' + $scope.objDisplay.value + '}');
                                $scope.styleObj = t;
                                $scope.objDisplay.value = true;
                            } catch (e) {

                            }
                        };*/

                        $scope.$watch('elementId', function () {
                            _init();
                        });

                        $scope.$watch('backgroundSizeBuffer.value', function (newValue, oldValue) {
                            if ($scope.backgroundSizeBuffer.type === 'numerical') {
                                // if ($scope.backgroundSizeBuffer.value.lockRatio) {
                                //     $scope.backgroundSizeBuffer.value.unitY = $scope.backgroundSizeBuffer.value.unitX;
                                // } else {
                                $scope.styleObj.backgroundSize = $scope.buildDimensions($scope.backgroundSizeBuffer);
                                // }
                            }
                        }, true);

                        $scope.$watch('backgroundPositionBuffer.value', function (newValue, oldValue) {
                            if ($scope.backgroundPositionBuffer.type === 'numerical') {
                                $scope.styleObj.backgroundPosition = $scope.buildDimensions($scope.backgroundPositionBuffer);
                            }
                        }, true);

                        $scope.onBackgroundUrlChange = function () {
                            $scope.styleObj.backgroundImage = $scope.backgroundBuffer.backgroundImageUrl ? 'url(' + $scope.backgroundBuffer.backgroundImageUrl + ')' : '';
                        };

                        $scope.onBackgroundSizeChange = function () {
                            if ($scope.backgroundSizeBuffer.type === 'numerical') {
                                $scope.styleObj.backgroundSize = $scope.buildDimensions($scope.backgroundSizeBuffer);
                            } else {
                                $scope.styleObj.backgroundSize = $scope.backgroundSizeBuffer.type;
                            }
                        };

                        $scope.onBackgroundPositionChange = function () {
                            if ($scope.backgroundPositionBuffer.type === 'numerical') {
                                $scope.styleObj.backgroundPosition = $scope.buildDimensions($scope.backgroundPositionBuffer);
                            } else {
                                $scope.styleObj.backgroundPosition = $scope.backgroundPositionBuffer.type;
                            }
                        };

                    }]
            }
        }]);
    }
);

