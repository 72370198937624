define(
	'directives/controls/customSelect/CustomSelect',[
		'app',
        'settings'
   	], 
   	function (app) {
		'use strict';
		app.directive('ngCustomSelect', ['BASE_DIRECTIVES_PATH', function(BASE_DIRECTIVES_PATH) {
            return {
                scope: {
                    remote: "=?",
                    onSearch: "=?",
                    label: "@",
                    outerLabel: "=?",
                    options: "=",
                    display: "@",
                    value: "@",
                    change: "=",
                    customChange: "&",
                    result: "=",
                    placeholder: "@",
                    styleClass: "@",
                    disable: "=",
                    id:"=",
                    customColor: "=",
                    mandatory: "@",
                    avoidSortValues: "=?"
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/controls/customSelect/views/CustomSelect.html",
                controller: ['$scope', function($scope) {

                    $scope.$watch('result', function(newValue, oldValue) {
                        if(newValue === undefined || newValue == oldValue) return;

                        if($scope.change !== undefined) $scope.change($scope.result);
                        if($scope.customChange !== undefined) $scope.customChange();

                    });

                    $scope.searchTerm;
                    $scope.clearSearchTerm = function() {
                        $scope.searchTerm = '';
                    };

                    $scope.onSearchChange = function ($event, searchTerm) {
                        $scope.onSearch($event, searchTerm)
                    };

                    $scope.onKeydown = function ($event) {
                        $event.stopPropagation();
                    };

                    if($scope.display === undefined) {
                        $scope.display = "display";
                    }

                    //&& $scope.options.sort
                    if($scope.options != undefined && !$scope.avoidSortValues && $scope.options.sort) {
                        $scope.options.sort(function(a, b) {
                            if (!a[$scope.display] || !b[$scope.display]) {
                                return;
                            }
                            var textA = a[$scope.display].toUpperCase();
                            var textB = b[$scope.display].toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                    }

                    if($scope.value === undefined) {
                        $scope.value = "value";
                    }
                	
                    if($scope.outerLabel === undefined) {
                        $scope.outerLabel = false;
                    }

                    $scope.getValue = function(option, path)
                    {
                        var dp = path.split(".");

                        var value = option;
                        for(var i=0; i<dp.length; i++)
                        {
                            value = value[dp[i]];
                        }

                        return value;
                    };
                }]
            }
        }
    ])
});

