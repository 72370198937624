define('services/EconCampaignService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('EconCampaignService', [
        '$injector',
        EconCampaignService
    ]);
});


function EconCampaignService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Campaign');

    angular.extend(this, crudInstance);
	
	crudInstance.update = this.saveCampaign;
    crudInstance.create = this.saveCampaign;

    return this;
}
;
