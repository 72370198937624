define(
    'modules/basemodule/controllers/EntityFormController',[
        'modules/module',
        'services/EntityCrudService',
        'services/NotificationService',
        'services/NavigationService',
        'services/ShopService'
    ],
    function (module) {
        'use strict';
        module.controller('EntityFormController', [
            '$scope',
            '$rootScope',
            '$state',
            '$q',
            '$injector',
            'NotificationService',
            'NavigationService',
            'ValidationService',
            'ShopService',
            'SeminarService',
            FormController
        ]);
    }
);

function FormController($scope, $rootScope, $state, $q, $injector, NotificationService, NavigationService, ValidationService, ShopService, SeminarService) {

    $scope.initialization = $q.defer();
    $scope.entityName = '';
    if (!$scope.preventInit) {
        $scope.entityId = $state.params.id;
    }
    $scope.newEntity = true;
    $scope.selected = {};
    $scope.stateName = $state.current.name;
    $scope.productType = "";

    if (NavigationService.getActiveSubitem() != undefined) {
        $scope.headline = NavigationService.getActiveSubitem().label;
        $scope.single = NavigationService.getActiveSubitem().single;
    }
    $scope.locales = [];

    $rootScope.$on('ShopChanged', function () {
        var states = NavigationService.getItemsRight();
        for (var i in states) {
            if (states[i].subMenu != undefined) {
                for (var j in states[i].subMenu) {
                    if (states[i].subMenu[j].states != undefined) {
                        for (var k in states[i].subMenu[j].states) {
                            if (states[i].subMenu[j].states[k] == $scope.listState) {
                                $scope.init($scope.entityName);
                                return;
                            }
                        }
                    }
                }
            }
        }
        if ($scope.entityId != "") {
            return;
        }
        $scope.init($scope.entityName);
    });
    // possibility to toggle action buttons from any point in app
    $rootScope.$on('EntityFormActionButtonsToggle', function (e, toggleFlag) {
        $scope.hideSaveButtons = !toggleFlag;
    });
    $scope.init = function (entity) {
        if (typeof entity === 'string') {
            $scope.entityService = $injector.get('EntityCrudService');
            $scope.entityService.setType(entity);
        } else {
            console.warn("Should be initialized via String. Otherwise there are problems with shop change.");
            $scope.entityService = entity;
        }
        $scope.entityName = $scope.entityService.getType();
        $scope.initMenu();
        if ($scope.entityId) {
            if ($scope.entityName == "Product") {
                if ($scope.stateName == "root.productAtomic") {
                    $scope.productType = "atomics";
                } else if ($scope.stateName == "root.productConfig") {
                    $scope.productType = "configurables";
                } else {
                    $scope.productType = "bundles";
                }
                $scope.entityService.findProduct($scope.entityId, $scope.productType).then(function () {
                    $scope.entity = $scope.entityService.getOne();
                    $scope.newEntity = false;
                    $scope.initialization.resolve();
                });
            } else {
                $scope.entityService.findOne($scope.entityId).then(function () {
                    $scope.entity = $scope.entityService.getOne();
                    $scope.newEntity = false;
                    $scope.initialization.resolve();
                });
            }
        } else {
            $scope.newEntity = true;
            $scope.entity = {};
            $scope.initialization.resolve();
        }

        ShopService.findShop().then(function () {
            $scope.shop = ShopService.getOne();
        });

        ShopService.findShopLocales().then(function () {
            $scope.locales = ShopService.getShopLocales(true);
        });

        ShopService.findDefaultCurrencyId().then(function () {
            ShopService.findDefaultCurrency().then(function () {
                $scope.currency =  ShopService.getDefaultCurrency();
            });
        });

        $scope.initialization.promise.then(function () {
            $scope.$broadcast("afterInit");
        });

        return $scope.initialization.promise;
    };

    $scope.initMenu = function () {
        $scope.formTabs.forEach(function (menuItem) {
            if (menuItem.active === true) {
                $scope.selected.menuItem = menuItem;
                $scope.selected.menuItem.active = true;
            }
        });
    };

    $scope.findMenuItemByName = function (name) {
        for (var i = 0; i < $scope.formTabs.length; i++) {
            if ($scope.formTabs[i].label === name) {
                return $scope.formTabs[i];
            }
        }
    };

    $scope.changeTab = function (menuItem) {
        if (typeof menuItem === 'string') {
            menuItem = $scope.findMenuItemByName(menuItem);
        }
        if ($scope.selected.menuItem !== undefined) {
            $scope.selected.menuItem.active = false;
        }
        menuItem.active = true;
        $scope.selected.menuItem = menuItem;
    };

    $scope.performUpdate = function(changeState, suppressNotification) {
        return $scope.entityService.update($scope.entity).then(function (response) {
            var type = $scope.entity.type;
            if (changeState) {
                NavigationService.changeState($scope.listState);
            }
            if (response.error == undefined && response.errorCode == undefined && response.ok != false) {
                if (!suppressNotification)
                    NotificationService.notifySuccess({statusText: 'Erfolgreich gespeichert'});
                $scope.entity = response;
                if ($scope.entityName == "Product") {
                    if ($scope.stateName == "root.productAtomic") {
                        $scope.productType = "atomics";
                    } else if ($scope.stateName == "root.productConfig") {
                        $scope.productType = "configurables";
                    } else {
                        $scope.productType = "bundles";
                    }
                }
                if ($scope.entityName == "Seminar") {
                    $scope.entity = SeminarService.convertDates($scope.entity);
                }
                $scope.entity.type = type;
                $scope.$broadcast("duplicateSaved");
                $scope.$broadcast("afterUpdate");
            } else {
                if (!suppressNotification) {
                    NotificationService.notifyGeneralError({statusText: 'Speichern fehlgeschlagen'});
                }

                $scope.$broadcast("updateError");
            }
        });
    };

    $scope.performCreate = function (changeState, suppressNotification) {
        return $scope.entityService.create($scope.entity).then(function (response) {
            var type = $scope.entity.type;
            if(changeState){
                NavigationService.changeState($scope.listState);
            }
            if(response.error == undefined && response.errorCode == undefined && response.ok != false) {
                if(!suppressNotification)
                    NotificationService.notifySuccess({statusText: 'Erfolgreich gespeichert'});
                $scope.newEntity = false;
                $scope.entity = response;
                if ($scope.entityName == "Product") {
                    if ($scope.stateName == "root.productAtomic") {
                        $scope.productType = "atomics";
                    } else if ($scope.stateName == "root.productConfig") {
                        $scope.productType = "configurables";
                    } else {
                        $scope.productType = "bundles";
                    }
                }
                if($scope.entityName == "Seminar") {
                    $scope.entity = SeminarService.convertDates($scope.entity);
                }
                $scope.entity.type = type;
                $scope.$broadcast("duplicateSaved");
                $scope.$broadcast("afterCreate");
            } else {
                if(!suppressNotification) {
                    if(response.error === "SKU already exists") {
                        NotificationService.notifyGeneralError({statusText: 'SKU bereits vorhanden'});
                    }
                }
                $scope.$broadcast("createError");
            }
        });
    };

    $scope.prepareSaveDelayed = function () {
        return $q(
            function (resolve, reject) {
                resolve();
            }
        );
    };

    $scope.prepareCreateDelayed = function () {
        return $q(
            function (resolve, reject) {
                resolve();
            }
        );
    };

    $scope.update = function (changeState, suppressNotification) {
        if (!$scope.validate()) {
            NotificationService.notifyValidationErrors(ValidationService.getErrors());
            return;
        }

        $scope.$broadcast("beforeUpdate");

        return $scope.prepareSaveDelayed().then(
            function () {
                return $scope.performUpdate(changeState, suppressNotification);
            }
        );
    };

    $scope.create = function (changeState, suppressNotification) {
        if (!$scope.validate()) {
            NotificationService.notifyValidationErrors(ValidationService.getErrors());
            return;
        }

        $scope.$broadcast("beforeCreate");

        return $scope.prepareCreateDelayed().then(
            function () {
                return $scope.performCreate(changeState, suppressNotification);
            }
        );
    };

    $scope.cancel = function () {
        NavigationService.changeState($scope.listState);
    };

    $scope.validate = function () {
        if ($scope.validationConfig != undefined) {
            return ValidationService.validate($scope.entity, $scope.validationConfig);
        }

        return true;
    };

}
;
