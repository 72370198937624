define('modules/settings/backup/BackupController',[
    'modules/module',
    'services/BackupService',
    'filters/DateFilter'
],
function (module) {
    'use strict';
    module.controller('BackupController', ['$scope', 'BackupService', function ($scope, BackupService) {

        $scope.state = {
            backupList: [],
            autoBackup: false
        }

        $scope.getshortDate = (val) => {
            const date = new Date(val);
            return date.getDate() +
            " " +  (date.getMonth() + 1) + 
            " " +  date.getFullYear();
        }

        BackupService.listBackup().then((response) => {
            for(let x of response) {
                if(x.filename || x.timestamp) {
                    let date = $scope.getshortDate(x.timestamp);
                    var name = x.filename;

                    $scope.state.backupList.push({
                        date: date,
                        timestamp: x.timestamp,
                        filename: name,
                        fileUrl: "",
                        disabled: false
                    });
                }
            }
        });

        $scope.createBackup = () => {
            return BackupService.createBackup();
        }

        $scope.downloadBackup = (timestamp, index, item) => {
            item.disabled = true;
            return BackupService.downloadBackup(timestamp, index, $scope.state.backupList[index])
        }
    }]);
}
);
