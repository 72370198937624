/**
 * Created by Nico on 11.07.2016.
 */
define('modules/wawi/attribute/controllers/AttributeFormController',[
        'modules/module',
        'modules/basemodule/controllers/EntityFormController',
        'services/ShopService',
        'services/ValidationService',
        'services/AttributeService',
        'settings'
    ],
    function (module) {
        'use strict';
        module.controller('AttributeFormController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH', 'ShopService', 'ValidationService', 'AttributeService',
            function ($scope, $controller, BASE_TEMPLATES_PATH, ShopService, ValidationService, AttributeService) {

                angular.extend(this, $controller('EntityFormController', {
                    $scope: $scope
                }));

                $scope.formTabs = [
                    {
                        label: 'Allgemein',
                        template: BASE_TEMPLATES_PATH + '/wawi/attribute/form-main.html',
                        active: true
                    }
                ];
                $scope.init("Attribute").then(function () {
                    $scope.options = [];
                    var countLocales = 0;
                    if($scope.entity.locales != undefined) {
                        for (var i=0; i < $scope.entity.locales.length; i++) {
                            if($scope.entity.locales[i].options != undefined) {
                                if($scope.entity.locales[i].options.length > countLocales) {
                                    countLocales = $scope.entity.locales[i].options.length;
                                }
                            }
                        }
                        for(var i=0; i < countLocales; i++) {
                            $scope.options.push({});
                        }
                    }

                    if($scope.entity.filterable === undefined) {
                        $scope.entity.filterable = true;
                    }
                });

                $scope.listState = 'root.attributes';

                $scope.addOption = function () {
                    $scope.options.push({});
                };

                $scope.removeOption = function (index) {
                    if($scope.entity.locales[0].options[index].id == undefined) {
                        $scope.options.splice(index, 1);
                        for (var i = 0; i < $scope.entity.locales.length; i++) {
                            $scope.entity.locales[i].options.splice(index, 1);
                        }
                    } else {
                        AttributeService.deleteOption($scope.entity.locales[0].options[index].id).then(function (response) {
                            if (response.ok) {
                                $scope.options.splice(index, 1);
                                for (var i = 0; i < $scope.entity.locales.length; i++) {
                                    $scope.entity.locales[i].options.splice(index, 1);
                                }
                            }
                        });
                    }
                };

                $scope.$on("beforeUpdate", function () {
                    $scope.beforeSave();
                });

                $scope.$on("beforeCreate", function () {
                    $scope.beforeSave();
                });

                $scope.beforeSave = function () {
                    $scope.entity.countOptions = $scope.options.length;

                    for(var i=0; i < $scope.entity.locales[0].options.length; i++) {
                        for(var j=1; j < $scope.entity.locales.length; j++) {
                            $scope.entity.locales[j].options[i].identifier = $scope.entity.locales[0].options[i].identifier;
                            $scope.entity.locales[j].options[i].position = $scope.entity.locales[0].options[i].position;
                            $scope.entity.locales[j].options[i].optionValue = $scope.entity.locales[0].options[i].optionValue;
                        }
                    }
                };

                $scope.validate = function () {
                    return ValidationService.validate($scope.entity,{
                        name: {
                            method: "isset",
                            fieldName: "Name",
                            errorMsg: "Das Feld Name darf in der Standardsprache des Shops nicht leer sein",
                            isLocaleValue: true
                        },
                        identifier: {
                            method: "isset",
                            fieldName: "Identifier",
                            errorMsg: "Das Feld Identifier darf nicht leer sein"
                        }
                    });
                };

            }
        ]);
    }
);
