define('services/EconStatisticsService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('EconStatisticsService', [
        '$injector',
        EconStatisticsService
    ]);
});

function EconStatisticsService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('EconStatistics');

    angular.extend(this, crudInstance);
    
    this.sendDayliMail = function(){
        var model = $injector.get("ModelFactory").EconStatistics();
        model.sendDayliMail();
    };

    this.getTrackedClicksByTrackedLinkId = function(trackedLinkId){
        const model = $injector.get("ModelFactory").EconStatistics();

        return model.getTrackedLinkTrackedClicks({
            trackedLinkId: trackedLinkId
        }).$promise;
    };
    
    return this;
};
