define('modules/shippingRules/controllers/ShippingRuleFormController',[
	'modules/module',
	'modules/models',
	'settings',
	'modules/basemodule/controllers/EntityFormController',
	'services/ShippingRuleService',
	'directives/rules/rules',
	'directives/shippingConditionGroups/shippingConditionGroups',
	'filters/I18nFilter',
	'directives/unitsInput/unitsInput'
], function (module) {
	'use strict';
	module.controller('ShippingRuleFormController', [
		'$scope',
		'$controller',
		'ShippingRuleService',
		'NavigationService',
		'BASE_TEMPLATES_PATH',
		ShippingRuleFormController
	]);
});

function ShippingRuleFormController($scope, $controller, ShippingRuleService, NavigationService, BASE_TEMPLATES_PATH) {
	$scope.formTabs = [
		{
			label: 'Versandregeln',
			template: BASE_TEMPLATES_PATH + '/shippingRules/partials/form-main.html',
			active: true
		}
	];

	$scope.listState = 'root.shippingrules';

	angular.extend(this, $controller('EntityFormController', {
		$scope: $scope
	}));

	$scope.init('ShippingRule').then(function () {
		$scope.isNew = true;
		if ($scope.entity.id !== undefined) {
			$scope.entity.action.type = {
				id: $scope.entity.action.type,
				name: $scope.entity.action.type
			};
			$scope.entity.shippingConditionGroups.forEach(function (group) {
				group.shippingConditions.forEach(function (condition) {
					condition.fieldType = {
						id: condition.fieldType,
						name: condition.fieldType
					};
					condition.operator = {
						id: condition.operator,
						name: condition.operator
					};
				});
			});
			$scope.isNew = false;
		} else {
			$scope.entity.shippingConditionGroups = [];
		}

		ShippingRuleService.loadAvailableActions().then(function () {
			$scope.availableActions = $scope.toObjectArray(ShippingRuleService.getAvailableActions());
		});
		
		$scope.create = function () {
			$scope.beforeSave();
			return $scope.entityService.create($scope.entity).then(function () {
				//var listState = ($scope.listState === undefined) ? 'root.' + $scope.entityName.toLowerCase() + 's' : 'root.' + $scope.listState;
				NavigationService.changeState($scope.listState);
			});
		};
		
		$scope.update = function () {
			$scope.beforeSave();
			return $scope.entityService.update($scope.entity).then(function () {
				//var listState = ($scope.listState === undefined) ? 'root.' + $scope.entityName.toLowerCase() + 's' : 'root.' + $scope.listState;
				NavigationService.changeState($scope.listState);
			});
		};
	});

	$scope.toObjectArray = function (arr) {
		var results = [];
		arr.forEach(function (string) {
			results.push({
				id: string,
				name: string
			});
		});
		return results;
	};


	$scope.beforeSave = function () {
		$scope.entity.action.type = $scope.entity.action.type.name;
		$scope.entity.shippingConditionGroups.forEach(function (group) {
			group.shippingConditions.forEach(function (condition) {
				condition.fieldType = condition.fieldType.name;
				condition.operator = condition.operator.name;
			});
		});
	};
	
	$scope.toObjectArray = function (arr) {
		var results = [];
		arr.forEach(function (string) {
			results.push({
				id: string,
				name: string
			});
		});
		return results;
	};
}
;
