/**
 * Created by nmayer on 13.06.16.
 */
define('modules/settings/currency/CurrencyController',[
        'modules/module',
        'settings',
        'modules/basemodule/controllers/EntityListController'
    ],
    function (module) {
        'use strict';
        module.controller('CurrencyController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.tableOptions.fields = [
                    {
                        id: 'NAME',
                        heading:    'Name',
                        attribute:  'name'
                    },
                    {
                        id: 'ISO_CODE',
                        heading:    'ISO Code',
                        attribute:  'currencyIso'
                    },
                    {
                        id: 'FAKTOR',
                        heading:    'Faktor',
                        attribute:  'factor'
                    },
                    {
                        id: 'UNICODE_SYMBOL',
                        heading:    'Unicode Symbol',
                        attribute:  'unicodeSymbol'
                    }
                ];
                $scope.formState = 'root.currency';

                $scope.init('Currency');
            }
        ]);
    }
);

