define('modules/orderManagement/controllers/OrderManagementController',[
        'app',
        'angular',
        'settings',
        'modules/basemodule/controllers/EntityListController',
        'modules/orderManagement/controllers/OrderManagementSettingsFormController',
        'services/ShippingLabelService',
        'services/NavigationService',
        'services/OrderService',
        'filters/TimestampFilter'
    ],
    function (module) {
        'use strict';
        module.controller('OrderManagementController', ['$timeout', '$controller', '$sce', '$scope', '$q',
            'ShippingLabelService', 'NavigationService', 'BASE_TEMPLATES_PATH', 'NotificationService', 'OrderService',
            'OrderManagementService', 'ShopService',
            function ($timeout, $controller, $sce, $scope, $q, ShippingLabelService, NavigationService,
                      BASE_TEMPLATES_PATH, NotificationService, OrderService, OrderManagementService, ShopService) {

                var generateArchivedParams = function(state) {
                    var archivedParams = [
                        {
                            name: 'archive',
                            value: state
                        } ];
                    return archivedParams;
                };

                $scope.archiveViewActive = false;

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.hideAddDeleteButtons = true;

                $scope.tableOptions.fields = [
                    {
                        id: 'ORDER_NUMBER',
                        heading: 'Bestellnummer',
                        template: BASE_TEMPLATES_PATH + '/orderManagement/partials/order-number-col.html',
                        attribute: "orderNumber"
                    },
                    {
                        id: 'TIMESTAMP',
                        heading: 'Bestelldatum',
                        template: BASE_TEMPLATES_PATH + '/orderManagement/partials/order-date-col.html',
                        attribute: 'orderDate'
                    },
                    {
                        id: 'ORIGIN',
                        heading: 'Kanal',
                        template: BASE_TEMPLATES_PATH + '/orderManagement/partials/order-origin-col.html',
                        searchDisabled: true,
                        orderDisabled: true
                    },
                    {
                        id: 'INVOICE_NAME',
                        heading: 'Rechnungsempfänger',
                        template: BASE_TEMPLATES_PATH + '/orderManagement/partials/invoice-name-col.html',
                        attribute: 'name'
                    },
                    {
                        id: 'sum',
                        heading: 'Total',
                        template: BASE_TEMPLATES_PATH + '/orderManagement/partials/total-col.html',
                        attribute: 'total',
                        searchDisabled: true,
                        orderDisabled: true
                    },
                    {
                        id: 'STATUS',
                        heading: 'Status',
                        template: BASE_TEMPLATES_PATH + '/orderManagement/partials/status-col.html',
                        attribute: 'orderStateIds',
                        searchDisabled: true,
                        orderDisabled: true
                    },
                    {
                        id: 'CUSTOMER_TYPE',
                        heading: 'Kundentyp',
                        template: BASE_TEMPLATES_PATH + '/orderManagement/partials/customer-type-col.html',
                        attribute: 'customerType',
                        searchDisabled: true,
                        orderDisabled: true
                    }
                ];

                $scope.formState = "root.ordermanagement";

                $scope.init('Order').then(function () {
                    if ($scope.isOrderManagementActive()) {
                        $scope.topBarLeftTemplate = BASE_TEMPLATES_PATH + '/orderManagement/partials/top-bar-left.html';
                        $scope.topBarTemplate = BASE_TEMPLATES_PATH + '/orderManagement/partials/top-bar.html';
                        $scope.tableOptions.optionalFields = [
                            {
                                id: 'TRACKING',
                                heading: 'Trackingnummer',
                                template: BASE_TEMPLATES_PATH + '/orderManagement/partials/tracking-col.html',
                                attribute: 'trackingNumber'
                            }
                        ];
                    }

                    $scope.orderManagement = {};

                    $scope.disabled = {
                        invalidCredentials: true,
                        noShopSelected: true
                    };
                });

                $scope.editConfig = function () {
                    NavigationService.changeState("root.ordermanagementsetting");
                };

                $scope.expressTimeDefault = '1200';

                $scope.settings = {
                    carrier: null,
                    expressTime: $scope.expressTimeDefault
                };

                $scope.expressCarrierSuffix = '_EXPRESS';

                var loadCustomCarriers = function() {
                    $scope.customCarriers = [{
                        display: 'Use Configuration',
                        value: null
                    }];

                    ShopService.findShop().then(function () {
                        var shop = ShopService.getOne();

                        if (!angular.isArray(shop.shopShippingMethods) || shop.shopShippingMethods.length === 0) {
                            return;
                        }

                        for (var i = 0;i < shop.shopShippingMethods.length;i++) {
                            if (!shop.shopShippingMethods[i].isActive) {
                                continue;
                            }

                            $scope.customCarriers.push({
                                display: shop.shopShippingMethods[i].name,
                                value: shop.shopShippingMethods[i].name
                            });
                        }
                    });
                };

                loadCustomCarriers();

                $scope.expressTimes = {
                    'DPD': [{
                        display: '8:30 (DPD)',
                        value: '830'
                    }, {
                        display: '10:00 (DPD)',
                        value: '1000'
                    }, {
                        display: '12:00 (DPD, DHL, UPS)',
                        value: '1200'
                    }, {
                        display: '18:00 (DPD)',
                        value: '1800'
                    }],
                    'DHL': [{
                        display: '9:00 (DHL, UPS)',
                        value: '900'
                    }, {
                        display: '12:00 (DPD, DHL, UPS)',
                        value: '1200'
                    }]
                };

                $scope.$watch('settings.carrier', function(carrier) {
                    $scope.settings.expressTime = carrier === null ? null : $scope.expressTimeDefault;
                });

                $scope.validateOrders = function (action) {
                    var areOrdersValid = true;

                    var isTrackingNumberRequired = (action === 'print' || action === 'cancel');

                    if ($scope.selected.items.length === 0) {
                        areOrdersValid = false;
                    } else {
                        for (var i = 0; i < $scope.selected.items.length; i++) {
                            var trackingNumber = $scope.selected.items[i].trackingNumber;

                            var isTrackingNumberValid = angular.isString(trackingNumber) && trackingNumber.length > 0;

                            if ((isTrackingNumberRequired && !isTrackingNumberValid) || (!isTrackingNumberRequired && isTrackingNumberValid)) {
                                areOrdersValid = false;
                                break;
                            }
                        }
                    }

                    return areOrdersValid;
                };

                $scope.createShippingLabels = function () {
                    var ordersToProcess = [];
                    for (var i = 0; i < $scope.selected.items.length; i++) {
                        var order = $scope.selected.items[i];
                        if (order.trackingNumber === undefined || order.trackingNumber === "") {
                            ordersToProcess.push(order);
                        } else {
                            NotificationService.notifyGeneralError({statusText: "Es ist ein Fehler aufgetreten.", msg: "Für ein oder mehrere Bestellungen wurden bereits ein Versandlabel erstellt."});
                            return;
                        }
                    }

                    var customSettings = {};
                    if ($scope.settings.carrier !== null) {
                        customSettings.carrier = $scope.settings.carrier;

                        if (customSettings.carrier.indexOf($scope.expressCarrierSuffix) !== -1) {
                            customSettings.carrier = customSettings.carrier.replace($scope.expressCarrierSuffix, '');
                            customSettings.expressTime = $scope.settings.expressTime;
                        }
                    }

                    OrderManagementService.createShippingLabels(ordersToProcess, $scope.items, customSettings);
                };

                var setShippingLinkHTML = function (html) {
                    $scope.shippingLinkHTML = $sce.trustAsHtml(html);
                };

                $scope.printShippingLabels = function () {
                    setShippingLinkHTML('');

                    var trackingNumbers = [];
                    $scope.selected.items.forEach(function (order) {
                        if (order.trackingNumber.indexOf(',') !== -1) {
                            trackingNumbers = trackingNumbers.concat(order.trackingNumber.split(','));
                        } else {
                            trackingNumbers.push(order.trackingNumber);
                        }
                    });

                    OrderManagementService.printShippingLabel(trackingNumbers).then(function (encodedString) {
                        if (encodedString) {
                            var link = OrderManagementService.getObjectURLFromEncodedString(encodedString);
                            setShippingLinkHTML("<a target='_blank' id='open-pdf-btn' class='' href='" + link + "'>Label-PDF öffnen</a>");
                        }
                    });
                };

                $scope.cancelShippingLabels = function () {
                    $scope.shippingLinkHTML = '';

                    OrderManagementService.clearOrderNotifications();

                    $scope.labelCancelPromises = [];
                    var carrier = $scope.settings.carrier === null ?
                        null : $scope.settings.carrier.replace($scope.expressCarrierSuffix, '');
                    $scope.selected.items.forEach(function (order) {
                        $scope.labelCancelPromises.push(OrderManagementService.cancelShippingLabel(
                            order,
                            carrier
                        ).then(function () {}));
                    });
                    $q.all($scope.labelCancelPromises).then(function () {
                        $scope.selected.items = [];
                        $scope.disabled.noSelection = true;
                        $scope.entityService.findAll();
                    });
                };

                $scope.generateOrder = function() {

                };

                $scope.reorder = function () {
                    if($scope.selected.items.length == 0){
                        return;
                    }

                    if($scope.selected.items.length > 1){
                        NotificationService.notifyGeneralError({statusText: 'Es ist nur möglich eine Bestellung neu zu erstellen.'});
                        return;
                    }
                    OrderService.reorder($scope.selected.items[0]).then(function (response) {
                        //success
                        //$state.go("root.ordermanagementReorder", {shoppingCartId: "1321"});

                        NavigationService.changeStateWithObject('root.ordermanagementReorder', {shoppingCartId: response.id});
                    }, function (response) {
                        //error
                        console.error(response);
                    });
                };

                $scope.switchDisplayOrderArchive = function() {
                    $scope.archiveViewActive = !$scope.archiveViewActive;
                    $scope.entityService.setViewSpecificParams(generateArchivedParams($scope.archiveViewActive));
                    $scope.entityService.findAll();
                };

                $scope.delOrder = function() {

                };

                $scope.isOrderManagementActive = function () {
                    //TODO: test if the user has bought ordermanagement
                    return true;
                };

                $scope.completeDateRangeModalController = function ($scope) {
                    var dateToday = new Date();
                    dateToday.setHours(0, 0, 0, 0);

                    $scope.dateFrom = dateToday;
                    $scope.dateTo = dateToday;

                    $scope.completeShipping = function () {
                        OrderManagementService.closeModal();

                        setShippingLinkHTML('');

                        OrderManagementService.completeShipping(
                            $filter('date')($scope.dateFrom, 'MMddyyyy'),
                            $filter('date')($scope.dateTo, 'MMddyyyy')
                        ).then(function (encodedString) {
                            if (encodedString) {
                                var link = OrderManagementService.getObjectURLFromEncodedString(encodedString);
                                setShippingLinkHTML("<a target='_blank' id='open-pdf-btn' class='' href='" + link + "'>Delivery note PDF</a>");
                            }
                        });
                    }
                };

            }]);
    }
);
