define('filters/I18nFilter',[
        'app',
        'settings',
        'services/I18nService'
    ],
    function (app) {
        'use strict';
        app.filter('i18nFilter', function (I18nService) {
            return function (translationKey) {
                return I18nService.getTranslatedString(translationKey);
            };
        });
    }
);
