define('filters/CurrencyFilter',[
		'app',
		'services/ShopService'
	],
	function (app){
		'use strict';
		app.filter('currencyFilter', function(ShopService){
			return function(price, symbol) {
				if (price) {
					var strPrice = price.toString();
					strPrice = strPrice.replace(".", ",");
					var index = strPrice.indexOf(',');
					if (index == -1) {
						strPrice += ',00';
					} else {
						if (strPrice.substring(index).length > 3) {
							strPrice = strPrice.substring(0, index + 3);
						}
						while (strPrice.substring(index).length < 3) {
							strPrice += '0';
						}
					}
					var index2 = strPrice.indexOf(',');
					var tmp = strPrice.substring(0, index2);
					if (tmp.length > 3) {
						index2 -= 3;
						strPrice = strPrice.substring(0, index2) + '.' + strPrice.substring(index2, strPrice.length);
					}

					if(!symbol){
						symbol = ShopService.getDefaultCurrency().unicodeSymbol;
					}

					return strPrice + ' ' + symbol;
				}
			}
		});
	}
);
