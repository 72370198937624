define('modules/settings/bankAccount/BankAccountController',[
        'modules/module',
        'settings',
        'modules/basemodule/controllers/EntityListController'
    ],
    function (module) {
        'use strict';
        module.controller('BankAccountController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityListController', {
                    $scope: $scope
                }));

                $scope.tableOptions.fields = [
                    {
                        heading:    'Bank',
                        attribute:  'bankName'
                    },
                    {
                        heading:    'BIC',
                        attribute:  'bankBic'
                    },
                    {
                        heading:    'IBAN',
                        attribute:  'bankIban'
                    }
                ];
                $scope.formState = 'root.bankAccount';

                $scope.init('BankAccount');
            }
        ]);
    }
);

