define('services/ContentPageService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('ContentPageService', [
        '$injector',
        ContentPageService
    ]);
});


function ContentPageService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('ContentPage');

    angular.extend(this, crudInstance);

    return this;
}
;
