define('modules/wawi/product/controllers/ProductController',[
        'modules/module',
        'modules/basemodule/controllers/EntityListController',
        'services/ProductService',
        'services/NavigationService',
        'services/NotificationService',
        'services/MetadataUnpagedService',
        'services/CategoryService',
        'services/LocalStorageService',
        'services/AttributeUnpagedService',
        'services/PublishStateService',
        'services/TaxService',
        'filters/productTypeFilter',
        'settings'
    ],
    function (module) {
        'use strict';
        module.controller('ProductController', [
            '$scope',
            '$rootScope',
            '$controller',
            '$timeout',
            '$injector',
            '$q',
            'NavigationService',
            'NotificationService',
            'ProductService',
            'BASE_TEMPLATES_PATH',
            'PRODUCT_FILTER_OPTIONS',
            'MetadataUnpagedService',
            'CategoryService',
            'LocalStorageService',
            'AttributeUnpagedService',
            'PublishStateService',
            'TaxService',
            ProductController
        ]);
    }
);


function ProductController($scope, $rootScope, $controller, $timeout, $injector, $q, NavigationService, NotificationService, ProductService, BASE_TEMPLATES_PATH, PRODUCT_FILTER_OPTIONS,
    MetadataUnpagedService, CategoryService, LocalStorageService, AttributeUnpagedService, PublishStateService, TaxService) {

    angular.extend(this, $controller('EntityListController', {
        $scope: $scope
    }));

    $scope.topBarTemplate = BASE_TEMPLATES_PATH + '/wawi/product/top-bar-template.html';
    $scope.data = {};
    $scope.data.bulkUpdate = false;
    $scope.data.filter = false;
    $scope.filters = ProductService.getFilters();
    $scope.data.filterIsActive = true;

    $scope.pageSize = 10;

    $scope.setBulkUpdate = function () {
        $scope.data.bulkUpdate = !$scope.data.bulkUpdate;
    };

    $scope.setFilter = function () {
        $scope.data.filter = !$scope.data.filter;
    };

    $scope.tableOptions.fields = [
        {
            id: 'SORT_ORDER',
            heading: 'Sortierung',
            attribute: 'sortOrder',
            template:  BASE_TEMPLATES_PATH + '/wawi/product/col/sort-order-col.html',
            orderDisabled: true

        },
        {
            id: 'THUMB_IMG',
            heading: 'Bild',
            template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-thumb-col.html',
            searchDisabled: true,
            orderDisabled: true
        },
        {
            id: 'STATUS',
            heading: 'Status',
            attribute: 'publishState',
            searchDisabled: true
        },
        {
            id: 'PRODUCT_TYPE',
            heading: 'Produktart',
            attribute: 'productType',
            searchDisabled: true,
            orderDisabled: true
        },
        {
            id: 'NAME',
            heading: 'Name',
            template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-name-col.html',
            attribute: 'name'
        },
        {
            id: 'SKU',
            heading: 'SKU',
            attribute: 'sku'
        },
        {
            id: 'SLUG',
            heading: 'Slug',
            attribute: 'slug'
        },
        {
            id: 'CATEGORY',
            heading: 'Kategorien',
            template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-category-col.html',
            attribute: 'category'
        }

    ];

    $scope.tableOptions.optionalFields = [
        {
            id: 'DESCRIPTION',
            heading: 'Langbeschreibung',
            attribute: 'description',
            searchDisabled: true,
            orderDisabled: true
        }
    ];

    $scope.productsToSave = [];


    $scope.saveSortOrder = function(){
        ProductService.updateSortOrder($scope.productsToSave);
    };


    $scope.$on('saveSortOrderFromTable', function(evt, data) {

        if ($scope.delay !== undefined) {
            $timeout.cancel($scope.delay);
        }

        $scope.delay = $timeout(
            function (){

                var flag;
                var index;
                for (index = 0; index < $scope.productsToSave.length; ++index) {
                    if ($scope.productsToSave[index].id === data.id) {
                        $scope.productsToSave[index] = data;
                        flag = true;
                    }
                }
                if(!flag)
                    $scope.productsToSave.push(data);

                $scope.delay = undefined;
            }, 800
        );

    });

    $scope.preserveForDialogs = function(item, field) {
        return ((field.id != "SORT_ORDER"));
    };


    /*/-----FILTER-----/*/

    $scope.$watch('filters', function (newVal, oldVal) {
        if(newVal === undefined) {
            return;
        }

        for(var i=0; i < $scope.filters.length; i++) {
            if (oldVal[i] != undefined) {
                if (newVal[i].filterValue !== oldVal[i].filterValue) {
                    $scope.filters[i].filterOptions = [];
                }
            }
        }

        ProductService.setFilters($scope.filters);
    }, true);

    MetadataUnpagedService.findAll().then(function () {
        $scope.metadatas = MetadataUnpagedService.getAll(true).data;
    });

    AttributeUnpagedService.findAll().then(function () {
        $scope.attributes = AttributeUnpagedService.getAll(true).data;
    });

    CategoryService.findAll().then(function () {
        $scope.categories = CategoryService.getAll().data || CategoryService.getAll();
        $scope.suggestCategories = CategoryService.getAll().data;
        $rootScope.$broadcast("$suggestSearchList", $scope.suggestCategories, "category");
    });

    TaxService.findAll(true).then(function(response) {
        $scope.taxes = response.data;
    });

    $scope.getMetadataById = function (metadataNameId) {
        if(metadataNameId != undefined) {
            for(var i=0; i < $scope.metadatas.length; i++) {
                if(metadataNameId == $scope.metadatas[i].id) {
                    return $scope.metadatas[i];
                }
            }
        }
    };

    $scope.getAttributeById = function (attributeNameId) {
        if(attributeNameId != undefined) {
            for(var i=0; i < $scope.attributes.length; i++) {
                if(attributeNameId == $scope.attributes[i].id) {
                    return $scope.attributes[i];
                }
            }
        }
    };

    $scope.availableFilterOptions = PRODUCT_FILTER_OPTIONS;

    $scope.filterOptions = [];

    $scope.getSelectOptions = function (type) {
        for(var i=0; i < $scope.availableFilterOptions.length; i++) {
            if($scope.availableFilterOptions[i].id === type) {
                return $scope.availableFilterOptions[i].types
            }
        }
    };

    $scope.removeFilter = function (index) {
        $scope.filters.splice(index, 1);
    };

    $scope.addFilter = function () {
        if($scope.filters === undefined) {
            $scope.filters = [];
        }
        $scope.filters.push({});
    };

    $scope.searchTerm;

    $scope.clearSearchTerm = function() {
        $scope.searchTerm = '';
    };

    $scope.onSearchChange = function($event) {
        $event.stopPropagation();
    };

    $scope.pageData = {};

    $scope.activateFilters = function() {
        if ($scope.data.filterIsActive) {
            if ($scope.filters.length > 0) {
                $scope.filterProducts(false, true);
            }
        } else {
            $scope.entityService.findAll();
        }
    };

    $scope.filterProducts = function (reset, fromButton) {
        var ref = $q.defer();

        if (fromButton) {
            /*if ($scope.filters.length > 0) {
             $scope.filterIsActive = true;
             } else {
             $scope.filterIsActive = false;
             }*/

        }

        if(reset) {
            LocalStorageService.write('ProductCurrentPage', 0);
        }

        const filters = $scope.getFiltersForBulkUpdateRequest();

        ProductService.filterForBulkUpdate(filters, $scope.entityService.getAdditionalParams()).then(function () {
            $scope.items = ProductService.getBulkUpdateProducts().data;

            $scope.totalItems = ProductService.getBulkUpdateProducts().count;
            $scope.totalPages = ProductService.getBulkUpdateProducts().pages;

            if ($scope.totalPages === 0) {
                //If there are no items the total pages size should be displayed as 1
                $scope.totalPages = 1;
            }

            $scope.currentPage = ProductService.getBulkUpdateProducts().page + 1;

        });

        return ref.promise;
    };

    $scope.getFiltersForBulkUpdateRequest = () => {
        const filters = [];

        if ($scope.data.filterIsActive) {
            //TODO: fix warnings; Use this function instead of repeating code in this file
            for(let i=0; i < $scope.filters.length; i++) {
                if($scope.filters[i].filterType != undefined && $scope.filters[i].filterType != "" &&
                    $scope.filters[i].filterCond != undefined && $scope.filters[i].filterCond != "" &&
                    $scope.filters[i].filterValue != undefined && $scope.filters[i].filterValue != "") {
                    filters.push($scope.filters[i]);
                }
            }
        }

        return filters;
    };

    $scope.setPageUpdateProducts = function () {
        var filters = [];
        for(var i=0; i < $scope.filters.length; i++) {
            if($scope.filters[i].filterType != undefined && $scope.filters[i].filterType != "" &&
                $scope.filters[i].filterCond != undefined && $scope.filters[i].filterCond != "" &&
                $scope.filters[i].filterValue != undefined && $scope.filters[i].filterValue != "") {
                filters.push($scope.filters[i]);
            }
        }

        if(filters.length < 1) {
            $scope.setPage();
        } else {
            ProductService.setCurrentPage($scope.getRealCurrentPage(), false);
            $scope.filterProducts();

        }
    };

    $scope.setPageSizeUpdateProducts = function () {
        var filters = [];
        for(var i=0; i < $scope.filters.length; i++) {
            if($scope.filters[i].filterType != undefined && $scope.filters[i].filterType != "" &&
                $scope.filters[i].filterCond != undefined && $scope.filters[i].filterCond != "" &&
                $scope.filters[i].filterValue != undefined && $scope.filters[i].filterValue != "") {
                filters.push($scope.filters[i]);
            }
        }

        if(filters.length < 1) {
            $scope.setPageSize();
        } else {
            ProductService.setPageSize($scope.pageSize, false);
            $scope.filterProducts();
        }
    };

    /*-----FILTER-----*/

    /*/-----UPDATE-----/*/

    $scope.updateObject = {};
    $scope.updateObject.updateActions = [{
        crossSellingProducts: [],
        upSellingProducts: []
    }];
    /*$scope.updateObject.updateActions[0].clearCategories = false;
     $scope.updateObject.updateActions[0].updateType = "CATEGORY";
     $scope.updateObject.updateActions[1].updateType = "ADDMETADATA";
     $scope.updateObject.updateActions[2].updateType = "TRANSFERTOPARENT";
     $scope.updateObject.updateActions[2].transferActions = [];*/

    $scope.actions = [
        {id:"CATEGORY", name:"Kategoriezuweisung"},
        {id:"ADDMETADATA", name:"Merkmalszuweisung"},
        {id:"TRANSFERTOPARENT", name:"Auf Kinder übertragen"},
        {id:"PASSIMAGESTOMATCHINGVARIANTS", name:"Bilder synchronisieren"},
        {id:"SETPARENTIMAGES", name:"Bilder auf Parent übertragen"},
        {id:"USESTOCK", name:"Lagerbestand verwenden"},
        {id:"SETPARENTPRICES", name:"Preise auf Parent übertragen"},
        { id: "ADD_CROSS_SELLING_PRODUCTS", name: "Cross Sellings" },
        { id: "ADD_UP_SELLING_PRODUCTS", name: "Up Sellings" },
        { id: "UPDATE_PUBLISH_STATE", name: "Produkte aktivieren/deaktivieren" },
        { id: "UPDATE_IS_SEARCHABLE", name: "Produkt in der Suche anzeigen" },
        { id: "UPDATE_SHOW_IN_CATALOG", name: "Produkt im Katalog anzeigen" },
        { id: "UPDATE_TAX", name: "Steuersatz" }
    ];


    $scope.transferActions = [{id:"SHORTDESCRIPTION",name:"Kurzbeschreibung"},{id:"LONGDESCRIPTION",name:"Langbeschreibung"},{id:"PRICES",name:"Preise"},
        {id:"CATEGORIES",name:"Kategorien"},{id:"IMAGES",name:"Bilder"},{id:"METADATA",name:"Produktmerkmale"},{id:"PRINTAREAS",name:"Druckflächen"},{id:"STOCK",name:"Lagerbestand"},
        {id:"SELLINGPRODUCTS",name:"Selling Products"}];

    $scope.toggleOptions = [{ label: "Ja", value: true }, { label: "Nein", value: false }];

    $scope.updateObject.productIds = [];

    $scope.addOnSelling = {};

    $scope.addOnSelling.productService = ProductService;

    $scope.addOnSelling.selectedObjects = []; //currently not used

    $scope.addOnSelling.addOnSellingTableOptions = {};
    $scope.addOnSelling.addOnSellingTableOptions.name = 'Add On Selling Produkte';
    $scope.addOnSelling.addOnSellingTableOptions.fields = [{
        id: 'NAME',
        heading: 'Name',
        attribute: 'name'
    },{
        id: 'SKU',
        heading: 'Sku',
        attribute: 'sku'
    }];

    $scope.crossSelling = {};

    $scope.crossSelling.productService = ProductService;

    $scope.crossSelling.selectedObjects = []; //currently not used

    $scope.crossSelling.crossSellingTableOptions = {};
    $scope.crossSelling.crossSellingTableOptions.name = 'Cross Selling Produkte';
    $scope.crossSelling.crossSellingTableOptions.fields = [{
        id: 'NAME',
        heading: 'Name',
        attribute: 'name'
    },{
        id: 'SKU',
        heading: 'Sku',
        attribute: 'sku'
    }];

    $scope.upSelling = {};

    $scope.upSelling.productService = ProductService;

    $scope.upSelling.selectedObjects = []; //currently not used

    $scope.upSelling.upSellingTableOptions = {};
    $scope.upSelling.upSellingTableOptions.name = 'Up Selling Produkte';
    $scope.upSelling.upSellingTableOptions.fields = [{
        id: 'NAME',
        heading: 'Name',
        attribute: 'name'
    },{
        id: 'SKU',
        heading: 'Sku',
        attribute: 'sku'
    }];

    $scope.bulkUpdate = function () {
        $scope.updateObject.filterProduct = null;
        $scope.updateObject.productIds = [];

        // this variable is set in ng-entity-table
        if ($scope.selected.allSelectedOnAllPages) {
            $scope.updateObject.filterProduct = {};
            $scope.updateObject.filterProduct.filter = $scope.getFiltersForBulkUpdateRequest();
        } else {
            for(var i=0; i < $scope.selected.items.length; i++) {
                $scope.updateObject.productIds.push($scope.selected.items[i].id);
            }
        }

        ProductService.bulkUpdate($scope.updateObject, $scope.entityService.getAdditionalParams()).then(() => {
            $scope.setPageUpdateProducts();
        });
    };

    $scope.getMetadataValueOptions = function (metadataNameId) {
        if(metadataNameId) {
            for(var i=0; i < $scope.metadatas.length; i++) {
                if($scope.metadatas[i].id === metadataNameId) {

                    for(var j=0; j < $scope.updateObject.updateActions.length; j++) {
                        if($scope.updateObject.updateActions[j].metadataNameId === metadataNameId) {
                            $scope.updateObject.updateActions[j].metadataValues = $scope.metadatas[i].metadataValues;
                        }
                    }

                }
            }
        }
    };

    $scope.removeAction = function (index) {
        $scope.updateObject.updateActions.splice(index, 1);
    };

    $scope.addAction = function () {
        if($scope.updateObject.updateActions === undefined) {
            $scope.updateObject.updateActions = [];
        }
        $scope.updateObject.updateActions.push(
            {
                addOnSellingProducts: [],
                crossSellingProducts: [],
                upSellingProducts: []
            });
    };

    /*-----UPDATE-----*/

    $scope.init("Product").then(function () {

        /*$scope.topBarTemplate = BASE_TEMPLATES_PATH + '/wawi/product/partials/duplicate-button.html';*/

        $scope.entityService.abandonRequests();

        $scope.create = function (type) {
            NavigationService.changeState('root.product' + type);
        };

        $scope.getEntities = $scope.filterProducts;

        $scope.update = function (item) {
            switch(item.productType) {
                case 'ATOMIC':
                    NavigationService.changeState('root.productAtomic', item.id);
                    break;
                case 'CONFIGURABLE':
                    NavigationService.changeState('root.productConfig', item.id);
                    break;
                case 'BUNDLE':
                    NavigationService.changeState('root.productBundle', item.id);
                    break;
                default:
                    break;
            }
        };
        PublishStateService.findAll().then(function () {
            $scope.publishStates = PublishStateService.getAll();
        });
    });

    $scope.setDefaultPublishState = function (updateAction) {
        updateAction.publishStateId = $scope.publishStates[0].id;
    }

    $scope.duplicate = function () {
        if ($scope.selected.items <= 0) {
            return;
        };

        for(var a = 0; a < $scope.selected.items.length; a++) {
            var params = {
                id: $scope.selected.items[a].id
            };
            switch($scope.selected.items[a].productType) {
                case 'ATOMIC':
                    params.type = 'atomics';
                    break;
                case 'CONFIGURABLE':
                    params.type = 'configurables';
                    break;
                case 'BUNDLE':
                    params.type = 'bundles';
                    break;
                default:
                    break;
            }

            ProductService.findProduct(params.id, params.type).then(function () {
                var product = ProductService.getOne();

                if(product != undefined) {
                    delete product.id;
                    delete product.slug;
                    delete product.stock.id;

                    product.sku = product.sku + '-duplicate';
                    product.publishState = 'MAINTENANCE';


                    for (var i = 0; i < product.locales.length; i++) {
                        product.locales[i].name = product.locales[i].name + '-duplicate';
                        for (var j = 0; j < product.locales[i].metadata.length; j++) {
                            delete product.locales[i].metadata[j].id;
                            for (var k = 0; k < product.locales[i].metadata[j].productMetadataValues.length; k++) {
                                delete product.locales[i].metadata[j].productMetadataValues[k].id;
                            }
                        }
                    }

                    for (var i = 0; i < product.prices.length; i++) {
                        delete product.prices[i].id;
                    }

                    product.type = params.type;

                    var entityService = $injector.get('EntityCrudService');
                    entityService.setType('Product');
                    entityService.create(product).then(function (response) {
                        if(response.error == undefined && response.errorCode == undefined ) {
                            NotificationService.notifySuccess({statusText: 'Duplizieren erfolgreich'});
                        } else {
                            NotificationService.notifyGeneralError({statusText: 'Duplizieren fehlgeschlagen'})
                        }
                    });
                }
            });
        }


    }
}
;
