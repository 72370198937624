define(
	'directives/productSlider/ProductSlider',[
		'app',
		'jquery-flexslider',
		// 'core/services/ShoppingCartService',
        'directives/productSlider/FlexSlider',
        'directives/customImage/CustomImage',
        // 'directives/imageSelector/ImageSelector',
   	],
   	function (app) {
		'use strict';
		app.directive('ngProductSlider', ['BASE_DIRECTIVES_PATH', 'ModelFactory', function(BASE_DIRECTIVES_PATH, ModelFactory) {
				return {
					scope: {
						products: "=?",
                        productIds: "=?",
						slug: "=?",
						visibleItems: "=?"
					},
					templateUrl: BASE_DIRECTIVES_PATH + '/productSlider/views/productSlider.html',
					controller: ['$scope', function($scope){
                        // debugger;
                        var $window = $(window);

					    $scope.visibleItems = getGridSize();

                        function getGridSize() {
                            return (window.innerWidth < 400) ? 1 :
                                   (window.innerWidth < 600) ? 2 :
                                   (window.innerWidth < 900) ? 2 : 3;
                        }

                        $window.resize(function() {
                            var gridSize = getGridSize();

                            $scope.visibleItems = gridSize;
                        });

                        var _sourceDefined = false;

                        if ($scope.slug !== undefined) {
                            _sourceDefined = true;
                        }
                        if ($scope.products !== undefined) {
                            if (_sourceDefined) {
                                throw "Product slider may not have more than one product source.";
                            }
                            _sourceDefined = true;
                        }
                        if ($scope.productIds !== undefined) {
                            if (_sourceDefined) {
                                throw "Product slider may not have more than one product source.";
                            }
                            _sourceDefined = true;
                        }

                        // if($scope.slug !== undefined) {
                        //     var requestParams = {
                        //         page: 0,
                        //         size: 24,
                        //         slug: $scope.slug
                        //     };
                        //     debugger;
                        //     ModelFactory.Catalog().findAll(requestParams).$promise.then(function (response) {
                        //         // if (response.docType === 'PRODUCT_LIST') {
                        //         $scope.products = response.data;
                        //         // }
                        //     });
                        // }

                        if($scope.productIds != undefined) {

                        }

                        $scope.$watch("products", function (newVal, oldVal) {
                            if(newVal === undefined) {
                                return;
                            }
                            $scope.products = newVal;
                        });

                        $scope.$watch("slug", function (newVal, oldVal) {
                            // debugger;
                            if (newVal === undefined) {
                                return;
                            }

                            var requestParams = {
                                // 'filter[order]': 'name.raw ASC',
                                page: 0,
                                size: 24,
                                'filter[category]': newVal,
                                publishState: 'ACTIVE'
                                // with_variants: false
                            };
                            // debugger;
                            ModelFactory.Product().findAll(requestParams).$promise.then(function (response) {
                                // if (response.docType === 'PRODUCT_LIST') {
                                $scope.products = response.data;
                                // }
                            });

                        });

                        // $scope.addProduct = function (product, amount) {
                        //     if (!amount) {
                        //         amount = 1;
                        //     }
                        //     ShoppingCartService.addProduct({product: product, amount: amount});
                        // };
					}]
				}
			}
		]);
	}
);

