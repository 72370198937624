define('modules/settings/smtp/SmtpSettingsController',[
    'modules/module',
    'services/SmtpSettingService',
    'services/NotificationService',
    'services/ValidationService'
],
        function (module) {
            'use strict';
            module.controller('SmtpSettingsController', [
                '$scope',
                'NotificationService',
                'ValidationService',
                'SmtpSettingService',
                SmtpSettingsController
            ]);
        }
);

function SmtpSettingsController($scope, NotificationService, ValidationService, SmtpSettingService) {

    SmtpSettingService.findOne().then(function () {
        $scope.settings = SmtpSettingService.getOne();
    });

    $scope.update = function () {

        if (!$scope.validate()) {
            NotificationService.notifyValidationErrors(ValidationService.getErrors());
            return;
        }

        return SmtpSettingService.update($scope.settings).then(function (response) {
            if(response.error == undefined && response.errorCode == undefined) {
                NotificationService.notifySuccess({statusText: 'Erfolgreich gespeichert'});
            } else {
                NotificationService.notifyGeneralError({statusText: 'Speichern fehlgeschlagen'})
            }
        });

    };

    $scope.validate = function () {
        if ($scope.validationConfig != undefined) {
            return ValidationService.validate($scope.settings, $scope.validationConfig);
        }
        return true;
    };

    $scope.validationConfig = {
        emailAddress: {
            method: "isset",
            fieldName: "Absender E-Mail"
        },
        smtpHost: {
            method: "isset",
            fieldName: "Smtp Host"
        },
        smtpPass: {
            method: "isset",
            fieldName: "Smtp Passwort"
        },
        smtpPort: {
            method: "isset",
            fieldName: "Smtp Port"
        },
        smtpReturnPath: {
            method: "isset",
            fieldName: "Fehlgeleitete E-Mails"
        },
        smtpUser: {
            method: "isset",
            fieldName: "Smtp Benutzer"
        },
        emailAddress: {
            method: "isEmail",
            fieldName: "Absender E-Mail"
        },
        smtpReturnPath: {
            method: "isEmail",
            fieldName: "Fehlgeleitete E-Mails"
        }
    };

};
