define(
    'directives/contentConfigurator/blankRow/BlankRow',[
        'app',
        'angular',
        'services/ContentBuilderHelper'
    ],
    function (app) {
        'use strict';
        app.directive('blankRow', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'E',
                scope: {
                    metaIdentifier: '<',
                    rowIndex: '=',
                    responsive: '<'
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/blankRow/views/blankRow.html",
                controller: ['$scope', 'ContentBuilderHelper',
                    function ($scope, ContentBuilderHelper) {

                        $scope.selectLayout = function (layoutIndex) {
                            var layout = ContentBuilderHelper.getInstance($scope.metaIdentifier).layout.root;

                            if (!(!layout || !layout.length)) {
                                switch (layoutIndex) {
                                    case 0: {
                                        ContentBuilderHelper.insertSingleRow(layout, $scope.rowIndex, $scope.responsive);
                                        break;
                                    }
                                    case 1: {
                                        ContentBuilderHelper.insertDoubleRow(layout, $scope.rowIndex, $scope.responsive);
                                        break;
                                    }
                                    case 2: {
                                        ContentBuilderHelper.insertTripleRow(layout, $scope.rowIndex, $scope.responsive);
                                        break;
                                    }
                                    case 3: {
                                        ContentBuilderHelper.insertDoupleRow(layout, $scope.rowIndex, $scope.responsive);
                                        break;
                                    }
                                    case 4: {
                                        ContentBuilderHelper.insertTripleRow(layout, $scope.rowIndex, $scope.responsive);
                                        break;
                                    }
                                    case 5: {
                                        layout.push(ContentBuilderHelper.insertOfferSetup(layout, undefined, $scope.metaIdentifier));
                                        break;
                                    }
                                }
                            }
                        };
                    }]
            }
        }]);
    }
);

