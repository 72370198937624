define('modules/changePassword/controllers/ChangePasswordController',[
        'app',
        'modules/module',
        'modules/models',
        'services/UserService',
        'services/StateFaderService',
        'services/NavigationService'
    ],
    function (module) {
        'use strict';
        module.controller('ChangePasswordController', ['$rootScope', '$scope', '$state', '$stateParams', '$location', 'UserService', 'NavigationService', function ($rootScope, $scope, $state, $stateParams, $location, UserService, NavigationService) {
            $rootScope.$emit('hideNavigation');

            $scope.isPasswordChange = true;
            $scope.change = { newPassword: "", newPasswordRepeat: "" };
            $scope.showBadPassword = false;

            var passwordChangeToken = $stateParams.resetKey;

            $scope.changePassword = function () {
                if ($scope.change.newPassword !== $scope.change.newPasswordRepeat) {
                    $scope.showBadPassword = true;
                    return;
                }

                $scope.showBadPassword = false;
                UserService.sendPasswortResetActivation(passwordChangeToken, $scope.change.newPassword).then(function (response) {
                    if (response.token) {
                        $scope.backToLogin();
                    }
                });
            };

            $scope.backToLogin = function () {
                $state.go('root.login');
            };


        }]);
    }
);

