define('modules/cms/controllers/TemplatePageController',[
    'modules/module',
    'settings',
    'modules/basemodule/controllers/EntityListController'

],
        function (module) {
            'use strict';
            module.controller('TemplatePageController', [
                '$scope',
                '$controller',
                'BASE_TEMPLATES_PATH',
                TemplatePageController
            ]);
        }
);

function TemplatePageController($scope, $controller, BASE_TEMPLATES_PATH) {
    angular.extend(this, $controller('EntityListController', {
        $scope: $scope
    }));

    $scope.tableOptions.fields = [
        {
            heading: 'Bezeichnung',
            attribute: 'name'
        }
    ];
    
    $scope.formState = 'root.templatePage';
    $scope.init('TemplatePage');
};
