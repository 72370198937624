define('services/UserService',[
    'app',
    'modules/module',
    'services/NotificationService',
    'services/LocalStorageService'
], function (app, module) {
    'use strict';
    return module.factory('UserService', ['$q', '$rootScope', '$state', '$cookies', 'ModelFactory', 'NotificationService', 'LocalStorageService',
        function($q, $rootScope, $state, $cookies, ModelFactory, NotificationService, LocalStorageService) {

            var constantCheckInteval = undefined;

            var badUsername = undefined;

            var checkUserStateConstantly = function () {
                constantCheckInteval = setTimeout(checkUserState, (10000 * 1000));
            };

            var checkUserState = function () {
                var ref = $q.defer();
                ModelFactory.User.status().$promise.then(function (response) {
                    setUser(response);
                });
                return ref.promise;
            };

            var setUser = function (user) {
                $cookies.putObject('_user', user);
                if(constantCheckInteval !== undefined){
                    clearTimeout(constantCheckInteval);
                }
                if(user !== undefined){
                    checkUserStateConstantly();
                }
                $rootScope.$emit('userStatusChanged', user);
            };

            var setToken = function (token) {
                LocalStorageService.write('_token', token);
            };

            var setShop = function (shopId) {
                LocalStorageService.write('_shop', shopId);
            };

            var setLocale = function (localeId) {
                LocalStorageService.write('_locale', localeId);
            };

            var setShopAndLocale = function () {
                var deferred = $q.defer();
                ModelFactory.Shop().findAll().$promise.then(function (response) {
                    if (response != undefined) {
                        for(var key in response.data) {
                            if(response.data[key].isDefault == true) {
                                setShop(response.data[key].id);
                                if(response.data[key].defaultLocaleId != undefined) {
                                    setLocale(response.data[key].defaultLocaleId);
                                } else {
                                    setLocale("");
                                }
                            }
                        }
                    }else{
                        setShop("");
                        setLocale("");
                    }
                    deferred.resolve();
                }, NotificationService.notifyRequestError);
                return deferred.promise;
            };

            return {
                isLoggedIn: function () {
                    return LocalStorageService.read("_token") !== null && LocalStorageService.read("_token") !== false;
                },
                getToken: function () {
                    return LocalStorageService.read("_token");
                },
                isBadUsername: function() {
                    return badUsername;
                },
                removeLogin: function() {
                    $cookies.remove('_user');
                },
                getUser: function(){
                    return $cookies.getObject('_user');
                },
                loadUser: checkUserState,
                login: function (user) {
                    var ref = $q.defer();
                    ModelFactory.User().login(user).$promise.then(function (response) {
                        if (response.token != undefined) {
                            setUser({username: user.username});
                            setToken(response.token);
                            setShopAndLocale();
                        } else{
                            setToken(false);
                        }
                        ref.resolve();
                    }, NotificationService.notifyRequestError);
                    return ref.promise;
                },
                logout: function() {
                    LocalStorageService.remove("_token");
                    LocalStorageService.remove("_user");
                    LocalStorageService.remove("_locale");
                    LocalStorageService.remove("_shop");
                },
                sendPasswordReset: function (emailOrUsername) {
                    badUsername = undefined;
                    ModelFactory.User().passwordReset({ emailOrUsername: emailOrUsername }).$promise.then( function (response) {
                        if (response.ok) {
                            NotificationService.notifySuccess({ statusText: "Password Reset Email wurde versandt." });
                            badUsername = false;
                        } else {
                            badUsername = true;
                        }
                    }, NotificationService.notifyRequestError);
                },
                sendPasswortResetActivation: function (resetKey, newPassword) {
                    ModelFactory.User().passwordResetActivation({ resetKey: resetKey, newPassword: newPassword }).$promise.then( function (response) {
                        if (response.token) {
                            NotificationService.notifySuccess({ statusText: "Password wurde geändert." });
                            $state.go('root.login');
                        }
                        return response;
                    }, NotificationService.notifyRequestError);
                },
                setLocale: function(localeId) {
                    setLocale(localeId);
                },
                setShop: function(shopId) {
                    setShop(shopId);
                },
                getShop: function() {
                    return LocalStorageService.read("_shop");
                }
            };

        }]);
});
