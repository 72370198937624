/**
 * Created by Nico on 07.07.2016.
 */
define('services/LocationUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('LocationUnpagedService', [
        '$injector',
        LocationUnpagedService
    ]);
});


function LocationUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('Location');

    angular.extend(this, crudInstance);

    return this;
};
