define(
    'directives/contentConfigurator/contentSnippets/accordionSnippet/AccordionSnippet',[
        'app',
        'angular',
        'services/ContentBuilderHelper',
        'directives/contentConfigurator/contentSnippets/_snippet'
    ],
    function (app) {
        'use strict';
        app.directive('accordionSnippet', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'E',
                scope: {
                    metaIdentifier: '<',
                    snippet: "=",
                    position: "=",
                    parent: "<"
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/accordionSnippet/views/accordionSnippet.html",
                controller: ['$scope', '$controller', '$element', '$compile', '$timeout', 'ContentBuilderHelper',
                    function ($scope, $controller, $element, $compile, $timeout, ContentBuilderHelper) {
                        var path = BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/accordionSnippet/views/";

                        $scope.customStyle = path + "style.css";
                        $scope.customJs = path + "js.html";
                        $scope.includeVisibility = true;

                        var wrapper = $($element).find('[data-content-snippet="accordion"]');
                        var template = '<ul class="accordion-container"><li ng-repeat="item in snippet.config.items" ng-class="{collapsed: !$first}"><div ng-content-clickable class="accordion-toggle"><span class="accordion-item-label">{{item.title}}</span><i class="fa fa-chevron-down"></i></div><div class="accordion-target collapse collapsable" ng-class="{in: $first}" aria-expanded="{{$first}}" ng-bind-html="item.text"></div></li></ul>';

                        angular.extend(this, $controller('snippetController', {
                            $scope: $scope
                        }));

                        var _init = function () {
                            $scope.snippetType = 'accordion';
                            $scope.elementType = $scope.ELEMENT_TYPE.SNIPPET;

                            if($scope.snippet.config === undefined) {
                                $scope.snippet.config = { items: [] };
                            }
                            _refresh();
                        };
                        _init();
                        function _refresh() {
                            try {
                                wrapper.accordion('destroy');
                            } catch (e) { }
                            wrapper.find('.accordion-container').remove();
                            wrapper.append($compile(template)($scope));
                            $scope.includeVisibility = false;
                            $timeout(function () {
                                $scope.$apply();
                                $scope.includeVisibility = true;
                            }, 100);
                        }

                        function _watcher(newValue, oldValue) {
                            if (newValue !== oldValue) {
                                _refresh();
                            }
                        }
                        $scope.$watch('snippet.config', _watcher, true);
                        $scope.$watch('edit', _watcher, true);

                    }]
            }
        }]);
    }
);


/*

 */
;
