define('modules/shipment/controllers/ShipmentController',[
    'modules/module',
    'modules/models',
    'services/ShipmentService'
	], 
	function (module) {
        'use strict';
		module.controller('ShipmentController', ['$scope', '$rootScope', '$state', 'ShipmentService',
            function ($scope, $rootScope, $state, ShipmentService) {

            $scope.search = {};
            $scope.selectionChanges = false;

            $scope.setTableData = function () { 
                $scope.shipments = ShipmentService.getAll(); /*Shipments();*/
                $scope.pageSize = ShipmentService.getPageSize();
                $scope.totalItems = ShipmentService.getTotalItems();
                $scope.totalPages = ShipmentService.getTotalPages();
                $scope.currentPage = ShipmentService.getCurrentPage();
            };

            $scope.getRealCurrentPage = function () {
                var cur = angular.copy(parseInt($scope.currentPage));
                cur = (cur === 0) ? cur : (cur - 1);
                return cur;
            };

            $scope.showCurrentlyDisplayed = function () {
                var from = parseInt($scope.pageSize) * parseInt($scope.getRealCurrentPage());
                var to = ((from + parseInt($scope.pageSize)) < $scope.totalItems) ? (from + parseInt($scope.pageSize)) : $scope.totalItems;
                return (from + 1) + ' - ' + to;
            };

            $scope.setPageSize = function () {
                ShipmentService.setPageSize($scope.pageSize);
            };

            $scope.setPage = function () {
                ShipmentService.setCurrentPage($scope.getRealCurrentPage());
            };

            $scope.loadShipments = function() {
           /*     ShipmentService.loadShipments().then(function () {
                    $scope.initialShipments = angular.copy(ShipmentService.getShipments());
                    $scope.setTableData();
                });*/

                ShipmentService.findAll().then(function(){
                    $scope.initialShipments = angular.copy(ShipmentService.getAll());
                    $scope.setTableData();
                });
            };

            $scope.getElementById = function (arr, id) {
                var element = false;
                arr.forEach(function (item) {
                    if(item.id === id) {
                        element = item;
                        return;
                    }
                });
                return element;
            };

            $scope.hasSelectionChanged = function () {
                var changes = false;
                if($scope.initialShipments === undefined) {
                    return changes;
                }
                $scope.shipments.forEach(function (shipment) {
                    var initialShipment = $scope.getElementById($scope.initialShipments, shipment.id);
                    if(initialShipment && initialShipment.enabled !== shipment.enabled) {
                        changes = true;
                    }
                });
                return changes;
            };

            $scope.saveButtonOnChanges = function () {
                if($scope.hasSelectionChanged()) {
                    $scope.selectionChanges = true;
                } else {
                    $scope.selectionChanges = false;
                }
            };

            $scope.saveAll = function () {
                ShipmentService.update($scope.shipments).then(function () {   /*saveAllShipments*/
                    $scope.initialShipments = angular.copy($scope.shipments);
                    $scope.saveButtonOnChanges();
                });
            };

            $scope.cancel = function () {
                $scope.shipments = angular.copy($scope.initialShipments);
                $scope.saveButtonOnChanges();
            };

            $rootScope.$on('shipmentsChanges', function (event, data) {
                $scope.setTableData();
            });

            $scope.loadShipments();

        }]);
    }
);
