define('modules/shippingGlobal/controllers/ShippingGlobalFormController',[
	'modules/module',
	'modules/models',
	'modules/basemodule/controllers/EntityFormController',
	'services/ShippingRuleService',
	'directives/rules/rules',
	'directives/shippingConditionGroups/shippingConditionGroups',
	'filters/I18nFilter',
	'directives/unitsInput/unitsInput',
	'settings',
], function (module) {
	'use strict';
	module.controller('ShippingGlobalFormController', [
		'$scope',
		'$controller',
		'ShippingRuleService',
		'NavigationService',
		'BASE_TEMPLATES_PATH',
		ShippingGlobalFormController
	]);
});

function ShippingGlobalFormController($scope, $controller, ShippingRuleService, NavigationService, BASE_TEMPLATES_PATH) {
	$scope.formTabs = [
        {
            label:      'Globale Versandregeln',
            template:   BASE_TEMPLATES_PATH + '/shippingGlobal/shipping-global.html',
            active:     true
        }
    ];

	$scope.listState = 'root.shippingglobals';

	angular.extend(this, $controller('EntityFormController', {
		$scope: $scope
	}));

	$scope.init('ShippingGlobal').then(function () {
		if (!$scope.newEntity) {
			$scope.entity.action.type = {
				id: $scope.entity.action.type,
				name: $scope.entity.action.type
			};
			$scope.entity.shippingConditionGroups.forEach(function (group) {
				group.shippingConditions.forEach(function (condition) {
					condition.fieldType = {
						id: condition.fieldType,
						name: condition.fieldType
					};
					condition.operator = {
						id: condition.operator,
						name: condition.operator
					};
				});
			});
		} else {
			$scope.entity.shippingConditionGroups = [];
		}

		$scope.toObjectArray = function (arr) {
			var results = [];
			arr.forEach(function (string) {
				results.push({
					id: string,
					name: string
				});
			});
			return results;
		};

		$scope.beforeSave = function () {
			$scope.entity.action.type = $scope.entity.action.type.name;
			$scope.entity.shippingConditionGroups.forEach(function (group) {
				group.shippingConditions.forEach(function (condition) {
					condition.fieldType = condition.fieldType.name;
					condition.operator = condition.operator.name;
				});
			});
		};

		ShippingRuleService.loadAvailableActions().then(function () {
			$scope.availableActions = $scope.toObjectArray(ShippingRuleService.getAvailableActions());
		});

		$scope.create = function () {
			$scope.beforeSave();
			return $scope.entityService.create($scope.entity).then(function () {
				//var listState = ($scope.listState === undefined) ? 'root.' + $scope.entityName.toLowerCase() + 's' : 'root.' + $scope.listState;
				NavigationService.changeState($scope.listState);
			});
		};

		$scope.update = function () {
			$scope.beforeSave();
			return $scope.entityService.update($scope.entity).then(function () {
				//var listState = ($scope.listState === undefined) ? 'root.' + $scope.entityName.toLowerCase() + 's' : 'root.' + $scope.listState;
				NavigationService.changeState($scope.listState);
			});
		};
	});

}
;
