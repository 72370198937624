/**
 * Created by Nico on 18.07.2016.
 */
define('services/ReferenceService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('ReferenceService', [
        '$injector',
        ReferenceService
    ]);
});


function ReferenceService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('Reference');

    angular.extend(this, crudInstance);

    return this;
};
