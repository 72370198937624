define('modules/wawi/product/controllers/ProductBundleFormController',[
    'modules/module',
    'settings',
    'modules/wawi/product/controllers/ProductFormController',
    'modules/basemodule/controllers/EntityListController',
    'services/NotificationService',
    'services/ValidationService',
    'services/ProductService',
    'services/AttributeService',
    'services/TagVariantService',
    'services/ImageService',
    'directives/entityTable/ngEntityTable',
    'directives/listSelectAmount/ListSelectAmount',
    'filters/RemoveSpacesAndSpecialCharacters'
],
    function (module) {
        'use strict';
        module.controller('ProductBundleFormController', ['$scope', '$controller', 'NotificationService', 'ValidationService', 'ProductService', 'AttributeService', 'ImageService',
            'BASE_TEMPLATES_PATH', ProductBundleFormController
        ]);
    }
);

function ProductBundleFormController($scope, $controller, NotificationService, ValidationService, ProductService, AttributeService, ImageService, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('ProductFormController', {
        $scope: $scope
    }));

    $scope.formTabs.push({
        label: 'Bundleprodukte',
        template: BASE_TEMPLATES_PATH + '/wawi/product/form-bundlearticle.html'
    });

    $scope.afterInit.then(function () {
        if ($scope.entity.products == undefined) {
            $scope.entity.products = [];
        }
    });

    $scope.addProduct = function () {
        if ($scope.entity.products == undefined) {
            $scope.entity.products = [];
        }
        $scope.entity.products.push({});
    };

    $scope.removeProduct = function (index) {
        $scope.entity.products.splice(index, 1);
    };

}
;
