define(
    'directives/components/common/transformInput/TransformInput',[
        'app',
        'settings',
        'directives/components/common/filters/Comma2DecimalFilter',
        'directives/components/common/filters/Decimal2CommaFilter'
    ],
    function (app) {
        'use strict';
        app.directive('transformInput', ['$filter', function ($filter) {
            return {
                require: 'ngModel',
                link: function (scope, element, attr, ngModelCtrl) {

                    jQuery(element).on('keyup', function() {
                   /*     ngModelCtrl.$setViewValue(jQuery(element).val());

                        var data = $filter('comma2decimal')(ngModelCtrl.$viewValue)

                        console.log("$parsers");
                        console.log(data.toString());

                        var model = $parse(attrs.ngModel),
                            modelSetter = model.assign;

                        scope.$apply(function () {
                            modelSetter(scope, data);
                        });*/
                    });

                    if (scope.datatype == 'number') {

                        ngModelCtrl.$parsers.push(function (data) {
                            //convert data from view format to model format
                            return $filter('comma2decimal')(data);
                        });

                        ngModelCtrl.$formatters.push(function (data) {
                            //convert data from model format to view format
                            //console.log("$formatters");
                            //console.log(data.toString());
                            return $filter('decimal2comma')(data);
                        });

                        /*ngModelCtrl.$render = function() {
                            scope.result   = ngModelCtrl.$viewValue;
                        };*/
                    }
                }
            };
        }]);
    }
);
