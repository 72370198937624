define('modules/settings/dataFeedInput/DataFeedInputFormController',[
        'modules/module',
        'modules/basemodule/controllers/EntityFormController',
        'services/DataFeedInputService',
        'services/ValidationService',
        'directives/productSelect/productSelect',
        'settings'
    ],
    function (module) {
        'use strict';
        module.controller('DataFeedInputFormController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH', 'ValidationService', 'ShopService', 'LocaleService', 'CurrencyService', 'CustomerGroupService', 'ProductService', 'NotificationService', 'DataFeedInputService',
            function ($scope, $controller, BASE_TEMPLATES_PATH, ValidationService, ShopService, LocaleService, CurrencyService, CustomerGroupService, ProductService, NotificationService, DataFeedInputService) {

                angular.extend(this, $controller('EntityFormController', {
                    $scope: $scope
                }));

                $scope.formTabs = [
                    {
                        label: 'Allgemein',
                        template: BASE_TEMPLATES_PATH + '/settings/dataFeedInput/form-main.html',
                        active: true
                    }
                ];

                $scope.selectedItems = [];
                $scope.init("DataFeedInput").then(function () {
                    if ($scope.entity.includeAllProducts === undefined) {
                        $scope.entity.includeAllProducts = true;
                    }
                    if ($scope.entity.productIds !== undefined) {
                        for (var i = 0; i < $scope.entity.productIds.length; i++) {
                            $scope.selectedItems.push({id: $scope.entity.productIds[i]});
                        }
                    }

                    if (!$scope.entity.fileType) {
                        $scope.entity.fileType = 'XML';
                    }
                });

                $scope.listState = 'root.dataFeedInputs';

                $scope.validate = function () {
                    var rules = {
                        fileType: {
                            method: "isset",
                            fieldName: "Dateityp"
                        },
                        shopId: {
                            method: "isset",
                            fieldName: "Shop"
                        },
                        localeId: {
                            method: "isset",
                            fieldName: "Sprache"
                        },
                        currencyId: {
                            method: "isset",
                            fieldName: "Währung"
                        },
                        customerGroupId: {
                            method: "isset",
                            fieldName: "Kundengruppe"
                        }
                    };
                    if (!$scope.entity.includeAllProducts) {
                        rules['productIds'] = {
                            method: "isset",
                            fieldName: "Produkte"
                        };
                    }
                    return ValidationService.validate($scope.entity,
                        rules);
                };

                $scope.dropDown = {
                    fileTypes: [{
                        display: 'XML',
                        value: 'XML'
                    },{
                        display: 'PDF',
                        value: 'PDF'
                    }]
                };

                ShopService.findAll().then(function () {
                    $scope.dropDown.shops = ShopService.getAll(true);
                });

                LocaleService.findAll().then(function (response) {
                    $scope.dropDown.locales = LocaleService.getAll(true);
                });

                CurrencyService.findAll().then(function () {
                    $scope.dropDown.currencies = CurrencyService.getAll(true);
                });

                CustomerGroupService.findAll().then(function () {
                    $scope.dropDown.customerGroups = CustomerGroupService.getAll(true);
                });

                $scope.$watch('selectedItems', function (newVal, oldVal) {
                    if ($scope.entity !== undefined) {
                        $scope.entity.productIds = [];
                        for (var i = 0; i < newVal.length; i++) {
                            $scope.entity.productIds.push(newVal[i].id);
                        }
                    }
                }, true);

                $scope.generateDataFeed = function() {
                    if (!$scope.entity.id) {
                        return;
                    }

                    NotificationService.confirm({
                        statusText: 'Dies kann sehr lange dauern und hat das System verlangsamt. Bist du sicher?'
                    }).then(function () {
                        DataFeedInputService.generateDataFeed($scope.entity.id).then(function(response) {
                            if (response.ok) {
                                NotificationService.notifySuccess({
                                    statusText: 'Die Generierung des Datenfeeds wurde gestartet.'
                                });
                            }
                        });
                    }, function() {});
                };
            }
        ]);
    }
);

