/**
 * Created by antarya on 12.07.2019.
 */
define('modules/crm/ticket/controllers/TicketListController',[
        'modules/module',
        'modules/basemodule/controllers/EntityListController'
    ], function (module) {
        'use strict';
        module.controller('TicketListController', [
            '$controller',
            '$scope',
            'BASE_TEMPLATES_PATH',
            TicketListController
        ]);
    }
);
function TicketListController($controller, $scope, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('EntityListController', {
        $scope: $scope
    }));

    $scope.tableOptions.fields = [
        {
            id: 'NAME',
            heading: 'Name',
            attribute: 'name'
        },
        {
            id: 'STATUS',
            heading: 'Status',
            attribute: 'status'
        },
        {
            id: 'AUTHOR',
            heading: 'Author',
            attribute: 'author'
        },
        {
            id: 'CUSTOMER',
            heading: 'Customer',
            attribute: 'customerFullName',
            searchDisabled: true,
            orderDisabled: true,
        },
        {
            id: "COMMUNICATIONS",
            heading: 'Kommunikation',
            template:  BASE_TEMPLATES_PATH + '/crm/ticket/ticket-communications-col.html',
            searchDisabled: true,
            orderDisabled: true,
            class: 'text-right',
            attribute: ''
        }
    ];

    $scope.formState = 'root.ticket';

    $scope.init("Ticket");
}
;
