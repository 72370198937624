define(
    'directives/contentConfigurator/contentSnippets/textSnippet/TextSnippet',[
        'app',
        'angular',
        'services/ContentBuilderHelper',
        'directives/contentConfigurator/contentSnippets/_snippet'
    ],
    function (app) {
        'use strict';

        // helper directive to create dynamic tags inside parent directive
        app.directive('textSnippetContent', ['$sce', '$compile', function ($sce, $compile) {
            return function (scope, element, attrs) {
                function render (config) {
                    var conf;
                    if(typeof config !== "undefined") {
                        conf = config;
                    } else {
                        conf = scope.snippet.config;
                    }
                    var hasLink = !!conf.link;
                    var hasIcon = !!conf.icon;

                    // p|h1|h2|h3[attr] > a[attr] > i span i

                    var children = [{
                        tag: 'span',
                        attrs: scope.edit ? 'ng-model="snippet.text" contenteditable convert-new-lines="true" placeholder="{{\'CONTENT.TEXT\' | translate}}"' : 'ng-bind="snippet.text"'
                    }]

                    // ... > i span || ... > span i
                    if (hasIcon) {
                        children.splice(conf.iconPosition === 'left' ? 0 : 1, 0, {
                            tag: 'i',
                            attrs: 'ng-class="[\'fa\',snippet.config.icon]"'
                        })
                    }

                    if (hasLink) { // ... > a[attr][target]
                        var target = '';
                        if (conf.link_target_blank) {
                            target = ' target="_blank"';
                        }
                        children = [
                            {
                                tag: 'a',
                                attrs: ' ng-href="{{snippet.config.link}}"' + target,
                                children: children
                            }
                        ]
                    }

                    // build tag tree first
                    var tags = [{ tag: conf.type, children: children, attrs: 'ng-class="\'{{snippet.config.classes}}\'"' }]; // p|h1|h2|h3

                    function buildTree(tagTree) {
                        var treeHtml = "";
                        if (Array.isArray(tagTree)) {

                            var innerHtml = "";
                            tagTree.forEach(function(tagElem) {
                                if (Array.isArray(tagElem.children)) {
                                    innerHtml += buildTree(tagElem.children)
                                }
                                treeHtml += "<" + tagElem.tag + (tagElem.attrs ? ' ' + tagElem.attrs : '') + '>' + innerHtml + '</' + tagElem.tag + '>';
                            })
                        }
                        return treeHtml;

                    }

                    element.html(buildTree(tags));
                    $compile(element.contents())(scope);
                }
                scope.$watch("snippet.config", function (value) {
                    render(value);
                });
                scope.$watch("edit", function(value) {
                    render();
                })
            };
        }]);

        app.directive('textSnippet', ['BASE_DIRECTIVES_PATH', function (BASE_DIRECTIVES_PATH) {
            return {
                restrict: 'E',
                scope: {
                    metaIdentifier: '<',
                    snippet: "=",
                    position: "=",
                    parent: "<"
                },
                templateUrl: BASE_DIRECTIVES_PATH + "/contentConfigurator/contentSnippets/textSnippet/views/textSnippet.html",
                controller: ['$scope', '$controller', '$document', 'ContentBuilderHelper',
                    function ($scope, $controller, $document, ContentBuilderHelper) {

                        angular.extend(this, $controller('snippetController', {
                            $scope: $scope
                        }));

                        var _init = function () {
                            $scope.snippetType = 'text';
                            $scope.elementType = $scope.ELEMENT_TYPE.SNIPPET;

                            if ($scope.snippet.text === undefined) {
                                $scope.snippet.text = '';
                            }
                            if ($scope.snippet.label === undefined) {
                                $scope.snippet.label = '';
                            }
                            if($scope.snippet.config === undefined) {
                                $scope.snippet.config = {
                                    type: 'p',
                                    classes: '',
                                    link: undefined,
                                    link_target_blank: false,
                                    icon: undefined,
                                    iconPosition: 'left'
                                };
                            }

                            $scope.dragStyle = {};
                        };
                        _init();
                    }]
            }
        }]);
    }
);

