/**
 * Created by Nico on 07.07.2016.
 */
define('services/CountryUnpagedService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('CountryUnpagedService', [
        '$injector',
        CountryUnpagedService
    ]);
});


function CountryUnpagedService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('Country');

    angular.extend(this, crudInstance);

    return this;
};
