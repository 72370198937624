define(
    'directives/beeWrapper/beeWrapper',[
        'app',
        'angular',
        'bee_plugin_filesaver',
        'services/NotificationService',
        'bee_plugin',
        'bee_plugin_blop'
    ],
    function (app){
        'use strict';
        var directive = function(BASE_TEMPLATES_PATH) {
            var controller = ['$scope', '$controller', '$rootScope', 'NotificationService',
                function($scope, $controller, $rootScope, NotificationService, loadingBar) {
                    $scope.listState = 'root.econMailtemplates';

                    var changeState = false;
                    var formAction = '';
                    $scope.started = false;

                    $scope.$on("beeSave", function (event, args) {
                        $scope.bee.save();
                        changeState = args[0];
                        formAction = 'save';
                    });

                    $scope.$on("beeCreate", function (event, args) {
                        $scope.bee.save();
                        changeState = args[0];
                        formAction = 'create';
                    });

                    var jsonCont;
                    var htmlCont;
                    $scope.saved = false;
                    var request = function(method, url, data, type, callback) {
                        var req = new XMLHttpRequest();
                        req.onreadystatechange = function() {
                            if (req.readyState === 4 && req.status === 200) {
                                var response = JSON.parse(req.responseText);
                                callback(response);
                            }
                        };

                        req.open(method, url, true);
                        if (data && type) {
                            if(type === 'multipart/form-data') {
                                var formData = new FormData();
                                for (var key in data) {
                                    formData.append(key, data[key]);
                                }
                                data = formData;

                            }
                            else {
                                req.setRequestHeader('Content-type', type);
                            }
                        }


                        req.send(data);
                    };

                    var save = function(htmlFile, jsonFile) {
                        jsonCont = jsonFile;
                        htmlCont = htmlFile;

                        $scope.entity.jsonContent = jsonCont;
                        $scope.entity.content = htmlCont;

                        if(formAction === 'save') {
                            $scope.$emit('saveBeeToDatabase', [changeState]);
                        }
                        if(formAction === 'create') {
                            $scope.$emit('createBeeCallback', [changeState]);
                        }
                        if(formAction === 'autosave') {
                            $scope.$emit('autosaveBee', [changeState]);
                        }

                        formAction = '';
                        changeState = false;
                    };

                    var specialLinks = [{
                        type: 'Newsletter',
                        label: 'Newsletter abonnieren',
                        link: '[OPT_IN_URL]'
                    }, {
                        type: 'Newsletter',
                        label: 'Newsletter abmelden',
                        link: '[UNSUBSCRIBE_URL]'
                    }, {
                        type: 'Newsletter',
                        label: 'Abmeldung bestätigen',
                        link: '[UNSUBSCRIBE_CONFIRM_URL]'
                    }, {
                        type: 'Newsletter',
                        label: 'Onlineversion des Newsletters',
                        link: '[PREVIEW_URL]'
                    }];

                    var mergeTags = [{
                        name: 'Anrede',
                        value: '[SALUTATION] '
                    }, {
                        name: 'Vorname',
                        value: '[FIRST_NAME] '
                    }, {
                        name: 'Nachname',
                        value: '[LAST_NAME] '
                    }];

                    var mergeContents = [/*{
                        name: 'content 1',
                        value: '[content1]'
                    }, {
                        name: 'content 2',
                        value: '[content1]'
                    }*/];

                    $scope.$watch("entity.mailTemplateContent", function (newValue, oldValue) {

                        if(!newValue){
                            return;
                        }

                        var template = JSON.parse(newValue);
                        $scope.bee.start(template);
                    });

                    var beeConfig = {
                        uid: 'moodia-clientside',
                        container: 'bee-plugin-container',
                        autosave: 120,
                        language: 'de-DE',
                        specialLinks: specialLinks,
                        mergeTags: mergeTags,
                        mergeContents: mergeContents,
                        onSave: function(jsonFile, htmlFile) {
                            save(htmlFile, jsonFile);
                        },
                        onSaveAsTemplate: function(jsonFile) { // + thumbnail?
                            save('newsletter-template.json', jsonFile);
                        },
                        onAutoSave: function(jsonFile) { // + thumbnail?
                            console.log(new Date().toISOString() + ' autosaving...');
                            formAction = 'autosave';
                            $scope.bee.save();
                            changeState = false;
                            window.localStorage.setItem('newsletter.autosave', jsonFile);
                        },
                        onSend: function(htmlFile) {
                            //write your send test function here
                        },
                        onError: function(errorMessage) {
                            console.log('onError ', errorMessage);
                        },
                        onLoad: function(jsonFile){
                        }
                    };

                    /*
                    $scope.beeloadscreen = document.getElementById('bee-plugin-container');
                    var loadlistener = $scope.$watch('beeloadscreen', function(newVal, oldVal){
                        if($scope.beeloadscreen.firstElementChild) {
                            $scope.beeloadscreen.firstElementChild.style.backgroundColor = 'transparent';
                            loadlistener();
                        }
                    }, true);*/

                    var fireUpBee = function() {
                        // Send an AJAX request
                        console.log("Send an AJAX request");
                        $scope.started = true;
                        $.ajax({
                            method: "GET",
                            url: "/api/v1/core/bee/token"
                        }).done(function (data) {
                            console.log('auth done', data);
                            var token = data.token;
                            BeePlugin.create(JSON.parse(token), beeConfig, function (beePluginInstance) {
                                $scope.bee = beePluginInstance;
                                $.ajax({
                                    method: "GET",
                                    url: 'https://rsrc.getbee.io/api/templates/m-bee'
                                }).done(function (template) {
                                    var template = JSON.parse($scope.entity.jsonContent);
                                    $scope.bee.start(template);
                                    //console.log("template loaded");
                                    //bee.load(template);
                                });
                            });
                        });
                    };

                    $scope.docReady = false;
                    $scope.beeInitialised = false;

                    $(document).ready(function () {
                        $scope.docReady = true;

                        if ($scope.entity && !$scope.beeInitialised) {
                            fireUpBee();
                            $scope.beeInitialised = true;
                        }
                    });

                    $scope.$watch("entity", function(newValue, oldValue) {
                        console.log($scope.entity);
                        console.log($scope.docReady);

                        if (!newValue) {
                            return;
                        }

                        if ($scope.docReady && !$scope.beeInitialised) {
                            fireUpBee();
                            $scope.beeInitialised = true;
                        }
                    });


                }
            ];

            return {
                restrict: 'E',
                scope: {
                    entity: "=",
                    label: "="
                },
                templateUrl: BASE_TEMPLATES_PATH + "/beeWrapper/beeWrapper.html",
                controller: controller
            }
        };
        app.directive('beeWrapper', [ 'BASE_TEMPLATES_PATH', directive]);
    }
);
