/**
 * Created by mrafiq on 06.10.17.
 */
define('services/BeePluginService',[
    'app',
    'modules/module',
    'services/NotificationService',
    'services/LocalStorageService'
], function (app, module) {
    'use strict';
    return module.factory('BeePluginService', ['$q', '$rootScope', '$cookies', 'ModelFactory', 'NotificationService', 'LocalStorageService',
        function($q, $rootScope, $cookies, ModelFactory, NotificationService, LocalStorageService) {

            var setToken = function (token) {
                LocalStorageService.write('_beetoken', token);
            };

            return {
                getToken: function () {
                    return LocalStorageService.read("_beetoken");
                },
                auth: function () {
                    var ref = $q.defer();
                    ModelFactory.BeePlugin().getToken().$promise.then(function (response) {
                        if (response.token != undefined) {
                            setToken(response.token);
                        }else{
                            setToken(false);
                        }
                        ref.resolve();
                    }, NotificationService.notifyRequestError);
                    return ref.promise;
                }
            };

        }]);
});
