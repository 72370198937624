/**
 * Created by Nico on 05.07.2016.
 */
define('services/PaymentMethodService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('PaymentMethodService', ['$injector', PaymentMethodService

    ]);
});

function PaymentMethodService($injector) {

    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('PaymentMethod');

    angular.extend(this, crudInstance);

    return this;
};
