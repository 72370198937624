define('modules/crm/customer/controllers/CustomerController',[
    'modules/module',
    'modules/basemodule/controllers/EntityListController',
    'modules/crm/customer/controllers/CustomerBaseController'
	], 
	function (module) {
        'use strict';
		module.controller('CustomerController', [
            '$scope',
            '$controller',
            CustomerController
        ]);
    }
);


function CustomerController ($scope, $controller) {

    angular.extend(this, $controller('CustomerBaseController', {
        $scope: $scope
    }));

	$scope.formState = "root.customer";
    $scope.init('Customer').then(function(){});
};
