define('services/CacheService',[
    'app',
    'modules/module'
], function (app, module) {
    'use strict';
    return module.factory('CacheService', [
        '$injector',
        CacheService
    ]);
});

function CacheService($injector) {
    const model = $injector.get("ModelFactory").Cache();

    this.findAll = function() {
        return model.findAll().$promise;
    };
    
    this.clearAll = function() {
        return model.clearAll().$promise;
    };
    
    this.clear = function(name) {
        return model.clear({
            name: name
        }).$promise;
    };

    return this;
};
