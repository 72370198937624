define('modules/shippingGroup/controllers/ShippingGroupController',[
    'modules/module',
    'modules/basemodule/controllers/EntityListController',
    'settings'
	], 
	function (module) {
        'use strict';
		module.controller('ShippingGroupController', [
            '$scope',
            '$controller',
            'BASE_TEMPLATES_PATH',
            ShippingGroupController
        ]);
    }
);


function ShippingGroupController ($scope, $controller, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('EntityListController', {
        $scope: $scope
    }));

    $scope.tableOptions.fields = [
		{
            id: 'NAME',
            heading: 'Name',            
			attribute: 'name'
        },
		{
            id: 'NAME',
            heading: 'Verpackungseinheit',
            attribute: 'packageUnit'
        },
		{
            id: 'NAME',
            heading: 'Versandländer',
            template: BASE_TEMPLATES_PATH + '/shippingGroup/partials/shipping-group-countries-col.html'
        },
		{
            id: 'NAME',
            heading: 'Mengenstaffeln',
            template: BASE_TEMPLATES_PATH + '/shippingGroup/partials/shipping-group-quantity-scales-col.html'
        }
    ];

    $scope.formState = 'root.shippinggroup';
	
    $scope.init('ShippingGroup');
};
