define('modules/cms/controllers/GlossaryPageFormController',[
        'modules/module',
        'settings',
        'services/GlossaryPageService',
        'services/NavigationService',
        'services/CmsTagUnpagedService',
        'services/CmsTagService',
        'services/FilterPageUnpagedService',
        'modules/cms/controllers/CmsFormBaseController',
        'services/FilterPageService',
        'directives/formatDate/formatDate',
        'directives/controls/customInputTime/CustomInputTime'
    ],
    function (module) {
        'use strict';
        module.controller('GlossaryPageFormController', [
            '$scope',
            '$controller',
            'PublishStateService',
            'GlossaryPageService',
            'CmsTagUnpagedService',
            'CmsTagService',
            'FilterPageUnpagedService',
            'FilterPageService',
            'BASE_TEMPLATES_PATH',
            GlossaryPageFormController
        ]);
    }
);

function GlossaryPageFormController($scope, $controller,  PublishStateService, GlossaryPageService, CmsTagUnpagedService, CmsTagService, FilterPageService , FilterPageUnpagedService, BASE_TEMPLATES_PATH) {

    angular.extend(this, $controller('CmsFormBaseController', {
        $scope: $scope
    }));

    $scope.tag = {};

    $scope.formTabs = [
        {
            label: 'Allgemein',
            template: BASE_TEMPLATES_PATH + '/cms/partials/_form-glossary-pages.html',
            active: true
        },
        {
            label: 'Inhalt',
            template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-content.html'
        },
        {
            label: 'Tags',
            template: BASE_TEMPLATES_PATH + '/cms/partials/_form-content-pages-tags.html'
        },
        {
            label: 'SEO',
            template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-seo.html'
        }
    ];

    $scope.validationConfig = {
        publishStateId: {
            method: "isset",
            fieldName: "Satus"
        },
        name: {
            method: "isset",
            fieldName: "Name",
            isLocaleValue: true
        }
    };


    $scope.tagsTableOptions = {};
    $scope.tagsTableOptions.name = 'Tags';
    $scope.tagsTableOptions.field = {
        id: 'NAME',
        heading: 'Name',
        attribute: 'name'
    };

    $scope.tinymceOptions = {
        theme: 'modern',
        height: '500',
        convert_urls: false,
        relative_urls: false,
        remove_script_host: false,
        plugins: [
            'link advlist lists image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template paste textcolor colorpicker textpattern imagetools',
            'fullpage moodiaimage'
        ],
        menubar: "insert | tools",
        toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
        toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | fontselect | code',
        image_advtab: true
    };

    $scope.publishStates = [];
    $scope.tags = [];
    $scope.selectedItems = {};
    $scope.listState = 'root.glossaryPages';
    $scope.filterPages = [];
    $scope.entityType = "GlossaryPage";

    $scope.init("GlossaryPage").then(function () {

        FilterPageService.findAll(false).then(function () {
            $scope.filterPages = FilterPageService.getAll(true).data;
            //$scope.filterPages.unshift({name: 'Bitte auswählen'});
        });

        PublishStateService.findAll().then(function () {
            $scope.publishStates = PublishStateService.getAll();
        });
        CmsTagUnpagedService.findAll().then(function () {
            $scope.tags = CmsTagUnpagedService.getAll(true).data;
        });

        /* if($scope.entity.filterPageId == null || $scope.entity.filterPageId==undefined){
         $scope.entity.filterPageId=0;
         }*/
        if ($scope.newEntity) {
            $scope.entity.tagIds = [];
        } else {
        }

        $scope.options = [];
        var countLocales = 0;
        if ($scope.entity.locales != undefined) {
            for (var i = 0; i < $scope.entity.locales.length; i++) {
                if ($scope.entity.locales[i].seoInformation != null && $scope.entity.locales[i].seoInformation != undefined) {
                    if ($scope.entity.locales[i].seoInformation.keywords != undefined) {
                        if ($scope.entity.locales[i].seoInformation.keywords.length > countLocales) {
                            countLocales = $scope.entity.locales[i].seoInformation.keywords.length;
                        }
                    }
                }
            }
            for (var i = 0; i < countLocales; i++) {
                $scope.options.push({});
            }
        }
    });

    $scope.save = function () {
        if($scope.entity.filterPageId != 0){
            $scope.entity.slug="";
        }

    };

    $scope.addKeyword = function () {
        $scope.options.push({});
    };

    $scope.removeKeyword = function (index) {
        $scope.options.splice(index, 1);
    };

    $scope.saveTag = function () {
        if($scope.tag.locales[0].name != undefined && $scope.tag.locales[0].name != "") {
            CmsTagService.create($scope.tag).then(function () {
                CmsTagUnpagedService.findAll().then(function () {
                    $scope.tags = CmsTagUnpagedService.getAll(true).data;
                });
                $scope.tag = {};
            });
        }
    }
}
;
