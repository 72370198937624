/**
 * Created by Nico on 04.08.2016.
 */
define('modules/crm/organisation/controllers/OrganisationFormController',[
        'modules/module',
        'modules/basemodule/controllers/EntityFormController',
        'services/ValidationService',
        'services/OrderService',
        'settings'
    ],
    function (module) {
        'use strict';
        module.controller('OrganisationFormController', ['$scope', '$controller', 'ValidationService', 'OrderService', 'OrganisationService', 'BASE_TEMPLATES_PATH', 'CustomerService',
            function ($scope, $controller, ValidationService, OrderService, OrganisationService, BASE_TEMPLATES_PATH, CurstomerService) {

                angular.extend(this, $controller('CustomerBaseFormController', {
                    $scope: $scope
                }));

                $scope.formTabs[$scope.formTabs.length] = {
                    label: 'Mitarbeiter',
                    template: BASE_TEMPLATES_PATH + '/crm/customer/organisation-customer.html'
                };

                $scope.budgetPeriods = [{display: "Jährlich", value: "YEARLY"}, {display: "Monatlich", value: "MONTHLY"}];
                $scope.init("Organisation").then(function () {

                    if ($scope.entity.isOnlineAccount == undefined) {
                        $scope.entity.isOnlineAccount = false;
                    }

                    if ($scope.entity.email == undefined) {
                        $scope.entity.email = "";
                    }

                    if (!angular.isString($scope.entity.firstName)) {
                        $scope.entity.firstName = "";
                    }

                    if (!angular.isString($scope.entity.lastName)) {
                        $scope.entity.lastName = "";
                    }

                    if ($scope.entity.tickets !== undefined) {
                        for (var i = 0; i < $scope.entity.tickets.length; i++) {
                            if ($scope.entity.tickets[i].orderId != undefined) {
                                var orderId = $scope.entity.tickets[i].orderId;
                                $scope.data.orderIds.indexOf(orderId) === -1 ? $scope.data.orderIds.push(orderId) : "";
                            }
                        }

                        for (var i = 0; i < $scope.data.orderIds.length; i++) {
                            OrderService.findOne($scope.data.orderIds[i]).then(function () {
                                var order = OrderService.getOne();
                                $scope.data.orders.push({
                                    orderId: order.id,
                                    order: order
                                })
                            });
                        }
                    }

                    if (!$scope.entity.assignedCustomers) {
                        $scope.entity.assignedCustomers = [];
                    }

                    if(!$scope.entity.customerGroup) {
                        $scope.entity.customerGroup = {"id":1,"name":"Shopkunden","groupKey":"SK","useDiscount":false,"isDeletable":false};
                    }


                    if(!$scope.entity.customerGroup) {
                        $scope.entity.customerGroup = {"id":1,"name":"Shopkunden","groupKey":"SK","useDiscount":false,"isDeletable":false};
                    }

                    if(!$scope.entity.customerType){
                        $scope.entity.customerType = "CUSTOMER";
                    }

                    if(!$scope.entity.customerNumber)
                        $scope.entity.customerNumber = 3;
                    if(!$scope.entity.optInStatus)
                        $scope.entity.optInStatus = 0;

                    if(!$scope.entity.defaultInvoiceAddress)
                        $scope.entity.defaultInvoiceAddress = {};
                    if(!$scope.entity.defaultShippingAddress)
                        $scope.entity.defaultShippingAddress = {};

                    if(!$scope.entity.customerGroup)
                        $scope.entity.customerGroup = {};
                    if(!$scope.entity.socialMediaProfiles)
                        $scope.entity.socialMediaProfiles = [];
                    if(!$scope.entity.tickets)
                        $scope.entity.tickets = [];
                    if(!$scope.entity.assignedOrganisations)
                        $scope.entity.assignedOrganisations = [];
                    if(!$scope.entity.recipientLists)
                        $scope.entity.recipientLists = [];

                    if ($scope.entity.phones != undefined) {
                        for (var i in $scope.entity.phones) {
                            if ($scope.entity.phones[i].name == 'Telefon') {
                                $scope.phone.id = $scope.entity.phones[i].id;
                                $scope.phone.number = $scope.entity.phones[i].number;
                            } else if ($scope.entity.phones[i].name == 'Mobil') {
                                $scope.mobilPhone.id = $scope.entity.phones[i].id;
                                $scope.mobilPhone.number = $scope.entity.phones[i].number;
                            } else if ($scope.entity.phones[i].name == 'Fax') {
                                $scope.fax.id = $scope.entity.phones[i].id;
                                $scope.fax.number = $scope.entity.phones[i].number;
                            }
                        }
                    }

                    if ($scope.entity.contactTypes != undefined) {
                        for (var i in $scope.entity.contactTypes) {
                            if ($scope.entity.contactTypes[i].name == "PHONE") {
                                $scope.contact.phone = true;
                            } else if ($scope.entity.contactTypes[i].name == "MOBIL_PHONE") {
                                $scope.contact.mobil = true;
                            } else if ($scope.entity.contactTypes[i].name == "SMS") {
                                $scope.contact.sms = true;
                            } else if ($scope.entity.contactTypes[i].name == "FAX") {
                                $scope.contact.fax = true;
                            } else if ($scope.entity.contactTypes[i].name == "EMAIL") {
                                $scope.contact.email = true;
                            } else if ($scope.entity.contactTypes[i].name == "WHATSAPP") {
                                $scope.contact.whatsapp = true;
                            }
                        }
                    }
                    if ($scope.entity.addresses != undefined) {
                        for(var i in $scope.entity.addresses){
                            if($scope.entity.addresses[i].isDefaultInvoiceAddress) {
                                $scope.defaultInvoiceAddress = $scope.entity.addresses[i];
                            }
                            if($scope.entity.addresses[i].isDefaultShippingAddress) {
                                $scope.defaultShippingAddress = $scope.entity.addresses[i];
                            }
                        }
                    } else {
                        $scope.entity.addresses = [];
                    }

                    $scope.availableCustomers = [];
                    $scope.addAvailableCustomersFromEntity();
                });

                $scope.onCustomerSearch = function (event, searchTerm) {
                    CurstomerService.search(searchTerm).then(function (response) {
                        $scope.availableCustomers = [];
                        for (var i = 0; i < response.length; i++) {
                            var customer = response[i];
                            $scope.addCustomerSelectEntry(customer);
                        }
                        $scope.addAvailableCustomersFromEntity();
                    });
                };

                $scope.$on("beforeUpdate", function () {
                    $scope.formatDataForBackend();
                });

                $scope.$on("beforeCreate", function () {
                    $scope.formatDataForBackend();
                });

                $scope.$on("afterUpdate", function () {

                });

                $scope.$on("afterCreate", function () {

                });

                $scope.removeEmployee = function (index) {
                    $scope.entity.assignedCustomers.splice(index, 1);
                };

                $scope.addEmployee = function(){
                    $scope.entity.assignedCustomers.push({active: true});
                };

                $scope.listState = 'root.organisations';

            }

        ]);
    }
);
