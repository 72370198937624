/**
 * Created by nmayer on 13.06.16.
 */
define('modules/settings/currency/CurrencyFormController',[
        'modules/module',
        'modules/basemodule/controllers/EntityFormController',
        'services/ValidationService',
        'settings'
    ],
    function (module) {
        'use strict';
        module.controller('CurrencyFormController', ['$scope', '$controller', 'ValidationService', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, ValidationService, BASE_TEMPLATES_PATH) {

                angular.extend(this, $controller('EntityFormController', {
                    $scope: $scope
                }));

                $scope.formTabs = [
                    {
                        label: 'Allgemein',
                        template: BASE_TEMPLATES_PATH + '/settings/currency/form-main.html',
                        active: true
                    }
                ];
                $scope.init("Currency").then(function () {

                });

                $scope.listState = 'root.currencies';

                $scope.validate = function () {
                    return ValidationService.validate($scope.entity, {
                        name: {
                            method: "isset",
                            fieldName: "Name"
                        },
                        currencyIso: {
                            method: "isset",
                            fieldName: "ISO Code"
                        },
                        factor: {
                            method: "isset",
                            fieldName: "Faktor"
                        }
                    });
                };

            }
        ]);
    }
);
