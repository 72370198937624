define('app',[
        'angular',
        'uiRouter',
        'uiBootstrap',
        'modules/module',
        'cookies',
        'services/MockupService',
        'services/UserService',
        'services/StateFaderService',
        'services/NotificationService',
        'angular-ui-tinymce',
        'angular-ui-sortable',
        'angular-ui-select',
        'angular-bootstrap-colorpicker',
        'angular-nvd3',
        'text-sanitize',
        'angular-material',
        'angular-local-storage',
        'angular-ui-tree',
        'angular-css',
        'angular-chart',
        'chart',
        'tags-input',
        'angular-loading-spinner',
        'angular-loading-bar',
        'angular-translate',
        'angular-translate-loader-static-files',
        'angular-drag-drop',
        'jqueryUi',
        'content-editable',
        'datatables',
        'lightgallery',
        'lg-video',
    ],
    function(angular) {
        'use strict';

        var underscore = angular.module('underscore', []);
        underscore.factory('_', function() {
            return window._; //Underscore should be loaded on the page
        });

        var app = angular.module('app', [
            'ngCookies',
            'ui.router',
            'ui.bootstrap',
            'app.modules',
            'ui.tinymce',
            'ui.sortable',
            'ui.select',
            'colorpicker.module',
            'ngSanitize',
            'nvd3',
            'ngMaterial',
            'LocalStorageModule',
            'ui.tree',
            'ngTagsInput',
            'angularCSS',
            'chart.js',
            'ngTagsInput',
            'angularSpinner',
            'angular-loading-bar',
            'ngAnimate',
			'pascalprecht.translate',
            'filearts.dragDrop',
            'content-editable'
        ]);

        app.run([
            "$rootScope",
            "$state",
            "$stateParams",
            "MockupService",
            "UserService",
            "StateFader",
            "NotificationService",
            "localStorageService",
            function ($rootScope, $state, $stateParams, MockupService, UserService, StateFader, NotificationService, localStorageService) {

                $rootScope.isAdminEnd = true;
                $rootScope.$state = $state;
                $rootScope.$stateParams = $stateParams;
                if(!localStorageService.get("_cacheReloaded")) {
                    window.location.reload(true);
                    localStorageService.set("_cacheReloaded", true);
                }

                $rootScope.$on("$stateChangeStart", function(e, toState, toParams, fromState, fromParams) {
                    if(toState.name !== 'root.login' && toState.name != 'root.resetPassword' && !UserService.isLoggedIn()){
                        e.preventDefault();
                        StateFader.go('root.login');
                    }
                });

                $rootScope.isUserLoggedIn = function() {
                    return !UserService.isLoggedIn();
                };

                MockupService.init();
            }
        ]);

        return app;
    });

