define('services/EconRecipientListService',[
    'app',
    'modules/module',
    'services/EntityCrudService'
], function (app, module) {
    'use strict';
    return module.factory('EconRecipientListService', [
        '$injector',
        RecipientListService
    ]);
});

function RecipientListService($injector) {
    crudInstance = $injector.instantiate(CrudService);
    crudInstance.setType('RecipientList');

    angular.extend(this, crudInstance);

    return this;
}
;
