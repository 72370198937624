define('modules/navigation/controllers/FooterController',[
		'app',
		'angular',
        'modules/module',
	    'modules/models',
	    'services/UserService',
	    'services/ToolboxService'    
	], 
	function (module) {
        'use strict';
		module.controller('FooterController', ['$rootScope', '$scope', '$state', 'UserService', function ($rootScope, $scope, $state, UserService) {
        }]);
    }
);
