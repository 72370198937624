define('modules/navigation/controllers/NavigationController',[
    'modules/module',
    'services/NavigationService',
    'services/UserService',
    'services/NotificationService',
    'bootstrap'
],
        function (module) {
            'use strict';
            module.controller('NavigationController', [
                '$rootScope',
                '$q',
                '$scope',
                '$state',
                'NavigationService',
                'ShopService',
                'UserService',
                'ModelFactory',
                'NotificationService',
                NavigationController
            ]);
        }
);
function NavigationController($rootScope, $q, $scope, $state, NavigationService, ShopService, UserService, ModelFactory, NotificationService) {
    $scope.isCollapsed = false;
    $scope.showNavigation = true;
    $scope.navbar = NavigationService;
    $scope.stateName = $state.current.name;
    $scope.navbar.setActiveItemByStateName($scope.stateName);
    $scope.act_user = UserService.getUser();

    $scope.selectMainMenuItem = function (item) {
        if (item.subMenu) {
            if (item.subMenu[0].states) {
                $scope.navbar.changeState(item.subMenu[0].states[0]);
            }
        } else if (item.states != undefined) {
            $scope.navbar.changeState(item.states[0]);
        }
    };

    $scope.getShops = function () {
        var deferred = $q.defer();
        ModelFactory.Shop().findAll().$promise.then(function (response) {
            $scope.shops = response.data;
            deferred.resolve();
        }, NotificationService.notifyRequestError);
        return deferred.promise;
    };

    $scope.unregisterHide = $rootScope.$on("hideNavigation", function (event, data) {
        $scope.showNavigation = false;
    });

    $scope.unregisterShow = $rootScope.$on("showNavigation", function (event, data) {
        $scope.showNavigation = true;
        $scope.getShops();
        /*$scope.shops = UserService.getShops();
         console.log($scope.shops);
         if($scope.shops.length < 1) {
         console.log("test");
         ShopService.findAll().then(function () {
         $scope.shops = ShopService.getAll(true);
         });
         }*/
    });

    $scope.unregisterShopsChanged = $rootScope.$on("shopsChanged", function (event, data) {
        $scope.getShops();
    });

    if (UserService.isLoggedIn()) {
        //Getting the shops on a refresh
        $scope.getShops();
        /*console.log("isloggedin");
         $scope.shops = UserService.getShops();
         console.log("$scope.shops");
         console.log($scope.shops);
         if($scope.shops.length < 1) {
         ShopService.findAll().then(function () {
         $scope.shops = ShopService.getAll(true);
         });
         }*/
    }

    $scope.$on('$destroy', function () {
        //will be called on controller destroy
        $scope.unregisterHide();
        $scope.unregisterShow();
        $scope.unregisterShopsChanged();
    });

    $scope.setShop = function (shop) {
        UserService.setShop(shop.id);
        UserService.setLocale(shop.defaultLocaleId);
        $rootScope.$emit('ShopChanged');
        location.reload();
        // window.location.href= "/"; //This hard reload is needed. Otherwise the shop id in the header will not change.
    };

    $scope.getShopName = function () {
        var shopId = UserService.getShop();
        for (var key in $scope.shops) {
            if ($scope.shops[key].id == shopId) {
                return $scope.shops[key].name;
            }
        }
    };
}
;
