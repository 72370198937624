define(
    'directives/listSelectAmount/ListSelectAmount',[
        'app',
        'settings'
    ],
    function (app) {
        'use strict';
        app.directive('ngListSelectAmount', [
            'BASE_DIRECTIVES_PATH',
            ngListSelectAmount
        ]);
    }
);
function ngListSelectAmount(BASE_DIRECTIVES_PATH) {
    return{
        scope: {
            entityService: "=",
            selectedItems: "=",
            itemIds: "=",
            fields: "=",
            name: "="
        },
        templateUrl: BASE_DIRECTIVES_PATH + "/listSelectAmount/views/view.html",
        controller: [
            '$scope',
            NgListSelectAmountController
        ]
    };
}
function NgListSelectAmountController($scope) {

    $scope.query = {};
    $scope.query.filters = {};
    $scope.firstPageLoaded = false;

    $scope.$watch("items", function(newValue, oldValue) {
        if($scope.firstPageLoaded || !$scope.entityService){
            return;
        }
        $scope.entityService.setCurrentPage(0).then(function () {
            $scope.items = $scope.entityService.getAll();
            $scope.totalItems = $scope.entityService.getTotalItems();
        });
        $scope.firstPageLoaded = true;
    });

    $scope.searchInputChanged = function () {
        if($scope.filterTimer){
            clearTimeout($scope.filterTimer);
        }

        var textfield = this;
        $scope.filterTimer = setTimeout(function () {
            $scope.query.filters[textfield.field.attribute] = textfield.fieldModel;
            $scope.entityService.setQuery($scope.query).then(function () {
                $scope.items = $scope.entityService.getAll();
                $scope.totalItems = $scope.entityService.getTotalItems();
            });
        }, 500);
    };

    $scope.listSelectPageChanged = function() {
        $scope.entityService.setCurrentPage($scope.selectedPage - 1).then(function () {
            $scope.items = $scope.entityService.getAll();
        });
    };

    $scope.$watch("items", function(newValue, oldValue) {
        if(newValue === undefined || newValue == null) {
            return;
        }
        $scope.selectItems();
    });


    $scope.selectItems = function () {
        if(!$scope.selectedItems){
            console.error("The attribute selected-items='X' is not set. Please set it to use the list control.");
            return;
        }

        for (var i = 0; i < $scope.items.length; i++) {
            var item = $scope.items[i];

            for(var j = 0 ;j < $scope.selectedItems.length; j++){
                var selectedItem = $scope.selectedItems[j];
                if (item.id == selectedItem.id) {
                    if (!item[$scope.name]) {
                        item[$scope.name] = {};
                    }
                    item[$scope.name].selected = false;
                    item[$scope.name].added = true;
                    item.amount = $scope.itemIds[i].amount;
                }
            }
        }
    };

    $scope.addItems = function () {
        for(var i = 0; i < $scope.items.length; i++) {
            if($scope.items[i][$scope.name] && $scope.items[i][$scope.name].selected && !$scope.items[i][$scope.name].added && $scope.items[i].amount > 0) {
                //TODO NUR kleines OBJECT
                var tmp = {};
                tmp.amount = $scope.items[i].amount;
                tmp.basePrice = $scope.items[i].basePrice;
                tmp.sku = $scope.items[i].sku;
                tmp.name = $scope.items[i].name;
                tmp.productId = $scope.items[i].id;
                tmp[$scope.name] = {};

                $scope.selectedItems.push(tmp);

                $scope.items[i][$scope.name].added = true;
                $scope.items[i][$scope.name].selected = false;
              /*  $scope.itemIds.push({
                    productId: $scope.items[i].id,
                    amount: $scope.items[i].amount
                });*/


            }
        }
    };

    $scope.removeItems = function () {
        for(var i = 0; i < $scope.selectedItems.length; i++) {
            if($scope.selectedItems[i][$scope.name] && $scope.selectedItems[i][$scope.name].selected) {
                for(var j = 0; j < $scope.items.length; j++) {
                    if($scope.items[j].id == $scope.selectedItems[i].productId) {
                        $scope.items[j][$scope.name].added = false;
                        $scope.items[j][$scope.name].selected = false;
                    }
                }
                for(var k = 0; k < $scope.itemIds.length; k++) {
                    if ($scope.itemIds[k].productId  == $scope.selectedItems[i].productId) {
                        $scope.itemIds.splice(k, 1);
                    }
                }

                $scope.selectedItems.splice(i, 1);
                i--;
            }
        }
    };

    $scope.itemsLeftSelected = function () {
        if($scope.items != undefined) {
            for(var i = 0; i < $scope.items.length; i++) {
                if($scope.items[i][$scope.name] && $scope.items[i][$scope.name].selected && !$scope.items[i][$scope.name].added && $scope.items[i].amount > 0) {
                    return true;
                }
            }
            return false;
        }
        return false;
    };

    $scope.itemsRightSelected = function () {
        for(var i = 0; i < $scope.selectedItems.length; i++) {
            if($scope.selectedItems[i][$scope.name] && $scope.selectedItems[i][$scope.name].selected) {
                return true;
            }
        }
        return false;
    };


    $scope.updateEntity = function () {
        for(var i = 0; i < $scope.selectedItems.length; i++) {
            for(var j = 0; j < $scope.itemIds.length; j++) {
                if($scope.selectedItems[i].id == $scope.itemIds[j].productId) {
                    $scope.itemIds[j].amount = $scope.selectedItems[i].amount;
                }
            }
        }
    };

};
