define('services/ShipmentService',[
    'app',
    'modules/module',
    'services/NotificationService',
    'services/EntityCrudService'

], function (app, module) {
    'use strict';
    return module.factory('ShipmentService', ['$q', '$rootScope', '$cookies', '$injector', 'ModelFactory', 'NotificationService',
        function($q, $rootScope, $cookies, $injector, ModelFactory, NotificationService) {

            var crudInstance = $injector.instantiate(CrudService);
            angular.extend(this, crudInstance);
            this.setType('Shipment');

            var _shipments = [],
            _totalPages,
            _totalItems,
            _shipment;

        if($cookies.get('_shipmentPageSize') === undefined) {
            $cookies.put('_shipmentPageSize', 10);
        }

        if($cookies.get('_shipmentCurrentPage') === undefined) {
            $cookies.put('_shipmentCurrentPage', 0 );
        }

        var loadShipments = function () {
            var ref = $q.defer();
            ModelFactory.Shipment.findAll({
                page: $cookies.get('_shipmentCurrentPage'),
                size: $cookies.get('_shipmentPageSize')
            }).$promise.then(function (response) {
                _shipments = response.content;
                $cookies.put('_shipmentCurrentPage', response.actualPage);
                _totalPages = response.totalPages;
                _totalItems = response.totalElements;
                ref.resolve();
                $rootScope.$broadcast('ShipmentsChanges', _shipments);
            }, NotificationService.notifyRequestError);
            return ref.promise;
        };

        var loadShipmentsWithoutPagination = function () {
            var ref = $q.defer();
            ModelFactory.Shipment.findAll().$promise.then(function (response) {
                _shipments = response.content;
                ref.resolve();
            }, NotificationService.notifyRequestError);
            return ref.promise;
        };

            this.getShipments = function(){
                return _shipments;
            };

            this.getTag = function(){
                return _shipment;
            };

            this.loadShipments = function(withPagination){
                if(withPagination !== false){
                    return loadShipments();
                } else {
                    return loadShipmentsWithoutPagination();
                }
            };

            this.loadShipment = function(id) {
                var ref = $q.defer();
                ModelFactory.Shipment.findOne({name: id}).$promise.then(function (response) {
                    _shipment = response;
                    ref.resolve();
                }, NotificationService.notifyRequestError);
                return ref.promise;
            };

            this.saveShipment = function(shipment) {
                var ref = $q.defer();
                ModelFactory.Shipment.save(shipment).$promise.then(function (response) {
                    ref.resolve();
                }, NotificationService.notifyRequestError);
                return ref.promise;
            };

            this.saveAllShipments = function (shipments) {
                var ref = $q.defer();
                ModelFactory.Shipment.saveAll({shipments: shipments}).$promise.then(function (response) {
                    ref.resolve();
                }, NotificationService.notifyRequestError);
                return ref.promise;
            };

            this.deleteShipment = function(id) {
                var ref = $q.defer();
                ModelFactory.Shipment.delete({id: id}).$promise.then(function(response) {
                    ref.resolve();
                }, NotificationService.notifyRequestError);
                return ref.promise;
            };

            return this;

    /*    return {
            getCurrentPage: function () {
                return (parseInt($cookies.get('_shipmentCurrentPage')) + 1);
            },
            getTotalPages: function () {
                return _totalPages;
            },
            getTotalItems: function () {
                return _totalItems;
            },
            getPageSize: function () {
                return $cookies.get('_shipmentPageSize');
            },
            setPageSize: function (pageSize) {
                $cookies.put('_shipmentPageSize', pageSize);
                loadShipments();
            },
            setCurrentPage: function (currentPage) {
                $cookies.put('_shipmentCurrentPage', currentPage);
                loadShipments();
            },
            getShipments: function(){
                return _shipments;
            },
            getTag: function(){
                return _shipment;
            },
            loadShipments: function(withPagination){
                if(withPagination !== false){
                    return loadShipments();
                } else {
                    return loadShipmentsWithoutPagination();
                }
            },
            loadShipment: function(id) {
                console.log('loading Tag ' + id);
                var ref = $q.defer();
                ModelFactory.Shipment.findOne({name: id}).$promise.then(function (response) {
                    _shipment = response;
                    ref.resolve();
                }, NotificationService.notifyRequestError);
                return ref.promise;
            },
            saveShipment: function(shipment) {
                var ref = $q.defer();
                ModelFactory.Shipment.save(shipment).$promise.then(function (response) {
                    ref.resolve();
                }, NotificationService.notifyRequestError);
                return ref.promise;
            },
            saveAllShipments: function (shipments) {
                var ref = $q.defer();
                ModelFactory.Shipment.saveAll({shipments: shipments}).$promise.then(function (response) {
                    ref.resolve();
                }, NotificationService.notifyRequestError);
                return ref.promise;
            },
            deleteShipment: function(id) {
                var ref = $q.defer();
                ModelFactory.Shipment.delete({id: id}).$promise.then(function(response) {
                    ref.resolve();
                }, NotificationService.notifyRequestError);
                return ref.promise;
            }
        }*/
    }]);
});
