/**
 * Created by gdoi on 21.01.19.
 */
define('modules/settings/log/LogController',[
        'modules/module',
        'settings',
        'modules/basemodule/controllers/EntityListController'
    ],
    function (module) {
        'use strict';
        module.controller('LogController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH',
            function ($scope, $controller, BASE_TEMPLATES_PATH) {

            angular.extend(this, $controller('EntityListController', {
                $scope: $scope
            }));

            $scope.hideAddDeleteButtons = true;

            $scope.tableOptions.fields = [
                {
                    id: 'CREATED_AT',
                    heading: 'Created-At',
                    template: BASE_TEMPLATES_PATH + '/settings/log/col-create-date.html',
                    attribute: 'createdAt',
                    searchDisabled: true
                },
                {
                    id: 'LOG_ACTION',
                    heading:    'Log-Action',
                    attribute:  'logAction'
                },
                {
                    id: 'MESSAGE',
                    heading:    'Message',
                    template: BASE_TEMPLATES_PATH + '/settings/log/col-message.html'
                },
                {
                    id: 'USER_SUMMARY',
                    heading:    'User Summary',
                    template: BASE_TEMPLATES_PATH + '/settings/log/col-user-summary.html'
                },
            ];

            $scope.tableOptions.optionalFields = [
                {
                    id: 'EMAIL',
                    heading:    'E-Mail',
                    attribute:  'email'
                },
                {
                    id: 'FIRST_NAME',
                    heading:    'Vorname',
                    attribute:  'firstName'
                },
                {
                    id: 'LAST_NAME',
                    heading:    'Nachname',
                    attribute:  'lastName'
                },
                {
                    id: 'IP_ADDRESS',
                    heading:    'IP-Address',
                    attribute:  'ipAddress'
                },
            ];

            //$scope.formState = 'root.logs';

            $scope.init('Log');
        }]);
    }
);



