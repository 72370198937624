define('services/DashboardService',[
    'app',
    'modules/module',
    'services/EntityCrudUnpagedService'
], function (app, module) {
    'use strict';
    return module.factory('DashboardService', [
        '$injector',
        DashboardService
    ]);
});

function DashboardService($injector) {

    crudInstance = $injector.instantiate(EntityCrudUnpagedService);
    crudInstance.setType('Dashboard');

    angular.extend(this, crudInstance);
    
    return this;
};
