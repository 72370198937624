define('services/ValidationService',[
    'app',
    'modules/module'
], function (app, module) {
    'use strict';
    return module.factory('ValidationService', ['$q', '$rootScope', 'SessionValuesService',
        function ($q, $rootScope, SessionValuesService) {

            var _self = this;

            this.errors = [];

            this.isset = function (attributeName, attributeValue, fieldName, errorMsg) {

                var failed = this.isUndefined(attributeValue);

                if (!failed && Array.isArray(attributeValue)) {
                    failed = _self.isEmptyArray(attributeValue);
                }
                if (!failed && !Array.isArray(attributeValue)) {
                    failed = _self.isEmptyString(attributeValue);
                }
                if (failed && errorMsg === undefined) {
                    this.addError(fieldName, "Das Feld \"" + fieldName + "\" kann nicht leer sein");
                }
                if (failed && errorMsg !== undefined) {
                    this.addError(fieldName, errorMsg);
                }
                return failed;
            };

            this.isNumeric = function (attributeName, attributeValue, fieldName, errorMsg) {
                if (isNaN(attributeValue)) {
                    if (errorMsg === undefined) {
                        this.addError(fieldName, "Das Feld \"" + fieldName + "\" muss einen numerischen Wert haben.");
                    } else {
                        this.addError(fieldName, errorMsg);
                    }
                    return true;
                }
                return false;
            };

            this.isEmail = function (attributeName, attributeValue, fieldName, errorMsg) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!re.test(attributeValue)) {
                    if (errorMsg === undefined) {
                        this.addError(fieldName, "Das Feld \"" + fieldName + "\" muss eine gültige E-Mail Adresse sein.");
                    } else {
                        this.addError(fieldName, errorMsg);
                    }
                    return true;
                }
                /*if (attributeValue.indexOf("@") == -1) {
                    if (errorMsg === undefined) {
                        this.addError(fieldName, "Das Feld \"" + fieldName + "\" muss eine gültige E-Mail Adresse sein.");
                    } else {
                        this.addError(fieldName, errorMsg);
                    }
                    return true;
                }*/
                return false;
            };

            this.isUndefined = function (attr) {
                return (attr === undefined || attr === null);
            };

            this.isEmptyArray = function (arr) {
                return (arr.length === 0);
            };

            this.isEmptyString = function (str) {
                if (typeof str == "string") {
                    return (str.trim().length === 0);
                } else {
                    return false;
                }
            };

            this.addError = function (attributeName, errorMsg) {
                _self.errors.push({
                    attributeName: attributeName,
                    errorMsg: errorMsg
                });
            };

            this.GetFieldName = function (attributeName, fieldName) {
                if (fieldName !== undefined) {
                    return fieldName;
                }
                return attributeName;
            };

            this.checkRule = function (attributes, rule, rules, value) {
                var methodName = rules[rule].method;
                var fieldName = _self.GetFieldName(rule, rules[rule].fieldName);
                var errorMsg = rules[rule].errorMsg;
                var res;
                if (rules[rule].customValidate) {
                    res = !rules[rule].customValidate(attributes);
                    if (res) {
                        _self.addError(fieldName, errorMsg);
                    }
                } else {
                    res = _self[methodName](rule, value, fieldName, errorMsg);
                }
                return res;
            };

            return {
                validate: function (attributes, rules) {
                    _self.errors = [];
                    var failed = false;
                    var messageAdded = false;
                    for (var varName in rules) {
                        var defaultLocaleId = SessionValuesService.getLocale();
                        if (rules[varName].isLocaleValue) {
                            for (var i = 0; i < attributes.locales.length; i++) {
                                var localeObj = attributes.locales[i];
                                var name = localeObj.name;
                                if (name == undefined) {
                                    for (var key in localeObj) {
                                        if (key != "localeId" && localeObj[key] != "") {
                                            if (!messageAdded) {
                                                _self.addError("Name", "Wenn Werte in einer Sprache ausgefüllt wurden, so ist in dieser Sprache der Name ein Pflichtfeld.");
                                                messageAdded = true;
                                            }
                                            failed = true;
                                        }
                                    }
                                }
                                if (localeObj.localeId == defaultLocaleId) {
                                    var value = attributes.locales[i][varName];
                                    var res = _self.checkRule(attributes, varName, rules, value);
                                    failed = (failed || res);
                                }
                            }
                        } else {
                            var value = attributes[varName];
                            var res = _self.checkRule(attributes, varName, rules, value);
                            failed = (failed || res);
                        }
                    }
                    return !failed;
                },
                getErrors: function () {
                    return _self.errors;
                }
            };

        }]);
});
